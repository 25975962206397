import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import Map, { Marker, Popup, Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { AgeIcon, AppLogo, DismissTagIcon, DistanceIcon, DogIcon, ElevationIcon, EstimationIcon, GameIcon, LinkIcon, LocationIcon, MarkerIcon, NodeIcon, PlayIcon, RouteIcon, SearchIcon, SelectIcon, StarIcon, StrollerIcon, TimerIcon, WheelchairIcon } from './Icons';

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import { dataLayer, textLayer, iconLayer, nodeLayer } from './MapStyle';

import Geocoder from './Geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import Select from './Select';
import { Link, useNavigate } from 'react-router-dom';
import SpringScrollbars from './SpringScrollbars';

import { isMobile } from 'react-device-detect';
import mapboxgl from 'mapbox-gl';

function ChannelMap(props) {

    const navigate = useNavigate();

    const scrollView = useRef(null);

    const mapContainer = useRef(null);
    const mapView = useRef(null);
    const [popupInfo, setPopupInfo] = useState(null);

    const [startInfo, setStartInfo] = useState(null);
    const [endInfo, setEndInfo] = useState(null);
    const [currentInfo, setCurrentInfo] = useState(null);

    const contentHeight = useRef(null);

    const [cursor, setCursor] = useState('auto');

    const mapStyles = {
        satellite: "mapbox://styles/mapbox/satellite-v9",
        wandelar: "mapbox://styles/maeroplane/clbcaemy7000a14nn8eo6o60b"
    }
    const [mapStyle, setMapStyle] = useState(mapStyles.wandelar);
    const [toggleStyles, setToggleStyles] = useState(true);

    const [hoverInfo, setHoverInfo] = useState(null);

    const [scrollHeight, setScrollHeight] = useState(0);

    const [isCollapsed, setIsCollapsed] = useState(true);

    let startPoint = props.geoData === null ? [0, 0] : props.geoData.features[0].geometry.coordinates;
    const [center, setCenter] = useState([startPoint[0], startPoint[1]]);

    const [pinLng, setPinLng] = useState(0);
    const [pinLat, setPinLat] = useState(0);

    const [isSearch, setIsSearch] = useState(false);
    const [isSearchOffset, setIsSearchOffset] = useState(0);

    const [_customLayer, setCustomLayer] = useState(null);

    const [togglePitch, setTogglePitch] = useState(false);
    const [dragPan, setDragPan] = useState(true);
    const [doubleClickZoom, setDoubleClickZoom] = useState(true);

    const [minPitch, setMinPitch] = useState(0);
    const [maxPitch, setMaxPitch] = useState(85);

    const [arrowTexture, setArrowTexture] = useState(null);

    function toggleMapStyle() {
        setToggleStyles(!toggleStyles);

        if (toggleStyles) {
            setMapStyle(mapStyles.satellite)
        } else {
            setMapStyle(mapStyles.wandelar)
        }
    }

    function toggleSearch() {
        setIsSearch(!isSearch);

        if (isSearch) {
            setIsSearchOffset(0);
        } else {
            setIsSearchOffset(60);
        }
    }

    function fitBounds(minLng, minLat, maxLng, maxLat) {
        mapView.current.fitBounds(
            [
                [minLng, minLat],
                [maxLng, maxLat]
            ],
            { padding: { top: 150, bottom: 250, left: 40, right: 40 }, duration: 2000 }
        );
    }

    function calculateExtremeCoords(coordinates) {
        let minX = Number.POSITIVE_INFINITY;
        let minY = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;

        coordinates.forEach(([x, y]) => {
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
        });

        return [
            extremeTopLeft(minX, minY),
            extremeBottomRight(maxX, maxY)
        ];
    };

    function extremeTopLeft(longitude, latitude) {
        let topCoordinate = latitude;
        let leftCoordinate = longitude;
        return [leftCoordinate, topCoordinate];
    }

    function extremeBottomRight(longitude, latitude) {
        let bottomCoordinate = longitude;
        let rightCoordinate = latitude;
        return [bottomCoordinate, rightCoordinate];
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const onMouseEnter = useCallback(() => setCursor('pointer'), []);
    const onMouseLeave = useCallback(() => setCursor('grab'), []);

    function mapEaseTo(pitch, isCameraRotating) {
        mapView.current.easeTo({ pitch: pitch, duration: 2000 })

        mapView.current.once('moveend', () => {
            // if (isCameraRotating) {
            //     // rotateCamera(0)
            //     setMaxPitch(75);
            //     setMinPitch(75);
            // } else {
            //     // rotateCamera(null)
            // }

        })
    }

    const onDragStart = useCallback(event => {
        // rotateCamera(null);
    })

    const onDrag = useCallback(event => {
        // console.log('drag')
        // console.log(event)

        // let rotate = 0;
        // if (!dragPan) {
        //     mapView.current.getMap().rotateTo((rotate / 200) % 360, { duration: 0 });
        // }
    })

    const onClick = useCallback(event => {

        const {
            features,
            point: { x, y }
        } = event;

        if (features && features[0]) {
            const getRoute = fetch('https://wandelar.be/api/routes/' + features[0].properties.id, {
                method: 'GET',
                credentials: 'same-origin'
            }).then(response => {
                return response.json();
            }).then(result => {

                const hoveredFeature = features[0];
                const hoveredCheckpoints = result.checkpoints ? result.checkpoints : [];
                const hoveredElevation = result.elevation ? result.elevation : [];
                const hoveredArea = result.area ? result.area : [];
                const hoveredNodes = {
                    "features": result.nodes ? result.nodes : [],
                    "type": "FeatureCollection"
                };
                const hoveredTheme = result.theme ? result.theme : null;

                setHoverInfo(hoveredFeature && {
                    feature: hoveredFeature,
                    x,
                    y,
                    checkpoints: hoveredCheckpoints,
                    nodes: hoveredNodes,
                    elevation: hoveredElevation,
                    area: hoveredArea,
                    id: result._id,
                    address: result.address,
                    age: result.age,
                    description: result.description,
                    name: result.name,
                    distance: result.distance,
                    dog: result.dog,
                    estimation: result.estimation,
                    qrcode: result.qrcode,
                    stroller: result.stroller,
                    tags: result.tags,
                    wheelchair: result.wheelchair,
                    url: result.url,
                    time: result.time,
                    theme: hoveredTheme
                });

                let bounds = [];
                for (var i = 0; i < hoveredCheckpoints.length; i++) {
                    let coordinates = [...hoveredCheckpoints[i].geo.coordinates];
                    coordinates.reverse();
                    bounds.push(coordinates);
                }

                let extremeBounds = calculateExtremeCoords(bounds);
                fitBounds(extremeBounds[0][0], extremeBounds[0][1], extremeBounds[1][0], extremeBounds[1][1]);

                if (mapView.current.getMap().getLayer('3d-model')) {
                    mapView.current.getMap().removeLayer('3d-model');
                    setCustomLayer(null);
                }

                if (hoveredElevation.terrain && hoveredElevation.terrain.length > 0) {
                    setModelLayer(event, hoveredElevation, hoveredNodes);
                    
                    // mapView.current.once('moveend', () => {
                    //     mapEaseTo(75, true);
                    // })

                } else {
                    // rotateCamera(null);
                    // mapView.current.once('moveend', () => {
                    //     mapEaseTo(0, false)
                    // })
                    setTogglePitch(false);
                }

            });
        } else {
            setTogglePitch(false);
            setHoverInfo(null);
        }

    }, []);

    let requestId;

    function rotateCamera(timestamp) {

        // console.log(mapView.current.getMap().getFreeCameraOptions())

        if (timestamp !== null) {
            mapView.current.getMap().rotateTo((timestamp / 200) % 360, { duration: 0 });
            requestId = requestAnimationFrame(rotateCamera);
        } else {
            cancelAnimationFrame(requestId);
        }
    }

    const onLoad = useCallback(map => {

        mapView.current.loadImage('/img/wandelar-pin.png', (error, image) => {
            if (error) throw error;
            if (!mapView.current.hasImage('map-pin')) mapView.current.addImage('map-pin', image);
        });

        mapView.current.loadImage('/img/wandelar-pin-empty.png', (error, image) => {
            if (error) throw error;
            if (!mapView.current.hasImage('map-pin-empty')) mapView.current.addImage('map-pin-empty', image);
        });

        if (props.routeId) {

            mapView.current.flyTo({
                center: [props.geoData.selected.coordinates[1], props.geoData.selected.coordinates[0]],
                duration: 4000,
                zoom: 16
            });

            // mapView.current.once('moveend', () => {
            //     const point = mapView.current.project([props.geoData.selected.coordinates[1], props.geoData.selected.coordinates[0]]);
            //     console.log(parseInt(point.x.toFixed(0)))
            //     console.log(parseInt(point.y.toFixed(0)))

            //     event.target.fire('click', {
            //         lngLat: {
            //             lng: props.geoData.selected.coordinates[1],
            //             lat: props.geoData.selected.coordinates[0]
            //         },
            //         point: {
            //             x: parseInt(point.x.toFixed(0)),
            //             y: parseInt(point.y.toFixed(0))
            //         }
            //     })
            // })

        }

    });

    function formatAge(ageRange) {
        let result = "";
        if (ageRange.min !== null && ageRange.max == null) {
            result = "+";
        }
        if (ageRange.min != null) {
            result += `${ageRange.min}`;
        }
        if (ageRange.min !== null && ageRange.max == null) {
            result += `${props.translations[props.lang].filter.year}`;
        }
        if (ageRange.max != null) {
            if (ageRange.min != null) {
                result += "-";
            }
            result += `${ageRange.max}${props.translations[props.lang].filter.year}`;
        }
        if (ageRange.min == null && ageRange.max == null) {
            result = "+";
        }
        return result;
    }

    function formatDuration(seconds) {
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);
        return `${hours.toString().padStart(2, "0")}h${minutes.toString().padStart(2, "0")}m`;
    }

    function toggleCollapse() {
        setIsCollapsed(!isCollapsed);
        if (isCollapsed) {
            setScrollHeight(window.innerHeight - 200)
        } else {
            setScrollHeight(0)
        }
    }

    function onDeepLink(routeId) {
        let url = `https://wandelar.be/invite/${routeId}`;
        window.location.href = url;
    }

    function onDeepLinkIndex(routeId, index) {
        let url = `https://wandelar.be/invite/${routeId}`;
        // let url = `https://wandelar.be/invite/${routeId}/${index}`;
        window.location.href = url;
    }

    var mouse = { x: 0, y: 0 };
    var INTERSECTED;

    var sprite1;
    var label_top;
    var label_bottom;
    var label_current;
    var canvas1, context1, texture1;
    var canvas_top, context_top, texture_top;
    var canvas_bottom, context_bottom, texture_bottom;
    var canvas_arrow, context_arrow, texture_arrow;
    var canvas_gradient, context_gradient, texture_gradient;

    function printAtWordWrap(context, text, x, y, lineHeight, fitWidth) {
        fitWidth = fitWidth || 0;

        if (fitWidth <= 0) {
            context.fillText(text, x, y);
            return;
        }
        var words = text.split('\n');
        // var currentLine = 0;
        // var idx = 1;
        var canvasWidth = context.canvas.width;
        // var textWidth = context.measureText(text).width;
        var y_width = (context.canvas.height / 2) + ((lineHeight) / 2);

        for (var i = 0; i < words.length; i++) {
            var word = words[i];
            var word_width = context.measureText(word).width;
            context.fillText(word, (canvasWidth - word_width) / 2, y_width + ((lineHeight * i) / 2));
        }
    }

    function printAtWordWrapCenter(context, text, x, y, lineHeight, fitWidth) {

        fitWidth = fitWidth || 0;

        if (fitWidth <= 0) {
            context.fillText(text, x, y);
            return;
        }
        var words = text.split('\n');
        var currentLine = 0;
        var idx = 1;
        var canvasWidth = context.canvas.width;
        var textWidth = context.measureText(text).width;
        var x_width = (canvasWidth - textWidth) / 2;
        var y_width = (context.canvas.height / 2) + ((lineHeight * currentLine) / 2);
        while (words.length > 0 && idx <= words.length) {
            var str = words.slice(0, idx).join(' ');
            var w = context.measureText(str).width;
            if (w > fitWidth) {
                if (idx == 1) {
                }
                if (words.length === 1) {
                    context.fillText(words.slice(0, idx - 1).join(' '), x_width, y_width + (lineHeight / 4));
                } else {
                    context.fillText(words.slice(0, idx - 1).join(' '), x_width, y_width + ((lineHeight * currentLine) / 2));
                }
                currentLine++;
                words = words.splice(idx - 1);
                idx = 1;
            }
            else { idx++; }
        }
        if (idx > 0) {
            if (words.length === 1) {
                context.fillText(words.join(' '), x_width, y_width + (lineHeight / 4));
            } else {
                context.fillText(words.join(' '), x_width, y_width + ((lineHeight * currentLine) / 2));
            }
        }
    }

    function getClosestCoordinate(coordinates, point) {
        let closestCoordinate = coordinates[0];
        let closestDistance = Infinity;

        coordinates.forEach(coordinate => {
            let R = 6371e3; // Earth's radius in meters
            let φ1 = point.lat * (Math.PI / 180);
            let φ2 = coordinate.lat * (Math.PI / 180);
            let Δφ = (coordinate.lat - point.lat) * (Math.PI / 180);
            let Δλ = (coordinate.lng - point.lng) * (Math.PI / 180);
            let a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                Math.cos(φ1) * Math.cos(φ2) *
                Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
            let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            let d = R * c; // distance in meters
            if (d < closestDistance) {
                closestDistance = d;
                closestCoordinate = coordinate;
            }
        });

        return closestCoordinate;
    }

    const THREE = window.THREE;

    function calculateFaces(vertexIndices) {
        const faces = [];
        for (let i = 0; i < vertexIndices.length; i += 3) {
            const face = [vertexIndices[i], vertexIndices[i + 1], vertexIndices[i + 2]];
            faces.push(face);
        }
        return faces;
    }

    function calcDistance(x, y) {
        return Math.sqrt(x * x + y * y);
    }

    function splitArray(arr) {
        const midPoint = Math.floor(arr.length / 2);
        let leftArray = arr.slice(0, midPoint);
        let rightArray = arr.slice(midPoint);

        return [leftArray, rightArray];
    }

    function calcDistance(v1, v2) {
        let xDiff = v2[0] - v1[0];
        let yDiff = v2[1] - v1[1];
        let zDiff = v2[2] - v1[2];
        return Math.sqrt(xDiff * xDiff + yDiff * yDiff + zDiff * zDiff);
    }

    function flattenCylindricalModel(vertices) {
        const verts_flat = [];
        const verts_uvs = [];

        const vertices_split = splitArray(vertices);

        let vertices_moved = []
        let vertices_bottom = [];
        let vertices_top = [];

        let x_bottom_moved = 0;
        let x_top_moved = 0;

        for (let i = vertices_split[0].length - 1; i >= 0; i--) {
            if (i < vertices_split[0].length - 1) {
                let distance = calcDistance(vertices_split[0][i], vertices_split[0][i + 1]);
                x_bottom_moved = x_bottom_moved + distance;
                x_top_moved = x_top_moved + distance;
            } else {
                x_bottom_moved = 0;
                x_top_moved = 0;
            }
            vertices_bottom.push(x_bottom_moved)
            vertices_top.push(x_top_moved)
        }

        vertices_bottom.reverse()
        vertices_top.reverse()

        vertices_moved = vertices_bottom.concat(vertices_top);

        for (var i = 0; i < vertices_moved.length; i++) {
            verts_flat.push(vertices_moved[i], 0, vertices[i][2])
            verts_uvs.push({
                x: vertices_moved[i],
                y: 0,
                z: vertices[i][2]
            })
        }
        return { verts_flat, verts_uvs };
    }

    function calculateUVCoordinates(vertices, size) {

        // Find the minimum and maximum x and y values in the array of vertices
        var minX = Number.MAX_VALUE;
        var maxX = Number.MIN_VALUE;
        var minZ = Number.MAX_VALUE;
        var maxZ = Number.MIN_VALUE;
        for (var i = 0; i < vertices.length; i++) {
            minX = Math.min(minX, vertices[i].x);
            maxX = Math.max(maxX, vertices[i].x);
            minZ = Math.min(minZ, vertices[i].z);
            maxZ = Math.max(maxZ, vertices[i].z);
        }

        // Find the aspect ratio of the bounding box that contains the original x and y values
        var aspectRatio = (maxX - minX) / (maxZ - minZ);

        // Scale the normalized x and y values to fit within the square UV map while preserving the aspect ratio
        var scale = {
            x: size,
            z: size
        };
        if (aspectRatio > 1) {
            // Scale based on the x values
            scale = size / (maxX - minX);
        } else {
            // Scale based on the y values
            scale = size * (maxZ - minZ)
        }

        let uvs_set = [];
        let uvs_flat = [];

        // let indices_and_verts = {
        //     indices: [],
        //     verts: [],
        //     uvs: []
        // };
        // let seenIndices = new Set();

        // for (let i = 0; i < indices.length; i++) {
        //     let index = indices[i];
        //     if (!seenIndices.has(index)) {
        //         indices_and_verts.indices.push(index);
        //         indices_and_verts.verts.push(vertices[index]);
        //         indices_and_verts.uvs.push({
        //             u: vertices[index].x * scale / size,
        //             v: vertices[index].z * scale / size
        //         });
        //         uvs_flat.push(
        //             vertices[index].x * scale / size,
        //             vertices[index].z * scale / size
        //         )
        //         uvs_set.push({
        //             u: vertices[index].x * scale / size,
        //             v: vertices[index].z * scale / size
        //         })
        //         seenIndices.add(index);
        //     }
        // }
        let highPoint = 0;
        for (let i = 0; i < vertices.length; i++) {
            if (vertices[i].z * scale / size > highPoint) {
                highPoint = vertices[i].z * scale / size;
            }
            uvs_flat.push(
                vertices[i].x * scale / size,
                vertices[i].z * scale / size
            )
            uvs_set.push({
                u: vertices[i].x * scale / size,
                v: vertices[i].z * scale / size
            })
        }

        return { uvs_flat, uvs_set, highPoint };
    }


    function exportGLTF(input) {

        const gltfExporter = new THREE.GLTFExporter();

        const params = {
            trs: false,
            onlyVisible: true,
            binary: false,
            maxTextureSize: 1024
        };

        const options = {
            trs: params.trs,
            onlyVisible: params.onlyVisible,
            binary: params.binary,
            maxTextureSize: params.maxTextureSize
        };
        gltfExporter.parse(
            input,
            function (result) {

                if (result instanceof ArrayBuffer) {

                    saveArrayBuffer(result, 'scene.glb');

                } else {

                    const output = JSON.stringify(result, null, 2);
                    // console.log( output );
                    saveString(output, 'scene.gltf');

                }

            },
            function (error) {

                console.log('An error happened during parsing', error);

            },
            options
        );

    }

    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link); // Firefox workaround, see #6594

    function save(blob, filename) {

        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();

        // URL.revokeObjectURL( url ); breaks Firefox...

    }

    function saveString(text, filename) {

        save(new Blob([text], { type: 'text/plain' }), filename);

    }


    function saveArrayBuffer(buffer, filename) {

        save(new Blob([buffer], { type: 'application/octet-stream' }), filename);

    }

    const setModelLayer = (map, elevation, nodes) => {

        // parameters to ensure the model is georeferenced correctly on the map
        const modelOrigin = [elevation.terrain[0].lng, elevation.terrain[0].lat];

        // const modelAltitude = elevation.terrain[0].y;
        const modelAltitude = 0;
        const modelRotate = [0, 0, 0];

        const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
            modelOrigin,
            modelAltitude
        );

        const scale = modelAsMercatorCoordinate.meterInMercatorCoordinateUnits();

        // transformation parameters to position, rotate and scale the 3D model onto the map
        const modelTransform = {
            translateX: modelAsMercatorCoordinate.x,
            translateY: modelAsMercatorCoordinate.y,
            translateZ: modelAsMercatorCoordinate.z,
            rotateX: modelRotate[0],
            rotateY: modelRotate[1],
            rotateZ: modelRotate[2],
            /* Since the 3D model is in real world meters, a scale transform needs to be
            * applied since the CustomLayerInterface expects units in MercatorCoordinates.
            */
            scale: scale
        };

        // configuration of the custom layer for a 3D model per the CustomLayerInterface
        const customLayer = {
            id: '3d-model',
            type: 'custom',
            renderingMode: '3d',
            source: 'models',
            onAdd: async function (map, gl) {

                this.camera = new THREE.PerspectiveCamera();
                this.scene = new THREE.Scene();
                this.raycaster = new THREE.Raycaster();

                this.highestPoint = null;
                this.lowestPoint = null;

                this.node_labels = [];

                // create a canvas element
                canvas1 = document.createElement('canvas');
                canvas1.id = '3d-tooltip';
                canvas1.width = 256;
                canvas1.height = 256;
                context1 = canvas1.getContext('2d');

                var label_current_image = new Image();
                label_current_image.src = '/img/label-current.png';
                label_current_image.onload = function () {
                    context1.drawImage(label_current_image, 0, 0, 256, 256);
                }

                context1.font = "bold 30px Arial";
                context1.fillStyle = "rgba(0,0,0,0.95)";

                // canvas contents will be used for a texture
                texture1 = new THREE.Texture(canvas1)
                texture1.needsUpdate = true;

                // create a canvas element
                canvas_arrow = document.createElement('canvas');
                canvas_arrow.id = 'direction-arrow';
                canvas_arrow.width = 512;
                canvas_arrow.height = 512;
                context_arrow = canvas_arrow.getContext('2d');

                var label_arrow_image = new Image();
                label_arrow_image.src = '/img/direction-arrow.png';
                label_arrow_image.onload = function () {
                    context_arrow.drawImage(label_arrow_image, 0, 0, 512, 512);
                }

                // canvas contents will be used for a texture
                texture_arrow = new THREE.Texture(canvas_arrow)
                texture_arrow.needsUpdate = true;

                // create a canvas element
                canvas_gradient = document.createElement('canvas');
                canvas_gradient.id = 'direction-gradient';
                canvas_gradient.width = 512;
                canvas_gradient.height = 512;
                context_gradient = canvas_gradient.getContext('2d');

                var label_gradient_image = new Image();
                label_gradient_image.src = '/img/direction-gradient.png';
                label_gradient_image.onload = function () {
                    context_gradient.drawImage(label_gradient_image, 0, 0, 512, 512);
                }

                // canvas contents will be used for a texture
                texture_gradient = new THREE.Texture(canvas_gradient)
                texture_gradient.needsUpdate = true;

                const geometryPlane = new THREE.PlaneGeometry(canvas1.width, canvas1.height);

                let verts = [];
                for (var i = 0; i < geometryPlane.attributes.position.array.length; i += 3) {
                    verts.push(geometryPlane.attributes.position.array[i]);
                    verts.push(geometryPlane.attributes.position.array[i + 1] + canvas1.width / 2);
                    verts.push(geometryPlane.attributes.position.array[i + 2]);
                }

                var vertices = new Float32Array(verts);
                geometryPlane.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

                const mat_plane = new THREE.MeshBasicMaterial({
                    color: 0xffffff,
                    side: THREE.DoubleSide,
                    map: texture1,
                    // transparent: true,
                    alphaTest: 0.9
                });

                label_current = new THREE.Mesh(geometryPlane, mat_plane);
                label_current.name = 'label_current';
                label_current.position.set(0, 0, 0);
                label_current.rotation.set(Math.PI / 2, 0, 0);
                label_current.scale.set(0.5, 0.5, 0.5)
                this.scene.add(label_current);

                // create a canvas element
                canvas_top = document.createElement('canvas');
                canvas_top.id = '3d-tooltip-top';
                canvas_top.width = 256;
                canvas_top.height = 256;
                context_top = canvas_top.getContext('2d');

                context_top.font = "bold 30px Arial";
                context_top.fillStyle = "rgba(0,0,0,1.0)";

                // canvas contents will be used for a texture
                texture_top = new THREE.Texture(canvas_top)

                const geometryPlane_top = new THREE.PlaneGeometry(canvas_top.width, canvas_top.height);

                let verts_top = [];
                for (var i = 0; i < geometryPlane_top.attributes.position.array.length; i += 3) {
                    verts_top.push(geometryPlane_top.attributes.position.array[i]);
                    verts_top.push(geometryPlane_top.attributes.position.array[i + 1] + canvas_top.width / 2);
                    verts_top.push(geometryPlane_top.attributes.position.array[i + 2]);
                }

                var vertices_top = new Float32Array(verts_top);
                geometryPlane_top.setAttribute('position', new THREE.BufferAttribute(vertices_top, 3));

                const mat_plane_top = new THREE.MeshBasicMaterial({
                    color: 0xffffff,
                    side: THREE.DoubleSide,
                    map: texture_top,
                    // transparent: true,
                    alphaTest: 0.9
                });

                label_top = new THREE.Mesh(geometryPlane_top, mat_plane_top);
                label_top.name = 'label_top';
                label_top.position.set(0, 0, 0);
                label_top.rotation.set(Math.PI / 2, 0, 0);
                label_top.scale.set(0.5, 0.5, 0.5)
                this.scene.add(label_top);

                // create a canvas element
                canvas_bottom = document.createElement('canvas');
                canvas_bottom.id = '3d-tooltip-bottom';
                canvas_bottom.width = 256;
                canvas_bottom.height = 256;
                context_bottom = canvas_bottom.getContext('2d');

                context_bottom.font = "bold 30px Arial";
                context_bottom.fillStyle = "rgba(0,0,0,1.0)";

                // canvas contents will be used for a texture
                texture_bottom = new THREE.Texture(canvas_bottom)

                const geometryPlane_bottom = new THREE.PlaneGeometry(canvas_bottom.width, canvas_bottom.height);

                let verts_bottom = [];
                for (var i = 0; i < geometryPlane_bottom.attributes.position.array.length; i += 3) {
                    verts_bottom.push(geometryPlane_bottom.attributes.position.array[i]);
                    verts_bottom.push(geometryPlane_bottom.attributes.position.array[i + 1] + canvas_bottom.width / 2);
                    verts_bottom.push(geometryPlane_bottom.attributes.position.array[i + 2]);
                }

                var vertices_bottom = new Float32Array(verts_bottom);
                geometryPlane_bottom.setAttribute('position', new THREE.BufferAttribute(vertices_bottom, 3));

                const mat_plane_bottom = new THREE.MeshBasicMaterial({
                    color: 0xffffff,
                    side: THREE.DoubleSide,
                    map: texture_bottom,
                    // transparent: true,
                    alphaTest: 0.9
                });

                label_bottom = new THREE.Mesh(geometryPlane_bottom, mat_plane_bottom);
                label_bottom.name = 'label_bottom';
                label_bottom.position.set(0, 0, 0);
                label_bottom.rotation.set(Math.PI / 2, 0, 0);
                label_bottom.scale.set(0.5, 0.5, 0.5)
                this.scene.add(label_bottom);


                // var spriteMaterial = new THREE.SpriteMaterial({
                //     map: texture1,
                //     color: 0xffffff,
                //     fog: true,
                //     rotation: 0,
                //     sizeAttenuation: false,
                //     transparent: true
                // });

                // sprite1 = new THREE.Sprite( spriteMaterial );
                // sprite1.name = 'label';
                // sprite1.scale.set(256, 256, 1.0);
                // sprite1.position.set( 0, 0, 0 );
                // this.scene.add( sprite1 );

                // create two three.js lights to illuminate the model
                const directionalLight = new THREE.DirectionalLight(0xffffff);
                directionalLight.position.set(0, -70, 100).normalize();
                this.scene.add(directionalLight);

                // const directionalLight2 = new THREE.DirectionalLight(0xffffff);
                // directionalLight2.position.set(0, 70, 100).normalize();
                // this.scene.add(directionalLight2);

                // Create 3d model
                let segments = elevation.terrain.length;
                let height = 400;
                let cylinderProps = {
                    radiusTop: 1000,
                    radiusBottom: 1000,
                    height: height,
                    radialSegments: segments,
                    heightSegments: 1,
                    openEnded: true
                };

                var cylinderGeometry = new THREE.CylinderGeometry(cylinderProps.radiusTop, cylinderProps.radiusBottom, cylinderProps.height, cylinderProps.radialSegments, cylinderProps.heightSegments, cylinderProps.openEnded);
                cylinderGeometry.rotateX(Math.PI / 2);
                cylinderGeometry.computeVertexNormals();
                cylinderGeometry.computeBoundingBox();

                var cylinderGeometry_test = new THREE.CylinderGeometry(cylinderProps.radiusTop, cylinderProps.radiusBottom, cylinderProps.height, cylinderProps.radialSegments, cylinderProps.heightSegments, cylinderProps.openEnded);
                cylinderGeometry_test.rotateX(Math.PI / 2);


                // console.log(cylinderGeometry_test)
                // const canvas_uv = THREE.UVsDebug(cylinderGeometry_test, 1024);
                // const base64_uv = canvas_uv.toDataURL('image/png');
                // console.log(base64_uv);

                var flatArray = cylinderGeometry.attributes.position.array;
                var objArray = [];
                for (var i = 0; i < flatArray.length; i += 3) {
                    objArray.push({ x: flatArray[i], y: flatArray[i + 1], z: flatArray[i + 2] });
                }

                let sphereProps = {
                    radius: 20,
                    widthSegments: 8,
                    heightSegments: 8
                };
                const sphereGeometry = new THREE.SphereGeometry(sphereProps.radius, sphereProps.widthSegments, sphereProps.heightSegments);
                const sphereGeometry_small = new THREE.SphereGeometry(2, sphereProps.widthSegments, sphereProps.heightSegments);

                var objFlattenArray = [];
                for (var i = 0; i <= objArray.length; i++) {
                    if (i < (objArray.length / 2) - 1) {
                        // set position
                        if (elevation.terrain[i]) {
                            let position = mapboxgl.MercatorCoordinate.fromLngLat(
                                [elevation.terrain[i].lng, elevation.terrain[i].lat],
                                0
                            );
                            objFlattenArray.push((position.x - modelAsMercatorCoordinate.x) / scale)
                            objFlattenArray.push((modelAsMercatorCoordinate.y - position.y) / scale)
                            objFlattenArray.push((position.z - modelAsMercatorCoordinate.z) / scale)
                        }
                    } else if (i === (objArray.length / 2) - 1) {
                        // set position
                        if (elevation.terrain[0]) {
                            let position = mapboxgl.MercatorCoordinate.fromLngLat(
                                [elevation.terrain[0].lng, elevation.terrain[0].lat],
                                0
                            );
                            objFlattenArray.push((position.x - modelAsMercatorCoordinate.x) / scale)
                            objFlattenArray.push((modelAsMercatorCoordinate.y - position.y) / scale)
                            objFlattenArray.push((position.z - modelAsMercatorCoordinate.z) / scale)
                        }
                    } else if (i < objArray.length - 1) {
                        // set position
                        let position = mapboxgl.MercatorCoordinate.fromLngLat(
                            [elevation.terrain[i - (objArray.length / 2)].lng, elevation.terrain[i - (objArray.length / 2)].lat],
                            elevation.terrain[i - (objArray.length / 2)].y
                        );
                        objFlattenArray.push((position.x - modelAsMercatorCoordinate.x) / scale)
                        objFlattenArray.push((modelAsMercatorCoordinate.y - position.y) / scale)
                        objFlattenArray.push((position.z - modelAsMercatorCoordinate.z) / scale)

                        // set highest and lowest point
                        if (this.highestPoint === null) {
                            this.highestPoint = {
                                x: (position.x - modelAsMercatorCoordinate.x) / scale,
                                y: (modelAsMercatorCoordinate.y - position.y) / scale,
                                z: (position.z - modelAsMercatorCoordinate.z) / scale,
                                distance: elevation.terrain[i - (objArray.length / 2)].x,
                                height: elevation.terrain[i - (objArray.length / 2)].y
                            }
                        } else if (((position.z - modelAsMercatorCoordinate.z) / scale) > this.highestPoint.z) {
                            this.highestPoint = {
                                x: (position.x - modelAsMercatorCoordinate.x) / scale,
                                y: (modelAsMercatorCoordinate.y - position.y) / scale,
                                z: (position.z - modelAsMercatorCoordinate.z) / scale,
                                distance: elevation.terrain[i - (objArray.length / 2)].x,
                                height: elevation.terrain[i - (objArray.length / 2)].y
                            }
                        }

                        if (this.lowestPoint === null) {
                            this.lowestPoint = {
                                x: (position.x - modelAsMercatorCoordinate.x) / scale,
                                y: (modelAsMercatorCoordinate.y - position.y) / scale,
                                z: (position.z - modelAsMercatorCoordinate.z) / scale,
                                distance: elevation.terrain[i - (objArray.length / 2)].x,
                                height: elevation.terrain[i - (objArray.length / 2)].y
                            }
                        } else if (((position.z - modelAsMercatorCoordinate.z) / scale) < this.lowestPoint.z) {
                            this.lowestPoint = {
                                x: (position.x - modelAsMercatorCoordinate.x) / scale,
                                y: (modelAsMercatorCoordinate.y - position.y) / scale,
                                z: (position.z - modelAsMercatorCoordinate.z) / scale,
                                distance: elevation.terrain[i - (objArray.length / 2)].x,
                                height: elevation.terrain[i - (objArray.length / 2)].y
                            }
                        }

                        const mat_transparent = new THREE.MeshPhongMaterial({
                            color: 0xffffff,
                            wireframe: false,
                            transparent: true,
                            opacity: 0.0
                        })

                        const mat_purple = new THREE.MeshPhongMaterial({
                            color: 0x4f46f4
                        })

                        const sphereMesh = new THREE.Mesh(sphereGeometry, mat_transparent);
                        sphereMesh.name = i + '_sphere';
                        sphereMesh["props"] = {
                            distance: elevation.terrain[i - (objArray.length / 2)].x,
                            height: elevation.terrain[i - (objArray.length / 2)].y
                        }
                        sphereMesh.position.set(
                            (position.x - modelAsMercatorCoordinate.x) / scale,
                            (modelAsMercatorCoordinate.y - position.y) / scale,
                            (position.z - modelAsMercatorCoordinate.z) / scale
                        );
                        sphereMesh.visible = false;
                        this.scene.add(sphereMesh)

                    } else if (i === objArray.length - 1) {
                        // set position
                        let position = mapboxgl.MercatorCoordinate.fromLngLat(
                            [elevation.terrain[i - (objArray.length / 2) - 1].lng, elevation.terrain[i - (objArray.length / 2) - 1].lat],
                            elevation.terrain[i - (objArray.length / 2) - 1].y
                        );
                        objFlattenArray.push((position.x - modelAsMercatorCoordinate.x) / scale)
                        objFlattenArray.push((modelAsMercatorCoordinate.y - position.y) / scale)
                        objFlattenArray.push((position.z - modelAsMercatorCoordinate.z) / scale)

                        // set highest and lowest point
                        if (this.highestPoint === null) {
                            this.highestPoint = {
                                x: (position.x - modelAsMercatorCoordinate.x) / scale,
                                y: (modelAsMercatorCoordinate.y - position.y) / scale,
                                z: (position.z - modelAsMercatorCoordinate.z) / scale,
                                distance: elevation.terrain[i - (objArray.length / 2) - 1].x,
                                height: elevation.terrain[i - (objArray.length / 2) - 1].y
                            }
                        } else if (((position.z - modelAsMercatorCoordinate.z) / scale) > this.highestPoint.z) {
                            this.highestPoint = {
                                x: (position.x - modelAsMercatorCoordinate.x) / scale,
                                y: (modelAsMercatorCoordinate.y - position.y) / scale,
                                z: (position.z - modelAsMercatorCoordinate.z) / scale,
                                distance: elevation.terrain[i - (objArray.length / 2) - 1].x,
                                height: elevation.terrain[i - (objArray.length / 2) - 1].y
                            }
                        }

                        if (this.lowestPoint === null) {
                            this.lowestPoint = {
                                x: (position.x - modelAsMercatorCoordinate.x) / scale,
                                y: (modelAsMercatorCoordinate.y - position.y) / scale,
                                z: (position.z - modelAsMercatorCoordinate.z) / scale,
                                distance: elevation.terrain[i - (objArray.length / 2) - 1].x,
                                height: elevation.terrain[i - (objArray.length / 2) - 1].y
                            }
                        } else if (((position.z - modelAsMercatorCoordinate.z) / scale) < this.lowestPoint.z) {
                            this.lowestPoint = {
                                x: (position.x - modelAsMercatorCoordinate.x) / scale,
                                y: (modelAsMercatorCoordinate.y - position.y) / scale,
                                z: (position.z - modelAsMercatorCoordinate.z) / scale,
                                distance: elevation.terrain[i - (objArray.length / 2) - 1].x,
                                height: elevation.terrain[i - (objArray.length / 2) - 1].y
                            }
                        }

                        const mat_transparent = new THREE.MeshPhongMaterial({
                            color: 0xffffff,
                            wireframe: false,
                            transparent: true,
                            opacity: 0.0
                        })

                        const mat_purple = new THREE.MeshPhongMaterial({
                            color: 0x4f46f4
                        })

                        const sphereMesh = new THREE.Mesh(sphereGeometry, mat_transparent);
                        sphereMesh.name = i + '_sphere';
                        sphereMesh["props"] = {
                            distance: elevation.terrain[i - (objArray.length / 2) - 1].x,
                            height: elevation.terrain[i - (objArray.length / 2) - 1].y
                        }
                        sphereMesh.position.set(
                            (position.x - modelAsMercatorCoordinate.x) / scale,
                            (modelAsMercatorCoordinate.y - position.y) / scale,
                            (position.z - modelAsMercatorCoordinate.z) / scale
                        );
                        sphereMesh.visible = false;
                        this.scene.add(sphereMesh)
                    }

                }

                let highestPoint = this.highestPoint;
                let lowestPoint = this.lowestPoint;

                var label_top_image = new Image();
                label_top_image.src = '/img/label-top.png';
                label_top_image.onload = function () {
                    context_top.drawImage(label_top_image, 0, 0, 256, 256);

                    var txt = `${highestPoint.distance} km\n${highestPoint.height} m`;
                    printAtWordWrap(context_top, txt, 50, 150, 80, 256)
                    texture_top.needsUpdate = true;
                }

                var label_bottom_image = new Image();
                label_bottom_image.src = '/img/label-bottom.png';
                label_bottom_image.onload = function () {
                    context_bottom.drawImage(label_bottom_image, 0, 0, 256, 256);

                    var txt = `${lowestPoint.distance} km\n${lowestPoint.height} m`;
                    printAtWordWrap(context_bottom, txt, 50, 150, 80, 256)
                    texture_bottom.needsUpdate = true;
                }

                var vertices = new Float32Array(objFlattenArray);
                cylinderGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
                cylinderGeometry.computeVertexNormals();
                cylinderGeometry.computeBoundingBox();

                let top = cylinderGeometry.boundingBox.max.z;

                // Material wireframe
                const mat_wireframe = new THREE.MeshPhongMaterial({ color: 0x0000ff, wireframe: true });

                // Calculate new UVs
                let vertices_structured = [];
                for (var i = 0; i < cylinderGeometry.attributes.position.array.length; i += 3) {
                    let vert = [cylinderGeometry.attributes.position.array[i], cylinderGeometry.attributes.position.array[i + 1], cylinderGeometry.attributes.position.array[i + 2]]
                    vertices_structured.push(vert);
                }

                // Flatten the cylindrical model
                const { verts_flat, verts_uvs } = await flattenCylindricalModel(vertices_structured);

                var flat_geometry = new THREE.BufferGeometry();
                let flat_position = [];
                let flat_normal = [];
                for (var i = 0; i < verts_flat.length; i += 3) {
                    flat_position.push(verts_flat[i], verts_flat[i + 1], verts_flat[i + 2]);
                    flat_normal.push(0, -1, 0);
                }
                const positionNumComponents = 3;
                const normalNumComponents = 3;
                flat_geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(flat_position), positionNumComponents));
                flat_geometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(flat_normal), normalNumComponents));

                const { uvs_flat, uvs_set, highPoint } = await calculateUVCoordinates(verts_uvs, 1024);
                const uvNumComponents = 2;
                flat_geometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uvs_flat), uvNumComponents));

                const indexNumComponents = 1;
                let flat_indices = cylinderGeometry.index.array;
                flat_geometry.setIndex(new THREE.BufferAttribute(new Uint16Array(flat_indices), indexNumComponents));

                cylinderGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uvs_flat), uvNumComponents));

                // var flat_mesh = new THREE.Mesh(flat_geometry, mat_wireframe);
                // flat_mesh.name = 'Route_flat';
                // this.scene.add(flat_mesh);

                const mat_test = new THREE.MeshBasicMaterial({
                    map: texture_arrow,
                    side: THREE.DoubleSide,
                    // wireframe: true
                });

                // Material gradient
                const uniforms = {
                    opacity: { value: 1 },
                    opacity_arrow: { value: 0.3 },
                    color_bottom: { value: new THREE.Color('#4f46f8') },
                    color_top: { value: new THREE.Color('#4f46f8') },
                    height_bottom: { value: 0.0 },
                    height_top: { value: top },
                    height_half: { value: top * 1.0 },
                    time: { value: 0.0 },
                    texture: { value: texture_arrow },
                    repeat: { value: new THREE.Vector2(1 / highPoint, 1 / highPoint) },
                    color_arrow: { value: new THREE.Color('#4f46f8') },
                    texture_gradient: { value: texture_gradient },
                    repeat_gradient: { value: new THREE.Vector2(1, 1) }
                };

                texture_arrow.wrapS = texture_arrow.wrapT = THREE.RepeatWrapping;
                texture_gradient.wrapS = texture_gradient.wrapT = THREE.RepeatWrapping;
                // texture_arrow.offset.set(0, 0);
                // texture_arrow.repeat.set(1 / highPoint, 1 / highPoint);

                var mat_gradient = new THREE.ShaderMaterial({
                    transparent: true,
                    side: THREE.DoubleSide,
                    uniforms: uniforms,
                    vertexShader: `
                    varying vec3 pos;
                    varying vec2 vUv;
                    varying vec2 vUv_gradient;
                    uniform vec2 repeat;
                    uniform vec2 repeat_gradient;
                    void main() {
                        pos = position;
                        vUv = uv * repeat;
                        vUv_gradient = uv * repeat_gradient;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
                    }
                `,
                    fragmentShader: `
                    uniform float opacity;
                    uniform float opacity_arrow;

                    uniform vec3 color_bottom;
                    uniform float height_bottom;

                    uniform vec3 color_top;
                    uniform float height_top;

                    uniform float height_half;

                    varying vec3 pos;

                    uniform float time;

                    uniform sampler2D texture;
                    varying vec2 vUv;
                    varying vec2 vUv_gradient;

                    uniform vec3 color_arrow;

                    uniform sampler2D texture_gradient;

                    void main() {
                        
                        // y < 0 = transparent, > 1 = opaque
                        float alpha = smoothstep(height_bottom, height_half, pos.z);

                        // y < 1 = color1, > 2 = color2
                        float colorMix = smoothstep(height_half, height_top, pos.z);

                        vec2 texCoord = vUv.xy;
                        texCoord.x += (time + vUv.y) * 0.1;
                        vec4 texColor = texture2D(texture, texCoord);

                        vec2 texCoord_gradient = vUv_gradient.xy;
                        texCoord_gradient.x += (time + vUv_gradient.y) * 0.05;
                        vec4 texColor_gradient = texture2D(texture_gradient, texCoord_gradient);

                        // vec4 texColor = texture2D(texture, vUv);
                        // gl_FragColor = vec4( mix(color_bottom, color_top, colorMix), alpha) * opacity * vec4(texColor.rgb * color_arrow, alpha);
                        gl_FragColor = vec4(texColor_gradient.rgb, alpha) + vec4(texColor.rgb * color_arrow, alpha) * opacity_arrow;
                    }
                `
                });

                var cylinderMesh = new THREE.Mesh(cylinderGeometry, mat_gradient);
                cylinderMesh.name = 'Route';

                // // var vertices = new Float32Array(verts_flat);
                // // cylinderGeometry_test.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
                // // cylinderGeometry_test.computeVertexNormals();

                // var cylinderMesh_test = new THREE.Mesh(flat_geometry, mat_wireframe);
                // cylinderMesh_test.name = 'Route_test';

                // console.log(cylinderMesh)
                // console.log(cylinderMesh_test)

                // const uvCoordinates = uvs;

                // // create a canvas element
                // let canvas_test = document.createElement('canvas');
                // let context_test = canvas_test.getContext('2d');
                // let size = 1024;
                // canvas_test.width = size;
                // canvas_test.height = size;
                // context_test.beginPath();
                // for (var i = 0; i < uvCoordinates.length; i += 1) {
                //     console.log(i)
                //     console.log(uvCoordinates[i])
                //     var x = uvCoordinates[i] * size;
                //     var y = uvCoordinates[i + 1] * size;
                //     if (i === 0) {
                //         context_test.moveTo(x, y);
                //     } else {
                //         context_test.lineTo(x, y);
                //     }
                // }
                // context_test.closePath();
                // context_test.stroke();

                // const base64 = canvas_test.toDataURL('image/png');
                // console.log(base64);


                // const canvas_uv_test = THREE.UVsDebug(flat_geometry, 1024);
                // const base64_uv_test = canvas_uv_test.toDataURL('image/png');
                // console.log(base64_uv_test)

                this.scene.add(cylinderMesh);

                // Generate nodes labels
                if (nodes.features.length > 0) {

                    // add nodes
                    mapView.current.loadImage('/img/node-pin-empty.png', (error, image) => {
                        if (error) throw error;
                        if (!mapView.current.hasImage('node-pin-empty')) mapView.current.addImage('node-pin-empty', image);
                    });

                    // create a canvas element
                    let canvas_size = 256;
                    const geometry_node = new THREE.PlaneGeometry(canvas_size, canvas_size);

                    let verts_node = [];
                    for (var i = 0; i < geometry_node.attributes.position.array.length; i += 3) {
                        verts_node.push(geometry_node.attributes.position.array[i]);
                        verts_node.push(geometry_node.attributes.position.array[i + 1] + canvas_size / 2);
                        verts_node.push(geometry_node.attributes.position.array[i + 2]);
                    }

                    var vertices_node = new Float32Array(verts_node);
                    geometry_node.setAttribute('position', new THREE.BufferAttribute(vertices_node, 3));

                    for (var i = 0; i < nodes.features.length; i++) {

                        let label = nodes.features[i].properties.label;
                        let label_color = nodes.features[i].properties.color;

                        let closestCoordinate = getClosestCoordinate(elevation.terrain, { lng: nodes.features[i].geometry.coordinates[1], lat: nodes.features[i].geometry.coordinates[0] });

                        let height = closestCoordinate.y;

                        // set position
                        let position_merc = mapboxgl.MercatorCoordinate.fromLngLat(
                            [nodes.features[i].geometry.coordinates[0], nodes.features[i].geometry.coordinates[1]],
                            height
                        );
                        let position = {
                            x: (position_merc.x - modelAsMercatorCoordinate.x) / scale,
                            y: (modelAsMercatorCoordinate.y - position_merc.y) / scale,
                            z: (position_merc.z - modelAsMercatorCoordinate.z) / scale
                        }

                        let canvas_node = document.createElement('canvas');
                        canvas_node.id = '3d-nodes';
                        canvas_node.width = canvas_size;
                        canvas_node.height = canvas_size;
                        let context_node = canvas_node.getContext('2d');
                        context_node.font = "bold 70px Arial";

                        context_node.clearRect(0, 0, 300, 300);
                        context_node.fillStyle = "#ffffff";
                        context_node.fillRect(0, 0, canvas_size, canvas_size);
                        context_node.fillStyle = label_color;

                        // canvas contents will be used for a texture
                        let texture_node = new THREE.Texture(canvas_node)

                        // var label_node_image = new Image();
                        // label_node_image.src = '/img/label-bottom.png';
                        // label_node_image.onload = function () {
                        //     context_node.drawImage(label_node_image, 0, 0, 256, 256);

                        // }

                        var txt = `${label}`;
                        printAtWordWrapCenter(context_node, txt, 70, 160, 120, 256);

                        texture_node.needsUpdate = true;

                        // Material node
                        const uniforms = {
                            color: { value: new THREE.Color(label_color) },
                            fillColor: { value: new THREE.Color(0xffffff) },
                            strokeWidth: { value: 0.05 },
                            texture: { value: texture_node }
                        };

                        var mat_node = new THREE.ShaderMaterial({
                            transparent: true,
                            side: THREE.DoubleSide,
                            uniforms: uniforms,
                            vertexShader: `
                            varying vec2 vUv;
                            void main() {
                                vUv = uv;
                                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                            }
                        `,
                            fragmentShader: `
                            uniform vec3 color;
                            uniform vec3 fillColor;
                            uniform float strokeWidth;
                            uniform sampler2D texture;
                            varying vec2 vUv;
                            void main() {
                                vec2 center = vec2(0.5, 0.5);
                                float radius = 0.4;
                                float dist = abs(length(vUv - center) - radius);
                                if (dist < strokeWidth) {
                                    gl_FragColor = vec4(color, 1.0);
                                } else if (length(vUv - center) < radius) {
                                    vec4 texColor = texture2D(texture, vUv);
                                    gl_FragColor = vec4(fillColor.rgb * texColor.rgb, texColor.a);
                                } else {
                                    discard;
                                }
                            }
                        `
                        });

                        let label_node = new THREE.Mesh(geometry_node, mat_node);
                        label_node.name = i + '_label_node';
                        label_node.position.set(0, 0, 0);
                        label_node.rotation.set(Math.PI / 2, 0, 0);
                        label_node.scale.set(0.25, 0.25, 0.25);
                        this.scene.add(label_node);

                        this.node_labels.push({
                            name: label_node.name,
                            texture: texture_node,
                            mesh: label_node,
                            position: position
                        })
                    }
                }

                // console.log(this.scene);

                // exportGLTF(this.scene)

                // var helper = new THREE.BoxHelper(mesh);
                // this.scene.add(helper);

                // const axesHelper = new THREE.AxesHelper(5000);
                // this.scene.add(axesHelper);

                this.map = map;

                // use the Mapbox GL JS map canvas for three.js
                this.renderer = new THREE.WebGLRenderer({
                    canvas: map.getCanvas(),
                    context: gl,
                    antialias: true
                });

                this.renderer.autoClear = false;
            },
            render: function (gl, matrix) {

                const rotationX = new THREE.Matrix4().makeRotationAxis(
                    new THREE.Vector3(1, 0, 0),
                    modelTransform.rotateX
                );
                const rotationY = new THREE.Matrix4().makeRotationAxis(
                    new THREE.Vector3(0, 1, 0),
                    modelTransform.rotateY
                );
                const rotationZ = new THREE.Matrix4().makeRotationAxis(
                    new THREE.Vector3(0, 0, 1),
                    modelTransform.rotateZ
                );

                const m = new THREE.Matrix4().fromArray(matrix);
                const l = new THREE.Matrix4()
                    .makeTranslation(
                        modelTransform.translateX,
                        modelTransform.translateY,
                        modelTransform.translateZ
                    )
                    .scale(
                        new THREE.Vector3(
                            modelTransform.scale,
                            -modelTransform.scale,
                            modelTransform.scale
                        )
                    )
                    .multiply(rotationX)
                    .multiply(rotationY)
                    .multiply(rotationZ);

                this.camera.projectionMatrix = m.multiply(l);
                this.renderer.resetState();

                // this.camera.projectionMatrixInverse.copy(this.camera.projectionMatrix).invert();
                // this.raycaster.setFromCamera(mouse, this.camera);

                const camInverseProjection = new THREE.Matrix4().copy(this.camera.projectionMatrix).invert();
                const cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection);
                const mousePosition = new THREE.Vector3(mouse.x, mouse.y, 1).applyMatrix4(camInverseProjection);
                const viewDirection = mousePosition.clone().sub(cameraPosition).normalize();

                this.camera.updateMatrixWorld();

                this.raycaster.set(cameraPosition, viewDirection);

                const camera_mapbox = mapView.current.getFreeCameraOptions();

                var quaternion = new THREE.Quaternion();
                quaternion.set(camera_mapbox.orientation[0], camera_mapbox.orientation[1], camera_mapbox.orientation[2], camera_mapbox.orientation[3]);
                var euler = new THREE.Euler();
                euler.setFromQuaternion(quaternion, 'XZY');

                label_current.rotation.set(label_current.rotation.x, euler._y, label_current.rotation.z);

                label_top.rotation.set(label_top.rotation.x, euler._y, label_top.rotation.z);
                label_bottom.rotation.set(label_bottom.rotation.x, euler._y, label_bottom.rotation.z);

                label_top.position.set(
                    this.highestPoint.x,
                    this.highestPoint.y,
                    this.highestPoint.z
                );

                label_bottom.position.set(
                    this.lowestPoint.x,
                    this.lowestPoint.y,
                    this.lowestPoint.z
                );

                for (var i = 0; i < this.node_labels.length; i++) {
                    this.node_labels[i].mesh.rotation.set(this.node_labels[i].mesh.rotation.x, euler._y, this.node_labels[i].mesh.rotation.z);
                    this.node_labels[i].mesh.position.set(
                        this.node_labels[i].position.x,
                        this.node_labels[i].position.y - 2,
                        this.node_labels[i].position.z + 55
                    )
                }

                // create an array containing all objects in the scene with which the ray intersects
                let objectsToIntersect = [];
                for (var i = 0; i < this.scene.children.length; i++) {
                    if (this.scene.children[i].name.includes('_sphere')) {
                        objectsToIntersect.push(this.scene.children[i])
                    }
                    if (this.scene.children[i].name.includes('Route')) {
                        this.scene.children[i].material.uniforms.time.value += 0.05;
                    }
                }
                var intersects = this.raycaster.intersectObjects(objectsToIntersect, true);

                // INTERSECTED = the object in the scene currently closest to the camera 
                //		and intersected by the Ray projected from the mouse position

                // if there is one (or more) intersections
                if (intersects.length > 0) {

                    if (intersects[0].object.name.includes('_sphere')) {
                        INTERSECTED = intersects[0].object;

                        // sprite1.position.set(intersects[0].object.position.x, intersects[0].object.position.y, intersects[0].object.position.z);

                        label_current.position.set(
                            intersects[0].object.position.x,
                            intersects[0].object.position.y,
                            intersects[0].object.position.z
                        );

                        const points = [];
                        points.push(new THREE.Vector3(this.camera.position.x, this.camera.position.y, this.camera.position.z));
                        points.push(new THREE.Vector3(INTERSECTED.position.x, INTERSECTED.position.y, INTERSECTED.position.z));

                        const geometry = new THREE.BufferGeometry().setFromPoints(points);
                        var material = new THREE.LineBasicMaterial({
                            color: 0xff0000
                        });
                        var line = new THREE.Line(geometry, material);
                        // this.scene.add(line);

                        // console.log(INTERSECTED.name)

                        var label_current_image = new Image();
                        label_current_image.src = '/img/label-current.png';
                        label_current_image.onload = function () {
                            context1.drawImage(label_current_image, 0, 0, 256, 256);

                            var txt = `${intersects[0].object.props.distance} km\n${intersects[0].object.props.height} m`;
                            printAtWordWrap(context1, txt, 50, 150, 80, 256)

                            texture1.needsUpdate = true;
                        }


                    } else {
                        context1.clearRect(0, 0, 300, 300);
                        texture1.needsUpdate = true;
                    }

                }
                else // there are no intersections
                {

                    INTERSECTED = null;

                    context1.clearRect(0, 0, 300, 300);
                    texture1.needsUpdate = true;
                }

                this.renderer.render(this.scene, this.camera);

                this.map.triggerRepaint();
            }
        };
        setCustomLayer(customLayer);

        map.target.addLayer(customLayer);
        map.target.setLayoutProperty('3d-model', 'visibility', 'none');

    }

    function togglePitchMap() {
        setTogglePitch(!togglePitch);
    }

    useEffect(() => {
        if (mapView.current) {
            setPinLng(mapView.current.getCenter().lng);
            setPinLat(mapView.current.getCenter().lat);
        }
    }, []);

    useEffect(() => {
        if (mapView.current) {
            mapView.current.getMap().triggerRepaint();
        }
    }, [_customLayer])

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);

        // cleanup this component
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    function handleMouseMove(e) {
        // console.log(e)
        // e.preventDefault();

        // update sprite position
        // if (sprite1) {
        //     sprite1.position.set( e.clientX, e.clientY - 20, 0 );
        // }

        mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
        mouse.y = 1 - (e.clientY / window.innerHeight) * 2;
    }

    useEffect(() => {
        if (mapView.current) {
            if (togglePitch) {
                // setDragPan(false);
                // setDoubleClickZoom(false);

                mapEaseTo(75, true);

                mapView.current.once('moveend', () => {
                    // setMaxPitch(75);
                    // setMinPitch(75);
                })

                if (mapView.current.getMap().getLayer('3d-model')) {
                    mapView.current.getMap().setLayoutProperty('3d-model', 'visibility', 'visible');
                }
            } else {
                // setDragPan(true);
                // setDoubleClickZoom(true);
                // setMaxPitch(85);
                // setMinPitch(0);
                mapEaseTo(0, false);

                if (mapView.current.getMap().getLayer('3d-model')) {
                    mapView.current.getMap().setLayoutProperty('3d-model', 'visibility', 'none');
                }
            }
        }
    }, [togglePitch])

    return (
        <>
            <Map
                ref={mapView}
                mapboxAccessToken={props.mapboxAccessToken}
                initialViewState={{
                    longitude: props.geoData === null ? 4.4699 : center[1],
                    latitude: props.geoData === null ? 50.5039 : center[0],
                    bounds: props.extremeBounds !== null && [props.extremeBounds[0][0], props.extremeBounds[0][1], props.extremeBounds[1][0], props.extremeBounds[1][1]],
                    fitBoundsOptions: {
                        padding: { top: 150, bottom: 250, left: 40, right: 40 }
                    },
                    // zoom: props.extremeBounds === null ? 9 : 14
                }}
                antialias={true}
                mapStyle={mapStyle}
                scrollZoom={true}
                dragRotate={true}
                doubleClickZoom={true}
                keyboard={true}
                touchPitch={true}
                pitchWithRotate={true}
                dragPan={true}
                touchZoomRotate={true}
                cursor={cursor}
                maxPitch={85}
                minPitch={0}
                style={{
                    width: props.width,
                    height: props.height
                }}
                interactiveLayerIds={!togglePitch ? ['wandelar-pin'] : []}
                onClick={onClick}
                onDragStart={onDragStart}
                onDrag={onDrag}
                onLoad={onLoad}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {
                    isSearch && <Geocoder
                        lang={props.lang}
                        translations={props.translations}
                        mapboxAccessToken={props.mapboxAccessToken}
                        position="top-left"
                        marker={true}
                        courseNew={props.courseNew}
                        changeCourseNew={props.changeCourseNew}
                        setTogglePitch={setTogglePitch}
                        setHoverInfo={setHoverInfo}
                        mapView={mapView}
                        createIcon={{
                            name: 'search-icon', path: `
                        <svg id="search-icon" class="search-icon" viewBox="0 0 46 46"><path d="M22.5,32A9.5,9.5,0,1,0,13,22.5,9.5,9.5,0,0,0,22.5,32Z" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"></path><path d="M33,33l-2-2" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"></path></svg>
                        `}}
                    />
                }
                {
                    props.geoData !== null && !togglePitch && <Source
                        type="geojson"
                        data={props.geoData}
                        cluster={true}
                        clusterMaxZoom={14} // Max zoom to cluster points on
                        clusterRadius={50} // Radius of each cluster when clustering points (defaults to 50)
                        generateId={true}
                    >
                        <Layer {...iconLayer} />
                        <Layer {...dataLayer} />
                        <Layer {...textLayer} />

                    </Source>
                }
                {
                    hoverInfo && hoverInfo.nodes && hoverInfo.nodes.features && <>
                        <Source
                            type="geojson"
                            data={hoverInfo && hoverInfo.nodes && hoverInfo.nodes.features ? hoverInfo.nodes : []}
                            generateId={true}
                        >
                            <Layer {...nodeLayer} />
                        </Source>
                    </>
                }
                {
                    hoverInfo && !togglePitch && hoverInfo.checkpoints.map((checkpoint, index) => {
                        let coordinates = checkpoint.geo.coordinates;
                        let str = null;
                        let type;
                        if (index === 0) {
                            type = "startpoint";
                        } else if (index > 0 && index < hoverInfo.checkpoints.length - 1) {
                            str = index;
                            type = "checkpoint";
                        } else if (index === hoverInfo.checkpoints.length - 1) {
                            type = "endpoint";
                        }

                        return (
                            <Marker
                                key={`marker-${index}`}
                                longitude={coordinates[1]}
                                latitude={coordinates[0]}
                                anchor="bottom"
                                draggable={false}
                                style={{ cursor: 'pointer' }}
                                onClick={e => {
                                    e.originalEvent.stopPropagation();

                                    let theme = null;
                                    if (!hoverInfo.theme || hoverInfo.theme === undefined || hoverInfo.theme === 'null') {
                                        theme = null
                                    } else {
                                        theme = {
                                            name: hoverInfo.theme.name,
                                            button: hoverInfo.theme.button,
                                            url: hoverInfo.theme.images.find(o => o.state === 'icon').url
                                        }
                                    }

                                    setPopupInfo({
                                        type: type,
                                        str: str,
                                        longitude: coordinates[1],
                                        latitude: coordinates[0],
                                        qr: checkpoint.qr,
                                        id: hoverInfo.id,
                                        index: index,
                                        theme: theme
                                    });

                                }}
                            >
                                <MarkerIcon
                                    width={46}
                                    height={46}
                                    state={type}
                                    text={str}
                                    place={false}
                                    isEdit={false}
                                    move={false}
                                />
                            </Marker>
                        )
                    })
                }
                {/* {
                    console.log(hoverInfo)
                } */}
                {
                    popupInfo !== null && <Popup
                        longitude={popupInfo.longitude}
                        latitude={popupInfo.latitude}
                        anchor="top"
                        closeButton={false}
                        closeOnClick={false}
                        closeOnMove={false}
                        focusAfterOpen={true}
                        offset={-5}
                        className="marker-popup smooth-shadow"
                    >
                        <div className="d-flex flex-direction-column">
                            <div className="w-100 d-flex flex-direction-row justify-content-space-between align-items m-b-0">
                                <div className="w-100 d-flex flex-direction-column justify-content">
                                    <div className="fs-0 text-color-gray letter-spacing-0">{props.translations[props.lang].invite.play.toUpperCase()}</div>
                                    <div className="fs-15 text-color">{capitalizeFirstLetter(props.translations[props.lang].course.checkpoints[popupInfo.type])} {popupInfo.str}</div>
                                </div>
                                <div onClick={() => setPopupInfo(null)} className="map-popup-close-button">
                                    <DismissTagIcon />
                                </div>
                            </div>
                            {
                                popupInfo.theme !== null && <div className="w-100 d-flex flex-direction-column align-items justify-content">
                                    <div className="channel-game">
                                        <div className="channel-game-img br-8" style={{ backgroundImage: `url(${popupInfo.theme.url})` }}></div>
                                    </div>
                                    <label className="fs-15 m-t-0 text-color">{popupInfo.theme.name[props.lang]}</label>
                                    <label className="fs-15 text-color-gray">{props.translations[props.lang].invite.part} {popupInfo.index + 1}</label>
                                </div>
                            }

                            {/* <div className="w-100 d-flex flex-direction-column align-items justify-content">
                                <div className="channel-qr" onClick={() => onDeepLinkIndex(popupInfo.id, popupInfo.index)}>
                                    <img
                                        className="wh-100 br-8"
                                        src={`data:image/svg+xml;utf8,${encodeURIComponent(popupInfo.qr)}`}
                                    />
                                </div>
                                {
                                    isMobile ?
                                        <>
                                            <div className="d-flex flex-direction-row justify-content align-items">
                                                <label className="fs-15 text-color-gray m-t-0 text-center">{props.translations[props.lang].invite.tap}</label>
                                                <label className="fs-15 text-color-gray m-t-0 text-center p-l-0">{props.translations[props.lang].invite.or}</label>
                                                <label className="fs-15 text-color-gray m-t-0 text-center p-l-0">{props.translations[props.lang].invite.scan}</label>
                                            </div>

                                            <label className="fs-15 text-color-gray m-t-0 text-center">{props.translations[props.lang].invite.qrcode}</label>
                                        </>
                                        :
                                        <>
                                            <label className="fs-15 text-color-gray m-t-0 text-center">{capitalizeFirstLetter(props.translations[props.lang].invite.scan)}</label>
                                            <label className="fs-15 text-color-gray m-t-0 text-center">{props.translations[props.lang].invite.qrcode}</label>
                                        </>
                                }
                            </div> */}
                        </div>

                    </Popup>
                }
                {
                    startInfo !== null && <Popup
                        longitude={startInfo.lng}
                        latitude={startInfo.lat}
                        anchor="top"
                        closeButton={false}
                        closeOnClick={false}
                        closeOnMove={false}
                        focusAfterOpen={false}
                        offset={-5}
                        className="marker-popup smooth-shadow"
                    >
                        <div className="w-100 d-flex flex-direction-column justify-content">
                            <div className="fs-0 text-color-gray letter-spacing-0">{startInfo.distance} km</div>
                            <div className="fs-15 text-color">{props.translations[props.lang].channel.height} {startInfo.elevation} m</div>
                        </div>
                    </Popup>
                }
            </Map>
            <div className="row d-flex flex-direction-row justify-content align-items-start pointer-none" style={{ position: 'absolute', top: isSearchOffset, width: '100%', height: '100%' }}>
                <div className="col-4 p-4">
                    <div className="card-form smooth-shadow-invite p-3 pointer-auto">
                        <div className="d-flex flex-direction-row justify-content-space-between align-items">
                            <div className="d-flex flex-direction-row justify-content-start align-items">
                                <div className="button p-0" onClick={toggleSearch}>
                                    <div className="icon-color icon-container icon-container-color-bg-2">
                                        <SearchIcon />
                                    </div>
                                </div>
                                {
                                    hoverInfo && hoverInfo.elevation && hoverInfo.elevation.terrain && hoverInfo.elevation.terrain.length > 0 && <div className="button p-0 m-l-0" onClick={() => togglePitchMap()}>
                                        <div className="icon-color icon-container icon-container-color-bg-2">
                                            <ElevationIcon />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="d-flex flex-direction-column justify-content align-items">

                                {
                                    togglePitch ? <div className="d-flex flex-direction-row justify-content align-items">
                                        {/* <label className="fs-15 text-color-gray text-center bg-gray br-4 p-3">{props.translations[props.lang].invite.ctrl}</label>
                                        <label className="fs-15 text-color-gray text-center p-l-0 p-r-0">{props.translations[props.lang].invite.or}</label>
                                        <label className="fs-15 text-color-gray text-center bg-gray br-4 p-3">{props.translations[props.lang].invite.cmd}</label> */}
                                        <label className="fs-15 text-color-gray text-center p-l-0">{props.translations[props.lang].invite.rightClick}</label>
                                        <label className="fs-15 text-color-gray text-center p-l-0">{props.translations[props.lang].invite.and}</label>
                                        <label className="fs-15 text-color-gray text-center p-l-0">{props.translations[props.lang].invite.drag}</label>
                                        <label className="fs-15 text-color-gray text-center p-l-0">{props.translations[props.lang].invite.toRotate}</label>
                                    </div>
                                        :
                                        <div className="d-flex flex-direction-row justify-content align-items">
                                            <label className="fs-15 text-color-gray text-center">{props.translations[props.lang].invite.tap}</label>
                                            <div className="channel-button">
                                                <PlayIcon />
                                            </div>
                                            <label className="fs-15 text-color-gray text-center">{props.translations[props.lang].invite.preview}</label>
                                        </div>
                                }
                            </div>

                            {/* <Select
                                lang={props.lang}
                                translations={props.translations}
                                selected={props.selected}
                                options={props.options}
                                changeOption={props.changeOption}
                                type={'lang'}
                                icons={null}
                                disabled={false}
                                theme={props.theme}
                                border={true}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex flex-direction-row justify-content align-items-end pointer-none" style={{ position: 'absolute', top: 0, width: '100%', height: '100%' }}>
                <div className="col-4 p-4">
                    <div className="card-form smooth-shadow-invite p-3 pointer-auto">
                        {
                            hoverInfo ?
                                <>
                                    <div className="p-3 d-flex flex-direction-row align-items-start flex-wrap">
                                        <div className="w-100 d-flex flex-direction-column">
                                            <div className="d-flex flex-direction-row">
                                                <div className="p-3 d-flex flex-direction-column">
                                                    <div className="channel-qr" onClick={() => onDeepLink(hoverInfo.id)}>
                                                        <img
                                                            className="wh-100 br-8"
                                                            src={`data:image/svg+xml;utf8,${encodeURIComponent(hoverInfo.qrcode)}`}
                                                        />
                                                    </div>
                                                    {
                                                        isMobile ?
                                                            <>
                                                                <div className="d-flex flex-direction-row justify-content align-items">
                                                                    <label className="fs-15 text-color-gray m-t-0 text-center">{props.translations[props.lang].invite.tap}</label>
                                                                    <label className="fs-15 text-color-gray m-t-0 text-center p-l-0">{props.translations[props.lang].invite.or}</label>
                                                                    <label className="fs-15 text-color-gray m-t-0 text-center p-l-0">{props.translations[props.lang].invite.scan}</label>
                                                                </div>

                                                                <label className="fs-15 text-color-gray m-t-0 text-center">{props.translations[props.lang].invite.qrcode}</label>
                                                            </>
                                                            :
                                                            <>
                                                                <label className="fs-15 text-color-gray m-t-0 text-center">{capitalizeFirstLetter(props.translations[props.lang].invite.scan)}</label>
                                                                <label className="fs-15 text-color-gray m-t-0 text-center">{props.translations[props.lang].invite.qrcode}</label>
                                                            </>
                                                    }
                                                </div>
                                                <div className="w-100 p-3 d-flex flex-direction-column justify-content-start align-items-start">
                                                    <div className="d-flex flex-direction-row align-items m-b-0">
                                                        <label className="w-100 fs-2 ff-corben text-color-black multiline-ellipsis p-l-0">{hoverInfo.name[props.lang]}</label>
                                                        <div onClick={toggleCollapse} className={`map-card-close-button ${isCollapsed === true ? 'active' : undefined}`}>
                                                            <SelectIcon />
                                                        </div>
                                                    </div>
                                                    <label className="w-100 fs-15 text-color-gray multiline-ellipsis m-b-0">@{props.username}</label>
                                                    <div className={`d-flex flex-direction-row align-items flex-wrap`}>
                                                        <div className={`d-flex flex-direction-row align-items`}>
                                                            <DistanceIcon />
                                                            <label className="fs-15 text-color-gray">{hoverInfo.distance} km</label>
                                                        </div>
                                                        <div className={`d-flex flex-direction-row align-items`}>
                                                            <EstimationIcon />
                                                            <label className="fs-15 text-color-gray">{formatDuration(hoverInfo.estimation)}</label>
                                                        </div>
                                                        {
                                                            hoverInfo.time && <div className={`d-flex flex-direction-row align-items`}>
                                                                <TimerIcon />
                                                                <label className="fs-15 text-color-gray">{props.translations[props.lang].invite.time}</label>
                                                            </div>
                                                        }
                                                        {
                                                            hoverInfo.theme !== null && <div className={`d-flex flex-direction-row align-items`}>
                                                                <GameIcon />
                                                                <label className="fs-15 text-color-gray">{props.translations[props.lang].invite.game}</label>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <SpringScrollbars
                                                ref={scrollView}
                                                style={{
                                                    height: scrollHeight,
                                                    maxHeight: window.innerHeight - 300,
                                                    zIndex: 0
                                                }}
                                                thumbcolor='rgba(116, 125, 137, 0.2)'
                                            >
                                                <div ref={contentHeight} className={`d-flex flex-direction-column p-4 route-details ${isCollapsed === true ? 'active' : undefined}`}>
                                                    <div className="w-100 d-flex flex-direction-row justify-content-start align-items flex-wrap">
                                                        {
                                                            hoverInfo.nodes.features.length > 0 && hoverInfo.nodes.features.map((node, index) => (

                                                                <div key={index} className="m-b-1">
                                                                    {
                                                                        index === 0 ?
                                                                            <>
                                                                                <NodeIcon color={node.properties.color} text={node.properties.label} />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className="d-flex flex-direction-row justify-content align-items">
                                                                                    <div className="node rotate-90-min">
                                                                                        <SelectIcon />
                                                                                    </div>
                                                                                    <NodeIcon color={node.properties.color} text={node.properties.label} />
                                                                                </div>
                                                                            </>
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <label className="fs-1 m-b-0">{hoverInfo.description[props.lang]}</label>
                                                    <div className={`d-flex flex-direction-row align-items flex-wrap m-b-1`}>
                                                        {
                                                            hoverInfo.url.name !== '' && hoverInfo.url.url !== '' &&
                                                            <>
                                                                <a href={hoverInfo.url.url} target="_blank">
                                                                    <div className={`d-flex flex-direction-row align-items`}>
                                                                        <LinkIcon />
                                                                        <label className="fs-15 pointer-none text-color-gray">{hoverInfo.url.name}</label>
                                                                    </div>
                                                                </a>
                                                            </>
                                                        }
                                                        {
                                                            hoverInfo.address.region !== '' && <div className={`d-flex flex-direction-row align-items`}>
                                                                <LocationIcon />
                                                                <label className="fs-15 text-color-gray">{hoverInfo.address.region}</label>
                                                            </div>
                                                        }
                                                        {
                                                            hoverInfo.age.min === null && hoverInfo.age.max === null ?
                                                                <>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className={`d-flex flex-direction-row align-items`}>
                                                                        <AgeIcon />
                                                                        <label className="fs-15 text-color-gray">{formatAge(hoverInfo.age)}</label>
                                                                    </div>
                                                                </>
                                                        }

                                                        {
                                                            hoverInfo.dog && <div className={`d-flex flex-direction-row align-items`}>
                                                                <DogIcon />
                                                                <label className="fs-15 text-color-gray">{props.translations[props.lang].channel.dog.true}</label>
                                                            </div>
                                                        }
                                                        {
                                                            hoverInfo.wheelchair && <div className={`d-flex flex-direction-row align-items`}>
                                                                <WheelchairIcon />
                                                                <label className="fs-15 text-color-gray">{props.translations[props.lang].channel.wheelchair.true}</label>
                                                            </div>
                                                        }
                                                        {
                                                            hoverInfo.stroller && <div className={`d-flex flex-direction-row align-items`}>
                                                                <StrollerIcon />
                                                                <label className="fs-15 text-color-gray">{props.translations[props.lang].channel.stroller.true}</label>
                                                            </div>
                                                        }

                                                    </div>
                                                    <div className={`tags-input-row`}>
                                                        <div className="d-flex align-items">
                                                            <div className={`tags-input-container p-0`}>
                                                                {
                                                                    hoverInfo.tags.map((tag, index) => (
                                                                        <div className="tag-item tag-padding" key={index}>
                                                                            <span className="text fs-15 text-color-gray">{tag[props.lang]}</span>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        hoverInfo.theme !== null && <>
                                                            <div className="w-100 fs-3 ff-corben m-t-2 p-4 text-color-gray">{props.translations[props.lang].invite.game}</div>
                                                            <div className="p-3 d-flex flex-direction-row align-items">
                                                                <div className="channel-game">
                                                                    <div className="channel-game-img br-8" style={{ backgroundImage: `url(${hoverInfo.theme.images.find(o => o.state === 'icon').url})` }}></div>
                                                                </div>
                                                                <label className="fs-2 ff-corben multiline-ellipsis p-l-1">{hoverInfo.theme.name[props.lang]}</label>
                                                            </div>
                                                            <label className="fs-1 m-b-0 m-t-1">{hoverInfo.theme.description[props.lang]}</label>
                                                            <div className={`d-flex flex-direction-row align-items flex-wrap m-b-1`}>
                                                                {
                                                                    hoverInfo.theme.age.min === null && hoverInfo.theme.age.max === null ?
                                                                        <>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className={`d-flex flex-direction-row align-items`}>
                                                                                <AgeIcon />
                                                                                <label className="fs-15 text-color-gray">{formatAge(hoverInfo.theme.age)}</label>
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className={`tags-input-row`}>
                                                                <div className="d-flex align-items">
                                                                    <div className={`tags-input-container p-0`}>
                                                                        {
                                                                            hoverInfo.theme.tags.map((tag, index) => (
                                                                                <div className="tag-item tag-padding" key={index}>
                                                                                    <span className="text fs-15 text-color-gray">{tag[props.lang]}</span>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </SpringScrollbars>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="d-flex flex-direction-row justify-content align-items p-3">
                                        <div className="d-flex flex-direction-column justify-content align-items p-l-0">
                                            <div className="fs-0 text-color-gray letter-spacing-0 m-b-5 text-center m-t-1">{props.translations[props.lang].channel.routes.toUpperCase()}</div>
                                            <div className="fs-1">@{props.username}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-direction-column justify-content align-items text-center">

                                        <div className="channel-stats m-t-0">
                                            <div className="channel-stats-box p-4">
                                                <div className="card-cl-2">
                                                    <div className="d-flex flex-direction-row justify-content align-items tooltip-down">
                                                        <div className="d-flex flex-direction-row justify-content align-items tooltip" data-tooltip={`${props.translations[props.lang].channel.hikes}`}>
                                                            <div className="channel-button">
                                                                <RouteIcon />
                                                            </div>
                                                            <label className="fs-1 text-color-gray">{props.stats.routes}</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card-cl-2 start-button">
                                                    <div className="d-flex flex-direction-row justify-content align-items tooltip-down">
                                                        <div className="d-flex flex-direction-row justify-content align-items tooltip" data-tooltip={`${props.translations[props.lang].channel.hikers}`}>
                                                            <div className="channel-button">
                                                                <PlayIcon />
                                                            </div>
                                                            <label className="fs-1 text-color-gray">{props.stats.started}</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="card-cl-2">
                                                    <div className="d-flex flex-direction-row justify-content align-items tooltip-down">
                                                        <div className="d-flex flex-direction-row justify-content align-items tooltip" data-tooltip={`${props.stats.rating === '0' ? props.translations[props.lang].channel.noRating : props.translations[props.lang].channel.rating}`}>
                                                            <div className="channel-button">
                                                                <StarIcon />
                                                            </div>
                                                            {
                                                                props.stats.rating === '0' ?
                                                                    <div className="d-flex flex-direction-row align-items-end">
                                                                        <label className="fs-1 text-color-gray">-</label>
                                                                        <label className="fs-0">/5</label>
                                                                    </div>
                                                                    :
                                                                    <div className="d-flex flex-direction-row align-items-end">
                                                                        <label className="fs-1 text-color-gray">{props.stats.rating}</label>
                                                                        <label className="fs-0">/5</label>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="d-flex flex-directions-row justify-content align-items m-t-0 m-b-0">
                                            <div className="fs-15 text-color-gray">{props.translations[props.lang].channel.poweredBy}</div>
                                            <div className="channel-footer p-l-0">
                                                <Link to="/">
                                                    <div className="logo-content">
                                                        <div className="logo">
                                                            <div className="logo-name">
                                                                <AppLogo scale={0.75} translateY={4} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>

                                    </div>
                                </>
                        }
                    </div>
                </div>

            </div>
            {/* <div className="map-ui">
                <div className="w-100 d-flex flex-direction-column">
                    <div className="w-100 d-flex flex-direction-row justify-content-space-between">
                        <div className="d-flex flex-direction-row">
                            <div className="button p-0 m-l-0" onClick={() => toggleMapStyle()}>
                                <div className="icon-color icon-container icon-container-color-bg-2">
                                    <MapStyleIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>       */}
        </>
    )
}
export default ChannelMap;