import { useCallback, useEffect, useRef, useState } from "react";
import { NavLink as Link, useNavigate, useParams } from "react-router-dom";

import { AppLogo, AppStoreLogo, NewTabIcon, PlayStoreLogo, QuestionIcon } from './Icons';
import Select from "./Select";

import { Helmet } from "react-helmet";

function Invite(props) {

	const navigate = useNavigate();

	let { routeId } = useParams();

	const headerRef = useRef();
	const [height, setHeight] = useState(0);
	const [contentHeight, setContentHeight] = useState(0);

	const [route, setRoute] = useState(null);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		setHeight(window.innerHeight);
		setContentHeight(window.innerHeight - headerRef.current.clientHeight);

		// cleanup this component
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const getRoute = useCallback(async () => {

		const getRoute = await fetch(`https://wandelar.be/api/routes/${routeId}`, {
			method: 'GET',
			credentials: 'same-origin'
		}).then((result) => {
			if (result) {
				return result.json()
			} else {
				navigate('/NotFound');
			}
		});

		Promise.all([getRoute]).then((responses) => {
			setRoute(responses[0])
		});
	}, [])

	useEffect(() => {
		if (route === null) {
			getRoute();
		}
	}, [route])

	function handleResize(e) {
		setHeight(window.innerHeight);
		setContentHeight(window.innerHeight - headerRef.current.clientHeight);
	}

	function onDeepLink() {
		let url = `wandelar://wandelar.be/invite/${routeId}`;
		window.location.href = url;
	}

	function obscureText(text, characters) {
		let amount = characters - 3;
		if (text.length > amount) {
			return text.substring(0, amount) + "...";
		}
		return text;
	}

	return (
		<>
			{
				route &&
				<>
					<Helmet>
						<meta property="og:type" content="website"/>
						<meta property="og:title" content={`${route.name[props.lang]}`} />
						<meta property="og:description" content={`${obscureText(route.description[props.lang], 200)}`}/>
						{
							route.images.find(o => o.state === 'icon') ?
								<meta property="og:image" content={`${route.images.find(o => o.state === 'icon').thumbnail}`} />
								:
								<meta property="og:image" content={`https://wandelar.be/img/logo-512.png`} />
						}
						<meta property="og:url" content={`https://wandelar.be/Invite/${routeId}`} />
						<meta property="og:site_name" content="WandelAR"/>
						<meta name="twitter:card" content={`summary`} />
						<meta name="twitter:creator" content={`@WandelAR_be`} />
						<meta name="twitter:site" content={`wandelar.be`} />
						<meta name="twitter:title" content={`${route.name[props.lang]}`} />
						<meta name="twitter:description" content={`${obscureText(route.description[props.lang], 200)}`} />
						{
							route.images.find(o => o.state === 'icon') ?
								<meta name="twitter:image" content={`${route.images.find(o => o.state === 'icon').thumbnail}`} />
								:
								<meta name="twitter:image" content={`https://wandelar.be/img/logo-512.png`} />
						}
					</Helmet>
				</>
			}

			<div className="invite-bg" style={{ height: height }}>
				<div ref={headerRef}>
					{/* <HeaderInvite
						lang={props.lang}
						translations={props.translations}
						isLoading={props.isLoading}
						selected={props.lang}
						options={props.languages}
						changeOption={props.changeOption}
						flags={props.flags}
					/> */}
				</div>
				<div className="row d-flex flex-direction-row justify-content align-items" style={{ height: contentHeight }}>
					<div className="col-4 p-4">
						<div className="card-form smooth-shadow-invite p-4">
							<div className="d-flex flex-direction-row justify-content-space-between align-items">
								<div className="d-flex flex-direction-row justify-content align-items tooltip-down">
									<div className="tooltip invite" data-tooltip={`${props.translations[props.lang].invite.inviteExplain}`}>
										<QuestionIcon />
									</div>
								</div>
								{/* <Select
									lang={props.lang}
									translations={props.translations}
									selected={props.lang}
									options={props.languages}
									changeOption={props.changeOption}
									type={'lang'}
									icons={null}
									disabled={false}
									theme={props.theme}
								/> */}
							</div>
							<div className="d-flex flex-direction-column justify-content align-items text-center">
								<div className="footer m-b-2">
									<Link to="/">
										<div className="logo-content">
											<div className="logo">
												<div className="logo-name">
													<AppLogo />
												</div>
											</div>
										</div>
									</Link>
								</div>
								{/* <div className="fs-3 text-color-gray m-b-1 ff-corben">{props.translations[props.lang].invite.title}</div> */}

								<div className="button-menu d-flex justify-content-end p-0" onClick={onDeepLink}>
									<label className="text-color-accent-0">{props.translations[props.lang].invite.button}</label>
									<div className="icon-white-0 icon-container icon-container-color-bg-accent-0">
										<NewTabIcon />
									</div>
								</div>
								<div className="fs-2 text-color-gray m-b-0 p-1">{props.translations[props.lang].channel.or}</div>
								<div className="fs-1 m-b-0 p-l-1 p-r-1">{props.translations[props.lang].invite.text1}</div>
								<div className="d-flex flex-direction-column justify-content align-items">
									<div className="appstore-logo m-b-0">
										<a href={props.urls.appstore} target="_blank"><AppStoreLogo /></a>
									</div>
									<div className="playstore-logo">
										<a href={props.urls.playstore} target="_blank"><PlayStoreLogo /></a>
									</div>
								</div>

								<div className="fs-15 text-color-gray m-t-2">{props.translations[props.lang].invite.caption}</div>

							</div>
						</div>
					</div>

				</div>
			</div>

		</>
	);
}
export default Invite;