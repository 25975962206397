import { useState, useEffect } from 'react';
import { SelectIcon } from './Icons';

function Select(props) {

    const[isVisible, setVisibility] = useState(false);
    const toggleVisibility = () => setVisibility(!isVisible);

    const result = props.options.find(e => e.key === props.selected)

    return (
		<>
            <div
                className={`selectionBox align-ver ${ !props.disabled ? 'pointer active' : undefined } ${ props.theme }`}
                onClick={ !props.disabled ? toggleVisibility : undefined }
                style={{ border: props.border === false && 'none'}}
            >
                <div className="selectBox">
                    {
                        props.icons !== null && <div className="icon">
                            <result.icon />
                        </div>
                    }
                    <label>{ props.translations[props.lang][props.selected] }</label>
                    <div className={`arrow ${ isVisible ? 'active' : '' }`}>
                    {
                        !props.disabled && <SelectIcon />
                    }
                    </div>
                </div>
                <div id={`select_${props.type}`} className={`selectContent smooth-shadow ${ isVisible ? 'active' : 'pointer-none' }`}>
                    {props.options.map(option => {
                        return <div className="pointer" key={option.id} onClick={() => {return (props.changeOption(option.key, props.type))}}>
                            <div className="list-item">
                                {
                                    (props.icons !== null && option.icon) && <option.icon />
                                }
                                <span className={ (props.icons !== null && option.icon) ? 'p-l-1' : undefined }>{ props.translations[props.lang][option.key] }</span>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            
        </>
	);
}

export default Select;