import { useEffect } from "react";
import { NavLink as Link, useParams } from "react-router-dom";

import { LoginIcon } from './Icons';
import Header from "./Header";
import SocialBanner from "./SocialBanner";
import Footer from "./Footer";

function Welcome(props) {

	let { lang } = useParams();

    useEffect ( () => {
        if (lang) {
            props.changeOption(lang, "lang");
        }
    }, [])

	return (
		<>
		<div className="mask-container mask-container-h-500">
			<div className="mask-shape mask-scale-120">
				<div className="mask-box mask-box-color"></div>
			</div>
			<div className="mask-box mask-top content">
			</div>
		</div>
		<Header
			lang={props.lang}
			translations={props.translations}
			isLoading={props.isLoading}
			selected={props.lang}
			options={props.languages}
			changeOption={props.changeOption}
			flags={props.flags}
		/>
		<div className="row banner">
			<div className="col-6 d-flex justify-content-start">
				<div className="d-block">
					
				</div>
			</div>
		</div>
		<div className="row pricing-w d-flex justify-content">
			<div className="col-6 m-1">
				<div className="card-form smooth-shadow p-1">
					<div className="row banner p-0">
						<h3>{ props.translations[props.lang].welcome.title }</h3>
						<h2>{ props.translations[props.lang].welcome.subtitle }</h2>
					</div>		
					<Link to="/Login">
						<div className="d-flex flex-direction-row justify-content m-b-2">
							<div className="button d-flex justify-content-end p-0">
								<label className="text-color-0">{ props.translations[props.lang].welcome.button }</label>
								<div className="icon-accent-0 icon-container icon-container-color-bg-white-0">
									<LoginIcon />
								</div>
							</div>
						</div>
					</Link>
				</div>
			</div>
			
		</div>

		<div className="pricing-container pricing-icons m-b-2">
			<div className="pricing-box pricing-box-content">
				<label>{ props.translations[props.lang].welcome.bigtitle }</label>
			</div>
		</div>

		<SocialBanner
			lang={props.lang}
			translations={props.translations}
			urls={props.urls}
		/>
		<Footer
			theme={props.theme}
			themes={props.themes}
			lang={props.lang}
			languages={props.languages}
			translations={props.translations}
			changeOption={props.changeOption}
			themeIcons={props.themeIcons}
			flags={props.flags}
			urls={props.urls}
		/>
	</>
	);
}
export default Welcome;