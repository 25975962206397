import Menu from './Menu';

function Events(props) {

	return (
		<>
			<Menu 
				lang={props.lang}
				translations={props.translations}
				isMenu={props.isMenu}
				toggleMenu={props.toggleMenu}
			/>
			<div className="page-box home_content">
				<div className="title">{ props.translations[props.lang].events.title }</div>
				<p className="subtitle">{ props.translations[props.lang].events.subtitle }</p>
			</div>
		</>
	);
}
export default Events;