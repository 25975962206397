import Header from './Header';
import SocialBanner from './SocialBanner';
import Footer from './Footer';

import { AppStoreLogo, PlayStoreLogo, AppIcon, CompassIcon, PlayIcon } from './Icons'

import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

function Homepage(props) {

	let { lang } = useParams();

    useEffect ( () => {
        if (lang) {
            props.changeOption(lang, "lang");
        }
    }, [])

	return (
		<>
			<Helmet>
				{/* Open graph tags */}
				<meta property="og:type" content={`website`}/>
				<meta property="og:title" content={`WandelAR`}/>
				<meta property="og:description" content={`${props.translations[props.lang].tagline}`}/>
				<meta property="og:image" content={`https://wandelar.be/img/logo-512.png`}/>
				<meta property="og:url" content={`https://wandelar.be/`}/>
				<meta property="og:site_name" content="WandelAR"></meta>
				{/* Twitter tags */}
				<meta name="twitter:card" content={`${props.translations[props.lang].tagline}`}/>
				<meta name="twitter:site" content={`wandelar.be`}/>
				<meta name="twitter:title" content={`WandelAR`}/>
				<meta name="twitter:description" content={`${props.translations[props.lang].bio}`}/>
				<meta name="twitter:image" content={`https://wandelar.be/img/logo-512.png`}/>
			</Helmet>
			<Header
				lang={props.lang}
				translations={props.translations}
				isLoading={props.isLoading}
				selected={props.lang}
				options={props.languages}
				changeOption={props.changeOption}
				flags={props.flags}
			/>
			<div className="canvas" style={{ height: window.innerHeight }}>
				<div className="row homepage-title-bg w-100">
					<div className="col-10">
						<div className="homepage-title-box">
							<label className="homepage-title">{props.translations[props.lang].homepage.title}</label>
							<label className="homepage-subtitle">{props.translations[props.lang].homepage.subtitle}</label>
						</div>
					</div>
				</div>
			</div>
			<div className="scroll-down">
				<label>{props.translations[props.lang].homepage.scroll}</label>
			</div>
			<div className="compass">
				<div className="compass-bg">
					<CompassIcon />
				</div>
			</div>
			<div className="map">
				<div className="map-bg"></div>
				<div className="map-content">
					<div className="row">
						<div className="col-7 d-flex flex-direction-column justify-content align-items">
							<div className="title m-b-2">{props.translations[props.lang].map.title}</div>
							<div className="subtitle m-b-1">{props.translations[props.lang].map.subtitle}</div>
							<div className="text m-b-2">{props.translations[props.lang].map.text}</div>
							<a href={props.urls.demo} target="_blank" className="button-menu d-flex justify-content-end p-0">
								<label className="text-color-accent-0">{props.translations[props.lang].map.play}</label>
								<div className="icon-white-0 icon-container icon-container-color-bg-accent-0">
									<PlayIcon />
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>

			<div className="row homepage">
				<div className="icons-homepage">
					<div className="row">
						<div className="col-6 d-flex flex-direction-column">
							<div className="col-6 d-flex justify-content-end">
								<div className="icons icon-1 rotate-90 m-b-1">
									<AppIcon />
								</div>
							</div>
							<div className="icons icon-2 rotate-45 m-b-3">
								<AppIcon />
							</div>
							<div className="col-6 d-flex justify-content-end">
								<div className="icons icon-3 rotate-135 m-b-2">
									<AppIcon />
								</div>
							</div>
						</div>
						<div className="col-6 d-flex flex-direction-column align-items">
							<div className="col-6 d-flex justify-content-end">
								<div className="icons icon-5 rotate-180 m-b-3">
									<AppIcon />
								</div>
							</div>
							<div className="icons icon-6 rotate-45">
								<AppIcon />
							</div>
							<div className="col-6 d-flex justify-content-end">
								<div className="icons icon-4 rotate-225">
									<AppIcon />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="homepage-content">
					<label className="title m-b-2">{props.translations[props.lang].homepage.info.title}</label>
					<label className="subtitle m-b-2">{props.translations[props.lang].homepage.info.subtitle}</label>
					<div className="stores">
						<div className="appstore-logo m-0">
							<a href={props.urls.appstore} target="_blank"><AppStoreLogo /></a>
						</div>
						<div className="playstore-logo m-0">
							<a href={props.urls.playstore} target="_blank"><PlayStoreLogo /></a>
						</div>
					</div>
				</div>

			</div>

			<div className="card-homepage-container">
				<div className="row m-b-2">
					<div className="col-3">
						<div className="card-homepage smooth-shadow">
							<div className="move"></div>
							<div className="body">
								<div className="title m-b-1 m-t-1">{props.translations[props.lang].homepage.card1.title}</div>
								<div className="subtitle m-b-2">{props.translations[props.lang].homepage.card1.subtitle}</div>
								<div className="text1 m-b-1">{props.translations[props.lang].homepage.card1.text1}</div>
								<div className="text2">{props.translations[props.lang].homepage.card1.text2}</div>
							</div>
						</div>
					</div>
					<div className="col-3">
						<div className="card-homepage smooth-shadow">
							<div className="game"></div>
							<div className="body">
								<div className="title m-b-1 m-t-1">{props.translations[props.lang].homepage.card2.title}</div>
								<div className="subtitle m-b-2">{props.translations[props.lang].homepage.card2.subtitle}</div>
								<div className="text1 m-b-1">{props.translations[props.lang].homepage.card2.text1}</div>
								<div className="text2">{props.translations[props.lang].homepage.card2.text2}</div>
							</div>
						</div>
					</div>
					<div className="col-3">
						<div className="card-homepage smooth-shadow">
							<div className="custom"></div>
							<div className="body">
								<div className="title m-b-1 m-t-1">{props.translations[props.lang].homepage.card3.title}</div>
								<div className="subtitle m-b-2">{props.translations[props.lang].homepage.card3.subtitle}</div>
								<div className="text1 m-b-1">{props.translations[props.lang].homepage.card3.text1}</div>
								<div className="text2 m-b-1">{props.translations[props.lang].homepage.card3.text2}</div>
								<div className="text3">{props.translations[props.lang].homepage.card3.text3}</div>
							</div>
						</div>
					</div>
					<div className="col-3">
						<div className="card-homepage smooth-shadow">
							<div className="win"></div>
							<div className="body">
								<div className="title m-b-1 m-t-1">{props.translations[props.lang].homepage.card4.title}</div>
								<div className="subtitle m-b-2">{props.translations[props.lang].homepage.card4.subtitle}</div>
								<div className="text1 m-b-1">{props.translations[props.lang].homepage.card4.text1}</div>
								<div className="text2">{props.translations[props.lang].homepage.card4.text2}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row m-b-3 row-content">
				<div className="col-4">
					<div className="homepage-6">
						<div className="title m-b-1 m-t-1">{props.translations[props.lang].homepage.teambuilding.title}</div>
						<div className="subtitle m-b-2">{props.translations[props.lang].homepage.teambuilding.subtitle}</div>
						<div className="text m-b-1">{props.translations[props.lang].homepage.teambuilding.text}</div>
					</div>
				</div>
				<div className="col-4">
					<div className="compass-6">
						<div className="team">
							<img></img>
						</div>
					</div>
				</div>
			</div>

			<div className="row m-b-3 row-content">
				<div className="col-4">
					<div className="compass-6">
						<div className="discover">
							<img></img>
						</div>
					</div>
				</div>
				<div className="col-4">
					<div className="homepage-6">
						<div className="title m-b-1 m-t-1">{props.translations[props.lang].homepage.discover.title}</div>
						<div className="subtitle m-b-2">{props.translations[props.lang].homepage.discover.subtitle}</div>
						<div className="text m-b-1">{props.translations[props.lang].homepage.discover.text}</div>
					</div>
				</div>
			</div>

			<SocialBanner
				lang={props.lang}
				translations={props.translations}
				urls={props.urls}
			/>
			<Footer
				theme={props.theme}
				themes={props.themes}
				lang={props.lang}
				languages={props.languages}
				translations={props.translations}
				changeOption={props.changeOption}
				themeIcons={props.themeIcons}
				flags={props.flags}
				urls={props.urls}
			/>
		</>
	);
}

export default Homepage;