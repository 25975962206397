import { uk_en, be_nl, be_fr, de_de } from "../Components/Icons";

export const languages = [
    {
        id: 0,
        key: "us_en",
        name: "English",
        icon: uk_en,
    }, 
    {
        id: 1,
        key: "be_nl",
        name: "Nederlands",
        icon: be_nl,
    },
    {
        id: 2,
        key: "be_fr",
        name: "Français",
        icon: be_fr,
    },
    // {
    //     id: 3,
    //     key: "de_de",
    //     name: "Deutsch",
    //     icon: de_de,
    // }
]

export const flags = {
    us_en: uk_en,
    be_nl: be_nl,
    be_fr: be_fr,
    de_de: de_de,
}

export const translations = {
    us_en: {
        loading: {
            title: "Loading..."
        },
        tagline: "Walking with games",
        bio: "Walk. Play. Discover. Games for team building, tourism and education.",
        us_en: "EN",
        be_nl: "NL",
        be_fr: "FR",
        de_de: "DE",
        menu: {
            menu: "Menu",
            howitworks: "How it works?",
            pricing: "Tariff",
            login: "Login",
            register: "Register",
            hello: "Hi",
            dashboard: "Dashboard",
            solutions: "Our solutions",
            teambuilding: "Teambuilding",
            discover: "Discover a location",
            custom: "Tailor-made",
            about: "About us",
            story: "Our story",
            team: "Team",
            contact: "Contact",
            home: {
                configurator: "All routes",
                dashboard: "Dashboard",
                organise: "Organise",
                events: "Events",
                settings: "Settings",
                profile: "Profile",
                manage: "Admin",
                orienteering: "Orienteering",
                invoices: "Invoices",
                billing: "Billing",
                account: "Account"
            }
        },
        pins: {
            pin1: {
                title: "Start"
            },
            pin2: {
                title: "Play game"
            },
            pin3: {
                title: "End"
            },
            pin4: {
                title: "Player",
                subtitle: "Team",
                time: "00:32:43",
                points: "100"
            }
        },
        map: {
            title: "Teambuilding, Tourism, Education",
            subtitle: "The WandelAR app combines extended and augmented reality games with the popular orienteering, creating an experience not to be missed.",
            text: "All you need as a participant is a smartphone or tablet and our WandelAR app.",
            play: "Play video"
        },
        homepage: {
            title: "Walking with games",
            subtitle: "Walk. Play. Grow. Games for team building, tourism and education.",
            scroll: "Scroll down",
            info: {
                title: "What will you get?",
                subtitle: "Download our app and start a FREE route",
                button: "Demo video"
            },
            sponsor: {
                title: "Sponsor with your own logo",
                subtitle: "Upload your own company logo and personalise the app in style",
                button: "Our prices"
            },
            route: {
                bigtitle: "Organise route",
                title: "Create your own route",
                subtitle: "Enter the date of your orientation run and receive a QR code to start the run",
                button: "Contact us"
            },
            card1: {
                title: "Movement",
                subtitle: "Orientation Walk",
                text1: "In an orienteering run, you try to get from start to finish as quickly as possible.",
                text2: "Create your own route and send us the date of your orientation run and receive a QR code to start the run."
            },
            card2: {
                title: "Fun",
                subtitle: "Games",
                text1: "The checkpoints are provided with extended reality games in the form of digital interactive games.",
                text2: "From word puzzles to scavenger hunts and from rebuses to escape rooms. Something for everyone!"
            },
            card3: {
                title: "Custom",
                subtitle: "Everything is possible!",
                text1: "Personalise the app with your own logo.",
                text2: "Do you want to incorporate our standard games into your orientation course or do you prefer custom-made games?",
                text3: "Everything is possible!"
            },
            card4: {
                title: "Community",
                subtitle: "Play to win",
                text1: "Play the orientation game with your team and strengthen your position in the leader board. Climb to the top and compare yourself with others.",
                text2: "Go for the fastest time and/or the most points."
            },
            teambuilding: {
                title: "Teambuilding",
                subtitle: "Onboarding and tours",
                text: "Organise an unseen experience for you and your colleagues. Provide us with a start, end point and checkpoints and we will give you an unforgettable experience.",
                button: "More info"
            },
            discover: {
                title: "Discover a location",
                subtitle: "In the city, school or provincial domain",
                text: "Your visitor discovers the location in an unprecedented way.",
                button: "More info"
            },
        },
        howitworks: {
            title: "How does it work?",
            subtitle: "Read our frequently asked questions to better understand WandelAR.",
            bigtitle: "How it works",
            faq1: {
                title: "How can I start an orientation course?",
                text1: "Download the WandelAR app from the AppStore (for iOS) or PlayStore (for Android). In the app you can find an overview of all current and upcoming orienteering events. Choose a run and go to the start address on the day of the orientation run. Then scan the QR code of the orienteering run organiser and start the run.",
            },
            faq2: {
                title: "What are themes?",
                text1: "Each organiser of an orienteering event chooses its own theme. The theme is translated into a game that is played during the orienteering run. At each checkpoint, you earn points by playing a game that allows you to finish higher on the leader board.  The player who earns the most points and completes the route the fastest wins the orienteering race.",
            },
            faq3: {
                title: "Can I personalise my orientation course?",
                text1: "If you choose to sponsor your orientation run, your logo will appear in our app on the calendar page and on the map.",
            },
            faq4: {
                title: "What if there are more people participating than I have signed up for?",
                text1: "When creating your orientation run, we set a start and end date and the number of participants you expect. If the QR code is scanned outside the given interval, the orienteering run will not start. If the number of participants is exceeded, the orienteering course will not start either.",
            }
        },
        pricing: {
            title: "Price calculator",
            subtitle: "Select a theme, enter the number of participants and choose if you want to sponsor with your own logo",
            send: "Send",
            bigtitle: "Prices",
            chooseTheme: "Choose a theme",
            theme: "Theme",
            amountParticipantsAsk: "Specify the number of participants",
            pricePerParticipant: "/ participant",
            placeholderAmount: "E.g. 10",
            extraProducts: "Extra products",
            sponsor: "Sponsor",
            withOwnLogo: "With your own logo",
            amountParticipants: "Number of participants",
            participants: "participants",
            summary: "Summary",
            total: "Total",
            vatExcluded: "VAT excluded",
            sendAsk: "Send us your price calculation without obligation.*",
            email: "E-mail",
            emailPlaceholder: "E.g. ella@WandelAR.be",
            phone: "Telephone number",
            phonePlaceholder: "E.g. +32 123 456 789",
            sendCaption: "After we have received the price calculation, a WandelAR team member will contact you to finalise the order."
        },
        socialbanner: {
            tagline: "Join us on social media"
        },
        footer: {
            tagline: "Walk. Play. Discover.",
            pricing: "Pricing",
            contact: "Contact",
            copyright: "© WandelAR. All rights reserved.",
            privacy: "Privacy Policy",
            terms: "Terms & Conditions"
        },
        monoLight: "Mono light",
        monoDark: "Mono dark",
        themes: {
            theme_0: {
				name: "None",
				description: "None"
			},
            theme_1: {
				name: "Shoot that ball",
				description: "Arcade basketball game"
			},
            theme_2: {
				name: "Custom experience",
				description: "Contact us for pricing"
			}
        },
        products: {
            product_0: {
				name: "Sponsor",
				description: "With your own logo"
			},
            product_1: {
				name: "Publish route",
				description: "on the calendar"
			}
        },
        toast: {
            ok: "Ok"
        },
        errors: {
            participantsCount: {
                title: "Error",
                msg: "Participant count is 0. Make sure the amount is between 1 and 10000.",
                caption: "Participants"
            },
            participantsExceeds: {
                title: "Error",
                msg: "Participant count exceeds 10000. Make sure the amount is between 1 and 10000.",
                caption: "Participants"
            },
            onlyNumbers: {
                title: "Error",
                msg: "Only numbers are allowed.",
                caption: "Participants"
            },
            email: {
                title: "Error",
                msg: "The e-mail address entered is invalid. An e-mail address looks like this: name@domain.com",
                caption: "E-mail"
            },
            phone: {
                title: "Error",
                msg: "The entered telephone number is invalid. A telephone number can only contain numbers, the characters +/.- and spaces.",
                caption: "Telephone number"
            }
        },
        messages: {
            msg404: {
                title: "Error",
                msg: "Not found.",
                caption: "Code 404"
            },
            msg500: {
                title: "Error",
                msg: "Unexpected condition.",
                caption: "Code 500"
            },
            msg201: {
                title: "Success",
                msg: "Thank you for the non-binding price calculation. A WandelAR team member will contact you as soon as possible to finalise your order.",
                caption: "Pre-order"
            }
        },
        login: {
            title: "Login",
            subtitle: "Log in and discover the analytics of your walks in a clear dashboard.",
            email: "E-mail",
            emailPlaceholder: "E.g. ella@WandelAR.be",
            password: "Password",
            passwordPlaceholder: "•••••••••••",
            forgotPassword: "Forgot password?",
            login: "Login",
            noAccountAsk: "Don't have an account yet?",
            registerHere: "Register here",
            bigtitle: "Login",
            button: "Login",
            messages: {
                msg401: {
                    title: "Error",
                    msg: "Unauthorized access.",
                    caption: "Code 401"
                },
                msg500: {
                    title: "Error",
                    msg: "Unexpected condition.",
                    caption: "Code 500"
                },
                msg200: {
                    title: "Success",
                    msg: "Successful login.",
                    caption: "Login"
                },
                msg402: {
                    title: "Error",
                    msg: "Your account has not been activated. Please check your e-mail and activate your account. Contact us if you haven't received an e-mail.",
                    caption: "Login"
                }
            },
        },
        register: {
            title: "Register",
            subtitle: "Create an account and login to your dashboard.",
            firstname: "Firstname",
            firstnamePlaceholder: "E.g. Ella",
            lastname: "Lastname",
            lastnamePlaceholder: "E.g. WandelAR",
            email: "E-mail",
            emailPlaceholder: "E.g. ella@WandelAR.be",
            password: "Password",
            passwordPlaceholder: "•••••••••••",
            declaration1: "By continuing, you declare that you have read and accepted the",
            privacyPolicy: "Privacy Policy",
            declaration2: "and the",
            terms: "Terms and Conditions",
            declaration3: "of WandelAR.",
            register: "Register",
            accountAsk: "Do you already have an account?",
            loginHere: "Login here",
            bigtitle: "Register",
            button: "Register",
            messages: {
                msg401: {
                    title: "Error",
                    msg: "Unauthorized access.",
                    caption: "Code 401"
                },
                msg500: {
                    title: "Error",
                    msg: "Unexpected condition.",
                    caption: "Code 500"
                },
                msg201: {
                    title: "Success",
                    msg: "Thank you for registering. You will soon receive an e-mail to activate your login. Please check your spam inbox to be sure.",
                    caption: "Register"
                },
                msg405: {
                    title: "Error",
                    msg: "Password must be at least 8 characters long.",
                    caption: "Register"
                },
                msg406: {
                    title: "Error",
                    msg: "No password provided.",
                    caption: "Register"
                },
                msg411: {
                    title: "Error",
                    msg: "Please agree with our Privacy Policy and Terms and Conditions.",
                    caption: "Register"
                },
                msg412: {
                    title: "Error",
                    msg: "Please agree with our Terms.",
                    caption: "Register"
                },
                msg409: {
                    title: "Error",
                    msg: "The e-mail address entered already exists.",
                    caption: "Register"
                },
                msg410: {
                    title: "Error",
                    msg: "The e-mail address entered is invalid. An e-mail address looks like this: name@domain.com",
                    caption: "Register"
                },
                msg413: {
                    title: "Error",
                    msg: "Firstname must be at least 1 character long and maximum 254 characters long.",
                    caption: "Register"
                },
                msg414: {
                    title: "Error",
                    msg: "Lastname must be at least 1 character long and maximum 254 characters long.",
                    caption: "Register"
                }
            }
        },
        forgot: {
            title: "Forgot password?",
            subtitle: "Enter your e-mail address below and you will receive an e-mail with a link to reset your password.",
            email: "E-mail",
            emailPlaceholder: "E.g. ella@WandelAR.be",
            button: "Request new password",
            bigtitle: "Forgot",
            messages: {
                msg200: {
                    title: "Success",
                    msg: "Instructions for setting a new password have been sent to your e-mail address. Please check your mailbox soon and don't forget to check your spam folder!",
                    caption: "Forgot password"
                },
                msg409: {
                    title: "Error",
                    msg: "This e-mail address is not linked to a WandelAR account.",
                    caption: "Forgot password"
                },
                msg410: {
                    title: "Error",
                    msg: "The e-mail address entered is invalid. An e-mail address looks like this: name@domain.com",
                    caption: "Forgot password"
                },
            }
        },
        reset: {
            title: "Reset password",
            subtitle: "Enter a new password",
            password: "New password",
            passwordPlaceholder: "•••••••••••",
            button: "Set new password",
            bigtitle: "Password",
            messages: {
                msg200: {
                    title: "Success",
                    msg: "Password changed successfully. We have send you a confirmation e-mail.",
                    caption: "Reset password"
                },
                msg404: {
                    title: "Error",
                    msg: "Unknown code.",
                    caption: "Reset password"
                },
                msg405: {
                    title: "Error",
                    msg: "Password must be at least 8 characters long.",
                    caption: "Reset password"
                },
                msg406: {
                    title: "Error",
                    msg: "No password provided.",
                    caption: "Reset password"
                },
            }
        },
        configurator: {
            title: "Configurator",
            subtitle: "Overzicht van alle routes.",
            searchPlaceholder: "Search for a walk"
        },
        dashboard: {
            title: "Welcome to your dashboard",
            subtitle: "Coming soon",
            hello: "Hi"
        },
        organise: {
            title: "Organise",
            subtitle: "Coming soon",
        },
        events: {
            title: "Events",
            subtitle: "Coming soon",
        },
        invoices: {
            title: "Invoices",
            subtitle: "Coming soon"
        },
        settings: {
            title: "Settings",
            subtitle: "Change the colours and language of the website here.",
        },
        profile: {
            title: "Profile",
            subtitle: "Details",
            logout: "Logout",
            username: "Username",
            usernameExplain: "Your username is unique and can be used to share your public page.",
            usernamePlaceholder: "Enter a unique username",
            channel: "Channel link",
            channelExplain: "This is your channel link. You can showcase your created routes and share it with your followers. Setup a custom and unique username to personalise your channel link.",
            error: {
                code409: {
                    set: {
                        title: "Error",
                        msg: "Username is already set",
                        caption: "Username"
                    },
                    taken: {
                        title: "Error",
                        msg: "Username is already taken",
                        caption: "Username"
                    },
                    user: {
                        title: "Error",
                        msg: "User does not exist",
                        caption: "Username"
                    },
                },
                code413: {
                    min: {
                        title: "Error",
                        msg: "Username must be at least 3 characters long",
                        caption: "Username"
                    },
                    max: {
                        title: "Error",
                        msg: "Username must be maximum 30 characters long",
                        caption: "Username"
                    },
                    char: {
                        title: "Error",
                        msg: "Your username may only consist of alphanumeric cha…), underscores (_), dashes (-) and full stops (.)",
                        caption: "Username"
                    },
                },
                code200: {
                    title: "Success",
                        msg: "Your username has been updated. Make sure to inform your followers of your channel link change.",
                        caption: "Username"
                }
            }
        },
        notfound: {
            title: "Error 404",
            subtitle: "Oops! Something went wrong :s",
            caption: "Page not found"
        },
        welcome: {
            title: "Welcome",
            subtitle: "Your account is activated.",
            button: "Login",
            bigtitle: "Welcome"
        },
        orderType_0: "By name",
        orderType_1: "Newest",
        orderType_2: "Oldest",
        orderType_3: "Last updated",
        noRoutesTitle: "Create your first route",
        noRoutesSubTitle: "Press the 'plus' button to create a new route.",
        noRoutesText: "Hint: Toggle 'grid' and 'list' view ;)",
        noRoutesFoundTitle: "No walks found",
        noRoutesFoundSubTitle: "Sorry, we could not find any results for your search.",
        noRoutesFoundText: "Hint: Use filters to refine your search.",
        createdOn: "Created on",
        months: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ],
        days: [
            "Sun",
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat"
        ],
        filter: {
            title: "Filters",
            apply: "Apply and search",
            clearAll: "Clear all",
            location: {
                search: "Search by location",
                error: {
                    title: "Error",
                    msg: "To filter by location, enter a location or tick 'near me'",
                    caption: "Location"
                }
            },
            locationPlaceholder: "Enter location",
            distance: "Route distance",
            age: "Age",
            withGames: "Only routes with game",
            noGames: "Only routes without game",
            withTimer: "Only routes with timer",
            noTimer: "Only routes without timer",
            range: "Range",
            nearMe: "In my area",
            radius: "Search radius",
            year: "y",
            geo: {
                error: {
                    title: "Error",
                    msg: "To use 'Near me', your location must be on.",
                    caption: "Near me"
                }
            }
        },
        course: {
            share: "Share link",
            shareExplain: "You can share this link with your friends, family or collegues",
            new: {
                error: {
                    title: 'Error',
					msg: 'Something went wrong while saving the route data. Please try again!',
					caption: 'Save new route'
                }
            },
            ok: "Ok",
            routePlaceholder: "Untitled route",
            addCheckpoint: "Checkpoint",
            noCheckpointsTitle: "No checkpoints yet",
            noCheckpointsSubtitle1: "It seems that you have not created any checkpoints yet.",
            noCheckpointsSubtitle2: "Click 'Edit' to add checkpoints.",
            noCheckpointsText1: "Press the 'plus' button",
            noCheckpointsText2: "to create a checkpoint.",
            noImagesTitle: "No images yet",
            noImagesSubtitle: "It seems that you have not added any images yet.",
            noImagesText1: "Press the 'edit' button",
            noImagesText2: "to add images.",
            legend: "Legend",
            scrollToZoom: "Scroll to zoom",
            clickAndDragMapToMove: "Click and drag map to move",
            clickMapToAddPin: "Click map to add pin",
            clickAndDragPinToMove: "Click and drag pin to move",
            placeholderSearch: "Search for a location",
            fitRoute: "Fit to map",
            dragPin: "Drag pin to move",
            dragToCrop: "Drag to crop",
            dragToCropTitle: "Crop and preview image",
            carousel: "Carousel",
            create: "Create route",
            upload: {
                remove: "Please remove all unsupported files.",
                dragDrop: "Drag & Drop images here or click to upload",
                allowed: "Only *.jpg, *.jpeg and *.png are allowed",
                unsupported: "Unsupported file",
                unsupportedSize: "Only files of maximum 3Mb are allowed",
                uploading: "uploading",
                uploaded: "uploaded",
                file: "file",
                files: "files",
                noUploadProgress: "No upload in progress"
            },
            copied: {
                title: "Copied",
                caption: "To clipboard"
            },
            placePin: {
                title: "Place checkpoint",
                subtitle: "Zoom and pan to adjust",
                close: "Close",
                add: "Add"
            },
            date: {
                created: "Created at",
                updated: "Last update",
                event: "Date of event",
                eventExplain: "Date for the start and end of your event. When both blank the route will be hidden. If you want to show a published route enter at least an end date.",
                start: "From",
                end: "To",
                today: "Today",
                continuous: "Continuous",
                clear: "Clear dates"
            },
            title: "Course",
            subtitle: "Coming soon",
            back: "Back",
            edit: "Edit",
            save: "Save",
            cancel: "Cancel",
            icon: "Route icon",
            iconImage: "icon",
            isIcon: "Set as icon",
            isSponsor: "Set as sponsor image",
            iconExplain: "Only images of 270x270 px are allowed.",
            namePlaceholder: "Enter a route name ...",
            descriptionPlaceholder: "Enter a route description ...",
            publish: "Publish",
            stop: "Stop",
            published: "Published",
            notPublished: "Not published",
            name: "Route name in the WandelAR app",
            description: "Route description",
            type: "Type",
            status: "Status",
            download: "Download original",
            social: "Social media download",
            socialTag1: "Scan QR-code",
            socialTag2: "to start the route",
            age: "Age",
            ageMin: "Minimum",
            ageMinPlaceholder: "Enter minimum age",
            ageMax: "Maximum",
            ageMaxPlaceholder: "Enter maximum age",
            url: "Social media link",
            urlName: "Name",
            urlNamePlaceholder: "Enter website name",
            urlUrl: "URL",
            urlUrlPlaceholder: "Enter website url",
            sponsor: "Sponsor",
            sponsorName: "Name",
            sponsorNamePlaceholder: "Enter sponsor name",
            sponsorUrl: "Website",
            sponsorUrlPlaceholder: "Enter sponsor website",
            sponsorLogo: "Logo",
            sponsorLogoExplain: "Only 270x270 px images are allowed",
            usersCurrentPlaceholder: "Participants who started the route ...",
            usersTotalPlaceholder: "Enter number or leave blank",
            pin: "Show pin on map",
            wheelchair: "Show wheelchair icon on info page",
            stroller: "Show stroller icon on info page",
            dog: "Show dog icon on info page",
            free: "Route is free to run",
            ads: "Show ads during run",
            skip: "Show skip button on checkpoint page",
            time: "Log time when running",
            pinExplain: "Show location of user on the map with a pulsing pin",
            wheelchairExplain: "Show a wheelchair icon when your route is wheelchair friendly",
            strollerExplain: "Show a stroller icon when your route is stroller friendly",
            dogExplain: "Show a dog bone icon when your route is dog friendly",
            freeExplain: "Your route will be searchable. Users will only be able to continue when free is turned on or when they scan the route QR code from above",
            adsExplain: "During a run users will see ads every 2min and when the app switches between screens",
            skipExplain: "On every checkpoint page there is a skip button. You can hide this button so that players are forced to play the game before they can continue",
            timeExplain: "Log your total time and time in between checkpoints. Turn off if you don't want a route to be competitive with time",
            address: {
                start: "Start address info",
                custom: "Custom explanation",
                street: "Street",
                number: "Number",
                postal: "Postal code",
                city: "City",
                region: "Region",
                country: "Country",
                explain: "If an address is not descriptive enough you can add additional information that describes the starting point. For example, 'Look for the red front door'",
                placeholders: {
                    start: "Enter a start address",
                    custom: "Enter a description of the start address",
                    street: "Enter a street name",
                    number: "Enter a street number",
                    postal: "Enter a postal code",
                    city: "Enter a city",
                    region: "Enter a region",
                    country: "Enter a country",
                }
            },
            price: "Price",
            distance: "Distance",
            estimation: "Duration",
            pricePlaceholder: "Enter retail price",
            distancePlaceholder: "Enter route distance",
            estimationPlaceholderHour: "Enter amount of hours",
            estimationPlaceholderMin: "Enter amount of minutes",
            checkpoints: {
                title: "Checkpoints",
                startpoint: "Startpoint",
                checkpoint: "Checkpoint",
                endpoint: "Endpoint",
                explain: "Below you can define the start point, end point and waypoints of the route.",
                none: "No checkpoints",
                longitude: "Longitude",
                latitude: "Latitude",
                longitudePlaceholder: "Enter longitude",
                latitudePlaceholder: "Enter latitude",
                qr: "QR code",
                placeholders: {
                    longitude: "Enter longitude",
                    latitude: "Enter latitude"
                },
                check: {
                    title: "Error",
                    msg: "Checkpoint limit reached. You can add a maximum of 25 checkpoints",
                    caption: "Checkpoints"
                }
            },
            tags: {
                title: "Tags",
                subtitle: "Add a tag",
                placeholders: {
                    empty: {
                        us_en: "Enter a tag",
                        be_nl: "Voer een tag in",
                        be_fr: "Saisir une étiquette"
                    },
                    full: {
                        us_en: "Type and press enter",
                        be_nl: "Typ en druk op enter",
                        be_fr: "Tapez et appuyez sur Entrée"
                    }
                },
                error: {
                    title: "Error",
                    msg: "Fill in all tag translations before adding a tag",
                    caption: "Tags"
                }
            },
            extra: "Extra features",
            images: "Route images",
            imagesExplain: "Best images of 1000x730 px otherwise we crop your image",
            noImages: "No images",
            current: "Current users",
            currentExplain: "Number of participants who walked the route.",
            total: "Maximum users",
            totalExplain: "Maximum users that can walk your route.",
            infinite: "No limit",
            qrcode: "Route QR-code",
            qrcodes: "Checkpoint QR-codes",
            qrcodeExplain: "You can download this QR code and use it to inform participants about this route through, for example, a post on social media.",
            qrcodesExplain: "These are the qrcodes for the startpoint, checkpoints and endpoint. These can be printed on paper and placed on billboards on every checkpoint. The QR-codes can be used as a fallback when there is not enough GPS reception.",
            remove: "Remove",
            removeRoute: "Remove route",
            yes: "Yes",
            no: "No",
            confirmRemoveRoute: {
                title: "Are you sure you want to delete this route?",
                subtitle: "This will delete all route data, including images, text and checkpoints attached to this route."
            },
            saveRoute: {
                minCheckpoint: {
                    title: "Error",
                    msg: "Add a startpoint, one checkpoint and an endpoint.",
                    caption: "Checkpoints"
                },
                invalid: {
                    title: "Error",
                    msg: "Remove all unsupported files",
                    caption: "Images"
                }
            },
            dragUpDown: "Drag images up and down with",
            dragUpDownExplain: "Move the order of how the carousel images show on the route info page in the app. The first image is used as your icon image."
        },
        invite: {
            title: "Welcome to WandelAR",
            subtitle: "Press the button below to load this route and start walking",
            text1: "Download the app",
            text2: "Open the app and press",
            text3: "to scan the QR code",
            caption: "Walk. Play. Discover.",
            what1: "The WandelAR app combines extended and augmented reality games while walking",
            what2: "On every checkpoint you can play a game!",
            button: "Go to app",
            inviteExplain: "The WandelAR app combines extended and augmented reality games while walking. On every checkpoint you can play a game!",
            notFound: "App not found",
            start: "Start the route",
            tap: "Tap",
            ctrl: "Ctrl",
            rightClick: "Right click",
            cmd: "Cmd",
            plus: "+",
            and: "and",
            drag: "drag",
            toRotate: "to rotate",
            or: "or",
            scan: "scan",
            qrcode: "QR-code",
            preview: "to preview",
            game: "Game",
            part: "Part",
            play: "Play",
            time: "On time"
        },
        channel: {
            title: "Walking routes",
            caption: "Walk. Play. Grow.",
            explain: "On this page you can find all routes from",
            poweredBy: "Powered by",
            noRating: "No rating yet",
            routes: "Routes",
            hikes: "Amount of routes",
            hikers: "Amount of walkers",
            rating: "Rating",
            scan: "Scan to start",
            height: "Height",
            or: "or",
            wheelchair: {
                false: "Not accessible",
                true: "Accessible"
            },
            stroller: {
                false: "Not accessible",
                true: "Accessible"
            },
            dog: {
                false: "Not possible with dog",
                true: "Possible with dog"
            }
        },
        qr: "QR-code",
        gps: "GPS",
        Pending: "Pending",
        Active: "Active",
        Cancelled: "Cancelled",
        Done: "Done",
        unsubscribe: {
            title: "You are unsubscribed",
            subtitle: "We are sorry to see you go!",
            button: "Go to homepage"
        },
        privacy: {
            privacyPolicy: "Privacy policy",
            userAgreementAndPrivacyPolicyTitle: "User Agreement and Privacy Policy Statement",
            userAgreementAndPrivacyPolicyText: "Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website and app.",
            userAgreementTitle: "User Agreement",
            userAgreementText1: "WandelAR ('App' or 'Site') provides location-based services, such as mapping outdoor activities by utilizing the geographical position of the user's device. It also contains a user management system to give the user control over the recorded and stored location data.",
            userAgreementText2: "The services are provided by WandelAR. These terms apply between WandelAR and the user of the App, and includes the mobile applications and the associated web sites. Usage of the App assumes that the user fully accepts and approves the conditions that follow. WandelAR disclaims all responsibility for any financial and material damage the App can cause the user.",
            privacyPolicyTitle: "Privacy policy",
            privacyPolicyText1: "The user's profile for the App is acquired from the information the user enters in the App. Only personal data required for the services the App provides is stored, such as name, email address, gender, year of birth and postal code. This is the information required so that when the user chooses to upload results, that these results can be entered into the appropriate gender/age/ categories.",
            privacyPolicyText2: "If the user chooses to upload data, the results website will display:",
            privacyPolicyBullet1: "1. The timing details of their run",
            privacyPolicyBullet2: "2. Their first name and surname",
            privacyPolicyText3: "The email address provided may be used to send an email containing details of the events they have participated in and to provide information on forthcoming events.",
            privacyPolicyText4: "This information may be shared with affiliated Orienteering associations and clubs, consistent with their privacy policies.",
            privacyPolicyText5: "To better understand and serve the users of the App, we may conduct research on our user's results and behaviors based on the personal data and other information provided to us. This research may be compiled and analyzed on an aggregate basis, and we may share this aggregated data with our affiliates, agents and business partners. This aggregate information does not identify users personally. We may also disclose aggregated user statistics in order to describe our services to current and prospective business partners, and to other third parties for other lawful purposes.",
            privacyPolicyText6: "Users can use the App without sending any Personal Data from their device, however, they may not be able to use certain features of the Services.",
            privacyPolicyText7: "If a minor, under 18 years of age, uses the App, we assume that it is with the consent of a legal guardian.",
            privacyPolicyText8: "The Services we offer may change from time to time. As a result, at times it may be necessary for us to make changes to this Privacy Policy. Accordingly, we reserve the right to update or modify this Privacy Policy at any time and from time to time without prior notice. Please review this policy periodically, and especially before you provide any personal data. This Privacy Policy was last updated on the date indicated below. Your continued use of the services after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.",
            privacyPolicyText9: "Should you have concerns with this policy, or would like to request the removal of your information from the system, please provide details to our email address.",
            managingPersonalInformationTitle: "Managing Personal Information",
            managingPersonalInformationText1: "A detailed description of how WandelAR manages personal information is available below.",
            managingPersonalInformationText2: "The system consists of apps for Android (WandelAR) and iPhone (WandelAR), a web frontend and a backend service for storing and processing data. Digital Ocean, Amazon and MongoDB are used as cloud services for user management and data storage.",
            managingPersonalInformationText3: "The user's privacy is important to us and we have implemented a number of functions to balance the desire to share event results with others and to provide control over the personal location data.",
            managingPersonalInformationText4: "The following personal data is handled by the WandelAR system.",
            userDataTitle: "User data",
            userDataBullet1: "1. Every user of the app needs to enter a name that is used for presentation in result lists etc. The name can be the full name (first name and surname), a nickname or similar.",
            userDataBullet2: "2. Email and password is used to identify a registered user.",
            userDataBullet3: "3. If a user participates in an event race without being a registered user the user will be a so-called guest user.",
            userDataBullet4: "4. 'cookies information' of the user (session and persistent cookie files).",
            locationDataTitle: "Location data",
            locationDataBullet1: "1. The location of the user's device is used in a background service during an event race. The location is used to determine if the user is visiting control points (so called punching) and the location is continuously recorded to later on be able to show which path the user has selected to follow to find the different control points.",
            locationDataBullet2: "2. For guest users and registered users the event result will be stored locally in the mobile device. The event result contains location data and consists of the control points visited and the path taken between the control points.",
            locationDataBullet3: "3. The event result is also stored in the cloud storage. Each user, both registered and guest user, has its own private storage for event results that only the user (and WandelAR) can access.",
            locationDataBullet4: "4. Both registered users and guest users can publish their event result to make it accessible by other users. The user can select to publish only the control points visited or the complete path. The event results for one event course is protected by a unique course id. Other users can access the published data if he/she knows the course id.",
            locationDataText1: "Our App uses the MapBox SDK to display maps in the App. By using the Site and App, you agree to MapBox's policies. You can find the policy on this ",
            locationDataText2: "link.",
            cookies: "Cookies",
            cookie1: "Cookie #1",
            cookieDescription11: "These cookies are used to collect information about traffic and how users navigate around our Site. The information is aggregated and therefore completely anonymous, and we have no access to information regarding individual users. Data gathered includes how users arrive on our Site, how they navigate between pages and what device they are using. We use this information to help operate our website more efficiently, to monitor the level of activity on our Site and to provide you with the relevant articles which could be interesting for you. You can find out more information about Google Analytics cookies ",
            cookieDescription12: "here. ",
            cookieDescription13: "You can prevent the use of Google Analytics via this ",
            cookieDescription14: "link.",
            cookieDuration1: "2 years",
            cookieType1: "Analytical",
            cookieParty1: "3rd",
            cookieLink1: "Go to site",
            cookie2: "Cookie #2",
            cookieDescription21: "Used to distinguish users on the Site to generate statistical information about their use of the Site. You can prevent the use of Google Analytics via this ",
            cookieDescription22: "link.",
            cookieDuration2: "1 day",
            cookieType2: "Analytical",
            cookieParty2: "3rd",
            cookieLink2: "Go to site",
            cookie3: "Cookie #3",
            cookieDescription31: "Used to throttle the request rate. You can prevent the use of Google Analytics via this ",
            cookieDescription32: "link.",
            cookieDuration3: "1 minute",
            cookieType3: "Analytical",
            cookieParty3: "3rd",
            cookieLink3: "Go to site",
            cookie4: "Cookie #4",
            cookieDescription4: "Used to store authorization token",
            cookieDuration4: "Session",
            cookieType4: "Technical",
            cookieParty4: "1st",
            cookieLink4: "Read this Privacy Policy",
            cookie5: "Cookie #5",
            cookieDescription51: "Used to throttle number of requests received by users. You can prevent the use of Google Analytics via this ",
            cookieDescription52: "link.",
            cookieDuration5: "1 minute",
            cookieType5: "Analytical",
            cookieParty5: "3rd",
            cookieLink5: "Go to site",
            cookie6: "Cookie #6",
            cookieDescription6: "Used to store user consent status",
            cookieDuration6: "6 month",
            cookieType6: "Technical",
            cookieParty6: "1st",
            cookieLink6: "Read this Privacy Policy",
            cookie7: "Cookie #7",
            cookieDescription7: "This cookie is used to identify the user session. The data gathered is processed only by WandelAR for analysis and reporting. WandelAR uses this information to evaluate the effectiveness and correctness of the WandelAR products.",
            cookieDuration7: "30 minutes",
            cookieType7: "Essential",
            cookieParty7: "1st",
            cookieLink7: "Read this Privacy Policy",
            cookie8: "Cookie #8",
            cookieDescription8: "These cookies contain a unique user ID set upon the activeness and correctness of the WandelAR products.",
            cookieDuration8: "2 years",
            cookieType8: "Essential",
            cookieParty8: "1st",
            cookieLink8: "Read this Privacy Policy",
            cookieName: "Name",
            cookieDescription: "Description",
            cookieDuration: "Duration",
            cookieType: "Type",
            cookieParty: "1st/3rd party",
            cookieLink: "Link to 3rd party website",
            cookiesText1: "A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. We use cookies to help us identify visitors, their usage of the App, their website access preferences, to improve their experience, to understand how our services are being used, to remember your choices, such as your language preferences, and otherwise to customize our services for you. App visitors who do not wish to have cookies placed on their computers (usually located under 'Settings' or 'Preferences') should set their browsers to refuse cookies before using our App, with the drawback that certain features of our App may not function properly without the aid of cookies. Please note that third parties (including for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies.",
            cookiesText2: "In particular, our App uses the following categories of cookies, in our legitimate interest, as described below:",
            cookiesText3: "Your use of our App indicates your consent to such use of cookies. You may disable cookies in your Web browser, but doing so will impact the usability of the website.",
            cookiesText4: "You also can contact us at hello@wandelar.be and our team will help you with your cookie questions.",
            ads: "Ads",
            adsText1: "Ads appearing on any of our App may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by us and does not cover the use of cookies by any advertisers. You can opt out of seeing online interest-based ads from wandelar.be or advertising partners by contacting us at via hello@wandelar.be or through your gadget settings. Please note ad blockers and tools that restrict our cookie use may interfere with these controls.",
            adsText2: "Our App uses a third party advertisement network to show ads in the app. By using the Site and App you agree with the policy of the Unity Ad network. You can find the policy on this ",
            adsText3: "link.",
            deletionOfDataTitle: "Deletion of data",
            deletionOfDataText: "Both registered and guest users can do the following:",
            deletionOfDataBullet1: "1. As a user you can revoke your published event result whenever you want. After revocation of an event result the location data will no longer be accessible by other users.",
            deletionOfDataBullet2: "2. As a user you can also permanently delete your event result, both from the mobile device and the private part of the cloud storage.",
            deletionOfDataBullet3: "3. As a user you can at any time delete your account which will permanently delete all your data (user data and location data) both locally on the device (smartphone) and in the cloud storage.",
            supportTitle: "Support",
            supportText: "Support is provided via email. Contact us at hello@wandelar.be. Please include a precise description about how to repeat the problem, error messages shown and other information that can be useful for us to know. This will make us able to provide rapid feedback.",
            contactDetailsTitle: "Contact details",
            contactDetailsText1: "Developer website: https://www.wandelar.be/",
            contactDetailsText2: "Email: hello@wandelar.be",
            dateLastEdited: "Last edited on 9 June 2022",
        },
        terms: {
            termsAndConditionsStatement: "Terms and Conditions Statement",
            termsAndConditionsStatementText1: "In using WandelAR for an event, users acknowledge and accept the following Event Terms and Conditions Statement.",
            termsAndConditionsStatementText2: "These Terms and Conditions, together with any and all other documents referred to herein, set out the terms of use under which you may use this website, www.wandelar.be and app ('Our App'). Please read these Terms and Conditions carefully and ensure that you understand them. Your agreement to comply with and be bound by these Terms and Conditions is deemed to occur upon your first use of Our App. If you do not agree to comply with and be bound by these Terms and Conditions, you must stop using Our App immediately.",
            riskWaiver: "Risk Waiver",
            riskWaiverText1: "We have read any general risk warning and the specific risk warning provided for this event. We are aware that orienteering is a recreational activity that can be dangerous, accept that there is a degree of risk and acknowledge that we participate in this event at our own risk.",
            riskWaiverText2: "We are aware of, and will comply with Competitors' Rules specified by the International Orienteering Federation and the Orienteering Organisation of the country/region of the event.",
            riskWaiverText3: "We release Orienteering Organizations, affiliated clubs, members and all other parties associated with organizing the event from any responsibility or legal liability associated with our presence at, and participation in, this orienteering event.",
            riskWaiverText4: "To the extent that the Competition and Consumer Act 2010 (or similar law) applies, this release is limited to responsibility and legal liability for death or personal injury and does not apply to significant personal injury caused by reckless conduct by Orienteering Organizations affiliated clubs, members and all other parties associated with organizing the event.",
            generalRiskWarning: "General Risk Warning",
            generalRiskWarningText1: "Competing in an orienteering event is subject to risks. These include death, serious injury or illness due to:",
            generalRiskWarningBullet1: "1. rough terrain and obstacles;",
            generalRiskWarningBullet2: "2. overexertion;",
            generalRiskWarningBullet3: "3. heat, cold or other adverse weather conditions;",
            generalRiskWarningBullet4: "4. plant and animal life; and",
            generalRiskWarningBullet5: "5. accidents with vehicles, other competitors or pedestrians.",
            generalRiskWarningText2: "There are also risks:",
            generalRiskWarningBullet6: "1. that access to medical, evacuation or search services might be slow; and",
            generalRiskWarningBullet7: "2. of damage to, or loss of, your personal property.",
            statements: "Statements",
            statementsBullet1: "1. We are aware there is no health, accident, ambulance, disability or life insurance provided.",
            statementsBullet2: "2. We allow the free use of our names and photos in entry information, results, news, marketing and promotional material pertaining to the event or orienteering. This does not extend to publishing a photo of a person under 18 with their name.",
            statementsBullet3: "3. We will ensure that any children under our control at the event are appropriately supervised at all times. We acknowledge that parents and guardians are responsible for deciding whether their children are capable of orienteering by themselves at each event.",
            definitionsAndInterpretation: "Definitions and Interpretation",
            definitionsAndInterpretationText: "In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:",
            definitionsAndInterpretationBullet1: "1. 'Content' means any and all text, images, audio, video, scripts, code, software, databases and any other form of information capable of being stored on a computer that appears on, or forms part of, Our App; and",
            definitionsAndInterpretationBullet2: "2. 'We/Us/Our' means WandelAR",
            informationAboutUs: "Information About Us",
            informationAboutUsText: "Our App is owned and operated by WandelAR.",
            accessToOurApp: "Access to Our App",
            accessToOurAppBullet1: "1. Access to Our App is free of charge.",
            accessToOurAppBullet2: "2. It is your responsibility to make any and all arrangements necessary in order to access Our App.",
            accessToOurAppBullet3: "3. Access to Our App is provided “as is” and on an “as available” basis. We may alter, suspend or discontinue Our App (or any part of it) at any time and without notice. We will not be liable to you in any way if Our App (or any part of it) is unavailable at any time and for any period.",
            intellectualPropertyRights: "Intellectual Property Rights",
            intellectualPropertyRightsBullet1: "1. All Content included on Our App and the copyright and other intellectual property rights subsisting in that Content, unless specifically labeled otherwise, belongs to or has been licensed by Us. All Content is protected by applicable Belgian and international intellectual property laws and treaties.",
            intellectualPropertyRightsBullet2: "2. You may not reproduce, copy, distribute, sell, rent, sub-licence, store, or in any other manner re-use Content from Our App unless given express written permission to do so by Us.",
            intellectualPropertyRightsBullet21: "2.1. You may:",
            intellectualPropertyRightsBullet211: "2.1.1. Access, view and use Our App in a web browser (including any web browsing capability built into other types of software or app);",
            intellectualPropertyRightsBullet212: "2.1.2. Download Our App (or any part of it) for caching;",
            intellectualPropertyRightsBullet213: "2.1.3. Print pages from Our App;",
            intellectualPropertyRightsBullet214: "2.1.4. Download extracts from pages on Our App; and",
            intellectualPropertyRightsBullet215: "2.1.5. Save pages from Our App for later and/or offline viewing.",
            intellectualPropertyRightsBullet22: "2.2. Our status as the owner and author of the Content on Our App (or that of identified licensors, as appropriate) must always be acknowledged.",
            intellectualPropertyRightsBullet23: "2.3. You may not use any Content saved or downloaded from Our App for commercial purposes without first obtaining a license from Us (or our licensors, as appropriate) to do so. This does not prohibit the normal access, viewing and use of Our App for general information purposes whether by business users or consumers.",
            intellectualPropertyRightsBullet24: "2.4. Nothing in these Terms and Conditions limits covering in particular the making of temporary copies; research and private study; the making of copies for text and data analysis for non-commercial research; criticism, review, quotation and news reporting; caricature, parody or pastiche; and the incidental inclusion of copyright material.",
            intellectualPropertyRightsBullet3: "3. Links to Our App",
            intellectualPropertyRightsBullet31: "3.1. You may link to Our App provided that:",
            intellectualPropertyRightsBullet311: "3.1.1. You do so in a fair and legal manner;",
            intellectualPropertyRightsBullet312: "3.1.2. You do not do so in a manner that suggests any form of association, endorsement or approval on Our part where none exists;",
            intellectualPropertyRightsBullet313: "3.1.3. You do not use any logos or trademarks displayed on Our App without Our express written permission; and",
            intellectualPropertyRightsBullet314: "3.1.4. You do not do so in a way that is calculated to damage Our reputation or to take unfair advantageof it.",
            intellectualPropertyRightsBullet32: "3.2. You may link to any page of Our App.",
            intellectualPropertyRightsBullet33: "3.3. Framing or embedding of Our App on other websites is not permitted without Our express written permission. Please contact Us at hello@wandelar.be for further information.",
            intellectualPropertyRightsBullet34: "3.4. You may not link to Our App from any other site the main content of which contains material that:",
            intellectualPropertyRightsBullet341: "3.4.1. is sexually explicit;",
            intellectualPropertyRightsBullet342: "3.4.2. is obscene, deliberately offensive, hateful or otherwise inflammatory;",
            intellectualPropertyRightsBullet343: "3.4.3. promotes violence;",
            intellectualPropertyRightsBullet344: "3.4.4. promotes or assists in any form of unlawful activity;",
            intellectualPropertyRightsBullet345: "3.4.5. discriminates against, or is in any way defamatory of, any person, group or class of persons, race, sex, religion, nationality, disability, sexual orientation, or age;",
            intellectualPropertyRightsBullet346: "3.4.6. is intended or is otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset, or embarrass another person;",
            intellectualPropertyRightsBullet347: "3.4.7. is calculated or is otherwise likely to deceive another person;",
            intellectualPropertyRightsBullet348: "3.4.8. is intended or otherwise likely to infringe (or threaten to infringe) another person's right to privacy;",
            intellectualPropertyRightsBullet349: "3.4.9. misleadingly impersonates any person or otherwise misrepresents the identity or affiliation of a particular person in a way that is calculated to deceive;",
            intellectualPropertyRightsBullet3410: "3.4.10.implies any form of affiliation with Us where none exists;",
            intellectualPropertyRightsBullet3411: "3.4.11. infringes, or assists in the infringement of, the intellectual property rights (including, but not limited to, copyright, trademarks and database rights) of any other party; or",
            intellectualPropertyRightsBullet3412: "3.4.12. is made in breach of any legal duty owed to a third party including, but not limited to, contractual duties and duties of confidence.",
            intellectualPropertyRightsBullet35: "3.5. You are not, for example, prohibited from posting links on general-purpose social networking sites merely because another user may post such content. You are, however, prohibited from posting links on websites which focus on or encourage the submission of such content from users.",
            intellectualPropertyRightsBullet4: "4. Links to Other Sites",
            intellectualPropertyRightsBullet4Text: "Links to other sites may be included on Our App. Unless expressly stated, these sites are not under Our control. We neither assume nor accept responsibility or liability for the content of third party sites. The inclusion of a link to another site on Our App is for information only and does not imply any endorsement of the sites themselves or of those in control of them.",
            intellectualPropertyRightsBullet5: "5. Disclaimers",
            intellectualPropertyRightsBullet51: "5.1. Nothing on Our App constitutes advice on which you should rely. It is provided for general information purposes only.",
            intellectualPropertyRightsBullet52: "5.2. Insofar as is permitted by law, We make no representation, warranty, or guarantee that Our App will meet your requirements, that it will not infringe the rights of third parties, that it will be compatible with all software and hardware, or that it will be secure.",
            intellectualPropertyRightsBullet53: "5.3. We make reasonable efforts to ensure that the Content on Our App is complete, accurate, and up-to-date. We do not, however, make any representations, warranties or guarantees (whether express or implied) that the Content is complete, accurate, or up-to-date.",
            intellectualPropertyRightsBullet6: "6. Our Liability",
            intellectualPropertyRightsBullet61: "1. To the fullest extent permissible by law, We accept no liability to any user for any loss or damage, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising out of or in connection with the use of (or inability to use) Our App or the use of or reliance upon any Content included on Our App.",
            intellectualPropertyRightsBullet62: "2. To the fullest extent permissible by law, We exclude all representations, warranties, and guarantees (whether express or implied) that may apply to Our App or any Content included on Our App",
            intellectualPropertyRightsBullet63: "3. If you are a business user, We accept no liability for loss of profits, sales, business or revenue; loss of business opportunity, goodwill or reputation; loss of anticipated savings; business interruption; or for any indirect or consequential loss or damage.",
            intellectualPropertyRightsBullet64: "4. We exercise all reasonable skill and care to ensure that Our App is free from viruses and other malware. We accept no liability for any loss or damage resulting from a virus or other malware, a distributed denial of service attack, or other harmful material or event that may adversely affect your hardware, software, data or other material that occurs as a result of your use of Our App (including the downloading of any Content from it) or any other site referred to on Our App.",
            intellectualPropertyRightsBullet65: "5. We neither assume nor accept responsibility or liability arising out of any disruption or non-availability of Our App resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, natural events, acts of war, or legal restrictions and censorship.",
            intellectualPropertyRightsBullet66: "6. Nothing in these Terms and Conditions excludes or restricts Our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be excluded or restricted by law. For full details of consumers’ legal rights, including those relating to digital content.",
            virusesMalwareAndSecurity: "Viruses, Malware and Security",
            virusesMalwareAndSecurityBullet1: "1. We exercise all reasonable skill and care to ensure that Our App is secure and free from viruses and other malware.",
            virusesMalwareAndSecurityBullet2: "2. You are responsible for protecting your hardware, software, data and other material from viruses, malware, and other internet security risks.",
            virusesMalwareAndSecurityBullet3: "3. You must not deliberately introduce viruses or other malware, or any other material which is malicious or technologically harmful either to or via Our App.",
            virusesMalwareAndSecurityBullet4: "4. You must not attempt to gain unauthorized access to any part of Our App, the server on which Our App is stored, or any other server, computer, or database connected to Our App.",
            virusesMalwareAndSecurityBullet5: "5. You must not attack Our App by means of a denial of service attack, a distributed denial of service attack, or by any other means.",
            virusesMalwareAndSecurityBullet6: "6. Any and all digital breaches will be reported to the relevant law enforcement authorities and We will cooperate fully with those authorities by disclosing your identity to them. Your right to use Our App will cease immediately in the event of such a breach.",
            acceptableUsagePolicy: "Acceptable Usage Policy",
            acceptableUsagePolicyBullet1: "1. You may only use Our App in a manner that is lawful. Specifically:",
            acceptableUsagePolicyBullet11: "1.1. you must ensure that you comply fully with any and all local, national or international laws and/or regulations;",
            acceptableUsagePolicyBullet12: "1.2. you must not use Our App in any way, or for any purpose, that is unlawful or fraudulent;",
            acceptableUsagePolicyBullet13: "1.3. you must not use Our App to knowingly send, upload, or in any other way transmit data that contains any form of virus or other malware, or any other code designed to adversely affect computer hardware, software, or data of any kind; and",
            acceptableUsagePolicyBullet14: "1.4. you must not use Our App in any way, or for any purpose, that is intended to harm any person or persons in any way.",
            acceptableUsagePolicyBullet15: "1.5. We reserve the right to suspend or terminate your access to Our App if you materially breach any of the other provisions of these Terms and",
            acceptableUsagePolicyBullet15Text: "Conditions. Specifically, We may take one or more of the following actions:",
            acceptableUsagePolicyBullet151: "1.5.1. suspend, whether temporarily or permanently, your right to access Our App;",
            acceptableUsagePolicyBullet152: "1.5.2. issue you with a written warning;",
            acceptableUsagePolicyBullet153: "1.5.3. take legal proceedings against you for reimbursement of any and all relevant costs on an indemnity basis resulting from your breach;",
            acceptableUsagePolicyBullet154: "1.5.4. take further legal action against you as appropriate;",
            acceptableUsagePolicyBullet155: "1.5.5. disclose such information to law enforcement authorities as required or as We deem reasonably necessary; and/or",
            acceptableUsagePolicyBullet156: "1.5.6. any other actions which We deem reasonably appropriate (and lawful).",
            acceptableUsagePolicyBullet2: "2. We hereby exclude any and all liability arising out of any actions (including, but not limited to those set out above) that We may take in response to breaches of these Terms and Conditions.",
            changesToTheseTermsAndConditions: "Changes to these Terms and Conditions",
            changesToTheseTermsAndConditionsText: "We may alter these Terms and Conditions at any time. Any such changes will become binding on you upon your first use of Our App after the changes have been implemented. You are therefore advised to check this page from time to time. In the event of any conflict between the current version of these Terms and Conditions and any previous version(s), the provisions current and in effect shall prevail unless it is expressly stated otherwise.",
            contactingUs: "Contacting Us",
            contactingUsText: "To contact Us, please email Us at hello@wandelar.be or using any of the methods provided on our contact fome at here",
            communicationsFromUs: "Communications from Us",
            communicationsFromUsBullet1: "1. If We have your contact details, We may from time to time send you important notices by email. Such notices may relate to matters including, but not limited to, service changes and changes to these Terms and Conditions.",
            communicationsFromUsBullet2: "2. We will never send you marketing emails of any kind without your express consent. If you do give such consent, you may opt out at any time. Any and all marketing emails sent by Us include an unsubscribe link. If you opt out of receiving emails from Us at any time, it may take up to 14 business days for Us to comply with your request. During that time, you may continue to receive emails from Us.",
            communicationsFromUsBullet3: "3. For questions or complaints about communications from Us (including, but not limited to marketing emails), please contact Us at hello@wandelar.be or via this form",
            dataProtection: "Data Protection",
            dataProtectionBullet1: "1. All personal information that We may use will be collected, processed, and held in accordance with the provisions of EU Regulation 2016/679 General Data Protection Regulation (“GDPR”) and your rights under the GDPR.",
            dataProtectionBullet2: "2. For complete details of Our collection, processing, storage, and retention of personal data including, but not limited to, the purpose(s) for which personal data is used, the legal basis or bases for using it, details of your rights and how to exercise them, and personal data sharing (where applicable), please refer to Privacy Policy.",
            lawAndJurisdiction: "Law and Jurisdiction",
            lawAndJurisdictionBullet1: "1. These Terms and Conditions, and the relationship between you and Us (whether contractual or otherwise) shall be governed by, and construed in accordance with the law of Belgium",
            lawAndJurisdictionBullet2: "2. If you are a consumer, any dispute, controversy, proceedings or claim between you and Us relating to these Terms and Conditions, or the relationship between you and Us (whether contractual or otherwise) shall be subject to the jurisdiction of the courts of Belgium, Mechelen.",
            lawAndJurisdictionBullet3: "3. If you are a business, any disputes concerning these Terms and Conditions, the relationship between you and Us, or any matters arising therefrom or associated therewith (whether contractual or otherwise) shall be subject to the exclusive jurisdiction of the courts of Belgium, Mechelen.",
        }
    },
    be_nl: {
        loading: {
            title: "Laden..."
        },
        tagline: "Wandelen met games",
        bio: "Wandel. Speel. Ontdek. Games voor teambuilding, toerisme en onderwijs.",
        us_en: "EN",
        be_nl: "NL",
        be_fr: "FR",
        de_de: "DE",
        menu: {
            menu: "Menu",
            howitworks: "Hoe werkt het?",
            pricing: "Tarief",
            login: "Aanmelden",
            register: "Registreren",
            hello: "Hallo",
            dashboard: "Dashboard",
            solutions: "Onze oplossing",
            teambuilding: "Teambuilding",
            discover: "Ontdek een locatie",
            custom: "Op maat",
            about: "Over ons",
            story: "Ons verhaal",
            team: "Team",
            contact: "Contact",
            home: {
                configurator: "Alle routes",
                dashboard: "Dashboard",
                organise: "Organiseer",
                events: "Evenementen",
                settings: "Instellingen",
                profile: "Profiel",
                manage: "Admin",
                orienteering: "Oriëntatieloop",
                invoices: "Facturen",
                billing: "Facturering",
                account: "Account"
            }
        },
        pins: {
            pin1: {
                title: "Start"
            },
            pin2: {
                title: "Speel een spel"
            },
            pin3: {
                title: "Einde"
            },
            pin4: {
                title: "Speler",
                subtitle: "Team",
                time: "00:32:43",
                points: "100"
            }
        },
        map: {
            title: "Teambuilding, toerisme, onderwijs",
            subtitle: "De WandelAR app combineert extended en augmented reality games met de populaire oriëntatielopen, en creëert een niet-te missen ervaring.",
            text: "Het enige wat je als deelnemer nodig hebt, is een smartphone of tablet en onze WandelAR app.",
            play: "Bekijk een video"
        },
        homepage: {
            title: "Wandelen met games",
            subtitle: "Wandel. Speel. Ontdek. Games voor teambuilding, toerisme en onderwijs.",
            scroll: "Scroll naar beneden",
            info: {
                title: "Wat kan je verwachten?",
                subtitle: "Download onze app en start een GRATIS route",
                button: "Demo video"
            },
            sponsor: {
                title: "Sponsor met een logo",
                subtitle: "Upload je eigen bedrijfslogo en personaliseer de app in stijl",
                button: "Onze prijzen"
            },
            route: {
                bigtitle: "Organiseer route",
                title: "Stippel zelf een route uit",
                subtitle: "Geef de datum van jouw oriëntatieloop op en ontvang een QR-code om de loop te starten",
                button: "Contacteer ons"
            },
            card1: {
                title: "Beweging",
                subtitle: "Oriëntatieloop",
                text1: "Bij een oriëntatieloop tracht je zo snel mogelijk van begin- tot eindpunt te stappen.",
                text2: "Stippel zelf een route uit en bezorg ons de datum van je oriëntatieloop en ontvang een QR-code om de loop te starten."
            },
            card2: {
                title: "Plezier",
                subtitle: "Games",
                text1: "De controlepunten worden voorzien van extended reality games in de vorm van digitale interactieve spelletjes.",
                text2: "Van woordpuzzels tot scavenger hunts en van rebussen tot escape rooms. Voor elk wat wils!"
            },
            card3: {
                title: "Maatwerk",
                subtitle: "Alles kan!",
                text1: "Personaliseer de app in stijl door een eigen logo.",
                text2: "Wil je onze standaard games verwerken in jouw oriëntatieloop of wil je liever games op maat uitgewerkt?",
                text3: "Alles kan!"
            },
            card4: {
                title: "Community",
                subtitle: "Speel voor de beker",
                text1: "Speel de oriëntatieloop met jouw team en versterk je positie in het leidersbord. Klim naar de top en vergelijk jezelf met anderen.",
                text2: "Ga voor de snelste tijd en/of het meeste aantal punten."
            },
            teambuilding: {
                title: "Teambuilding",
                subtitle: "Onboarding en rondleidingen",
                text: "Organiseer een ongeziene ervaring voor jou en je collega's. Bezorg ons een start-, eindpunt en controlepunten en wij bezorgen je een onvergetelijke ervaring.",
                button: "Meer info"
            },
            discover: {
                title: "Ontdek een locatie",
                subtitle: "In de stad, op school of provinciaal domein",
                text: "Je bezoeker ontdekt de locatie op een nooit eerder geziene manier.",
                button: "Meer info"
            },
        },
        howitworks: {
            title: "Hoe werkt het?",
            subtitle: "Lees onze veelgestelde vragen om WandelAR beter te begrijpen.",
            bigtitle: "Hoe werkt het",
            faq1: {
                title: "Hoe kan je een oriëntatieloop starten?",
                text1: "Download de WandelAR app in de AppStore (voor iOS) of PlayStore (voor Android). In de app kan je een overzicht terugvinden van alle huidige en komende oriëntatielopen. Kies een loop uit en begeef je naar het start adres op de dag van de oriëntatieloop. Scan vervolgens de QR-code van de oriëntatieloop organisator en start de loop.",
            },
            faq2: {
                title: "Wat zijn thema's?",
                text1: "Elke organisator van een oriëntatieloop kiest zijn eigen thema.",
                text2: "Het thema vertaalt zich in een spel dat gespeeld wordt tijdens de oriëntatieloop. Bij elk controlepunt verdien je punten door een spel te spelen waardoor je hoger op het leidersbord kan eindigen. De speler die het meeste punten verdient en het snelste de route aflegt wint de oriëntatieloop.",
            },
            faq3: {
                title: "Kan ik mijn oriëntatieloop personaliseren?",
                text1: "Indien je kiest om jouw oriëntatieloop te sponsoren dan verschijnt jouw logo in onze app op de kalender pagina en op de kaart.",
            },
            faq4: {
                title: "Wat als er meer mensen deelnemen dat ik heb opgegeven?",
                text1: "Bij de aanmaak van jouw oriëntatieloop stellen we een startdatum en eindatum in alsook het aantal deelnemers dat je verwacht. Indien de QR-code gescand wordt buiten het gegeven interval dan zal de oriëntatieloop niet starten. Indien het aantal opgegeven deelnemers overschreden wordt zal de oriëntatieloop ook niet meer starten.",
            }
        },
        pricing: {
            title: "Prijzen calculator",
            subtitle: "Selecteer een thema, geef het aantal deelnemers op en kies of je wilt sponsoren met een logo",
            send: "Verstuur",
            bigtitle: "Prijzen",
            chooseTheme: "Kies een thema",
            theme: "Thema",
            amountParticipantsAsk: "Geef het aantal deelnemers aan",
            pricePerParticipant: "/ deelnemer",
            placeholderAmount: "Bv. 10",
            extraProducts: "Extra producten",
            amountParticipants: "Aantal deelnemers",
            participants: "deelnemers",
            summary: "Samenvatting",
            total: "Totaal",
            vatExcluded: "Exclusief BTW",
            sendAsk: "Stuur ons uw prijsberekening zonder verplichting.*",
            email: "E-mail",
            emailPlaceholder: "Bv. ella@WandelAR.be",
            phone: "Telefoonnummer",
            phonePlaceholder: "Bv. +32 123 456 789",
            sendCaption: "*Nadat wij de prijs calculatie ontvangen hebben, contacteert een teamlid van WandelAR u om de bestelling af te ronden."
        },
        socialbanner: {
            tagline: "Neem een kijkje op onze sociale media"
        },
        footer: {
            tagline: "Wandel. Speel. Ontdek.",
            pricing: "Prijzen",
            contact: "Contacteer ons",
            copyright: "© WandelAR. Alle rechten voorbehouden.",
            privacy: "Privacybeleid",
            terms: "Voorwaarden"
        },
        monoLight: "Mono licht",
        monoDark: "Mono donker",
        themes: {
            theme_0: {
				name: "Geen",
				description: "Geen"
			},
            theme_1: {
				name: "Shoot that ball",
				description: "Arcade basketball game"
			},
            theme_2: {
				name: "Ervaring op maat",
				description: "Contacteer ons"
			}
        },
        products: {
            product_0: {
				name: "Sponsor",
				description: "Met een eigen logo"
			},
            product_1: {
				name: "Publiceer route",
				description: "op de kalender"
			}
        },
        toast: {
            ok: "Ok"
        },
        errors: {
            participantsCount: {
                title: "Foutmelding",
                msg: "Aantal deelnemers is 0. Zorg ervoor dat het aantal deelnemers tussen 1 en 10000 ligt.",
                caption: "Deelnemers"
            },
            participantsExceeds: {
                title: "Foutmelding",
                msg: "Deelnemersaantal is hoger dan 10000. Zorg ervoor dat het aantal tussen 1 en 10000 ligt.",
                caption: "Deelnemers"
            },
            onlyNumbers: {
                title: "Foutmelding",
                msg: "Alleen nummers zijn toegestaan.",
                caption: "Deelnemers"
            },
            email: {
                title: "Foutmelding",
                msg: "Het opgegeven e-mailadres is ongeldig. Een e-mailadres ziet er zo uit: naam@domeinnaam.be",
                caption: "E-mail"
            },
            phone: {
                title: "Error",
                msg: "Het ingevoerde telefoonnummer is ongeldig. Een telefoonnummer kan alleen cijfers, de tekens +/.- en spaties bevatten.",
                caption: "Telefoonnumer"
            }
        },
        messages: {
            msg404: {
                title: "Foutmelding",
                msg: "Niet gevonden.",
                caption: "Code 404"
            },
            msg500: {
                title: "Foutmelding",
                msg: "Onverwachte toestand.",
                caption: "Code 500"
            },
            msg201: {
                title: "Succes",
                msg: "Bedankt voor de vrijblijvende prijs calculatie. Een teamlid van WandelAR contacteert u zo snel mogelijk om de bestelling af te ronden.",
                caption: "Pre-order"
            }
        },
        login: {
            title: "Aanmelden",
            subtitle: "Meld je aan en ontdek de analytics van je wandelingen in een overzichtelijk dashboard.",
            email: "E-mail",
            emailPlaceholder: "Bv. ella@WandelAR.be",
            password: "Paswoord",
            passwordPlaceholder: "•••••••••••",
            forgotPassword: "Paswoord vergeten?",
            login: "Aanmelden",
            noAccountAsk: "Heb je nog geen account?",
            registerHere: "Registreer je hier",
            bigtitle: "Aanmelden",
            button: "Aanmelden",
            messages: {
                msg401: {
                    title: "Foutmelding",
                    msg: "Onbevoegde toegang.",
                    caption: "Code 401"
                },
                msg500: {
                    title: "Foutmelding",
                    msg: "Onverwachte toestand.",
                    caption: "Code 500"
                },
                msg200: {
                    title: "Succes",
                    msg: "Succesvol ingelogt.",
                    caption: "Login"
                },
                msg402: {
                    title: "Foutmelding",
                    msg: "Uw account is niet geactiveerd. Bekijk uw e-mail en activeer uw account. Neem contact met ons op als u geen e-mail hebt ontvangen",
                    caption: "Aanmelden"
                }
            },
        },
        register: {
            title: "Registreren",
            subtitle: "Maak een account aan en login op je dashboard.",
            firstname: "Voornaam",
            firstnamePlaceholder: "Bv. Ella",
            lastname: "Achternaam",
            lastnamePlaceholder: "Bv. WandelAR",
            email: "E-mail",
            emailPlaceholder: "Bv. ella@WandelAR.be",
            password: "Paswoord",
            passwordPlaceholder: "•••••••••••",
            declaration1: "Door verder te gaan verklaar je het",
            privacyPolicy: "Privacybeleid",
            declaration2: "en de",
            terms: "Voorwaarden",
            declaration3: "van WandelAR te hebben gelezen en goedgekeurd.",
            register: "Registreer",
            accountAsk: "Heb je al een account?",
            loginHere: "Meld je hier aan",
            bigtitle: "Registreren",
            button: "Registreren",
            messages: {
                msg401: {
                    title: "Foutmelding",
                    msg: "Onbevoegde toegang.",
                    caption: "Code 401"
                },
                msg500: {
                    title: "Foutmelding",
                    msg: "Onverwachte toestand.",
                    caption: "Code 500"
                },
                msg201: {
                    title: "Succes",
                    msg: "Bedankt om te registreren. Binnenkort ontvangt u een e-mail om uw login te activeren. Controleer je spam inbox voor de zekerheid.",
                    caption: "Registreren"
                },
                msg405: {
                    title: "Foutmelding",
                    msg: "Het wachtwoord moet ten minste 8 tekens lang zijn.",
                    caption: "Registreren"
                },
                msg406: {
                    title: "Foutmelding",
                    msg: "Geen paswoord voorzien.",
                    caption: "Registreren"
                },
                msg411: {
                    title: "Foutmelding",
                    msg: "Gelieve akkoord te gaan met ons privacybeleid en onze algemene voorwaarden.",
                    caption: "Registreren"
                },
                msg412: {
                    title: "Foutmelding",
                    msg: "Gelieve akkoord te gaan met ons privacybeleid en onze algemene voorwaarden.",
                    caption: "Registreren"
                },
                msg409: {
                    title: "Foutmelding",
                    msg: "Het ingevoerde e-mailadres bestaat al.",
                    caption: "Registreren"
                },
                msg410: {
                    title: "Foutmelding",
                    msg: "Het ingevoerde e-mailadres is ongeldig. Een e-mailadres ziet er als volgt uit: name@domain.com",
                    caption: "Registreren"
                },
                msg413: {
                    title: "Foutmelding",
                    msg: "De voornaam moet minstens 1 teken lang zijn en mag maximaal 254 tekens lang zijn.",
                    caption: "Registreren"
                },
                msg414: {
                    title: "Foutmelding",
                    msg: "De achternaam moet minstens 1 teken lang zijn en mag maximaal 254 tekens lang zijn.",
                    caption: "Registreren"
                }
            },
        },
        forgot: {
            title: "Paswoord vergeten?",
            subtitle: "Vul hieronder je e-mailadres in en je ontvangt een e-mail met een link waarmee je jouw paswoord opnieuw instelt.",
            email: "E-mail",
            emailPlaceholder: "Bv. ella@WandelAR.be",
            button: "Nieuw paswoord aanvragen",
            bigtitle: "Paswoord",
            messages: {
                msg200: {
                    title: "Succes",
                    msg: "De instructies voor het instellen van een nieuw paswoord zijn doorgestuurd naar je e-mail adres. kijk spoedig je mailbox na en vergeet niet de spamfolder te controleren!",
                    caption: "Paswoord vergeten"
                },
                msg409: {
                    title: "Error",
                    msg: "Dit e-mail adres is niet gekoppeld aan een WandelAR account.",
                    caption: "Paswoord vergeten"
                },
                msg410: {
                    title: "Error",
                    msg: "Het ingevoerde e-mailadres is ongeldig. Een e-mail adres ziet er als volgt uit: name@domain.com",
                    caption: "Paswoord vergeten"
                },
            }
        },
        reset: {
            title: "Nieuw paswoord instellen",
            subtitle: "Stel hieronder een nieuw paswoord in.",
            password: "Nieuw paswoord",
            passwordPlaceholder: "•••••••••••",
            button: "Wijzig paswoord",
            bigtitle: "Paswoord",
            messages: {
                msg200: {
                    title: "Succes",
                    msg: "Paswoord succesvol gewijzigd. Wij hebben u een bevestigings e-mail gestuurd.",
                    caption: "Nieuw paswoord"
                },
                msg404: {
                    title: "Error",
                    msg: "Onbekende code.",
                    caption: "Nieuw paswoord"
                },
                msg405: {
                    title: "Error",
                    msg: "Het wachtwoord moet ten minste 8 tekens lang zijn.",
                    caption: "Nieuw paswoord"
                },
                msg406: {
                    title: "Error",
                    msg: "Geen paswoord voorzien.",
                    caption: "Nieuw paswoord"
                },
            }
        },
        configurator: {
            title: "Configurator",
            subtitle: "Overzicht van alle routes.",
            searchPlaceholder: "Zoek naar een wandeling"
        },
        dashboard: {
            title: "Welkom op uw dashboard",
            subtitle: "Binnenkort beschikbaar.",
            hello: "Hallo"
        },
        organise: {
            title: "Organiseer",
            subtitle: "Binnenkort beschikbaar.",
        },
        events: {
            title: "Evenementen",
            subtitle: "Binnenkort beschikbaar.",
        },
        settings: {
            title: "Instellingen",
            subtitle: "Verander hier de settings van de website.",
        },
        invoices: {
            title: "Facturen",
            subtitle: "Binnenkort beschikbaar."
        },
        profile: {
            title: "Profiel",
            subtitle: "Details",
            logout: "Afmelden",
            username: "Gebruikersnaam",
            usernameExplain: "Uw gebruikersnaam is uniek en kan worden gebruikt om uw openbare pagina te delen.",
            usernamePlaceholder: "Voer een unieke gebruikersnaam in",
            channel: "Kanaal link",
            channelExplain: "Dit is de link van je kanaal. Je kunt je gemaakte routes laten zien en delen met je volgers. Stel een aangepaste en unieke gebruikersnaam in om je kanaal link te personaliseren.",
            error: {
                code409: {
                    set: {
                        title: "Foutmelding",
                        msg: "Gebruikersnaam is al ingesteld",
                        caption: "Gebruikersnaam"
                    },
                    taken: {
                        title: "Foutmelding",
                        msg: "Gebruikersnaam is al bezet",
                        caption: "Gebruikersnaam"
                    },
                    user: {
                        title: "Foutmelding",
                        msg: "Gebruiker bestaat niet",
                        caption: "Gebruikersnaam"
                    },
                },
                code413: {
                    min: {
                        title: "Foutmelding",
                        msg: "Gebruikersnaam moet tenminste 3 karakters lang zijn",
                        caption: "Gebruikersnaam"
                    },
                    max: {
                        title: "Foutmelding",
                        msg: "Gebruikersnaam moet maximaal 30 karakters lang zijn",
                        caption: "Gebruikersnaam"
                    },
                    char: {
                        title: "Foutmelding",
                        msg: "Uw gebruikersnaam mag alleen bestaan uit alfanumerieke tekens (A-Z, a-z, 0-9), underscores (_), streepjes (-) en punten (.)",
                        caption: "Gebruikersnaam"
                    },
                },
                code200: {
                    title: "Succes",
                    msg: "Je gebruikersnaam is bijgewerkt. Zorg ervoor dat u uw volgers informeert over de verandering van uw kanaal link",
                    caption: "Gebruikersnaam"
                }
            }
        },
        notfound: {
            title: "Foutmelding 404",
            subtitle: "Oeps! Er ging iets mis :s",
            caption: "Pagina niet gevonden"
        },
        welcome: {
            title: "Welkom",
            subtitle: "Uw account is geactiveerd.",
            button: "Aanmelden",
            bigtitle: "Welkom"
        },
        orderType_0: "Op naam",
        orderType_1: "Nieuwste",
        orderType_2: "Oudste",
        orderType_3: "Laatst bijgewerkt",
        noRoutesTitle: "Maak een route aan",
        noRoutesSubTitle: "Druk op de 'plus' knop om een nieuwe route aan te maken.",
        noRoutesText: "Tip: Toggel tussen 'raster' en 'lijst' weergave ;)",
        noRoutesFoundTitle: "Geen wandelingen gevonden",
        noRoutesFoundSubTitle: "Sorry, we konden geen resultaten vinden voor je zoekopdracht.",
        noRoutesFoundText: "Tip: Gebruik filters om je zoekopdracht te verfijnen.",
        createdOn: "Aangemaakt op",
        months: [
            "Jan",
            "Feb",
            "Mrt",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Okt",
            "Nov",
            "Dec"
        ],
        days: [
            "Zo",
            "Ma",
            "Di",
            "Wo",
            "Do",
            "Vr",
            "Za"
        ],
        filter: {
            title: "Filters",
            apply: "Pas toe en zoek",
            clearAll: "Wis alles",
            location: {
                search: "Zoek op locatie",
                error: {
                    title: "Foutmelding",
                    msg: "Om te filteren op locatie moet je een locatie ingeven of 'in mijn buurt' aanvinken",
                    caption: "Locatie"
                }
            },
            locationPlaceholder: "Geef locatie in",
            distance: "Route afstand",
            age: "Leeftijd",
            withGames: "Enkel routes met spel",
            noGames: "Enkel routes zonder spel",
            withTimer: "Enkel routes met timer",
            noTimer: "Enkel routes zonder timer",
            range: "Bereik",
            nearMe: "In mijn buurt",
            radius: "Zoekradius",
            year: "j",
            geo: {
                error: {
                    title: "Foutmelding",
                    msg: "Om 'In mijn buurt' te gebruiken moet je locatie aanstaan.",
                    caption: "In mijn buurt"
                }
            }
        },
        course: {
            share: "Link delen",
            shareExplain: "U kunt deze link delen met uw vrienden, familie of collega's",
            new: {
                error: {
                    title: 'Foutmelding',
					msg: 'Er is iets misgegaan bij het opslaan van de routegegevens. Probeer het opnieuw!',
					caption: 'Nieuwe route opslaan'
                }
            },
            ok: "Ok",
            routePlaceholder: "Ongetitelde route",
            addCheckpoint: "Controlepunt",
            noCheckpointsTitle: "Nog geen controlepunten",
            noCheckpointsSubtitle1: "Het lijkt erop dat u nog geen controlepunten heeft aangemaakt.",
            noCheckpointsSubtitle2: "Klik op 'Bewerken' om controlpunten toe te voegen.",
            noCheckpointsText1: "Druk op de 'plus' knop",
            noCheckpointsText2: "om een checkpoint toe te voegen.",
            noImagesTitle: "Nog geen afbeeldingen",
            noImagesSubtitle: "Het lijkt erop dat je nog geen afbeeldingen hebt toegevoegd.",
            noImagesText1: "Druk op de knop 'bewerken'.",
            noImagesText2: "om afbeeldingen toe te voegen.",
            legend: "Legende",
            scrollToZoom: "Scroll om te zoomen",
            clickAndDragMapToMove: "Klik en sleep de kaart om te verplaatsen",
            clickMapToAddPin: "Klik op de kaart om een pin toe te voegen",
            clickAndDragPinToMove: "Klik en sleep pin om te verplaatsen",
            placeholderSearch: "Zoek naar een locatie",
            fitRoute: "Overzicht",
            dragPin: "Sleep de pin om te verplaatsen",
            dragToCrop: "Sleep om bij te snijden",
            dragToCropTitle: "Beeld bijsnijden en voorvertoning.",
            carousel: "Carrousel",
            create: "Route aanmaken",
            upload: {
                remove: "Gelieve alle niet-ondersteunde bestanden te verwijderen",
                dragDrop: "Sleep afbeeldingen hierheen of klik om te uploaden",
                allowed: "Alleen *.jpg, *.jpeg en *.png zijn toegestaan.",
                unsupported: "Bestand niet ondersteund",
                unsupportedSize: "Alleen bestanden van maximaal 3Mb zijn toegestaan",
                uploading: "uploaden",
                uploaded: "geüpload",
                file: "bestand",
                files: "bestanden",
                noUploadProgress: "Geen upload aan de gang"
            },
            copied: {
                title: "Gekopieerd",
                caption: "Naar klembord"
            },
            placePin: {
                title: "Plaats controlepunt",
                subtitle: "Zoom en pan om aan te passen",
                close: "Sluit",
                add: "Voeg toe"
            },
            date: {
                created: "Aangemaakt op",
                updated: "Laatste update",
                event: "Datum van het evenement",
                eventExplain: "Datum voor het begin en einde van uw evenement. Als beide leeg zijn wordt de route verborgen. Als u een gepubliceerde route wilt tonen, voer dan minstens een einddatum in.",
                start: "Van",
                end: "Tot",
                today: "Vandaag",
                continuous: "Doorlopend",
                clear: "Datums verwijderen"
            },
            title: "Route",
            subtitle: "Binnenkort beschikbaar.",
            back: "Terug",
            edit: "Bewerken",
            save: "Opslaan",
            cancel: "Annuleer",
            icon: "Route icon",
            iconImage: "icon",
            isIcon: "Instellen als icoon",
            isSponsor: "Instellen als sponsorafbeelding",
            iconExplain: "Enkel afbeeldingen van 270x270 px zijn toegelaten.",
            namePlaceholder: "Geef een route naam in ...",
            descriptionPlaceholder: "Geef een route beschrijving in ...",
            publish: "Publiceer",
            stop: "Stop",
            published: "Gepubliceerd",
            notPublished: "Niet gepubliceerd",
            name: "Route naam in de WandelAR app",
            description: "Route beschrijving",
            type: "Type",
            status: "Status",
            download: "Download",
            social: "Download social",
            socialTag1: "Scan de QR-code",
            socialTag2: "om de wandeling te starten",
            age: "Leeftijd",
            ageMin: "Minimum",
            ageMinPlaceholder: "Geef minimum leeftijd in",
            ageMax: "Maximum",
            ageMaxPlaceholder: "Geef maximum leeftijd in",
            url: "Sociale media link",
            urlName: "Naam",
            urlNamePlaceholder: "Geef website in",
            urlUrl: "URL",
            urlUrlPlaceholder: "Geef website url in",
            sponsor: "Sponsor",
            sponsorName: "Naam",
            sponsorNamePlaceholder: "Geef sponsor naam in",
            sponsorUrl: "Website",
            sponsorUrlPlaceholder: "Geef sponsor website in",
            sponsorLogo: "Logo",
            sponsorLogoExplain: "Enkel 270x270 px afbeeldingen zijn toegestaan",
            usersCurrentPlaceholder: "Deelnemers die route gestart hebben ...",
            usersTotalPlaceholder: "Totaal aantal deelnemers die mogen starten ...",
            pin: "Toon pin op kaart",
            wheelchair: "Toon rolstoel icoontje op infopagina",
            stroller: "Toon kinderwagen icoontje op info pagina",
            dog: "Toon hondenbot icoontje op infopagina",
            free: "Route is vrij om te lopen",
            ads: "Toon advertenties tijdens het lopen",
            skip: "Toon volgende knop op checkpoint pagina",
            time: "Log de tijd tijdens het lopen",
            pinExplain: "Toon de locatie van de gebruiker op de kaart met een pulserende pin.",
            wheelchairExplain: "Toon een rolstoel icoontje als je route rolstoelvriendelijk is.",
            strollerExplain: "Toon een kinderwagen icoontje als je route kinderwagen vriendelijk is.",
            dogExplain: "Toon een hondenbot icoontje als je route hondvriendelijk is.",
            freeExplain: "Je route zal te vinden zijn in de app. Gebruikers kunnen alleen verder als gratis is ingeschakeld of als ze de route QR-code van bovenaf scannen.",
            adsExplain: "Tijdens een run zien gebruikers elke 2 minuten advertenties wanneer de app schakelt tussen schermen.",
            skipExplain: "Op elke checkpointpagina staat een volgende knop. Je kunt deze knop verbergen zodat spelers gedwongen worden om het spel te spelen voordat ze verder kunnen gaan.",
            timeExplain: "Log je totale tijd en de tijd tussen de controlepunten. Zet uit als u niet wilt dat een route op tijd wordt gelopen.",
            address: {
                start: "Start adres info",
                custom: "Beschrijving van start adres",
                street: "Straat",
                number: "Nummer",
                postal: "Post code",
                city: "Stad",
                region: "Regio",
                country: "Land",
                explain: "Als een adres niet beschrijvend genoeg is, kunt u extra informatie toevoegen die het vertrekpunt beschrijft. Bijvoorbeeld: 'Start aan de rode voordeur'.",
                placeholders: {
                    start: "Geef een start adres in",
                    custom: "Geef een beschrijving van het start adres in",
                    street: "Geef een straat naam in",
                    number: "Geef een straat nummer in",
                    postal: "Geef een postcode in",
                    city: "Geef een stad in",
                    region: "Geef een regio in",
                    country: "Geef een land in",
                }
            },
            price: "Prijs",
            distance: "Afstand",
            estimation: "Duur",
            pricePlaceholder: "Geef retail prijs in",
            distancePlaceholder: "Geef route afstand in",
            estimationPlaceholderHour: "Geef aantal uur in",
            estimationPlaceholderMin: "Geef aantal minuten in",
            checkpoints: {
                title: "Controlepunten",
                startpoint: "Startpunt",
                checkpoint: "Controlepunt",
                endpoint: "Eindpunt",
                explain: "Hieronder kan je het startpunt, eindpunt en tussenpunten bepalen van de route.",
                none: "Geen controlepunten",
                longitude: "Lengtegraad",
                latitude: "Breedtegraad",
                longitudePlaceholder: "Geef lengtegraad in",
                latitudePlaceholder: "Geef breedtegraad in",
                qr: "QR code",
                placeholders: {
                    longitude: "Geef lengtegraad in",
                    latitude: "Geef breedtegraad in"
                },
                check: {
                    title: "Foutmelding",
                    msg: "Controlepunt limiet bereikt. Je kan maximaal 25 controlepunten toevoegen",
                    caption: "Controlepunten"
                }
            },
            tags: {
                title: "Tags",
                subtitle: "Voeg een tag toe",
                placeholders: {
                    empty: {
                        us_en: "Enter a tag",
                        be_nl: "Voer een tag in",
                        be_fr: "Saisir une étiquette"
                    },
                    full: {
                        us_en: "Type and press enter",
                        be_nl: "Typ en druk op enter",
                        be_fr: "Tapez et appuyez sur Entrée"
                    }
                },
                error: {
                    title: "Foutmelding",
                    msg: "Vul alle tagvertalingen in voordat u een tag toevoegt",
                    caption: "Tags"
                }
            },
            extra: "Extra functies",
            images: "Route afbeeldingen",
            imagesExplain: "Beste afbeeldingen van 1000x730 px anders snijden we uw afbeelding bij",
            noImages: "Geen afbeeldingen",
            current: "Huidige gebruikers",
            currentExplain: "Aantal deelnemers die de route hebben gelopen.",
            total: "Maximum deelnemers",
            totalExplain: "Maximum aantal deelnemers die uw route mogen lopen.",
            infinite: "Geen limiet",
            qrcode: "Route QR-code",
            qrcodes: "QR-codes voor controlepunten",
            qrcodeExplain: "U kan deze QR-code downloaden en gebruiken om deelnemers te informeren over deze route via bijvoorbeeld een bericht op sociale media.",
            qrcodesExplain: "Dit zijn de qrcodes voor het startpunt, de controleposten en het eindpunt. Deze kunnen op papier worden afgedrukt en op billboards op elk controlepunt worden geplaatst. De QR-codes kunnen worden gebruikt als noodoplossing wanneer er onvoldoende GPS-ontvangst is.",
            remove: "Verwijder",
            removeRoute: "Verwijder route",
            yes: "Ja",
            no: "Neen",
            confirmRemoveRoute: {
                title: "Bent u zeker dat u deze route wilt verwijderen?",
                subtitle: "Hiermee worden alle routegegevens verwijderd, inclusief afbeeldingen, tekst en controlepunten die aan deze route zijn gekoppeld."
            },
            saveRoute: {
                minCheckpoint: {
                    title: "Foutmelding",
                    msg: "Voeg een startpunt, één controlepunt en een eindpunt toe.",
                    caption: "Controlepunten"
                },
                invalid: {
                    title: "Foutmelding",
                    msg: "Verwijder alle niet ondersteunde bestanden",
                    caption: "Afbeeldingen"
                }
            },
            dragUpDown: "Sleep afbeeldingen omhoog en omlaag met",
            dragUpDownExplain: "Verplaats de volgorde van hoe de carrouselafbeeldingen worden getoond op de route-infopagina in de app. De eerste afbeelding wordt gebruikt als je icoonafbeelding."
        },
        invite: {
            title: "Welkom bij WandelAR",
            subtitle: "Druk op de knop hieronder om deze route te laden en te beginnen met lopen",
            text1: "Download de app",
            text2: "Open de app en druk op",
            text3: "om de route te scannen",
            caption: "Loop. Speel. Groei.",
            what1: "De WandelAR app combineert games met uitgebreide en verhoogde reality tijdens het wandelen",
            what2: "Op elk controlepunt kan je een spel spelen!",
            button: "Ga naar de app",
            inviteExplain: "De WandelAR app combineert games met uitgebreide en verhoogde realiteit tijdens het wandelen. Op elk controlepunt kan je een spel spelen!",
            notFound: "App niet gevonden",
            start: "Start de route",
            tap: "Tap",
            ctrl: "Ctrl",
            rightClick: "Rechts klik",
            cmd: "Cmd",
            plus: "+",
            and: "en",
            drag: "slepen",
            toRotate: "om te draaien",
            or: "of",
            scan: "scan",
            qrcode: "QR-code",
            preview: "om te bekijken",
            game: "Spel",
            part: "Deel",
            play: "Speel",
            time: "Op tijd"
        },
        channel: {
            title: "Wandelroutes",
            caption: "Loop. Speel. Groei.",
            explain: "Op deze pagina kan u alle routes terugvinden van",
            poweredBy: "Ondersteund door",
            noRating: "Nog geen beoordeling",
            routes: "Wandelingen",
            hikes: "Aantal wandelingen",
            hikers: "Aantal wandelaars",
            rating: "Beoordeling",
            scan: "Scan om te starten",
            height: "Hoogte",
            or: "of",
            wheelchair: {
                false: "Niet toegankelijk",
                true: "Toegankelijk"
            },
            stroller: {
                false: "Niet toegankelijk",
                true: "Toegankelijk"
            },
            dog: {
                false: "Niet mogelijk met hond",
                true: "Mogelijk met hond"
            }
        },
        qr: "QR-code",
        gps: "GPS",
        Pending: "In afwachting",
        Active: "Actief",
        Cancelled: "Geannuleerd",
        Done: "Gedaan",
        unsubscribe: {
            title: "U bent uitgeschreven",
            subtitle: "Het spijt ons je te zien gaan!",
            button: "Ga naar homepage"
        },
        privacy: {
            privacyPolicy: "Privacybeleid",
            userAgreementAndPrivacyPolicyTitle: "Gebruikersovereenkomst en verklaring privacybeleid",
            userAgreementAndPrivacyPolicyText: "Lees ons privacybeleid zorgvuldig door om een duidelijk inzicht te krijgen in hoe wij uw Persoonlijk Identificeerbare Informatie verzamelen, gebruiken, beschermen of anderszins behandelen in overeenstemming met onze website en app.",
            userAgreementTitle: "Gebruikersovereenkomst",
            userAgreementText1: "WandelAR ('App' of 'Site') biedt locatie-gebaseerde diensten, zoals het in kaart brengen van buitenactiviteiten door gebruik te maken van de geografische positie van het apparaat van de gebruiker. Het bevat ook een gebruikersbeheersysteem om de gebruiker controle te geven over de geregistreerde en opgeslagen locatiegegevens.",
            userAgreementText2: "De diensten worden geleverd door WandelAR. Deze voorwaarden zijn van toepassing tussen WandelAR en de gebruiker van de App, en omvat de mobiele applicaties en de bijbehorende websites. Gebruik van de App veronderstelt dat de gebruiker de voorwaarden die volgen volledig accepteert en goedkeurt. WandelAR wijst alle verantwoordelijkheid af voor eventuele financiële en materiële schade die de App de gebruiker kan berokkenen.",
            privacyPolicyTitle: "Privacybeleid",
            privacyPolicyText1: "Het profiel van de gebruiker voor de App wordt verkregen uit de informatie die de gebruiker in de App invoert. Alleen persoonlijke gegevens die nodig zijn voor de diensten die de App levert worden opgeslagen, zoals naam, email adres, geslacht, geboortejaar en postcode. Dit is de informatie die nodig is zodat wanneer de gebruiker ervoor kiest om resultaten te uploaden, dat deze resultaten kunnen worden ingevoerd in de juiste geslacht/leeftijd/ categorieën.",
            privacyPolicyText2: "Als de gebruiker ervoor kiest gegevens te uploaden, wordt de resultatenwebsite weergegeven:",
            privacyPolicyBullet1: "1. De timing details van hun loop",
            privacyPolicyBullet2: "2. Hun voornaam en achternaam",
            privacyPolicyText3: "Het opgegeven e-mailadres kan worden gebruikt om een e-mail te sturen met details over de evenementen waaraan zij hebben deelgenomen en om informatie te verstrekken over komende evenementen.",
            privacyPolicyText4: "Deze informatie kan worden gedeeld met aangesloten Orientatieloop verenigingen en clubs, in overeenstemming met hun privacybeleid.",
            privacyPolicyText5: "Om de gebruikers van de App beter te begrijpen en van dienst te zijn, kunnen wij onderzoek doen naar de resultaten en het gedrag van onze gebruikers op basis van de persoonlijke gegevens en andere informatie die aan ons is verstrekt. Dit onderzoek kan worden gecompileerd en geanalyseerd op een geaggregeerde basis, en wij kunnen deze geaggregeerde gegevens delen met onze filialen, agenten en zakelijke partners. Deze geaggregeerde informatie identificeert gebruikers niet persoonlijk. Wij kunnen ook geaggregeerde gebruikersstatistieken vrijgeven om onze diensten te beschrijven aan huidige en toekomstige zakenpartners, en aan andere derde partijen voor andere wettige doeleinden.",
            privacyPolicyText6: "Gebruikers kunnen de App gebruiken zonder Persoonsgegevens te versturen vanaf hun apparaat, maar zij kunnen dan mogelijk bepaalde functies van de Services niet gebruiken.",
            privacyPolicyText7: "Indien een minderjarige, jonger dan 18 jaar, de App gebruikt, gaan wij ervan uit dat dit gebeurt met toestemming van een wettelijke voogd.",
            privacyPolicyText8: "De Diensten die wij aanbieden kunnen van tijd tot tijd veranderen. Als gevolg daarvan kan het soms noodzakelijk zijn dat wij wijzigingen aanbrengen in dit Privacybeleid. Dienovereenkomstig behouden wij ons het recht voor om dit Privacybeleid te allen tijde en van tijd tot tijd bij te werken of te wijzigen zonder voorafgaande kennisgeving. Gelieve dit beleid regelmatig door te nemen, en vooral voordat u persoonlijke gegevens verstrekt. Dit Privacybeleid is voor het laatst bijgewerkt op de hieronder aangegeven datum. Uw voortgezet gebruik van de diensten na eventuele wijzigingen of herzieningen van dit Privacybeleid betekent dat u instemt met de voorwaarden van dit herziene Privacybeleid.",
            privacyPolicyText9: "Indien u bezwaren heeft tegen dit beleid, of indien u wenst dat uw gegevens uit het systeem worden verwijderd, gelieve dan details te verstrekken op ons e-mailadres.",
            managingPersonalInformationTitle: "Beheer van persoonlijke informatie",
            managingPersonalInformationText1: "Hieronder vindt u een gedetailleerde beschrijving van hoe WandelAR persoonlijke informatie beheert.",
            managingPersonalInformationText2: "Het systeem bestaat uit apps voor Android (WandelAR) en iPhone (WandelAR), een web frontend en een backend service voor de opslag en verwerking van gegevens. Digital Ocean, Amazon en MongoDB worden gebruikt als clouddiensten voor gebruikersbeheer en gegevensopslag.",
            managingPersonalInformationText3: "De privacy van de gebruiker is belangrijk voor ons en wij hebben een aantal functies geïmplementeerd om een evenwicht te vinden tussen de wens om resultaten van evenementen met anderen te delen en het bieden van controle over de persoonlijke locatiegegevens.",
            managingPersonalInformationText4: "De volgende persoonsgegevens worden door het WandelAR-systeem verwerkt.",
            userDataTitle: "Gebruikersgegevens",
            userDataBullet1: "1. Elke gebruiker van de app moet een naam invoeren die wordt gebruikt voor de presentatie in resultatenlijsten enz. De naam kan de volledige naam zijn (voornaam en achternaam), een bijnaam of iets dergelijks.",
            userDataBullet2: "2. E-mail en wachtwoord worden gebruikt om een geregistreerde gebruiker te identificeren.",
            userDataBullet3: "3. Indien een gebruiker deelneemt aan een evenementenwedstrijd zonder een geregistreerde gebruiker te zijn, zal de gebruiker een zogenaamde gastgebruiker zijn.",
            userDataBullet4: "4. 'cookies-informatie' van de gebruiker (sessie- en permanente cookiebestanden).",
            locationDataTitle: "Locatiegegevens",
            locationDataBullet1: "1. De locatie van het toestel van de gebruiker wordt gebruikt in een achtergronddienst tijdens een event race. De locatie wordt gebruikt om te bepalen of de gebruiker controlepunten bezoekt (het zogenaamde ponsen) en de locatie wordt voortdurend geregistreerd om later te kunnen laten zien welk pad de gebruiker heeft gekozen om de verschillende controlepunten te vinden.",
            locationDataBullet2: "2. Voor gastgebruikers en geregistreerde gebruikers wordt het resultaat van de gebeurtenis lokaal in het mobiele toestel opgeslagen. Het resultaat van de gebeurtenis bevat locatiegegevens en bestaat uit de bezochte controlepunten en het afgelegde pad tussen de controlepunten.",
            locationDataBullet3: "3. Het resultaat van de gebeurtenis wordt ook opgeslagen in de cloudopslag. Elke gebruiker, zowel geregistreerde als gastgebruiker, heeft zijn eigen privé-opslag voor gebeurtenisresultaten waartoe alleen de gebruiker (en WandelAR) toegang heeft.",
            locationDataBullet4: "4. Zowel geregistreerde gebruikers als gastgebruikers kunnen het resultaat van hun evenement publiceren om het toegankelijk te maken voor andere gebruikers. De gebruiker kan kiezen om alleen de bezochte controlepunten te publiceren of het volledige traject. De resultaten van een evenement worden beschermd door een unieke cursus-id. Andere gebruikers kunnen toegang krijgen tot de gepubliceerde gegevens als hij/zij de route-id kent.",
            locationDataText1: "Onze App maakt gebruik van de MapBox SDK om kaarten weer te geven in de App. Door gebruik te maken van de Site en App gaat u akkoord met het beleid van MapBox. U kunt het beleid vinden op deze ",
            locationDataText2: "link.",
            cookies: "Cookies",
            cookie1: "Cookie #1",
            cookieDescription11: "Deze cookies worden gebruikt om informatie te verzamelen over het verkeer en de manier waarop gebruikers op onze Site navigeren. De informatie is geaggregeerd en daarom volledig anoniem, en wij hebben geen toegang tot informatie over individuele gebruikers. De verzamelde gegevens omvatten hoe gebruikers op onze Site komen, hoe ze tussen pagina's navigeren en welk apparaat ze gebruiken. Wij gebruiken deze informatie om onze website efficiënter te laten werken, om het activiteitenniveau op onze Site te monitoren en om u de relevante artikelen aan te bieden die interessant voor u zouden kunnen zijn. Meer informatie over Google Analytics-cookies vindt u ",
            cookieDescription12: "hier. ",
            cookieDescription13: "U kunt het gebruik van Google Analytics voorkomen via deze ",
            cookieDescription14: "link.",
            cookieDuration1: "2 jaar",
            cookieType1: "Analytisch",
            cookieParty1: "3e",
            cookieLink1: "Ga naar site",
            cookie2: "Cookie #2",
            cookieDescription21: "Gebruikt om gebruikers op de Site te onderscheiden en om statistische informatie over hun gebruik van de Site te genereren. U kunt het gebruik van Google Analytics voorkomen via deze ",
            cookieDescription22: "link.",
            cookieDuration2: "1 dag",
            cookieType2: "Analytisch",
            cookieParty2: "3e",
            cookieLink2: "Ga naar site",
            cookie3: "Cookie #3",
            cookieDescription31: "Wordt gebruikt om het aantal verzoeken te beperken. U kunt het gebruik van Google Analytics voorkomen via deze ",
            cookieDescription32: "link.",
            cookieDuration3: "1 minute",
            cookieType3: "Analytisch",
            cookieParty3: "3e",
            cookieLink3: "Ga naar site",
            cookie4: "Cookie #4",
            cookieDescription4: "Gebruikt om machtigingstoken op te slaan",
            cookieDuration4: "Session",
            cookieType4: "Technisch",
            cookieParty4: "1e",
            cookieLink4: "Lees dit privacybeleid",
            cookie5: "Cookie #5",
            cookieDescription51: "Wordt gebruikt om het aantal verzoeken van gebruikers te beperken. U kunt het gebruik van Google Analytics voorkomen via deze ",
            cookieDescription52: "link.",
            cookieDuration5: "1 minute",
            cookieType5: "Analytisch",
            cookieParty5: "3e",
            cookieLink5: "Ga naar site",
            cookie6: "Cookie #6",
            cookieDescription6: "Wordt gebruikt om de toestemmingsstatus van de gebruiker op te slaan",
            cookieDuration6: "6 month",
            cookieType6: "Technisch",
            cookieParty6: "1e",
            cookieLink6: "Lees dit privacybeleid",
            cookie7: "Cookie #7",
            cookieDescription7: "Deze cookie wordt gebruikt om de gebruikerssessie te identificeren. De verzamelde gegevens worden alleen door WandelAR verwerkt voor analyse en rapportage. WandelAR gebruikt deze informatie om de effectiviteit en juistheid van de WandelAR producten te evalueren.",
            cookieDuration7: "30 minutes",
            cookieType7: "Essentieel",
            cookieParty7: "1e",
            cookieLink7: "Lees dit privacybeleid",
            cookie8: "Cookie #8",
            cookieDescription8: "Deze cookies bevatten een unieke gebruikers-ID die is ingesteld op de actviteit en juistheid van de WandelAR producten.",
            cookieDuration8: "2 years",
            cookieType8: "Essentieel",
            cookieParty8: "1e",
            cookieLink8: "Lees dit privacybeleid",
            cookieName: "Naam",
            cookieDescription: "Beschrijving",
            cookieDuration: "Duur",
            cookieType: "Type",
            cookieParty: "1e/3e partij",
            cookieLink: "Link naar website van derden",
            cookiesText1: "Een cookie is een reeks gegevens die een website op de computer van een bezoeker opslaat, en die de browser van de bezoeker bij elke terugkeer van de bezoeker aan de website verstrekt. Wij gebruiken cookies om ons te helpen bezoekers te identificeren, hun gebruik van de Site, hun website toegangsvoorkeuren, om hun ervaring te verbeteren, om te begrijpen hoe onze diensten worden gebruikt, om uw keuzes te onthouden, zoals uw taalvoorkeuren, en anderszins om onze diensten aan u aan te passen. Bezoekers van de Site die niet willen dat er cookies op hun computer worden geplaatst (meestal te vinden onder 'Instellingen' of 'Voorkeuren') moeten hun browser zo instellen dat cookies worden geweigerd voordat zij onze Site gebruiken, met als nadeel dat bepaalde functies van onze Site niet naar behoren kunnen functioneren zonder de hulp van cookies. Wij wijzen u erop dat derden (waaronder bijvoorbeeld advertentienetwerken en aanbieders van externe diensten zoals web traffic analysis services) ook cookies kunnen gebruiken.",
            cookiesText2: "In het bijzonder maakt onze Site gebruik van de volgende categorieën cookies, in ons legitiem belang, zoals hieronder beschreven:",
            cookiesText3: "Uw gebruik van onze Site geeft aan dat u instemt met dit gebruik van cookies. U kunt cookies in uw webbrowser uitschakelen, maar dit zal gevolgen hebben voor de bruikbaarheid van de website.",
            cookiesText4: "U kunt ook contact met ons opnemen via hello@wandelar.be en ons team zal u helpen met uw cookie-vragen.",
            ads: "Advertenties",
            adsText1: "Advertenties die op een van onze App verschijnen kunnen aan gebruikers worden geleverd door advertentie partners, die cookies kunnen plaatsen. Deze cookies stellen de advertentieserver in staat om uw computer te herkennen elke keer dat zij u een online advertentie sturen om informatie te verzamelen over u of anderen die uw computer gebruiken. Met deze informatie kunnen advertentienetwerken, onder andere, gerichte advertenties leveren waarvan zij denken dat deze het meest interessant voor u zullen zijn. Dit Privacybeleid dekt het gebruik van cookies door ons en dekt niet het gebruik van cookies door adverteerders. U kunt zich afmelden voor het zien van online op interesses gebaseerde advertenties van wandelar.be of reclamepartners door contact met ons op te nemen via hello@wandelar.be of via uw gadget-instellingen. Let op: ad blockers en tools die ons cookiegebruik beperken kunnen deze controles verstoren.",
            adsText2: "Onze App maakt gebruik van een advertentienetwerk van derden om advertenties in de App te tonen. Door gebruik te maken van de Site en App gaat u akkoord met het beleid van het Unity Ad netwerk. U kunt het beleid vinden op deze ",
            adsText3: "link.",
            deletionOfDataTitle: "Verwijdering van gegevens",
            deletionOfDataText: "Zowel geregistreerde als gastgebruikers kunnen het volgende doen:",
            deletionOfDataBullet1: "1. Als gebruiker kunt u uw gepubliceerde gebeurtenisresultaat intrekken wanneer u maar wilt. Na intrekking van een gebeurtenisresultaat zullen de locatiegegevens niet langer toegankelijk zijn voor andere gebruikers.",
            deletionOfDataBullet2: "2. Als gebruiker kunt u ook het resultaat van uw evenement definitief verwijderen, zowel van het mobiele apparaat als van het privégedeelte van de cloudopslag.",
            deletionOfDataBullet3: "3. Als gebruiker kunt u op elk moment uw account verwijderen, waardoor al uw gegevens (gebruikersgegevens en locatiegegevens) zowel lokaal op het apparaat (smartphone) als in de cloudopslag permanent worden verwijderd.",
            supportTitle: "Ondersteuning",
            supportText: "Ondersteuning wordt verleend via e-mail. Neem contact met ons op via hello@wandelar.be. Voeg een nauwkeurige beschrijving bij van hoe het probleem zich herhaalt, getoonde foutmeldingen en andere informatie die voor ons nuttig kan zijn om te weten. Dit zal ons in staat stellen om snel feedback te geven.",
            contactDetailsTitle: "Contactgegevens",
            contactDetailsText1: "Website ontwikkelaar: https://www.wandelar.be/",
            contactDetailsText2: "E-mail: hello@wandelar.be",
            dateLastEdited: "Laatst bewerkt op 9 juni 2022",
        },
        terms: {
            termsAndConditionsStatement: "Voorwaarden Verklaring",
            termsAndConditionsStatementText1: "Door WandelAR te gebruiken voor een evenement, erkennen en accepteren gebruikers de volgende Evenement Voorwaarden Verklaring.",
            termsAndConditionsStatementText2: "Deze Voorwaarden, samen met alle andere documenten waarnaar hierin wordt verwezen, beschrijven de gebruiksvoorwaarden waaronder u deze website, www.wandelar.be en app ('Onze App') mag gebruiken. Lees deze Voorwaarden zorgvuldig door en zorg ervoor dat u ze begrijpt. Uw instemming om te voldoen aan en te worden gebonden door deze Voorwaarden wordt geacht te zijn ontstaan bij uw eerste gebruik van Onze App. Als u niet instemt met en niet gebonden bent aan deze Voorwaarden, moet u het gebruik van onze App onmiddellijk staken.",
            riskWaiver: "Verklaring van afstand van risico",
            riskWaiverText1: "Wij hebben de algemene risicowaarschuwing en de specifieke risicowaarschuwing voor dit evenement gelezen. Wij zijn ons ervan bewust dat oriëntatieloop een recreatieve activiteit is die gevaarlijk kan zijn, aanvaarden dat er een zekere mate van risico is en erkennen dat wij op eigen risico aan dit evenement deelnemen.",
            riskWaiverText2: "Wij zijn op de hoogte van, en zullen voldoen aan, de Regels voor Deelnemers zoals gespecificeerd door de Internationale Oriëntatieloop Federatie en de Oriëntatieloop Organisatie van het land/regio van het evenement.",
            riskWaiverText3: "Wij ontslaan Orienteering Organisaties, aangesloten clubs, leden en alle andere partijen die betrokken zijn bij de organisatie van het evenement van elke verantwoordelijkheid of wettelijke aansprakelijkheid in verband met onze aanwezigheid op, en deelname aan, dit oriënteering evenement.",
            riskWaiverText4: "Voor zover de Competition and Consumer Act 2010 (of vergelijkbare wet) van toepassing is, is deze vrijwaring beperkt tot verantwoordelijkheid en wettelijke aansprakelijkheid voor overlijden of persoonlijk letsel en is niet van toepassing op aanzienlijk persoonlijk letsel veroorzaakt door roekeloos gedrag door bij Orienteering Organisaties aangesloten clubs, leden en alle andere partijen die betrokken zijn bij de organisatie van het evenement.",
            generalRiskWarning: "Waarschuwing voor algemeen risico",
            generalRiskWarningText1: "Deelname aan een oriënteringsevenement is onderhevig aan risico's. Deze omvatten overlijden, ernstig letsel of ziekte als gevolg van:",
            generalRiskWarningBullet1: "1. ruw terrein en obstakels;",
            generalRiskWarningBullet2: "2. oververmoeidheid;",
            generalRiskWarningBullet3: "3. hitte, koude of andere ongunstige weersomstandigheden;",
            generalRiskWarningBullet4: "4. planten- en dierenleven; en",
            generalRiskWarningBullet5: "5. ongevallen met voertuigen, andere deelnemers of voetgangers.",
            generalRiskWarningText2: "Er zijn ook risico's:",
            generalRiskWarningBullet6: "1. dat de toegang tot medische, evacuatie- of opsporingsdiensten traag zou kunnen verlopen; en",
            generalRiskWarningBullet7: "2. van schade aan, of verlies van, uw persoonlijke eigendommen.",
            statements: "Verklaringen",
            statementsBullet1: "1. Wij zijn ons ervan bewust dat er geen ziektekosten-, ongevallen-, ambulance-, invaliditeits- of levensverzekering is afgesloten.",
            statementsBullet2: "2. Wij staan het vrije gebruik van onze namen en foto's toe in informatie over deelname, resultaten, nieuws, marketing en promotiemateriaal met betrekking tot het evenement of oriëntatieloop. Dit strekt zich niet uit tot het publiceren van een foto van een persoon onder de 18 met zijn naam.",
            statementsBullet3: "3. Wij zullen ervoor zorgen dat alle kinderen onder onze controle tijdens het evenement te allen tijde onder passend toezicht staan. Wij erkennen dat ouders en voogden verantwoordelijk zijn om te beslissen of hun kinderen in staat zijn om zelf te oriënteren tijdens elk evenement.",
            definitionsAndInterpretation: "Definities en Interpretatie",
            definitionsAndInterpretationText: "In deze Algemene Voorwaarden hebben, tenzij de context anders vereist, de volgende uitdrukkingen de volgende betekenissen:",
            definitionsAndInterpretationBullet1: "1. 'Inhoud' betekent alle tekst, afbeeldingen, audio, video, scripts, code, software, databases en elke andere vorm van informatie die kan worden opgeslagen op een computer en verschijnt op, of deel uitmaakt van, Onze App; en",
            definitionsAndInterpretationBullet2: "2. 'Wij/Us/Onze' betekent WandelAR",
            informationAboutUs: "Informatie over ons",
            informationAboutUsText: "Onze App is eigendom van en wordt beheerd door WandelAR.",
            accessToOurApp: "Toegang tot onze App",
            accessToOurAppBullet1: "1. Toegang tot Onze App is gratis.",
            accessToOurAppBullet2: "2. Het is uw verantwoordelijkheid om alle noodzakelijke regelingen te treffen om toegang te krijgen tot Onze App.",
            accessToOurAppBullet3: "3. Toegang tot Onze App wordt verleend 'zoals het is' en op een 'zoals beschikbaar' basis. Wij mogen Onze App (of een deel ervan) te allen tijde en zonder aankondiging wijzigen, opschorten of stopzetten. Wij zijn op geen enkele manier aansprakelijk voor u als Onze App (of een deel daarvan) niet beschikbaar is op een bepaald moment en voor een bepaalde periode.",
            intellectualPropertyRights: "Intellectuele eigendomsrechten",
            intellectualPropertyRightsBullet1: "1. Alle Inhoud die in Onze App is opgenomen en de auteursrechten en andere intellectuele eigendomsrechten die op die Inhoud rusten, behoren toe aan of zijn in licentie gegeven door Ons, tenzij uitdrukkelijk anders is aangegeven. Alle Inhoud is beschermd door toepasselijke Belgische en internationale wetten en verdragen inzake intellectuele eigendom.",
            intellectualPropertyRightsBullet2: "2. U mag de Inhoud van onze App niet reproduceren, kopiëren, distribueren, verkopen, verhuren, in sub-licentie geven, opslaan of op enige andere wijze hergebruiken, tenzij u hiervoor uitdrukkelijke schriftelijke toestemming van Ons heeft gekregen.",
            intellectualPropertyRightsBullet21: "2.1. U mag:",
            intellectualPropertyRightsBullet211: "2.1.1. Toegang verkrijgen tot, bekijken en gebruiken van Onze App in een webbrowser (inclusief enige webbrowser mogelijkheid ingebouwd in andere soorten software of app);",
            intellectualPropertyRightsBullet212: "2.1.2. Download onze app (of een deel ervan) voor caching;",
            intellectualPropertyRightsBullet213: "2.1.3. Pagina's afdrukken vanuit onze app;",
            intellectualPropertyRightsBullet214: "2.1.4. Uittreksels downloaden van pagina's op Onze App; en",
            intellectualPropertyRightsBullet215: "2.1.5. Sla pagina's van onze app op om ze later en/of offline te bekijken.",
            intellectualPropertyRightsBullet22: "2.2. Onze status als eigenaar en auteur van de Inhoud op Onze App (of die van geïdentificeerde licentiegevers, indien van toepassing) moet altijd worden erkend.",
            intellectualPropertyRightsBullet23: "2.3. U mag geen Inhoud die is opgeslagen of gedownload van Onze App gebruiken voor commerciële doeleinden zonder eerst een licentie van Ons (of onze licentiegevers, voor zover van toepassing) te hebben verkregen om dit te doen. Dit verbiedt niet de normale toegang, het bekijken en gebruik van Onze App voor algemene informatiedoeleinden door zakelijke gebruikers of consumenten.",
            intellectualPropertyRightsBullet24: "2.4. Niets in deze Algemene Voorwaarden beperkt in het bijzonder het maken van tijdelijke kopieën; onderzoek en privéstudie; het maken van kopieën voor tekst- en gegevensanalyse voor niet-commercieel onderzoek; kritiek, recensie, citaat en nieuwsberichtgeving; karikatuur, parodie of pastiche; en het incidenteel opnemen van auteursrechtelijk beschermd materiaal.",
            intellectualPropertyRightsBullet3: "3. Links naar onze App",
            intellectualPropertyRightsBullet31: "3.1. U mag een link naar Onze App plaatsen op voorwaarde dat:",
            intellectualPropertyRightsBullet311: "3.1.1. U doet dit op een eerlijke en legale manier;",
            intellectualPropertyRightsBullet312: "3.1.2. U doet dit niet op een manier die enige vorm van associatie, goedkeuring of goedkeuring van Onze kant suggereert waar die niet bestaat;",
            intellectualPropertyRightsBullet313: "3.1.3. U maakt geen gebruik van logo's of handelsmerken die op Onze App worden getoond zonder Onze uitdrukkelijke schriftelijke toestemming; en",
            intellectualPropertyRightsBullet314: "3.1.4. U doet dit niet op een manier die bedoeld is om Onze reputatie te schaden of om er oneerlijk voordeel uit te halen.",
            intellectualPropertyRightsBullet32: "3.2. U mag naar elke pagina van Onze App linken.",
            intellectualPropertyRightsBullet33: "3.3. Framing of embedding van Onze App op andere websites is niet toegestaan zonder Onze uitdrukkelijke schriftelijke toestemming. Neem contact met Ons op via hello@wandelar.be voor verdere informatie.",
            intellectualPropertyRightsBullet34: "3.4. U mag geen link naar Onze App plaatsen vanaf een andere site waarvan de hoofdinhoud materiaal bevat dat:",
            intellectualPropertyRightsBullet341: "3.4.1. seksueel expliciet is;",
            intellectualPropertyRightsBullet342: "3.4.2. obsceen, opzettelijk beledigend, haatdragend of anderszins opruiend is;",
            intellectualPropertyRightsBullet343: "3.4.3. geweld bevordert;",
            intellectualPropertyRightsBullet344: "3.4.4. enige vorm van onwettige activiteit bevordert of daarbij behulpzaam is;",
            intellectualPropertyRightsBullet345: "3.4.5. discrimineert tegen, of op enigerlei wijze lasterlijk is voor, een persoon, groep of klasse van personen, ras, geslacht, godsdienst, nationaliteit, handicap, seksuele geaardheid of leeftijd;",
            intellectualPropertyRightsBullet346: "3.4.6. bedoeld is of anderszins waarschijnlijk is om een andere persoon te bedreigen, lastig te vallen, te ergeren, te alarmeren, te verontrusten of in verlegenheid te brengen;",
            intellectualPropertyRightsBullet347: "3.4.7. berekend is of op andere wijze kan leiden tot misleiding van een andere persoon;",
            intellectualPropertyRightsBullet348: "3.4.8. bedoeld is of anderszins waarschijnlijk is dat het recht op privacy van een ander wordt geschonden (of dreigt te worden geschonden);",
            intellectualPropertyRightsBullet349: "3.4.9. zich op misleidende wijze voordoet als een ander persoon of op andere wijze een verkeerde voorstelling geeft van de identiteit of verbondenheid van een bepaalde persoon op een wijze die misleiding tot doel heeft;",
            intellectualPropertyRightsBullet3410: "3.4.10.enige vorm van verbondenheid met ons impliceert waar die niet bestaat;",
            intellectualPropertyRightsBullet3411: "3.4.11. inbreuk maakt op, of helpt bij de inbreuk op, de intellectuele eigendomsrechten (met inbegrip van, maar niet beperkt tot, auteursrechten, handelsmerken en databankrechten) van enige andere partij; of",
            intellectualPropertyRightsBullet3412: "3.4.12. wordt gemaakt in strijd met een wettelijke verplichting jegens een derde partij, met inbegrip van, maar niet beperkt tot, contractuele verplichtingen en vertrouwensverplichtingen.",
            intellectualPropertyRightsBullet35: "3.5. Het is u bijvoorbeeld niet verboden links te plaatsen op sociale netwerksites voor algemeen gebruik, alleen omdat een andere gebruiker dergelijke inhoud kan plaatsen. Het is u echter wel verboden links te plaatsen op websites die zich richten op of aanmoedigen tot het plaatsen van dergelijke inhoud door gebruikers.",
            intellectualPropertyRightsBullet4: "4. Links naar andere sites",
            intellectualPropertyRightsBullet4Text: "Links naar andere sites kunnen in Onze App worden opgenomen. Tenzij uitdrukkelijk vermeld, vallen deze sites niet onder Onze controle. Wij aanvaarden geen verantwoordelijkheid of aansprakelijkheid voor de inhoud van sites van derden. Het opnemen van een link naar een andere site in Onze App is uitsluitend ter informatie en impliceert geen goedkeuring van de sites zelf of van degenen die er zeggenschap over hebben.",
            intellectualPropertyRightsBullet5: "5. Disclaimers",
            intellectualPropertyRightsBullet51: "5.1. Niets in Onze App vormt advies waarop u moet vertrouwen. Het wordt uitsluitend voor algemene informatiedoeleinden verstrekt.",
            intellectualPropertyRightsBullet52: "5.2. Voor zover toegestaan door de wet, geven Wij geen verklaring, garantie of waarborg dat Onze App aan uw eisen zal voldoen, dat het geen inbreuk zal maken op de rechten van derden, dat het compatibel zal zijn met alle software en hardware, of dat het veilig zal zijn.",
            intellectualPropertyRightsBullet53: "5.3. Wij doen redelijke inspanningen om ervoor te zorgen dat de Inhoud op Onze App volledig, nauwkeurig en up-to-date is. Wij geven echter geen verklaringen, garanties of waarborgen (hetzij uitdrukkelijk of impliciet) dat de Inhoud volledig, nauwkeurig of up-to-date is.",
            intellectualPropertyRightsBullet6: "6. Onze aansprakelijkheid",
            intellectualPropertyRightsBullet61: "1. Voor zover wettelijk toegestaan aanvaarden Wij geen aansprakelijkheid jegens enige gebruiker voor enig verlies of schade, voorzienbaar of anderszins, in contract, onrechtmatige daad (inclusief nalatigheid), voor schending van wettelijke plicht, of anderszins, voortvloeiend uit of in verband met het gebruik van (of het onvermogen tot gebruik) Onze App of het gebruik van of vertrouwen op enige Inhoud opgenomen in Onze App.",
            intellectualPropertyRightsBullet62: "2. Voor zover toegestaan door de wet, sluiten Wij alle verklaringen, garanties en waarborgen uit (zowel expliciet als impliciet) die van toepassing kunnen zijn op Onze App of enige Inhoud die op Onze App staat",
            intellectualPropertyRightsBullet63: "3. Als u een zakelijke gebruiker bent, aanvaarden wij geen aansprakelijkheid voor verlies van winst, verkoop, zaken of inkomsten; verlies van zakelijke kansen, goodwill of reputatie; verlies van verwachte besparingen; bedrijfsonderbreking; of voor enige indirecte of gevolgschade of verlies.",
            intellectualPropertyRightsBullet64: "4. Wij betrachten alle redelijke vakkundigheid en zorg om ervoor te zorgen dat Onze App vrij is van virussen en andere malware. Wij aanvaarden geen aansprakelijkheid voor verlies of schade als gevolg van een virus of andere malware, een distributed denial of service aanval, of ander schadelijk materiaal of gebeurtenis die een nadelige invloed kan hebben op uw hardware, software, gegevens of ander materiaal dat ontstaat als gevolg van uw gebruik van Onze App (inclusief het downloaden van Content daarvan) of een andere site waarnaar op Onze App wordt verwezen.",
            intellectualPropertyRightsBullet65: "5. Wij aanvaarden geen verantwoordelijkheid of aansprakelijkheid die voortvloeit uit een onderbreking of niet-beschikbaarheid van Onze App als gevolg van externe oorzaken met inbegrip van, maar niet beperkt tot, storing in de ISP-apparatuur, storing in de host-apparatuur, storing in het communicatienetwerk, natuurverschijnselen, oorlogshandelingen, of wettelijke beperkingen en censuur.",
            intellectualPropertyRightsBullet66: "6. Nothing in these Terms and Conditions excludes or restricts Our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be excluded or restricted by law. For full details of consumers' legal rights, including those relating to digital content.",
            virusesMalwareAndSecurity: "Virussen, malware en veiligheid",
            virusesMalwareAndSecurityBullet1: "1. Wij betrachten alle redelijke vakkundigheid en zorg om ervoor te zorgen dat Onze App veilig is en vrij van virussen en andere malware.",
            virusesMalwareAndSecurityBullet2: "2. U bent verantwoordelijk voor de bescherming van uw hardware, software, gegevens en ander materiaal tegen virussen, malware en andere veiligheidsrisico's op het internet.",
            virusesMalwareAndSecurityBullet3: "3. U mag niet opzettelijk virussen of andere malware, of ander materiaal dat kwaadaardig of technologisch schadelijk is, in of via Onze App introduceren.",
            virusesMalwareAndSecurityBullet4: "4. U mag niet proberen om ongeautoriseerde toegang te verkrijgen tot enig deel van Onze App, de server waarop Onze App is opgeslagen, of enige andere server, computer of database verbonden met Onze App.",
            virusesMalwareAndSecurityBullet5: "5. U mag Onze App niet aanvallen door middel van een denial of service aanval, een gedistribueerde denial of service aanval, of op enige andere wijze.",
            virusesMalwareAndSecurityBullet6: "6. Alle digitale inbreuken zullen worden gemeld aan de relevante rechtshandhavingsinstanties en Wij zullen volledig samenwerken met deze instanties door uw identiteit aan hen bekend te maken. Uw recht om Onze App te gebruiken zal onmiddellijk worden beëindigd in het geval van een dergelijke inbreuk.",
            acceptableUsagePolicy: "Beleid inzake aanvaardbaar gebruik",
            acceptableUsagePolicyBullet1: "1. U mag Onze App alleen gebruiken op een manier die rechtmatig is. In het bijzonder:",
            acceptableUsagePolicyBullet11: "1.1. U dient ervoor te zorgen dat u volledig voldoet aan alle lokale, nationale of internationale wetten en/of regelgeving;",
            acceptableUsagePolicyBullet12: "1.2. U mag Onze App niet gebruiken op een manier, of voor een doel, dat onwettig of frauduleus is;",
            acceptableUsagePolicyBullet13: "1.3. u mag Onze App niet gebruiken om willens en wetens gegevens te verzenden, te uploaden of op andere wijze te verzenden die een vorm van virus of andere malware bevatten, of enige andere code die is ontworpen om nadelige invloed uit te oefenen op computerhardware, -software of -gegevens van welke aard dan ook; en",
            acceptableUsagePolicyBullet14: "1.4. U mag Onze App niet gebruiken op een manier, of voor een doel, dat bedoeld is om een persoon of personen op enigerlei wijze schade te berokkenen.",
            acceptableUsagePolicyBullet15: "1.5. Wij behouden ons het recht voor om uw toegang tot Onze App op te schorten of te beëindigen als u wezenlijk inbreuk maakt op een van de andere bepalingen van deze Voorwaarden en",
            acceptableUsagePolicyBullet15Text: "Voorwaarden. In het bijzonder kunnen Wij één of meer van de volgende acties ondernemen:",
            acceptableUsagePolicyBullet151: "1.5.1. uw recht op toegang tot Onze App tijdelijk of permanent op te schorten;",
            acceptableUsagePolicyBullet152: "1.5.2. u een schriftelijke waarschuwing geven;",
            acceptableUsagePolicyBullet153: "1.5.3. gerechtelijke stappen tegen u ondernemen voor de terugbetaling van alle relevante kosten op basis van schadeloosstelling als gevolg van uw inbreuk;",
            acceptableUsagePolicyBullet154: "1.5.4. indien nodig verdere juridische stappen tegen u te ondernemen;",
            acceptableUsagePolicyBullet155: "1.5.5. dergelijke informatie bekend te maken aan rechtshandhavingsinstanties, zoals vereist of zoals Wij redelijkerwijs noodzakelijk achten; en/of",
            acceptableUsagePolicyBullet156: "1.5.6. alle andere acties die Wij redelijkerwijs passend (en wettig) achten.",
            acceptableUsagePolicyBullet2: "2. Wij sluiten hierbij alle aansprakelijkheid uit die voortvloeit uit handelingen (met inbegrip van, maar niet beperkt tot die welke hierboven zijn uiteengezet) die Wij kunnen verrichten naar aanleiding van inbreuken op deze Algemene Voorwaarden.",
            changesToTheseTermsAndConditions: "Wijzigingen in deze voorwaarden",
            changesToTheseTermsAndConditionsText: "Wij kunnen deze Algemene Voorwaarden te allen tijde wijzigen. Dergelijke wijzigingen zullen bindend voor u worden bij uw eerste gebruik van Onze App nadat de wijzigingen zijn doorgevoerd. U wordt daarom geadviseerd om deze pagina van tijd tot tijd te controleren. In geval van tegenstrijdigheid tussen de huidige versie van deze Voorwaarden en enige eerdere versie(s), prevaleren de huidige en van kracht zijnde bepalingen tenzij uitdrukkelijk anders is vermeld.",
            contactingUs: "Contact met ons opnemen",
            contactingUsText: "Om contact met ons op te nemen, kunt u een e-mail sturen naar hello@wandelar.be of een van de methoden gebruiken die u vindt op ons contactformulier hier",
            communicationsFromUs: "Mededelingen van ons",
            communicationsFromUsBullet1: "1. Indien Wij over uw contactgegevens beschikken, kunnen Wij u van tijd tot tijd belangrijke mededelingen per e-mail sturen. Dergelijke kennisgevingen kunnen betrekking hebben op zaken zoals, maar niet beperkt tot, wijzigingen in de dienstverlening en wijzigingen in deze Algemene Voorwaarden.",
            communicationsFromUsBullet2: "2. Wij zullen u nooit marketing emails sturen van welke aard dan ook zonder uw uitdrukkelijke toestemming. Indien u wel dergelijke toestemming geeft, kunt u zich op elk moment uitschrijven. Alle marketing emails die door ons worden verzonden bevatten een link om u af te melden. Als u zich op elk moment afmeldt voor het ontvangen van e-mails van ons, kan het tot 14 werkdagen duren voordat wij aan uw verzoek voldoen. Gedurende die tijd kunt u e-mails van ons blijven ontvangen.",
            communicationsFromUsBullet3: "3. Voor vragen of klachten over communicatie van ons (met inbegrip van, maar niet beperkt tot marketing e-mails), neem dan contact met ons op via hello@wandelar.be of via dit formulier",
            dataProtection: "Gegevensbescherming",
            dataProtectionBullet1: "1. Alle persoonlijke informatie die Wij kunnen gebruiken, zal worden verzameld, verwerkt en bewaard in overeenstemming met de bepalingen van EU-verordening 2016/679 Algemene Verordening Gegevensbescherming ('GDPR') en uw rechten op grond van de GDPR.",
            dataProtectionBullet2: "2. Voor volledige details over het verzamelen, verwerken, opslaan en bewaren van persoonsgegevens door ons, inclusief maar niet beperkt tot de doeleinden waarvoor persoonsgegevens worden gebruikt, de rechtsgrondslag(en) voor het gebruik ervan, details over uw rechten en hoe u deze kunt uitoefenen, en het delen van persoonsgegevens (indien van toepassing), verwijzen wij u naar het Privacybeleid.",
            lawAndJurisdiction: "Recht en bevoegdheid",
            lawAndJurisdictionBullet1: "1. Deze Algemene Voorwaarden, en de relatie tussen u en ons (contractueel of anderszins) worden beheerst door, en geïnterpreteerd in overeenstemming met de Belgische wetgeving",
            lawAndJurisdictionBullet2: "2. Als u een consument bent, zal elk geschil, controverse, procedure of claim tussen u en ons met betrekking tot deze Algemene Voorwaarden, of de relatie tussen u en ons (hetzij contractueel of anderszins) onderworpen zijn aan de jurisdictie van de rechtbanken van België, Mechelen.",
            lawAndJurisdictionBullet3: "3. Indien u een bedrijf bent, zullen alle geschillen met betrekking tot deze Algemene Voorwaarden, de relatie tussen u en ons, of alle zaken die daaruit voortvloeien of daarmee verband houden (hetzij contractueel of anderszins) onderworpen zijn aan de exclusieve jurisdictie van de rechtbanken van België, Mechelen.",
        }
    },
    be_fr: {
        "loading": {
            "title": "Chargement..."
        },
        "tagline": "Marcher avec des jeux",
        "bio": "Marche. Jouez. Découvrez. Jeux pour le renforcement de l'esprit d'équipe, le tourisme et l'éducation.",
        "us_en": "EN",
        "be_nl": "NL",
        "be_fr": "FR",
        "de_de": "DE",
        "menu": {
            "menu": "Menu",
            "howitworks": "Comment cela fonctionne-t-il ?",
            "pricing": "Tariff",
            "contact": "Contact",
            "login": "Connexion",
            "register": "Registre",
            "hello": "Bonjour",
            "dashboard": "Tableau de bord",
            "solutions": "Nos solutions",
            "teambuilding": "Teambuilding",
            "discover": "Découvrez un lieu",
            "custom": "Sur mesure",
            "about": "A propos de nous",
            "story": "Notre histoire",
            "team": "Équipe",
            "home": {
                "configurator": "Toutes les routes",
                "dashboard": "Tableau de bord",
                "organise": "Organisez",
                "events": "Événements",
                "settings": "Paramètres",
                "profile": "Profil",
                "manage": "Admin",
                "orienteering": "Course d'orientation",
                "invoices": "Factures",
                "billing": "Facturation",
                "account": "Compte"
            }
        },
        "pins": {
            "pin1": {
                "title": "Début"
            },
            "pin2": {
                "title": "Jouer le jeu"
            },
            "pin3": {
                "title": "Fin"
            },
            "pin4": {
                "title": "Joueur",
                "subtitle": "Équipe",
                "time": "00:32:43",
                "points": "100"
            }
        },
        "map": {
            "title": "Teambuilding, tourisme, éducation",
            "subtitle": "L'application WandelAR combine des jeux de réalité étendue et augmentée avec la populaire course d'orientation, créant ainsi une expérience à ne pas manquer.",
            "text": "Tout ce dont vous avez besoin en tant que participant est un smartphone ou une tablette et notre application WandelAR.",
            "play": "Jouer la vidéo"
        },
        "homepage": {
            "title": "Marcher avec des jeux",
            "subtitle": "Marche. Jouez. Découvrez. Des jeux pour le renforcement des équipes, le tourisme et l'éducation.",
            "scroll": "Faites défiler vers le bas",
            "info": {
                "title": "Qu'allez-vous obtenir ?",
                "subtitle": "Téléchargez notre application et commencez un parcours GRATUIT",
                "button": "Vidéo de démonstration"
            },
            "sponsor": {
                "title": "Sponsoriser avec votre propre logo",
                "subtitle": "Téléchargez le logo de votre entreprise et personnalisez l'application avec style.",
                "button": "Nos prix"
            },
            "route": {
                "bigtitle": "Organise route",
                "title": "Créez votre propre itinéraire",
                "subtitle": "Saisissez la date de votre course d'orientation et recevez un code QR pour commencer la course.",
                "button": "Nous contacter"
            },
            "card1": {
                "title": "Mouvement",
                "subtitle": "Marche d'orientation",
                "text1": "Dans une course d'orientation, vous essayez d'aller du départ à l'arrivée aussi vite que possible.",
                "text2": "Créez votre propre itinéraire, envoyez-nous la date de votre course d'orientation et recevez un code QR pour lancer la course."
            },
            "card2": {
                "title": "Fun",
                "subtitle": "Jeux",
                "text1": "Les points de contrôle sont dotés de jeux de réalité étendue sous forme de jeux interactifs numériques.",
                "text2": "Des puzzles de mots aux chasses au trésor et des rébus aux salles d'évasion. Il y en a pour tous les goûts !"
            },
            "card3": {
                "title": "Personnalisé",
                "subtitle": "Tout est possible !",
                "text1": "Personnalisez l'application avec votre propre logo.",
                "text2": "Voulez-vous intégrer nos jeux standard dans votre cours d'orientation ou préférez-vous des jeux sur mesure ?",
                "text3": "Tout est possible !"
            },
            "card4": {
                "title": "Communauté",
                "subtitle": "Jouer pour gagner",
                "text1": "Jouez le jeu d'orientation avec votre équipe et renforcez votre position dans le classement. Grimpez au sommet et comparez-vous aux autres.",
                "text2": "Visez le temps le plus rapide et/ou le plus de points."
            },
            "teambuilding": {
                "title": "Teambuilding",
                "subtitle": "Embarquement et visites",
                "text": "Organisez une expérience inédite pour vous et vos collègues. Fournissez-nous un point de départ, un point d'arrivée et des points de contrôle et nous vous ferons vivre une expérience inoubliable.",
                "button": "Plus d'informations"
            },
            "discover": {
                "title": "Découvrez un lieu",
                "subtitle": "Dans le domaine municipal, scolaire ou provincial",
                "text": "Votre visiteur découvre le lieu d'une manière inédite.",
                "button": "Plus d'informations"
            }
        },
        "howitworks": {
            "title": "Comment cela fonctionne-t-il ?",
            "subtitle": "Lisez nos questions fréquemment posées pour mieux comprendre WandelAR.",
            "bigtitle": "Comment cela fonctionne",
            "faq1": {
                "title": "Comment puis-je commencer un cours d'orientation ?",
                "text1": "Téléchargez l'application WandelAR depuis l'AppStore (pour iOS) ou le PlayStore (pour Android). Dans l'appli, vous trouverez un aperçu de tous les événements de course d'orientation actuels et à venir. Choisissez une course et rendez-vous à l'adresse de départ le jour de la course d'orientation. Scannez ensuite le code QR de l'organisateur de la course d'orientation et lancez la course."
            },
            "faq2": {
                "title": "Que sont les thèmes ?",
                "text1": "Chaque organisateur d'une course d'orientation choisit son propre thème. Le thème est traduit en un jeu qui se joue pendant la course d'orientation. À chaque point de contrôle, vous gagnez des points en jouant à un jeu qui vous permet de terminer plus haut dans le classement.  Le joueur qui gagne le plus de points et qui effectue le parcours le plus rapidement gagne la course d'orientation."
            },
            "faq3": {
                "title": "Puis-je personnaliser mon cours d'orientation ?",
                "text1": "Si vous choisissez de sponsoriser votre course d'orientation, votre logo apparaîtra dans notre application sur la page du calendrier et sur la carte."
            },
            "faq4": {
                "title": "Que se passe-t-il si le nombre de participants est supérieur à celui de mon inscription ?",
                "text1": "Lors de la création de votre course d'orientation, nous fixons une date de début et de fin et le nombre de participants que vous attendez. Si le code QR est scanné en dehors de l'intervalle donné, le parcours d'orientation ne démarrera pas. Si le nombre de participants est dépassé, la course d'orientation ne commencera pas non plus."
            }
        },
        "pricing": {
            "title": "Calculateur de prix",
            "subtitle": "Sélectionnez un thème, indiquez le nombre de participants et choisissez si vous souhaitez parrainer avec votre propre logo.",
            "send": "Pré-commande",
            "bigtitle": "Prix",
            "chooseTheme": "Choisissez un thème",
            "theme": "Thème",
            "amountParticipantsAsk": "Indiquez le nombre de participants",
            "pricePerParticipant": "/ participant",
            "placeholderAmount": "Par exemple, 10",
            "extraProducts": "Produits supplémentaires",
            "sponsor": "Sponsor",
            "withOwnLogo": "Avec votre propre logo",
            "amountParticipants": "Nombre de participants",
            "participants": "participants",
            "summary": "Résumé",
            "total": "Total",
            "vatExcluded": "TVA non comprise",
            "sendAsk": "Envoyez-nous votre calcul de prix sans engagement.*",
            "email": "Courriel :",
            "emailPlaceholder": "Par exemple, ella@WandelAR.be",
            "phone": "Numéro de téléphone",
            "phonePlaceholder": "Par exemple, le 32 123 456 789.",
            "sendCaption": "Après avoir reçu le calcul du prix, un membre de l'équipe WandelAR vous contactera pour finaliser la commande."
        },
        "socialbanner": {
            "tagline": "Rejoignez-nous sur les médias sociaux"
        },
        "footer": {
            "tagline": "Marche. Jouez. Découvrez.",
            "pricing": "Fixation des prix",
            "contact": "Contact",
            "copyright": "© WandelAR. Tous droits réservés.",
            "privacy": "Politique de confidentialité",
            "terms": "Conditions"
        },
        "monoLight": "Lumière mono",
        "monoDark": "Mono dark",
        "themes": {
            "theme_0": {
                "name": "Aucun",
                "description": "Aucun"
            },
            "theme_1": {
                "name": "Tirez sur cette balle",
                "description": "Jeu de basket-ball d'arcade"
            },
            "theme_2": {
                "name": "Expérience personnalisée",
                "description": "Contactez nous pour les prix"
            }
        },
        "products": {
            "product_0": {
                "name": "Sponsor",
                "description": "Avec votre propre logo"
            },
            "product_1": {
                "name": "Publier l'itinéraire",
                "description": "sur le calendrier"
            }
        },
        "toast": {
            "ok": "Ok"
        },
        "errors": {
            "participantsCount": {
                "title": "Erreur",
                "msg": "Le nombre de participants est de 0. Assurez-vous que le montant est compris entre 1 et 10000.",
                "caption": "Participants"
            },
            "participantsExceeds": {
                "title": "Erreur",
                "msg": "Le nombre de participants dépasse 10000. Assurez-vous que le montant est compris entre 1 et 10000.",
                "caption": "Participants"
            },
            "onlyNumbers": {
                "title": "Erreur",
                "msg": "Seuls les chiffres sont autorisés.",
                "caption": "Participants"
            },
            "email": {
                "title": "Erreur",
                "msg": "L'adresse électronique saisie n'est pas valide. Une adresse électronique ressemble à ceci : name@domain.com",
                "caption": "Courriel :"
            },
            "phone": {
                "title": "Erreur",
                "msg": "Le numéro de téléphone saisi n'est pas valide. Un numéro de téléphone ne peut contenir que des chiffres, les caractères /.- et des espaces.",
                "caption": "Numéro de téléphone"
            }
        },
        "messages": {
            "msg404": {
                "title": "Erreur",
                "msg": "Non trouvé.",
                "caption": "Code 404"
            },
            "msg500": {
                "title": "Erreur",
                "msg": "Condition inattendue.",
                "caption": "Code 500"
            },
            "msg201": {
                "title": "Succès",
                "msg": "Merci pour le calcul du prix sans engagement. Un membre de l'équipe WandelAR vous contactera dans les plus brefs délais pour finaliser votre commande.",
                "caption": "Pré-commande"
            }
        },
        "login": {
            "title": "Connexion",
            "subtitle": "Inscrivez-vous et découvrez les analyses de vos promenades dans un tableau de bord clair.",
            "email": "Courriel :",
            "emailPlaceholder": "Par exemple, ella@WandelAR.be",
            "password": "Mot de passe",
            "passwordPlaceholder": "•••••••••••",
            "forgotPassword": "Mot de passe oublié ?",
            "login": "Connexion",
            "noAccountAsk": "Vous n'avez pas encore de compte ?",
            "registerHere": "Inscrivez-vous ici",
            "bigtitle": "Connexion",
            "button": "Connexion",
            "messages": {
                "msg401": {
                    "title": "Erreur",
                    "msg": "Accès non autorisé.",
                    "caption": "Code 401"
                },
                "msg500": {
                    "title": "Erreur",
                    "msg": "Condition inattendue.",
                    "caption": "Code 500"
                },
                "msg200": {
                    "title": "Succès",
                    "msg": "Connexion réussie.",
                    "caption": "Connexion"
                },
                "msg402": {
                    "title": "Erreur",
                    "msg": "Votre compte n'a pas été activé. Veuillez vérifier votre e-mail et activer votre compte. Contactez-nous si vous n'avez pas reçu d'e-mail.",
                    "caption": "Connexion"
                }
            }
        },
        "register": {
            "title": "Registre",
            "subtitle": "Créez un compte et connectez-vous à votre tableau de bord.",
            "firstname": "Prénom",
            "firstnamePlaceholder": "Par exemple, Ella",
            "lastname": "Nom de famille",
            "lastnamePlaceholder": "E.g. WandelAR",
            "email": "Courriel :",
            "emailPlaceholder": "Par exemple, ella@WandelAR.be",
            "password": "Mot de passe",
            "passwordPlaceholder": "•••••••••••",
            "declaration1": "En continuant, vous déclarez que vous avez lu et accepté les",
            "privacyPolicy": "Politique de confidentialité",
            "declaration2": "et le",
            "terms": "Modalités et conditions",
            "declaration3": "de WandelAR.",
            "register": "Registre",
            "accountAsk": "Avez-vous déjà un compte ?",
            "loginHere": "Connectez-vous ici",
            "bigtitle": "Registre",
            "button": "Registre",
            "messages": {
                "msg401": {
                    "title": "Erreur",
                    "msg": "Accès non autorisé.",
                    "caption": "Code 401"
                },
                "msg500": {
                    "title": "Erreur",
                    "msg": "Condition inattendue.",
                    "caption": "Code 500"
                },
                "msg201": {
                    "title": "Succès",
                    "msg": "Merci de vous être inscrit. Vous recevrez bientôt un e-mail pour activer votre connexion. Veuillez vérifier votre boîte à spam pour être sûr.",
                    "caption": "Registre"
                },
                "msg405": {
                    "title": "Erreur",
                    "msg": "Le mot de passe doit comporter au moins 8 caractères.",
                    "caption": "Registre"
                },
                "msg406": {
                    "title": "Erreur",
                    "msg": "Aucun mot de passe n'est fourni.",
                    "caption": "Registre"
                },
                "msg411": {
                    "title": "Erreur",
                    "msg": "Veuillez accepter notre politique de confidentialité et nos conditions générales.",
                    "caption": "Registre"
                },
                "msg412": {
                    "title": "Erreur",
                    "msg": "Veuillez accepter nos conditions.",
                    "caption": "Registre"
                },
                "msg409": {
                    "title": "Erreur",
                    "msg": "L'adresse électronique saisie existe déjà.",
                    "caption": "Registre"
                },
                "msg410": {
                    "title": "Erreur",
                    "msg": "L'adresse électronique saisie n'est pas valide. Une adresse électronique ressemble à ceci : name@domain.com",
                    "caption": "Registre"
                },
                "msg413": {
                    "title": "Erreur",
                    "msg": "Le prénom doit comporter au moins un caractère et au maximum 254 caractères.",
                    "caption": "Registre"
                },
                "msg414": {
                    "title": "Erreur",
                    "msg": "Le nom de famille doit comporter au moins un caractère et au maximum 254 caractères.",
                    "caption": "Registre"
                }
            }
        },
        "forgot": {
            "title": "Mot de passe oublié ?",
            "subtitle": "Saisissez votre adresse e-mail ci-dessous et vous recevrez un e-mail contenant un lien pour réinitialiser votre mot de passe.",
            "email": "Courriel :",
            "emailPlaceholder": "Par exemple, ella@WandelAR.be",
            "button": "Demander un nouveau mot de passe",
            "bigtitle": "J'ai oublié",
            "messages": {
                "msg200": {
                    "title": "Succès",
                    "msg": "Les instructions pour définir un nouveau mot de passe ont été envoyées à votre adresse électronique. Veuillez vérifier votre boîte aux lettres électronique prochainement et n'oubliez pas de vérifier votre dossier de spam !",
                    "caption": "Mot de passe oublié"
                },
                "msg409": {
                    "title": "Erreur",
                    "msg": "Cette adresse e-mail n'est pas liée à un compte WandelAR.",
                    "caption": "Mot de passe oublié"
                },
                "msg410": {
                    "title": "Erreur",
                    "msg": "L'adresse électronique saisie n'est pas valide. Une adresse électronique ressemble à ceci : name@domain.com",
                    "caption": "Mot de passe oublié"
                }
            }
        },
        "reset": {
            "title": "Réinitialiser le mot de passe",
            "subtitle": "Entrez un nouveau mot de passe",
            "password": "Nouveau mot de passe",
            "passwordPlaceholder": "•••••••••••",
            "button": "Définir un nouveau mot de passe",
            "bigtitle": "Mot de passe",
            "messages": {
                "msg200": {
                    "title": "Succès",
                    "msg": "Le mot de passe a été modifié avec succès. Nous vous avons envoyé un e-mail de confirmation.",
                    "caption": "Réinitialiser le mot de passe"
                },
                "msg404": {
                    "title": "Erreur",
                    "msg": "Code inconnu.",
                    "caption": "Réinitialiser le mot de passe"
                },
                "msg405": {
                    "title": "Erreur",
                    "msg": "Le mot de passe doit comporter au moins 8 caractères.",
                    "caption": "Réinitialiser le mot de passe"
                },
                "msg406": {
                    "title": "Erreur",
                    "msg": "Aucun mot de passe n'est fourni.",
                    "caption": "Réinitialiser le mot de passe"
                }
            }
        },
        "configurator": {
            "title": "Configurateur",
            "subtitle": "Vue d'ensemble de tous les itinéraires.",
            "searchPlaceholder": "Trouver une promenade"
        },
        "dashboard": {
            "title": "Bienvenue dans votre tableau de bord",
            "subtitle": "Prochainement",
            "hello": "Bonjour"
        },
        "organise": {
            "title": "Organisez",
            "subtitle": "Prochainement"
        },
        "events": {
            "title": "Événements",
            "subtitle": "Prochainement"
        },
        "invoices": {
            "title": "Factures",
            "subtitle": "Disponible prochainement."
        },
        "settings": {
            "title": "Paramètres",
            "subtitle": "Changez les couleurs et la langue du site web ici."
        },
        "profile": {
            "title": "Profil",
            "subtitle": "Détails",
            "logout": "Déconnexion",
            "username": "Nom d'utilisateur",
            "usernameExplain": "Votre nom d'utilisateur est unique et peut être utilisé pour partager votre page publique.",
            "usernamePlaceholder": "Entrez un nom d'utilisateur unique",
            "channel": "Lien vers le canal",
            "channelExplain": "Il s'agit du lien de votre chaîne. Vous pouvez mettre en valeur vos itinéraires créés et les partager avec vos followers. Configurez un nom d'utilisateur personnalisé et unique pour personnaliser votre lien de canal.",
            "error": {
                "code409": {
                    "set": {
                        "title": "Erreur",
                        "msg": "Le nom d'utilisateur est déjà défini",
                        "caption": "Nom d'utilisateur"
                    },
                    "taken": {
                        "title": "Erreur",
                        "msg": "Le nom d'utilisateur est déjà pris",
                        "caption": "Nom d'utilisateur"
                    },
                    "user": {
                        "title": "Erreur",
                        "msg": "L'utilisateur n'existe pas",
                        "caption": "Nom d'utilisateur"
                    },
                },
                "code413": {
                    "min": {
                        "title": "Erreur",
                        "msg": "Le nom d'utilisateur doit comporter au moins 3 caractères",
                        "caption": "Nom d'utilisateur"
                    },
                    "max": {
                        "title": "Erreur",
                        "msg": "Le nom d'utilisateur doit comporter au maximum 30 caractères",
                        "caption": "Nom d'utilisateur"
                    },
                    "char": {
                        "title": "Erreur",
                        "msg": "Votre nom d'utilisateur ne peut être composé que de caractères alphanumériques (A-Z, a-z, 0-9), de traits de soulignement (_), de tirets (-) et de points (.).",
                        "caption": "Nom d'utilisateur"
                    },
                },
                "code200": {
                    "title": "Succès",
                    "msg": "Votre nom d'utilisateur a été mis à jour. Assurez-vous d'informer vos followers du changement de lien de votre chaîne.",
                    "caption": "Nom d'utilisateur"
                }
            }
        },
        "notfound": {
            "title": "Erreur 404",
            "subtitle": "Oups! Quelque chose s'est mal passé :s",
            "caption": "Page non trouvée"
        },
        "welcome": {
            "title": "Bienvenue sur",
            "subtitle": "Votre compte est activé.",
            "button": "Connexion",
            "bigtitle": "Bienvenue sur"
        },
        "orderType_0": "Par nom",
        "orderType_1": "Le plus récent",
        "orderType_2": "Le plus ancien",
        "orderType_3": "Dernière mise à jour",
        "noRoutesTitle": "Créez votre premier itinéraire",
        "noRoutesSubTitle": "Appuyez sur le bouton 'plus' pour créer un nouvel itinéraire.",
        "noRoutesText": "Conseil : basculez entre les vues 'grille' et 'liste' ;)",
        "noRoutesFoundTitle": "Aucune randonnée trouvée",
        "noRoutesFoundSubTitle": "Désolé, nous n'avons pu trouver aucun résultat pour votre recherche.",
        "noRoutesFoundText": "Conseil : utilisez des filtres pour affiner votre recherche.",
        "createdOn": "Créé le",
        "months": ["Jan", "Fév", "Mar", "Avril", "Mai", "Juin", "Juil", "Août", "Sep", "Oct", "Nov", "Déc"],
        "days": ["dim", " lun", " mar", " mer", " jeu", " ven", " sam."],
        "filter": {
            "title": "Filtres",
            "apply": "Postuler et rechercher",
            "clearAll": "Effacer tout",
            "location": {
                "search": "Recherche par lieu",
                "error": {
                    "title": "Erreur",
                    "msg": "Pour filtrer par lieu, saisissez un lieu ou cochez 'près de moi'",
                    "caption": "Location"
                }
            },
            "locationPlaceholder": "Entrez le lieu",
            "distance": "Distance de l'itinéraire",
            "age": "Âge",
            "withGames": "Seulement les routes avec le jeu",
            "noGames": "Seulement les routes sans jeu",
            "withTimer": "Seulement les routes avec le timer",
            "noTimer": "Seulement les routes sans timer",
            "range": "Gamme",
            "nearMe": "Dans ma région",
            "radius": "Rayon de recherche",
            "year": "ans",
            "geo": {
                "error": {
                    "title": "Erreur",
                    "msg": "Pour utiliser la fonction 'Près de moi', votre position doit être activée.",
                    "caption": "Près de moi"
                }
            }
        },
        "course": {
            "share": "Partager le lien",
            "shareExplain": "Vous pouvez partager ce lien avec vos amis, votre famille ou vos collègues.",
            "new": {
                "error": {
                    "title": "Erreur",
					"msg": "Un problème est survenu lors de la sauvegarde des données de l'itinéraire. Veuillez réessayer!",
					"caption": "Sauvegarder"
                }
            },
            "ok": "Ok",
            "routePlaceholder": "Parcours sans titre",
            "addCheckpoint": "point de contrôle",
            "noCheckpointsTitle": "Aucun point de contrôle pour le moment",
            "noCheckpointsSubtitle1": "Il semble que vous n'ayez pas encore créé de points de contrôle",
            "noCheckpointsSubtitle2": "Cliquez sur 'Editer' pour ajouter des points de contrôle.",
            "noCheckpointsText1": "Appuyez sur le bouton 'plus'",
            "noCheckpointsText2": "pour créer un point de contrôle.",
            "noImagesTitle": "Pas encore d'images",
            "noImagesSubtitle": "Il semble que vous n'ayez pas encore ajouté d'images.",
            "noImagesText1": "Appuyez sur le bouton 'modifier'",
            "noImagesText2": "pour ajouter des images.",
            "legend": "Légende",
            "scrollToZoom": "Faire défiler pour zoomer",
            "clickAndDragMapToMove": "Cliquer et faire glisser la carte pour la déplacer",
            "clickMapToAddPin": "Cliquer sur la carte pour ajouter une épingle",
            "clickAndDragPinToMove": "Cliquer et faire glisser l'épingle pour la déplacer",
            "placeholderSearch": "Recherche d'un lieu",
            "fitRoute": "Adapté à la carte",
            "dragPin": "Faites glisser l'épingle pour la déplacer",
            "dragToCrop": "Faire glisser pour recadrer",
            "dragToCropTitle": "Recadrage et aperçu de l'image",
            "carousel": "Carrousel",
            "create": "Créer une route",
            "upload": {
                "remove": "Veuillez supprimer tous les fichiers non pris en charge.",
                "dragDrop": "Faites glisser les images ici ou cliquez pour les télécharger",
                "allowed": "Seuls les formats *.jpg, *.jpeg et *.png sont autorisés.",
                "unsupported": "Fichier non pris en charge",
                "unsupportedSize": "Seuls les fichiers de 3Mb maximum sont autorisés",
                "uploading": "téléchargement de",
                "uploaded": "téléchargés",
                "file": "fichier",
                "files": "fichiers",
                "noUploadProgress": "Pas de téléchargement en cours"
            },
            "copied": {
                "title": "Copié",
                "caption": "Vers le presse-papiers"
            },
            "placePin": {
                "title": "Poste de contrôle",
                "subtitle": "Zoom et panoramique pour ajuster",
                "close": "Fermer",
                "add": "Ajouter"
            },
            "date": {
                "created": "Créé à",
                "updated": "Dernière mise à jour",
                "event": "Date de l'événement",
                "eventExplain": "Date de début et de fin de votre événement. Si les deux sont vides, l'itinéraire sera masqué. Si vous souhaitez afficher un itinéraire publié, saisissez au moins une date de fin.",
                "start": "De",
                "end": "A",
                "today": "Aujourd'hui",
                "continuous": "Continu",
                "clear": "Dates claires"
            },
            "title": "Cours",
            "subtitle": "Prochainement",
            "back": "Dos",
            "edit": "Editer",
            "save": "Sauvez",
            "cancel": "Annuler",
            "icon": "Icône de l'itinéraire",
            "iconImage": "icône",
            "isIcon": "Définir comme icône",
            "isSponsor": "Défini comme image du sponsor",
            "iconExplain": "Seules les images de 270x270 px sont autorisées",
            "namePlaceholder": "Entrez un nom de route ...",
            "descriptionPlaceholder": "Entrez une description de l'itinéraire ...",
            "publish": "Publier",
            "stop": "Stop",
            "published": "Publié sur",
            "notPublished": "Non publié",
            "name": "Nom de l'itinéraire dans l'application WandelAR",
            "type": "Type",
            "download": "Télécharger",
            "social": "Téléchargement social",
            "socialTag1": "Scanner le code QR",
            "socialTag2": "pour commencer l'itinéraire",
            "age": "Âge",
            "ageMin": "Minimum",
            "ageMinPlaceholder": "Entrez l'âge minimum",
            "ageMax": "Maximum",
            "ageMaxPlaceholder": "Entrez l'âge maximum",
            "url": "Lien vers les médias sociaux",
            "urlName": "Nom",
            "urlNamePlaceholder": "Entrez le nom du site Web",
            "urlUrl": "URL",
            "urlUrlPlaceholder": "Entrez l'adresse du site Web",
            "sponsor": "Sponsor",
            "sponsorName": "Name",
            "sponsorNamePlaceholder": "Entrez le nom du sponsor",
            "sponsorUrl": "Website",
            "sponsorUrlPlaceholder": "Entrez le site web du sponsor",
            "sponsorLogo": "Logo",
            "sponsorLogoExplain": "Seules les images de 270x270 px sont autorisées",
            "usersCurrentPlaceholder": "Les participants qui ont commencé le parcours ...",
            "usersTotalPlaceholder": "Nombre total de participants autorisés à prendre le départ ...",
            "pin": "Afficher l'épingle sur la carte",
            "wheelchair": "Afficher l'icône du fauteuil roulant sur la page d'information",
            "stroller": "Afficher l'icône de la poussette sur la page d'information",
            "dog": "Afficher l'icône du chien sur la page d'information",
            "free": "L'itinéraire est libre",
            "ads": "Afficher les publicités pendant le parcours",
            "skip": "Afficher le bouton sauter sur la page des points de contrôle",
            "time": "Enregistrement du temps de fonctionnement",
            "pinExplain": "Afficher l'emplacement de l'utilisateur sur la carte avec une épingle pulsée",
            "wheelchairExplain": "Afficher une icône de fauteuil roulant lorsque votre itinéraire est adapté aux fauteuils roulants",
            "strollerExplain": "Afficher une icône de poussette lorsque votre itinéraire est adapté aux poussettes",
            "dogExplain": "Afficher une icône d'os de chien lorsque votre itinéraire est adapté aux chiens",
            "freeExplain": "Votre itinéraire sera consultable. Les utilisateurs ne pourront continuer que si la fonction gratuite est activée ou s'ils scannent le code QR de l'itinéraire depuis le haut",
            "adsExplain": "Pendant une course, les utilisateurs verront des publicités toutes les 2 minutes et lorsque l'application change d'écran",
            "skipExplain": "Sur chaque page de point de contrôle, il y a un bouton sauter. Vous pouvez cacher ce bouton afin que les joueurs soient obligés de jouer le jeu avant de pouvoir continuer",
            "timeExplain": "Enregistrez votre temps total et le temps entre les points de contrôle. Désactiver si vous ne souhaitez pas effectuer un parcours dans les temps.",
            "address": {
                "start": "Infos sur l'adresse de départ",
                "custom": "Explication personnalisée",
                "street": "Rue",
                "number": "Numéro",
                "postal": "Code postal",
                "city": "Ville",
                "region": "Région",
                "country": "Pays",
                "explain": "Si une adresse n'est pas suffisamment descriptive, vous pouvez ajouter des informations supplémentaires qui décrivent le point de départ. Par exemple, 'Cherchez la porte d'entrée rouge'.",
                "placeholders": {
                    "start": "Entrez une adresse de départ",
                    "custom": "Entrez une description de l'adresse de départ",
                    "street": "Entrez un nom de rue",
                    "number": "Entrez un numéro de rue",
                    "postal": "Entrez un code postal",
                    "city": "Entrez une ville",
                    "region": "Entrez une région",
                    "country": "Entrez un pays"
                }
            },
            "price": "Prix",
            "distance": "Distance",
            "estimation": "Durée",
            "pricePlaceholder": "Entrez le prix de détail",
            "distancePlaceholder": "Entrez la distance de l'itinéraire",
            "estimationPlaceholderHour": "Entrez le nombre d'heures",
            "estimationPlaceholderMin": "Saisir le nombre de minutes",
            "checkpoints": {
                "title": "Points de contrôle",
                "startpoint": "Point de départ",
                "checkpoint": "Point de contrôle",
                "endpoint": "Point final",
                "explain": "Vous pouvez définir ci-dessous le point de départ, le point d'arrivée et les points de passage de l'itinéraire.",
                "none": "Pas de points de contrôle",
                "longitude": "Longitude",
                "latitude": "Latitude",
                "longitudePlaceholder": "Entrez la longitude",
                "latitudePlaceholder": "Entrez la latitude",
                "qr": "Code QR",
                "placeholders": {
                    "longitude": "Entrez la longitude",
                    "latitude": "Entrez la latitude"
                },
                "check": {
                    "title": "Erreur",
                    "msg": "Limite de points de contrôle atteinte. Vous pouvez ajouter un maximum de 25 points de contrôle",
                    "caption": "Points de contrôle"
                }
            },
            "tags": {
                "title": "Tags",
                "subtitle": "Ajouter une étiquette",
                "placeholders": {
                    "empty": {
                        "us_en": "Enter a tag",
                        "be_nl": "Voer een tag in",
                        "be_fr": "Saisir une étiquette"
                    },
                    "full": {
                        "us_en": "Type and press enter",
                        "be_nl": "Typ en druk op enter",
                        "be_fr": "Tapez et appuyez sur Entrée"
                    }
                },
                "error": {
                    "title": "Erreur",
                    "msg": "Remplir toutes les traductions des balises avant d'ajouter une balise",
                    "caption": "Tags"
                }
            },
            "extra": "Caractéristiques supplémentaires",
            "images": "Images de l'itinéraire",
            "imagesExplain": "Les meilleures images de 1000x730 px sinon nous recadrons votre image",
            "noImages": "Aucune image",
            "current": "Utilisateurs actuels",
            "currentExplain": "Nombre de participants ayant effectué le parcours à pied.",
            "total": "Maximum de participants",
            "totalExplain": "Nombre maximal de participants autorisés à parcourir votre itinéraire.",
            "infinite": "Pas de limite",
            "qrcode": "Itinéraire QR-code",
            "qrcodes": "Codes QR du point de contrôle",
            "qrcodeExplain": "Vous pouvez télécharger ce code QR et l'utiliser pour informer les participants de cet itinéraire par le biais, par exemple, d'un message sur les médias sociaux.",
            "qrcodesExplain": "Il s'agit des qrcodes pour le point de départ, les points de contrôle et le point final. Ils peuvent être imprimés sur papier et placés sur des panneaux d'affichage à chaque point de contrôle. Les QR-codes peuvent être utilisés comme solution de repli lorsque la réception GPS est insuffisante.",
            "remove": "Retirer",
            "removeRoute": "Supprimer la route",
            "yes": "Oui",
            "no": "Non",
            "confirmRemoveRoute": {
                "title": "Êtes-vous sûr de vouloir supprimer cette route?",
                "subtitle": "Cela supprimera toutes les données de l'itinéraire, y compris les images, le texte et les points de contrôle associés à cet itinéraire"
            },
            "saveRoute": {
                "minCheckpoint": {
                    "title": "Erreur",
                    "msg": "Ajouter un point de départ, un point de contrôle et un point final",
                    "caption": "Points de contrôle"
                },
                "invalid": {
                    "title": "Erreur",
                    "msg": "Supprimer tous les fichiers non pris en charge",
                    "caption": "Images"
                }
            },
            "dragUpDown": "Faire glisser les images vers le haut et le bas avec",
            "dragUpDownExplain": "Déplacez l'ordre d'affichage des images du carrousel sur la page d'informations sur l'itinéraire dans l'application. La première image est utilisée comme image d'icône."
        },
        "invite": {
            "title": "Bienvenue à WandelAR",
            "subtitle": "Appuyez sur le bouton ci-dessous pour charger cet itinéraire et commencer à marcher",
            "text1": "Téléchargez l'application",
            "text2": "Ouvrez l'application et appuyez sur",
            "text3": "pour scanner l'itinéraire",
            "caption": "Marcher. Jouez. Grandir.",
            "what1": "L'application WandelAR combine des jeux en réalité étendue et augmentée tout en marchant",
            "what2": "A chaque point de contrôle, vous pouvez jouer à un jeu!",
            "button": "Aller à l'application",
            "inviteExplain": "L'application WandelAR combine des jeux en réalité étendue et augmentée tout en marchant. A chaque point de contrôle, vous pouvez jouer à un jeu!",
            "notFound": "Application non trouvée",
            "start": "Commencer l'itinéraire",
            "tap": "Tape",
            "ctrl": "Ctrl",
            "rightClick": "Clic droit",
            "cmd": "Cmd",
            "plus": "+",
            "and": "et",
            "drag": "glisser",
            "toRotate": "pour faire pivoter",
            "or": "ou",
            "scan": "scanne",
            "qrcode": "le code QR",
            "preview": "pour prévisualiser",
            "game": "Jeu",
            "part": "Partie",
            "play": "Jouer",
            "time": "À temps"
        },
        "channel": {
            "title": "Itinéraires pédestres",
            "caption": "Marche. Jouez. Cultivez.",
            "explain": "Sur cette page, vous pouvez trouver tous les itinéraires de",
            "poweredBy": "Soutenu par",
            "noRating": "Pas encore d'évaluation",
            "routes": "Promenades",
            "hikers": "Nombre de promeneurs",
            "hikes": "Nombre de promenades",
            "rating": "Classement",
            "scan": "Scanner pour commencer",
            "height": "Hauteur",
            "or": "ou",
            "wheelchair": {
                "false": "Non accessible",
                "true": "Accessible"
            },
            "stroller": {
                "false": "Non accessible",
                "true": "Accessible"
            },
            "dog": {
                "false": "Pas possible avec un chien",
                "true": "Possible avec un chien"
            }
        },
        "qr": "Code QR",
        "gps": "GPS",
        "Pending": "En attente",
        "Active": "Actif",
        "Cancelled": "Annulé",
        "Done": "Terminé",
        "unsubscribe": {
            "title": "Vous êtes désinscrit",
            "subtitle": "Nous sommes désolés de vous voir partir !",
            "button": "Aller à la page d'accueil"
        },
        "privacy": {
            "privacyPolicy": "Politique de confidentialité",
            "userAgreementAndPrivacyPolicyTitle": "Accord de l'utilisateur et déclaration de politique de confidentialité",
            "userAgreementAndPrivacyPolicyText": "Veuillez lire attentivement notre politique de confidentialité pour bien comprendre comment nous collectons, utilisons, protégeons ou traitons autrement vos informations personnellement identifiables conformément à notre site web et à notre application.",
            "userAgreementTitle": "Accord de l'utilisateur",
            "userAgreementText1": "WandelAR (\"App\" ou \"Site\") fournit des services de localisation, tels que la cartographie des activités de plein air en utilisant la position géographique de l'appareil de l'utilisateur. Elle contient également un système de gestion de l'utilisateur pour lui permettre de contrôler les données de localisation enregistrées et stockées.",
            "userAgreementText2": "Les services sont fournis par WandelAR. Les présentes conditions s'appliquent entre WandelAR et l'utilisateur de l'application, ce qui inclut les applications mobiles et les sites web associés. L'utilisation de l'application suppose que l'utilisateur accepte et approuve pleinement les conditions qui suivent. WandelAR décline toute responsabilité pour tout dommage financier et matériel que l'Application pourrait causer à l'utilisateur.",
            "privacyPolicyTitle": "Politique de confidentialité",
            "privacyPolicyText1": "Le profil de l'utilisateur pour l'App est acquis à partir des informations que l'utilisateur saisit dans l'App. Seules les données personnelles nécessaires aux services fournis par l'application sont stockées, telles que le nom, l'adresse électronique, le sexe, l'année de naissance et le code postal. Il s'agit des informations nécessaires pour que, lorsque l'utilisateur choisit de télécharger des résultats, ces résultats puissent être saisis dans les catégories genre/âge appropriées.",
            "privacyPolicyText2": "Si l'utilisateur choisit de télécharger des données, le site web des résultats s'affichera :",
            "privacyPolicyBullet1": "1. Les détails du timing de leur course",
            "privacyPolicyBullet2": "2. Leur prénom et leur nom de famille",
            "privacyPolicyText3": "L'adresse e-mail fournie peut être utilisée pour envoyer un e-mail contenant les détails des événements auxquels ils ont participé et pour fournir des informations sur les événements à venir.",
            "privacyPolicyText4": "Ces informations peuvent être partagées avec les associations et clubs de course d'orientation affiliés, conformément à leur politique de confidentialité.",
            "privacyPolicyText5": "Pour mieux comprendre et servir les utilisateurs de l'application, nous pouvons mener des recherches sur les résultats et les comportements de nos utilisateurs sur la base des données personnelles et autres informations qui nous sont fournies. Ces recherches peuvent être compilées et analysées sur une base agrégée, et nous pouvons partager ces données agrégées avec nos sociétés affiliées, agents et partenaires commerciaux. Ces informations globales ne permettent pas d'identifier les utilisateurs personnellement. Nous pouvons également divulguer des statistiques agrégées sur les utilisateurs afin de décrire nos services à nos partenaires commerciaux actuels et potentiels, et à d'autres tiers à d'autres fins légales.",
            "privacyPolicyText6": "Les utilisateurs peuvent utiliser l'App sans envoyer de Données à caractère personnel depuis leur appareil, cependant, ils peuvent ne pas être en mesure d'utiliser certaines fonctionnalités des Services.",
            "privacyPolicyText7": "Si un mineur, âgé de moins de 18 ans, utilise l'App, nous supposons que c'est avec le consentement d'un tuteur légal.",
            "privacyPolicyText8": "Les services que nous proposons peuvent changer de temps à autre. Par conséquent, il peut parfois être nécessaire pour nous d'apporter des modifications à cette politique de confidentialité. Par conséquent, nous nous réservons le droit de mettre à jour ou de modifier cette politique de confidentialité à tout moment et de temps en temps sans préavis. Veuillez consulter cette politique périodiquement, et surtout avant de fournir des données personnelles. La présente politique de confidentialité a été mise à jour pour la dernière fois à la date indiquée ci-dessous. Si vous continuez à utiliser les services après toute modification ou révision de la présente politique de confidentialité, cela signifie que vous acceptez les conditions de cette politique de confidentialité révisée.",
            "privacyPolicyText9": "Si vous avez des doutes sur cette politique ou si vous souhaitez demander la suppression de vos informations du système, veuillez fournir des détails à notre adresse électronique.",
            "managingPersonalInformationTitle": "Gestion des informations personnelles",
            "managingPersonalInformationText1": "Une description détaillée de la manière dont WandelAR gère les informations personnelles est disponible ci-dessous.",
            "managingPersonalInformationText2": "Le système se compose d'applications pour Android (WandelAR) et iPhone (WandelAR), d'un frontal web et d'un service dorsal pour le stockage et le traitement des données. Digital Ocean, Amazon et MongoDB sont utilisés comme services en nuage pour la gestion des utilisateurs et le stockage des données.",
            "managingPersonalInformationText3": "La confidentialité de l'utilisateur est importante pour nous et nous avons mis en place un certain nombre de fonctions pour équilibrer le désir de partager les résultats des événements avec d'autres personnes et pour permettre le contrôle des données de localisation personnelles.",
            "managingPersonalInformationText4": "Les données personnelles suivantes sont traitées par le système WandelAR.",
            "userDataTitle": "Données de l'utilisateur",
            "userDataBullet1": "1. Chaque utilisateur de l'application doit saisir un nom qui sera utilisé pour la présentation dans les listes de résultats, etc. Le nom peut être le nom complet (prénom et nom de famille), un surnom ou un nom similaire.",
            "userDataBullet2": "2. L'adresse électronique et le mot de passe sont utilisés pour identifier un utilisateur enregistré.",
            "userDataBullet3": "3. Si un utilisateur participe à une course événementielle sans être un utilisateur enregistré, il sera ce qu'on appelle un utilisateur invité.",
            "userDataBullet4": "4. Les \"informations sur les cookies\" de l'utilisateur (fichiers de cookies de session et persistants).",
            "locationDataTitle": "Données de localisation",
            "locationDataBullet1": "1. La localisation de l'appareil de l'utilisateur est utilisée dans un service d'arrière-plan pendant une course événementielle. L'emplacement est utilisé pour déterminer si l'utilisateur visite des points de contrôle (ce qu'on appelle le poinçonnage) et l'emplacement est enregistré en continu pour pouvoir montrer ultérieurement quel chemin l'utilisateur a choisi de suivre pour trouver les différents points de contrôle.",
            "locationDataBullet2": "2. Pour les utilisateurs invités et les utilisateurs enregistrés, le résultat de l'événement sera stocké localement dans l'appareil mobile. Le résultat de l'événement contient des données de localisation et se compose des points de contrôle visités et du chemin emprunté entre les points de contrôle.",
            "locationDataBullet3": "3. Le résultat de l'événement est également stocké dans le stockage en nuage. Chaque utilisateur, qu'il s'agisse d'un utilisateur enregistré ou d'un utilisateur invité, dispose de son propre stockage privé pour les résultats des événements, auquel seul l'utilisateur (et WandelAR) peut accéder.",
            "locationDataBullet4": "4. Les utilisateurs enregistrés et les utilisateurs invités peuvent publier le résultat de leur événement pour le rendre accessible aux autres utilisateurs. L'utilisateur peut choisir de publier uniquement les points de contrôle visités ou le parcours complet. Les résultats d'un événement pour un cours sont protégés par un identifiant de cours unique. Les autres utilisateurs peuvent accéder aux données publiées s'ils connaissent l'identifiant du cours.",
            "locationDataText1": "Notre App utilise le SDK MapBox pour afficher des cartes dans l'App. En utilisant le Site et l'App, vous acceptez les politiques de MapBox. Vous pouvez trouver la politique sur ce site",
            "locationDataText2": "lien.",
            "cookies": "Cookies",
            "cookie1": "Cookie #1",
            "cookieDescription11": "Ces cookies sont utilisés pour recueillir des informations sur le trafic et la façon dont les utilisateurs naviguent sur notre site. Ces informations sont agrégées et donc totalement anonymes, et nous n'avons pas accès aux informations concernant les utilisateurs individuels. Les données recueillies comprennent la façon dont les utilisateurs arrivent sur notre Site, comment ils naviguent entre les pages et quel appareil ils utilisent. Nous utilisons ces informations afin d'exploiter plus efficacement notre site, de surveiller le niveau d'activité sur notre Site et de vous fournir les articles pertinents qui pourraient vous intéresser. Vous pouvez obtenir plus d'informations sur les cookies de Google Analytics",
            "cookieDescription12": "ici.",
            "cookieDescription13": "Vous pouvez empêcher l'utilisation de Google Analytics à l'aide de ce formulaire.",
            "cookieDescription14": "lien.",
            "cookieDuration1": "2 ans",
            "cookieType1": "Analytique",
            "cookieParty1": "3ème",
            "cookieLink1": "Aller sur le site",
            "cookie2": "Cookie #2",
            "cookieDescription21": "Utilisé pour distinguer les utilisateurs sur le site afin de générer des informations statistiques sur leur utilisation du site. Vous pouvez empêcher l'utilisation de Google Analytics à l'adresse suivante",
            "cookieDescription22": "lien.",
            "cookieDuration2": "1 jour",
            "cookieType2": "Analytique",
            "cookieParty2": "3ème",
            "cookieLink2": "Aller sur le site",
            "cookie3": "Cookie #3",
            "cookieDescription31": "Utilisé pour limiter le taux de demande. Vous pouvez empêcher l'utilisation de Google Analytics par le biais de cette page.",
            "cookieDescription32": "lien.",
            "cookieDuration3": "1 minute",
            "cookieType3": "Analytique",
            "cookieParty3": "3ème",
            "cookieLink3": "Aller sur le site",
            "cookie4": "Cookie #4",
            "cookieDescription4": "Utilisé pour stocker le jeton d'autorisation",
            "cookieDuration4": "Session",
            "cookieType4": "Technique",
            "cookieParty4": "1er",
            "cookieLink4": "Lire cette politique de confidentialité",
            "cookie5": "Cookie #5",
            "cookieDescription51": "Utilisé pour limiter le nombre de requêtes reçues par les utilisateurs. Vous pouvez empêcher l'utilisation de Google Analytics via ce lien",
            "cookieDescription52": "lien.",
            "cookieDuration5": "1 minute",
            "cookieType5": "Analytique",
            "cookieParty5": "3ème",
            "cookieLink5": "Aller sur le site",
            "cookie6": "Cookie #6",
            "cookieDescription6": "Utilisé pour stocker le statut du consentement de l'utilisateur",
            "cookieDuration6": "6 mois",
            "cookieType6": "Technique",
            "cookieParty6": "1er",
            "cookieLink6": "Lire cette politique de confidentialité",
            "cookie7": "Cookie #7",
            "cookieDescription7": "Ce cookie est utilisé pour identifier la session de l'utilisateur. Les données recueillies sont traitées uniquement par WandelAR à des fins d'analyse et de rapport. WandelAR utilise ces informations pour évaluer l'efficacité et l'exactitude des produits WandelAR.",
            "cookieDuration7": "30 minutes",
            "cookieType7": "Essentiel",
            "cookieParty7": "1er",
            "cookieLink7": "Lire cette politique de confidentialité",
            "cookie8": "Cookie #8",
            "cookieDescription8": "Ces cookies contiennent un identifiant d'utilisateur unique défini en fonction de l'acuité et de l'exactitude des produits WandelAR.",
            "cookieDuration8": "2 ans",
            "cookieType8": "Essentiel",
            "cookieParty8": "1er",
            "cookieLink8": "Lire cette politique de confidentialité",
            "cookieName": "Nom",
            "cookieDescription": "Description",
            "cookieDuration": "Duration",
            "cookieType": "Type",
            "cookieParty": "1ère/3ème partie",
            "cookieLink": "Lien vers le site web d'un tiers",
            "cookiesText1": "Un cookie est une chaîne d'informations qu'un site web stocke sur l'ordinateur d'un visiteur et que le navigateur du visiteur fournit au site web chaque fois que le visiteur revient. Nous utilisons les cookies pour nous aider à identifier les visiteurs, leur utilisation de l'application, leurs préférences d'accès au site web, pour améliorer leur expérience, pour comprendre comment nos services sont utilisés, pour mémoriser vos choix, tels que vos préférences linguistiques, et pour personnaliser nos services pour vous. Les visiteurs de l'application qui ne souhaitent pas que des cookies soient placés sur leur ordinateur (généralement sous \"Paramètres\" ou \"Préférences\") doivent configurer leur navigateur de manière à refuser les cookies avant d'utiliser notre application, avec l'inconvénient que certaines fonctionnalités de notre application peuvent ne pas fonctionner correctement sans l'aide des cookies. Veuillez noter que des tiers (y compris, par exemple, les réseaux publicitaires et les fournisseurs de services externes tels que les services d'analyse du trafic web) peuvent également utiliser des cookies.",
            "cookiesText2": "En particulier, notre App utilise les catégories de cookies suivantes, dans notre intérêt légitime, comme décrit ci-dessous :",
            "cookiesText3": "Votre utilisation de notre application indique que vous consentez à cette utilisation des cookies. Vous pouvez désactiver les cookies dans votre navigateur Web, mais cela aura un impact sur la convivialité du site Web.",
            "cookiesText4": "Vous pouvez également nous contacter à l'adresse hello@wandelar.be et notre équipe vous aidera à répondre à vos questions sur les cookies.",
            "ads": "Annonces",
            "adsText1": "Les publicités apparaissant sur l'une de nos applications peuvent être transmises aux utilisateurs par des partenaires publicitaires, qui peuvent installer des cookies. Ces cookies permettent au serveur publicitaire de reconnaître votre ordinateur chaque fois qu'il vous envoie une publicité en ligne afin de compiler des informations sur vous ou sur d'autres personnes qui utilisent votre ordinateur. Ces informations permettent aux réseaux publicitaires, entre autres, de diffuser des publicités ciblées qui, selon eux, vous intéresseront le plus. Cette politique de confidentialité couvre l'utilisation des cookies par nous et ne couvre pas l'utilisation des cookies par les annonceurs. Vous pouvez refuser de voir les publicités en ligne basées sur les intérêts de wandelar.be ou des partenaires publicitaires en nous contactant à l'adresse hello@wandelar.be ou via les paramètres de votre gadget. Veuillez noter que les bloqueurs de publicité et les outils qui limitent notre utilisation des cookies peuvent interférer avec ces contrôles.",
            "adsText2": "Notre application utilise un réseau publicitaire tiers pour afficher des publicités dans l'application. En utilisant le Site et l'Application, vous acceptez la politique du réseau publicitaire Unity. Vous pouvez trouver la politique sur ce site",
            "adsText3": "lien.",
            "deletionOfDataTitle": "Suppression des données",
            "deletionOfDataText": "Les utilisateurs enregistrés et invités peuvent effectuer les opérations suivantes :",
            "deletionOfDataBullet1": "1. En tant qu'utilisateur, vous pouvez révoquer votre résultat d'événement publié quand vous le souhaitez. Après la révocation d'un résultat d'événement, les données de localisation ne seront plus accessibles aux autres utilisateurs.",
            "deletionOfDataBullet2": "2. En tant qu'utilisateur, vous pouvez également supprimer définitivement le résultat de votre événement, à la fois sur l'appareil mobile et dans la partie privée du stockage en nuage.",
            "deletionOfDataBullet3": "3. En tant qu'utilisateur, vous pouvez à tout moment supprimer votre compte, ce qui aura pour effet de supprimer définitivement toutes vos données (données utilisateur et données de localisation), tant localement sur l'appareil (smartphone) que dans le stockage en nuage.",
            "supportTitle": "Soutien",
            "supportText": "L'assistance est fournie par courrier électronique. Contactez-nous à l'adresse hello@wandelar.be. Veuillez inclure une description précise de la façon dont le problème se répète, des messages d'erreur affichés et d'autres informations qui peuvent nous être utiles. Nous serons ainsi en mesure de fournir un retour d'information rapide.",
            "contactDetailsTitle": "Coordonnées de contact",
            "contactDetailsText1": "Site web du développeur : https://www.wandelar.be/",
            "contactDetailsText2": "Courriel : hello@wandelar.be",
            "dateLastEdited": "Dernière modification le 9 juin 2022"
        },
        "terms": {
            "termsAndConditionsStatement": "Déclaration des termes et conditions",
            "termsAndConditionsStatementText1": "En utilisant WandelAR pour un événement, les utilisateurs reconnaissent et acceptent la déclaration suivante sur les conditions générales de l'événement.",
            "termsAndConditionsStatementText2": "Les présentes conditions générales, ainsi que tous les autres documents auxquels elles font référence, définissent les conditions d'utilisation dans lesquelles vous pouvez utiliser ce site web, www.wandelar.be et l'application (\"notre application\"). Veuillez lire attentivement les présentes conditions générales et vous assurer que vous les comprenez. Votre accord pour vous conformer à ces conditions et être lié par elles est réputé intervenir lors de votre première utilisation de notre application. Si vous n'acceptez pas de vous conformer à ces conditions et d'être lié par elles, vous devez cesser immédiatement d'utiliser notre application.",
            "riskWaiver": "Renonciation aux risques",
            "riskWaiverText1": "Nous avons lu tout avertissement général sur les risques et l'avertissement spécifique sur les risques fourni pour cet événement. Nous sommes conscients que la course d'orientation est une activité récréative qui peut être dangereuse, acceptons qu'il existe un certain degré de risque et reconnaissons que nous participons à cet événement à nos propres risques.",
            "riskWaiverText2": "Nous sommes conscients et nous nous conformerons aux règles des compétiteurs spécifiées par la Fédération Internationale de Course d'Orientation et l'Organisation de Course d'Orientation du pays/de la région de l'événement.",
            "riskWaiverText3": "Nous dégageons les organisations de course d'orientation, les clubs affiliés, les membres et toutes les autres parties associées à l'organisation de l'événement de toute responsabilité ou obligation légale liée à notre présence et à notre participation à cet événement de course d'orientation.",
            "riskWaiverText4": "Dans la mesure où la loi sur la concurrence et la consommation de 2010 (ou une loi similaire) s'applique, cette décharge est limitée à la responsabilité et à la responsabilité légale en cas de décès ou de blessures corporelles et ne s'applique pas aux blessures corporelles importantes causées par une conduite imprudente des clubs affiliés aux organisations de course d'orientation, des membres et de toutes les autres parties associées à l'organisation de l'événement.",
            "generalRiskWarning": "Avertissement général sur les risques",
            "generalRiskWarningText1": "La participation à une course d'orientation comporte des risques. Ceux-ci incluent la mort, des blessures graves ou des maladies dues à :",
            "generalRiskWarningBullet1": "1. terrain accidenté et obstacles ;",
            "generalRiskWarningBullet2": "2. le surmenage ;",
            "generalRiskWarningBullet3": "3. la chaleur, le froid ou d'autres conditions météorologiques défavorables ;",
            "generalRiskWarningBullet4": "4. la vie végétale et animale ; et",
            "generalRiskWarningBullet5": "5. les accidents avec des véhicules, d'autres concurrents ou des piétons.",
            "generalRiskWarningText2": "Il y a aussi des risques :",
            "generalRiskWarningBullet6": "1. que l'accès aux services médicaux, d'évacuation ou de recherche pourrait être lent ; et",
            "generalRiskWarningBullet7": "2. des dommages ou de la perte de vos biens personnels.",
            "statements": "Déclarations",
            "statementsBullet1": "1. Nous sommes conscients qu'aucune assurance maladie, accident, ambulance, invalidité ou vie n'est fournie.",
            "statementsBullet2": "2. Nous autorisons la libre utilisation de nos noms et photos dans les informations sur les inscriptions, les résultats, les nouvelles, le marketing et le matériel promotionnel relatifs à l'événement ou à la course d'orientation. Ceci ne s'étend pas à la publication d'une photo d'une personne de moins de 18 ans avec son nom.",
            "statementsBullet3": "3. Nous nous assurerons que tous les enfants sous notre contrôle lors de l'événement sont surveillés de manière appropriée à tout moment. Nous reconnaissons que les parents et les tuteurs sont responsables de décider si leurs enfants sont capables de faire une course d'orientation par eux-mêmes lors de chaque événement.",
            "definitionsAndInterpretation": "Définitions et interprétation",
            "definitionsAndInterpretationText": "Dans les présentes conditions générales, sauf si le contexte l'exige autrement, les expressions suivantes ont la signification suivante :",
            "definitionsAndInterpretationBullet1": "1. Le \"contenu\" désigne tout texte, image, audio, vidéo, script, code, logiciel, base de données et toute autre forme d'information susceptible d'être stockée sur un ordinateur qui apparaît sur notre application ou en fait partie.",
            "definitionsAndInterpretationBullet2": "2. Nous/Notre/Notre\" signifie WandelAR.",
            "informationAboutUs": "Informations sur nous",
            "informationAboutUsText": "Notre application est détenue et exploitée par WandelAR.",
            "accessToOurApp": "Accès à notre application",
            "accessToOurAppBullet1": "1. L'accès à notre application est gratuit.",
            "accessToOurAppBullet2": "2. Il est de votre responsabilité de prendre toutes les dispositions nécessaires pour accéder à notre application.",
            "accessToOurAppBullet3": "3. L'accès à Notre App est fourni \"tel quel\" et sur une base \"telle que disponible\". Nous pouvons modifier, suspendre ou interrompre Notre App (ou toute partie de celle-ci) à tout moment et sans préavis. Nous ne serons pas responsables envers vous de quelque manière que ce soit si notre application (ou toute partie de celle-ci) est indisponible à tout moment et pour toute période.",
            "intellectualPropertyRights": "Droits de propriété intellectuelle",
            "intellectualPropertyRightsBullet1": "1. Tout le Contenu inclus dans Notre App ainsi que les droits d'auteur et autres droits de propriété intellectuelle subsistant dans ce Contenu, sauf indication contraire spécifique, appartiennent à Nous ou ont fait l'objet d'une licence. Tout le Contenu est protégé par les lois et traités belges et internationaux applicables en matière de propriété intellectuelle.",
            "intellectualPropertyRightsBullet2": "2. Vous ne pouvez pas reproduire, copier, distribuer, vendre, louer, sous-licencier, stocker ou réutiliser de quelque manière que ce soit le contenu de notre application, sauf si nous vous en donnons l'autorisation écrite expresse.",
            "intellectualPropertyRightsBullet21": "2.1. vous pouvez :",
            "intellectualPropertyRightsBullet211": "2.1.1. Accéder, visualiser et utiliser Notre Application dans un navigateur web (y compris toute capacité de navigation web intégrée dans d'autres types de logiciels ou d'applications) ;",
            "intellectualPropertyRightsBullet212": "2.1.2. Télécharger notre application (ou toute partie de celle-ci) pour la mise en cache ;",
            "intellectualPropertyRightsBullet213": "2.1.3. Imprimez les pages de notre application ;",
            "intellectualPropertyRightsBullet214": "2.1.4. Télécharger des extraits de pages sur Notre App ; et",
            "intellectualPropertyRightsBullet215": "2.1.5. Sauvegarder les pages de notre application pour une consultation ultérieure et/ou hors ligne.",
            "intellectualPropertyRightsBullet22": "2.2. Notre statut de propriétaire et d'auteur du Contenu de Notre App (ou celui des concédants de licence identifiés, le cas échéant) doit toujours être reconnu.",
            "intellectualPropertyRightsBullet23": "2.3. Vous ne pouvez pas utiliser tout Contenu sauvegardé ou téléchargé à partir de Notre App à des fins commerciales sans avoir obtenu au préalable une licence de Notre part (ou de nos concédants de licence, selon le cas) pour le faire. Cela n'interdit pas l'accès, la visualisation et l'utilisation normaux de notre application à des fins d'information générale, que ce soit par des utilisateurs professionnels ou des consommateurs.",
            "intellectualPropertyRightsBullet24": "2.4. Rien dans les présentes conditions générales ne limite la couverture, en particulier, de la réalisation de copies temporaires, de la recherche et de l'étude privée, de la réalisation de copies pour l'analyse de textes et de données dans le cadre de recherches non commerciales, de la critique, de la revue, de la citation et du reportage, de la caricature, de la parodie ou du pastiche, et de l'inclusion accessoire de matériel protégé par le droit d'auteur.",
            "intellectualPropertyRightsBullet3": "3. Liens vers notre application",
            "intellectualPropertyRightsBullet31": "3.1. Vous pouvez créer un lien vers Notre App à condition que :",
            "intellectualPropertyRightsBullet311": "3.1.1. Vous le faites de manière équitable et légale ;",
            "intellectualPropertyRightsBullet312": "3.1.2. Vous ne le faites pas d'une manière qui suggère une quelconque forme d'association, d'approbation ou de soutien de notre part alors qu'il n'en existe aucune ;",
            "intellectualPropertyRightsBullet313": "3.1.3. Vous n'utilisez pas les logos ou les marques commerciales affichés sur Notre App sans Notre autorisation écrite expresse ; et",
            "intellectualPropertyRightsBullet314": "3.1.4. Vous ne le faites pas d'une manière qui vise à porter atteinte à Notre réputation ou à en tirer un avantage injuste.",
            "intellectualPropertyRightsBullet32": "3.2. Vous pouvez créer un lien vers n'importe quelle page de notre application.",
            "intellectualPropertyRightsBullet33": "3.3. Le cadrage ou l'intégration de Notre App sur d'autres sites web n'est pas autorisé sans Notre autorisation écrite expresse. Veuillez nous contacter à l'adresse hello@wandelar.be pour de plus amples informations.",
            "intellectualPropertyRightsBullet34": "3.4. Vous ne pouvez pas établir de lien vers Notre App à partir d'un autre site dont le contenu principal contient des éléments qui :",
            "intellectualPropertyRightsBullet341": "3.4.1. est sexuellement explicite ;",
            "intellectualPropertyRightsBullet342": "3.4.2. est obscène, délibérément offensant, haineux ou autrement incendiaire ;",
            "intellectualPropertyRightsBullet343": "3.4.3. encourage la violence ;",
            "intellectualPropertyRightsBullet344": "3.4.4. promouvoir ou aider toute forme d'activité illégale ;",
            "intellectualPropertyRightsBullet345": "3.4.5. est discriminatoire ou diffamatoire à l'égard d'une personne, d'un groupe ou d'une classe de personnes, de la race, du sexe, de la religion, de la nationalité, d'un handicap, de l'orientation sexuelle ou de l'âge ;",
            "intellectualPropertyRightsBullet346": "3.4.6. est destiné ou est susceptible de menacer, harceler, ennuyer, alarmer, déranger, contrarier ou embarrasser une autre personne ;",
            "intellectualPropertyRightsBullet347": "3.4.7. est calculé ou est susceptible de tromper une autre personne ;",
            "intellectualPropertyRightsBullet348": "3.4.8. est destiné ou autrement susceptible de porter atteinte (ou de menacer de porter atteinte) au droit à la vie privée d'une autre personne ;",
            "intellectualPropertyRightsBullet349": "3.4.9. se fait passer pour une autre personne de manière trompeuse ou représente de manière erronée l'identité ou l'affiliation d'une personne particulière d'une manière qui est calculée pour tromper ;",
            "intellectualPropertyRightsBullet3410": "3.4.10.implique toute forme d'affiliation avec Nous lorsqu'elle n'existe pas ;",
            "intellectualPropertyRightsBullet3411": "3.4.11. enfreint, ou aide à enfreindre, les droits de propriété intellectuelle (y compris, mais sans s'y limiter, les droits d'auteur, les marques et les droits relatifs aux bases de données) de toute autre partie ; ou",
            "intellectualPropertyRightsBullet3412": "3.4.12. est faite en violation de toute obligation légale envers un tiers, y compris, mais sans s'y limiter, les obligations contractuelles et les obligations de confidentialité.",
            "intellectualPropertyRightsBullet35": "3.5. Il ne vous est pas interdit, par exemple, de publier des liens sur des sites de réseautage social à vocation générale simplement parce qu'un autre utilisateur peut publier un tel contenu. Il vous est toutefois interdit de publier des liens sur des sites Web qui se concentrent sur la soumission de ce type de contenu par les utilisateurs ou qui l'encouragent.",
            "intellectualPropertyRightsBullet4": "4. Liens vers d'autres sites",
            "intellectualPropertyRightsBullet4Text": "Des liens vers d'autres sites peuvent être inclus dans notre application. Sauf mention expresse, ces sites ne sont pas sous Notre contrôle. Nous n'assumons ni n'acceptons aucune responsabilité quant au contenu des sites tiers. L'inclusion d'un lien vers un autre site sur Notre App est uniquement à titre d'information et n'implique aucune approbation des sites eux-mêmes ou de ceux qui les contrôlent.",
            "intellectualPropertyRightsBullet5": "5. Avis de non-responsabilité",
            "intellectualPropertyRightsBullet51": "5.1. Aucun élément de notre application ne constitue un conseil sur lequel vous devriez vous appuyer. Il est fourni à des fins d'information générale uniquement.",
            "intellectualPropertyRightsBullet52": "5.2. Dans la mesure où la loi le permet, Nous ne faisons aucune déclaration, garantie ou assurance que Notre App répondra à vos besoins, qu'elle ne violera pas les droits de tiers, qu'elle sera compatible avec tous les logiciels et matériels, ou qu'elle sera sécurisée.",
            "intellectualPropertyRightsBullet53": "5.3. Nous faisons des efforts raisonnables pour nous assurer que le Contenu de Notre App est complet, exact et à jour. Nous ne faisons cependant aucune déclaration, garantie ou assurance (expresse ou implicite) que le Contenu est complet, exact ou à jour.",
            "intellectualPropertyRightsBullet6": "6. Notre responsabilité",
            "intellectualPropertyRightsBullet61": "1. Dans toute la mesure permise par la loi, Nous n'acceptons aucune responsabilité envers tout utilisateur pour toute perte ou tout dommage, qu'ils soient prévisibles ou non, dans le cadre d'un contrat, d'un délit (y compris la négligence), d'une violation d'une obligation légale ou autre, découlant de ou en relation avec l'utilisation (ou l'incapacité d'utiliser) de notre application ou l'utilisation ou la confiance accordée à tout contenu inclus dans notre application.",
            "intellectualPropertyRightsBullet62": "2. Dans toute la mesure permise par la loi, nous excluons toute représentation, garantie et caution (qu'elle soit expresse ou implicite) qui pourrait s'appliquer à notre application ou à tout contenu inclus dans notre application.",
            "intellectualPropertyRightsBullet63": "3. Si vous êtes un utilisateur professionnel, Nous n'acceptons aucune responsabilité pour la perte de profits, de ventes, d'activités ou de revenus ; la perte d'opportunités commerciales, de clientèle ou de réputation ; la perte d'économies anticipées ; l'interruption des activités ; ou pour toute perte ou dommage indirect ou consécutif.",
            "intellectualPropertyRightsBullet64": "4. Nous exerçons toutes les compétences et tous les soins raisonnables pour nous assurer que notre application est exempte de virus et d'autres logiciels malveillants. Nous n'acceptons aucune responsabilité pour toute perte ou tout dommage résultant d'un virus ou d'un autre logiciel malveillant, d'une attaque par déni de service distribué, ou de tout autre matériel ou événement nuisible qui peut affecter négativement votre matériel, vos logiciels, vos données ou tout autre matériel qui se produit à la suite de votre utilisation de notre application (y compris le téléchargement de tout contenu à partir de celle-ci) ou de tout autre site mentionné sur notre application.",
            "intellectualPropertyRightsBullet65": "5. Nous n'assumons ni n'acceptons la responsabilité de toute perturbation ou non-disponibilité de notre application résultant de causes externes, y compris, mais sans s'y limiter, une défaillance de l'équipement du fournisseur d'accès, une défaillance de l'équipement de l'hôte, une défaillance du réseau de communication, des événements naturels, des actes de guerre ou des restrictions légales et de la censure.",
            "intellectualPropertyRightsBullet66": "6. Rien dans les présentes conditions générales n'exclut ou ne restreint notre responsabilité en cas de fraude ou de fausse déclaration frauduleuse, en cas de décès ou de préjudice corporel résultant d'une négligence, ou pour toute autre forme de responsabilité qui ne peut être exclue ou restreinte par la loi. Pour connaître tous les détails des droits légaux des consommateurs, y compris ceux relatifs au contenu numérique.",
            "virusesMalwareAndSecurity": "Virus, logiciels malveillants et sécurité",
            "virusesMalwareAndSecurityBullet1": "1. Nous exerçons toutes les compétences et tous les soins raisonnables pour garantir que notre application est sécurisée et exempte de virus et autres logiciels malveillants.",
            "virusesMalwareAndSecurityBullet2": "2. Vous êtes responsable de la protection de votre matériel, de vos logiciels, de vos données et de tout autre matériel contre les virus, les logiciels malveillants et autres risques liés à la sécurité sur Internet.",
            "virusesMalwareAndSecurityBullet3": "3. Vous ne devez pas introduire délibérément des virus ou d'autres logiciels malveillants, ou tout autre matériel qui est malveillant ou technologiquement nuisible à ou via notre application.",
            "virusesMalwareAndSecurityBullet4": "4. Vous ne devez pas tenter d'obtenir un accès non autorisé à une partie de Notre App, au serveur sur lequel Notre App est stockée, ou à tout autre serveur, ordinateur ou base de données connecté à Notre App.",
            "virusesMalwareAndSecurityBullet5": "5. Vous ne devez pas attaquer Notre App par le biais d'une attaque par déni de service, d'une attaque par déni de service distribué, ou par tout autre moyen.",
            "virusesMalwareAndSecurityBullet6": "6. Toute violation numérique sera signalée aux autorités compétentes chargées de l'application de la loi et Nous coopérerons pleinement avec ces autorités en leur divulguant votre identité. Votre droit d'utiliser notre application cessera immédiatement dans le cas d'une telle violation.",
            "acceptableUsagePolicy": "Politique d'utilisation acceptable",
            "acceptableUsagePolicyBullet1": "1. Vous ne pouvez utiliser notre application que d'une manière légale. Plus précisément :",
            "acceptableUsagePolicyBullet11": "1.1. vous devez vous assurer que vous vous conformez pleinement à toutes les lois et/ou réglementations locales, nationales ou internationales ;",
            "acceptableUsagePolicyBullet12": "1.2. vous ne devez pas utiliser notre application de quelque manière que ce soit, ou à quelque fin que ce soit, qui soit illégale ou frauduleuse ;",
            "acceptableUsagePolicyBullet13": "1.3. vous ne devez pas utiliser notre application pour envoyer, télécharger ou transmettre de toute autre manière, en connaissance de cause, des données contenant toute forme de virus ou autre logiciel malveillant, ou tout autre code conçu pour affecter négativement le matériel informatique, les logiciels ou les données de toute nature ; et",
            "acceptableUsagePolicyBullet14": "1.4. vous ne devez pas utiliser Notre App de quelque manière que ce soit, ou à quelque fin que ce soit, qui soit destinée à nuire à une ou plusieurs personnes de quelque manière que ce soit.",
            "acceptableUsagePolicyBullet15": "1.5. Nous nous réservons le droit de suspendre ou de résilier votre accès à Notre App si vous enfreignez matériellement l'une des autres dispositions des présentes Conditions et",
            "acceptableUsagePolicyBullet15Text": "Conditions. Plus précisément, Nous pouvons prendre une ou plusieurs des mesures suivantes :",
            "acceptableUsagePolicyBullet151": "1.5.1. suspendre, de manière temporaire ou permanente, votre droit d'accès à Notre App ;",
            "acceptableUsagePolicyBullet152": "1.5.2. vous adresser un avertissement écrit ;",
            "acceptableUsagePolicyBullet153": "1.5.3. d'intenter une action en justice contre vous afin d'obtenir le remboursement de tous les frais pertinents sur une base indemnitaire résultant de votre violation ;",
            "acceptableUsagePolicyBullet154": "1.5.4. prendre d'autres mesures juridiques à votre encontre, le cas échéant ;",
            "acceptableUsagePolicyBullet155": "1.5.5. divulguer ces informations aux autorités chargées de l'application de la loi, comme requis ou comme Nous le jugeons raisonnablement nécessaire ; et/ou",
            "acceptableUsagePolicyBullet156": "1.5.6. toute autre action que Nous jugeons raisonnablement appropriée (et légale).",
            "acceptableUsagePolicyBullet2": "2. Nous excluons par la présente toute responsabilité découlant de toute action (y compris, mais sans s'y limiter, celles énoncées ci-dessus) que nous pourrions prendre en réponse à des violations des présentes conditions générales.",
            "changesToTheseTermsAndConditions": "Modifications des présentes conditions générales",
            "changesToTheseTermsAndConditionsText": "Nous pouvons modifier les présentes conditions générales à tout moment. Ces modifications vous lieront dès votre première utilisation de notre application après la mise en œuvre des modifications. Il vous est donc conseillé de consulter cette page de temps en temps. En cas de conflit entre la version actuelle des présentes Conditions générales et toute(s) version(s) antérieure(s), les dispositions actuelles et en vigueur prévaudront, sauf mention contraire expresse.",
            "contactingUs": "Nous contacter",
            "contactingUsText": "Pour nous contacter, veuillez nous envoyer un courriel à l'adresse hello@wandelar.be ou en utilisant l'une des méthodes fournies sur notre page de contact à l'adresse suivante",
            "communicationsFromUs": "Communications de notre part",
            "communicationsFromUsBullet1": "1. Si Nous disposons de vos coordonnées, Nous pouvons de temps à autre vous envoyer des avis importants par e-mail. Ces avis peuvent porter sur des questions incluant, mais sans s'y limiter, des changements de service et des modifications des présentes Conditions générales.",
            "communicationsFromUsBullet2": "2. Nous ne vous enverrons jamais de courriels de marketing de quelque nature que ce soit sans votre consentement explicite. Si vous donnez ce consentement, vous pouvez vous désinscrire à tout moment. Tous les courriels de marketing envoyés par Nous comprennent un lien de désinscription. Si vous décidez de ne plus recevoir d'e-mails de notre part à tout moment, il se peut que nous devions attendre jusqu'à 14 jours ouvrables pour donner suite à votre demande. Pendant cette période, vous pouvez continuer à recevoir des courriels de notre part.",
            "communicationsFromUsBullet3": "3. Pour toute question ou plainte concernant nos communications (y compris, mais sans s'y limiter, les courriers électroniques de marketing), veuillez nous contacter à l'adresse hello@wandelar.be ou via ce formulaire.",
            "dataProtection": "Protection des données",
            "dataProtectionBullet1": "1. Toutes les informations personnelles que Nous sommes susceptibles d'utiliser seront collectées, traitées et conservées conformément aux dispositions du Règlement UE 2016/679 Règlement général sur la protection des données (\" RGPD \") et à vos droits en vertu du RGPD.",
            "dataProtectionBullet2": "2. Pour des détails complets sur Notre collecte, traitement, stockage et conservation des données personnelles, y compris, mais sans s'y limiter, la ou les finalités pour lesquelles les données personnelles sont utilisées, la ou les bases juridiques de leur utilisation, les détails de vos droits et la manière de les exercer, et le partage des données personnelles (le cas échéant), veuillez vous référer à la Politique de confidentialité.",
            "lawAndJurisdiction": "Droit et juridiction",
            "lawAndJurisdictionBullet1": "1. Les présentes conditions générales et la relation entre vous et Nous (qu'elle soit contractuelle ou autre) sont régies et interprétées conformément à la loi belge.",
            "lawAndJurisdictionBullet2": "2. Si vous êtes un consommateur, tout litige, controverse, procédure ou réclamation entre vous et nous concernant les présentes conditions générales ou la relation entre vous et nous (qu'elle soit contractuelle ou autre) sera soumis à la compétence des tribunaux de Belgique, Malines.",
            "lawAndJurisdictionBullet3": "3. Si vous êtes une entreprise, tout litige concernant les présentes Conditions générales, la relation entre vous et Nous, ou toute question en découlant ou y étant associée (qu'elle soit contractuelle ou autre) sera soumis à la compétence exclusive des tribunaux de Belgique, Malines."
        }
    },
    de_de: {
        loading: {
            title: ""
        },
        tagline: "",
        us_en: "English",
        be_nl: "Nederlands",
        be_fr: "Français",
        de_de: "Deutsch",
        menu: {
            menu: "",
            pricing: "",
            contact: "",
            login: "",
            register: ""
        },
        homepage: {
            title: "",
            subtitle: ""
        },
        pricing: {
            title: "",
            subtitle: "",
            send: "",
            bigtitle: "",
            chooseTheme: "",
            theme: "",
            amountParticipantsAsk: "",
            pricePerParticipant: "",
            placeholderAmount: "",
            extraProducts: "",
            sponsor: "",
            withOwnLogo: "",
            amountParticipants: "",
            participants: "",
            summary: "",
            total: "",
            vatExcluded: "",
            sendAsk: "",
            email: "",
            emailPlaceholder: "",
            send: "",
            sendCaption: ""
        },
        socialbanner: {
            tagline: ""
        },
        footer: {
            tagline: "",
            pricing: "",
            contact: "",
            copyright: "",
            privacy: "",
            terms: ""
        },
        monoLight: "",
        monoDark: "",
        apps: {
            noTheme: {
				name: "",
				description: ""
			},
            shootThatBall: {
				name: "",
				description: ""
			}
        },
        toast: {
            ok: ""
        },
        errors: {
            participantsCount: {
                title: "",
                msg: "",
                caption: ""
            },
            participantsExceeds: {
                title: "",
                msg: "",
                caption: ""
            },
            onlyNumbers: {
                title: "",
                msg: "",
                caption: ""
            },
            email: {
                title: "",
                msg: "",
                caption: ""
            }
        },
        success: {
            email: {
                title: "",
                msg: "",
                caption: ""
            }
        }
    }
}