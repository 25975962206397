import { useContext } from 'react';

import { NavLink as Link } from "react-router-dom";

import { LoginIcon, RegisterIcon, DashboardIcon } from "./Icons";
import Select from './Select';

import { UserContext } from '../Hooks/UserContext';

function NavbarDesktop(props) {

    const { user } = useContext(UserContext);

    return (
        <>
            <nav className="onboard">
                <div className="dropdown">
                    <div className="dropbtn">
                        <label>{ props.translations[props.lang].menu.about }</label>
                    </div>
                    <div className="dropdown-content smooth-shadow">
                        {/* <Link to="/Story">
                            <label>{ props.translations[props.lang].menu.story }</label>
                        </Link>
                        <Link to="/Team">
                            <label>{ props.translations[props.lang].menu.team }</label>
                        </Link> */}
                        <a href="mailto:hello@wandelar.be">{ props.translations[props.lang].menu.contact }</a>
                    </div>
                </div>
            {
                user.status === 200
                ? 
                <>
                    <Link to="/Dashboard">
                        <div className="button">
                            <label>{ props.translations[props.lang].menu.hello } {user.firstname}</label>
                            <div className="icon-accent-0 icon-container icon-container-color-bg-white-0">
                                <DashboardIcon />
                            </div>
                        </div>
                    </Link>
                </>
                :
                <>
                    <Link to="/Login">
                        <div className="button">
                            <label>{ props.translations[props.lang].menu.login }</label>
                            <div className="icon-accent-0 icon-container icon-container-color-bg-white-0">
                                <LoginIcon />
                            </div>
                        </div>
                    </Link>
                    <Link to="/Register">
                        <div className="button">
                            <label>{ props.translations[props.lang].menu.register }</label>
                            <div className="icon-white-0 icon-container icon-container-border-white-0">
                                <RegisterIcon />
                            </div>
                        </div>
                    </Link>
                </>
            }
                {/* <Select
                    lang={props.lang}
                    translations={props.translations}
                    selected={props.selected}
                    options={props.options}
                    changeOption={props.changeOption}
                    type={'lang'}
                    icons={null}
                    disabled={false}
                    theme={'neutral'}
                /> */}
            </nav>
        </>
    );
}

export default NavbarDesktop;