import React, { useState, useEffect, useRef } from 'react';
import { UploadIcon, DismissIcon, CropIcon, TrashIcon, DragIcon, OkIcon } from '../Icons';
import './Dropzone.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ReactCrop, {
    centerCrop,
    makeAspectCrop
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import SpringScrollbars from '../SpringScrollbars';

const Dropzone = (props) => {

    const [errorMessage, setErrorMessage] = useState('');

    const modalImageRef = useRef();
    const modalRef = useRef();

    const fileInputRef = useRef();

    const [isHoverDrop, setIsHoverDrop] = useState(false);

    const [divHeightCropper, setDivHeightCropper] = useState(0);
    const [divHeightCropperBox, setDivHeightCropperBox] = useState(0);
    const canvasRefs = useRef([]);
    const cropperContentRefs = useRef([]);
    const imgRefs = useRef([]);

    const [droppedIndex, setDroppedIndex] = useState(null);

    const dragOver = (e) => {
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
        setIsHoverDrop(true);
    }

    const dragLeave = (e) => {
        e.preventDefault();
        setIsHoverDrop(false);
    }

    const validateFile = (file) => {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        return validTypes.includes(file.type);
    }

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
            setIsHoverDrop(false);
        }
    }

    const fileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const removeFile = (index) => {

        if (props.selectedFiles[index].type === 'url') {
            props.setRemovedFiles([...props.removedFiles, props.selectedFiles[index]]);
        }

        let newSelectedFiles = [...props.selectedFiles];
        const selectedFileIndex = newSelectedFiles.findIndex(e => e.id === index);
        newSelectedFiles.splice(selectedFileIndex, 1);

        for (var i = 0; i < newSelectedFiles.length; i++) {
            newSelectedFiles[i].id = i;
        }
        // update selectedFiles array
        props.setSelectedFiles(newSelectedFiles);
        props.setValidFiles(newSelectedFiles);

        const unsupportedFileIndex = props.unsupportedFiles.findIndex(e => e.id === index);
        if (unsupportedFileIndex !== -1) {
            props.unsupportedFiles.splice(unsupportedFileIndex, 1);
            // update unsupportedFiles array
            props.setUnsupportedFiles([...props.unsupportedFiles]);
        }
    }

    const closeModal = () => {
        modalRef.current.style.display = "none";
        modalImageRef.current.style.backgroundImage = 'none';
    }

    const fileInputClicked = (e) => {
        fileInputRef.current.value = null;
        fileInputRef.current.click();
    }

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
        }
    }

    const handleFiles = (files) => {
        let newSelectedFiles = [...props.selectedFiles];
        for (let i = 0; i < files.length; i++) {
            files[i]['id'] = props.selectedFiles.length + i;
            files[i]['cropper'] = {
                isCropping: false,
                scale: 1,
                rotate: 0,
                crop: {
                    unit: "%",
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0
                }
            };
            files[i]['state'] = 'image';
            if (validateFile(files[i])) {
                // if (validateFile(files[i]) && validateFileSize(files[i])) {
                props.setSelectedFiles(prevArray => [...prevArray, files[i]]);
            } else {
                // files[i]['invalid'] = { filetype: !validateFile(files[i]), filesize: !validateFileSize(files[i]) };
                files[i]['invalid'] = { filetype: !validateFile(files[i]) };
                props.setSelectedFiles(prevArray => [...prevArray, files[i]]);
                setErrorMessage(props.translations[props.lang].course.upload.unsupported);
                // setErrorMessageSize(props.translations[props.lang].course.upload.unsupportedSize);

                props.setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            }
            newSelectedFiles.push(files[i]);

        }

        previewImages(newSelectedFiles);
    }

    const handleDrop = (droppedItem) => {
        // Do nothing when dropped outside of container
        if (!droppedItem.destination) return;
        var newSelectedFiles = [...props.selectedFiles];
        // Remove dragged checkpoint
        const [reorderedItem] = newSelectedFiles.splice(droppedItem.source.index, 1);
        // Add dropped checkpoint
        newSelectedFiles.splice(droppedItem.destination.index, 0, reorderedItem);

        for (let i = 0; i < newSelectedFiles.length; i++) {
            newSelectedFiles[i]['id'] = i;
        }

        setDroppedIndex({
            source: droppedItem.source.index,
            destination: droppedItem.destination.index
        });

        props.setSelectedFiles(newSelectedFiles);
        props.setValidFiles(newSelectedFiles);

        previewImages(newSelectedFiles);
    };

    const getFilenameFromUrl = (string) => {
        let match = string.match(/([^\/]+$)/gm);
        const firstIndex = match[0].indexOf('-');
        const secondIndex = match[0].indexOf('-', firstIndex + 1);
        const trimmedStr = match[0].substring(secondIndex + 1);
        return trimmedStr
    }

    const previewImages = (files) => {
        // Set preview images
        // let fileReaders = [];
        if (files.length) {
            files.forEach((file) => {

                if (typeof file.url !== 'string') {

                    let src = window.URL.createObjectURL(file);

                    if (validateFile(file)) {
                        file['base64'] = src;
                    } else {
                        let svg = '<svg width="46px" height="46px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46"><path fill="rgba(116, 125, 137, 0.2)" d="M26,12.35h-6c-5.362,0-7.65,2.288-7.65,7.649v6c0,5.362,2.288,7.65,7.65,7.65h6c5.362,0,7.65-2.288,7.65-7.65v-6c0-5.362-2.288-7.649-7.65-7.649Zm-8.156,8.081l1.072-1.073-1.04-1.04c-.254-.254-.254-.666,0-.919,.254-.253,.666-.253,.92,0l1.04,1.04,1.008-1.008c.254-.254,.666-.254,.92,0s.254,.665,0,.919l-1.008,1.008,1.04,1.041c.254,.254,.254,.666,0,.919-.127,.126-.294,.19-.46,.19s-.333-.063-.46-.19l-1.04-1.04-1.072,1.072c-.127,.127-.294,.19-.46,.19s-.333-.063-.46-.19c-.254-.254-.254-.665,0-.919Zm8.041,8.918h-5.768c-.685,0-1.242-.557-1.242-1.242,0-2.275,1.851-4.125,4.126-4.125s4.126,1.851,4.126,4.125c0,.685-.557,1.242-1.242,1.242Zm2.272-8.031c-.127,.126-.294,.19-.46,.19s-.333-.063-.46-.19l-1.04-1.04-1.072,1.072c-.127,.127-.294,.19-.46,.19s-.333-.063-.46-.19c-.254-.254-.254-.665,0-.919l1.072-1.073-1.04-1.04c-.254-.254-.254-.666,0-.919,.254-.253,.666-.253,.92,0l1.04,1.04,1.008-1.008c.254-.254,.666-.254,.92,0s.254,.665,0,.919l-1.008,1.008,1.04,1.041c.254,.254,.254,.666,0,.919Z"/></svg>';
                        file['base64'] = `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`;
                    }
                }

            })
        };

    }

    // Cropper
    function isCropperBox(index) {

        let newSelectedFiles = [...props.selectedFiles];
        const selectedCropIndex = newSelectedFiles.findIndex(e => e.id === index);

        newSelectedFiles[selectedCropIndex].cropper.isCropping = true;

        props.setSelectedFiles(newSelectedFiles);
    }

    function onCropChange(percentCrop, index) {
        let newSelectedFiles = [...props.selectedFiles];
        const selectedCropIndex = newSelectedFiles.findIndex(e => e.id === index);
        newSelectedFiles[selectedCropIndex].cropper.crop = percentCrop;
        props.setSelectedFiles(newSelectedFiles);
    }

    function onCropComplete(crop, index) {
        const { width, height } = imgRefs.current[index];

        // Adjust canvas settings
        let ratio = 1005 / crop.width;
        let canvasCrop = {
            x: crop.x * ratio,
            y: crop.y * ratio,
            width: crop.width * ratio,
            height: crop.height * ratio,
        };
        canvasRefs.current[index].width = canvasCrop.width;
        canvasRefs.current[index].height = canvasCrop.height;

        const ctx = canvasRefs.current[index].getContext("2d");
        ctx.clearRect(0, 0, canvasRefs.current[index].width, canvasRefs.current[index].height);
        ctx.drawImage(imgRefs.current[index], -canvasCrop.x, -canvasCrop.y, width * ratio, height * ratio);

        let newSelectedFiles = [...props.selectedFiles];
        if (newSelectedFiles[index].type !== 'url') {
            const jpeg = canvasRefs.current[index].toDataURL('image/jpeg', 1);
            newSelectedFiles[index]["cropped"] = jpeg;

            canvasRefs.current[index].toBlob((blob) => {
                let file = new File([blob], newSelectedFiles[index].name, { type: "image/jpeg" });
                newSelectedFiles[index]["croppedFile"] = file;
            }, 'image/jpeg');

            // Set cropped images
            props.setSelectedFiles(newSelectedFiles);
            props.setValidFiles(newSelectedFiles);
        }
    }

    function saveCroppedImage(index) {
        let newSelectedFiles = [...props.selectedFiles];
        const selectedCropIndex = newSelectedFiles.findIndex(e => e.id === index);
        newSelectedFiles[selectedCropIndex].cropper.isCropping = false;
        props.setSelectedFiles(newSelectedFiles);
    }

    function onImageLoad(e, index, aspect, cropData, droppedItem) {

        const { width, height } = e.currentTarget;
        let crop = cropData;

        if (cropData.x === 0 && cropData.y === 0 && cropData.width === 0 && cropData.height === 0) {
            crop = centerAspectCrop(width, height, aspect);
        }

        let newSelectedFiles = [...props.selectedFiles];
        const selectedFileIndex = newSelectedFiles.findIndex(e => e.id === index);

        newSelectedFiles[selectedFileIndex].cropper.crop = crop;

        // Set initial canvas settings
        let ratio = 1005 / Math.round(width * (crop.width / 100));
        let canvasCrop = {
            x: Math.round(width * (crop.x / 100)) * ratio,
            y: Math.round(height * (crop.y / 100)) * ratio,
            width: Math.round(width * (crop.width / 100)) * ratio,
            height: Math.round(height * (crop.height / 100)) * ratio,
        };
        canvasRefs.current[index].width = canvasCrop.width;
        canvasRefs.current[index].height = canvasCrop.height;

        const ctx = canvasRefs.current[index].getContext("2d");
        ctx.drawImage(e.currentTarget, -canvasCrop.x, -canvasCrop.y, width * ratio, height * ratio);

        if (newSelectedFiles[selectedFileIndex].type !== 'url') {
            const jpeg = canvasRefs.current[index].toDataURL('image/jpeg', 1);
            newSelectedFiles[selectedFileIndex]["cropped"] = jpeg;

            canvasRefs.current[index].toBlob((blob) => {
                let file = new File([blob], newSelectedFiles[selectedFileIndex].name, { type: "image/jpeg" });
                newSelectedFiles[selectedFileIndex]["croppedFile"] = file;
            }, 'image/jpeg');
        }

        // Set cropped images
        props.setSelectedFiles(newSelectedFiles);
        props.setValidFiles(newSelectedFiles);

    }

    function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        if (window.innerWidth < 768) {
            setDivHeightCropper(window.innerHeight - 120 - props.homeTopDiv.current.clientHeight);
            setDivHeightCropperBox(window.innerHeight - 252 - props.homeTopDiv.current.clientHeight);
        } else {
            setDivHeightCropper(window.innerHeight);
            setDivHeightCropperBox(window.innerHeight - 132);
        }

        // cleanup this component
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function handleResize(e) {
        if (window.innerWidth < 768) {
            setDivHeightCropper(window.innerHeight - 120 - props.homeTopDiv.current.clientHeight);
            setDivHeightCropperBox(window.innerHeight - 252 - props.homeTopDiv.current.clientHeight);
        } else {
            setDivHeightCropper(window.innerHeight);
            setDivHeightCropperBox(window.innerHeight - 132);
        }
    }

    function setIconImage(index) {
        let newSelectedFiles = [...props.selectedFiles];

        for (var i = 0; i < newSelectedFiles.length; i++) {
            if (i === index) {
                if (newSelectedFiles[i].state === 'icon') {
                    newSelectedFiles[i].state = 'image';
                } else {
                    newSelectedFiles[i].state = 'icon';
                }
            } else {
                if (newSelectedFiles[i].state === 'icon') {
                    newSelectedFiles[i].state = 'image';
                }
            }
        }

        props.setSelectedFiles(newSelectedFiles);
        props.setValidFiles(newSelectedFiles);
    }

    function setSponsorImage(index) {
        let newSelectedFiles = [...props.selectedFiles];

        for (var i = 0; i < newSelectedFiles.length; i++) {
            if (i === index) {
                if (newSelectedFiles[i].state === 'sponsor') {
                    newSelectedFiles[i].state = 'image';
                } else {
                    newSelectedFiles[i].state = 'sponsor';
                }
            } else {
                if (newSelectedFiles[i].state === 'sponsor') {
                    newSelectedFiles[i].state = 'image';
                }
            }
        }

        props.setSelectedFiles(newSelectedFiles);
        props.setValidFiles(newSelectedFiles);
    }

    return (
        <>
            <div className="row">
                <div
                    className="col-12 p-3"
                >
                    <div
                        id={props.dropzoneId}
                        className={`drop-container ${isHoverDrop ? 'hover' : undefined} p-4`}
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                        onClick={fileInputClicked}
                    >
                        <div className="drop-message">
                            <UploadIcon />
                            <label>{props.translations[props.lang].course.upload.dragDrop}</label>
                            <label className="caption m-t-0">{props.translations[props.lang].course.upload.allowed}</label>
                        </div>
                        <input
                            ref={fileInputRef}
                            className="file-input"
                            type="file"
                            multiple
                            onChange={filesSelected}
                        />
                    </div>
                    {props.unsupportedFiles.length ? <p className="w-100 text-center color-text-error">{props.translations[props.lang].course.upload.remove}</p> : ''}
                </div>
                <div className="p-3">
                    <DragDropContext
                        onDragEnd={handleDrop}
                    >
                        <Droppable droppableId="list-container">
                            {(provided) =>
                                <>
                                    <div
                                        className={`list-container drop-container-box ${props.isEdit.status && props.validFiles.length !== 0 || props.isEdit.status && props.unsupportedFiles.length !== 0 ? 'active' : undefined}`}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {
                                            props.selectedFiles.map((data, i) => {
                                                return (
                                                    <div
                                                        key={`image-key-${i}`}
                                                    >

                                                        <Draggable
                                                            draggableId={`image-drag-${i}`}
                                                            draggable={props.isEdit.status ? true : false}
                                                            index={i}
                                                        >
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.dragHandleProps}
                                                                    {...provided.draggableProps}
                                                                >
                                                                    <div className="dropzone-list-item">
                                                                        <div className="row">
                                                                            <div className="d-flex flex-direction-row align-items justify-content-space-between">
                                                                                <div className={`d-flex flex-direction-row align-items justify-content-end`}>
                                                                                    <div className="drag-image-icon rotate-90">
                                                                                        <DragIcon />
                                                                                    </div>
                                                                                    {
                                                                                        data.state === 'icon' && <>
                                                                                            <div className={`d-flex flex-direction-row align-items ${data.state === 'icon' || data.state === 'sponsor' ? 'app-preview-bg' : undefined}`}>
                                                                                                <div className={`d-flex flex-direction-column justify-content align-items`}>
                                                                                                    <div className="app-preview m-r-1">
                                                                                                        <div className="app-preview-container">
                                                                                                            {
                                                                                                                typeof data.url === 'string' ?
                                                                                                                    <>
                                                                                                                        <img
                                                                                                                            src={data.url}
                                                                                                                        />
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            data.cropped ?
                                                                                                                                <>
                                                                                                                                    <img
                                                                                                                                        src={data.cropped}
                                                                                                                                    />
                                                                                                                                </>
                                                                                                                                :
                                                                                                                                <>
                                                                                                                                    <img
                                                                                                                                        src={data.base64}
                                                                                                                                    />
                                                                                                                                </>
                                                                                                                        }
                                                                                                                    </>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="d-flex flex-direction-column">
                                                                                                    <div className="app-preview-caption"></div>
                                                                                                    <div className="app-preview-title"></div>
                                                                                                    <div className="app-preview-subtitle"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        data.state === 'sponsor' && <>
                                                                                            <div className={`d-flex flex-direction-row align-items ${data.state === 'icon' || data.state === 'sponsor' ? 'app-preview-bg' : undefined}`}>
                                                                                                <div className={`d-flex flex-direction-row justify-content align-items`}>
                                                                                                    <div className="d-flex flex-direction-column align-items-end m-r-1">
                                                                                                        <div className="app-preview-title-sponsor "></div>
                                                                                                        <div className="app-preview-url"></div>
                                                                                                    </div>
                                                                                                    <div className="d-flex flex-direction-column justify-content align-items">
                                                                                                        <div className="app-preview">
                                                                                                            <div className="app-preview-container">
                                                                                                                {
                                                                                                                    typeof data.url === 'string' ?
                                                                                                                        <>
                                                                                                                            <img
                                                                                                                                src={data.url}
                                                                                                                            />
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                data.cropped ?
                                                                                                                                    <>
                                                                                                                                        <img
                                                                                                                                            src={data.cropped}
                                                                                                                                        />
                                                                                                                                    </>
                                                                                                                                    :
                                                                                                                                    <>
                                                                                                                                        <img
                                                                                                                                            src={data.base64}
                                                                                                                                        />
                                                                                                                                    </>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        data.state === 'image' && <>
                                                                                            <div className="app-preview-caroussel">
                                                                                                <div className="app-preview-caroussel-image">
                                                                                                    <div className="app-preview-caroussel-image-container">
                                                                                                        {
                                                                                                            typeof data.url === 'string' ?
                                                                                                                <img
                                                                                                                    src={data.url}
                                                                                                                />
                                                                                                                :
                                                                                                                <>
                                                                                                                    {
                                                                                                                        !data.invalid && data.cropped ?
                                                                                                                            <>
                                                                                                                                <img
                                                                                                                                    src={data.cropped}
                                                                                                                                />
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <>
                                                                                                                                <img
                                                                                                                                    src={data.base64}
                                                                                                                                />
                                                                                                                            </>
                                                                                                                    }
                                                                                                                </>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="app-preview-caroussel-content">
                                                                                                    <div className="app-preview-caroussel-thumbs">
                                                                                                        <div className="app-preview-caroussel-thumb-1"></div>
                                                                                                        <div className="app-preview-caroussel-thumb-2"></div>
                                                                                                        <div className="app-preview-caroussel-thumb-2"></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    <div className="row drop-title-1">
                                                                                        {
                                                                                            !data.invalid && <div className="d-flex flex-direction-column justify-content-start p-l-1">
                                                                                                <div className="set-icon d-flex flex-direction-row justify-content-start align-items">
                                                                                                    <div className={`toggle-small`}>
                                                                                                        <div
                                                                                                            className={`toggle-box-small ${data.state === 'icon' ? "active" : undefined}`}
                                                                                                            onClick={() => setIconImage(i)}
                                                                                                        >
                                                                                                            <div className={`toggle-thumb-small ${data.state === 'icon' ? "active" : undefined}`}></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="text-color-gray fs-15 p-l-0">
                                                                                                        {props.translations[props.lang].course.isIcon}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="set-icon d-flex flex-direction-row justify-content-start align-items m-t-0">
                                                                                                    <div className={`toggle-small`}>
                                                                                                        <div
                                                                                                            className={`toggle-box-small ${data.state === 'sponsor' ? "active" : undefined}`}
                                                                                                            onClick={() => setSponsorImage(i)}
                                                                                                        >
                                                                                                            <div className={`toggle-thumb-small ${data.state === 'sponsor' ? "active" : undefined}`}></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="text-color-gray fs-15 p-l-0">
                                                                                                        {props.translations[props.lang].course.isSponsor}
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex flex-direction-row align-items justify-content-end">
                                                                                    <div className="drop-title-1 d-flex align-items ellipsis p-r-1">

                                                                                        <div className="d-flex flex-direction-column justify-content-space-between pointer-none">
                                                                                            {
                                                                                                data.state === 'image' && <>
                                                                                                    {
                                                                                                        typeof data.url === 'string' && data.url !== '' ?
                                                                                                            <>
                                                                                                                <label className="text-title fs-15">{getFilenameFromUrl(data.url)}</label>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <label className="text-title fs-15">{data.name}</label>
                                                                                                            </>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                            
                                                                                                <div className="caption-text text-end">
                                                                                                    {
                                                                                                        typeof data.url === 'string' ?
                                                                                                            <>
                                                                                                                <span className="fs-15 text-color-gray w-100"></span>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <span className="fs-15 text-color-gray w-100">
                                                                                                                    {fileSize(data.size)}
                                                                                                                </span>
                                                                                                            </>
                                                                                                    }
                                                                                                    {
                                                                                                        typeof data.url === 'string' ?
                                                                                                            <>

                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    data.invalid && data.invalid.filetype && <div className='file-error-message fs-15'>{errorMessage}</div>
                                                                                                                }
                                                                                                            </>
                                                                                                    }
                                                                                                </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        typeof data.url === 'string' ?
                                                                                            <>
                                                                                               
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    !data.invalid && <div className="dropzone-button" onClick={() => isCropperBox(i)}>
                                                                                                        <CropIcon />
                                                                                                    </div>
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                    <div className="dropzone-button m-l-0" onClick={() => removeFile(data.id)}>
                                                                                        <TrashIcon />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                typeof data.url === 'string' ?
                                                                                    <>

                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div className="drop-title-2 d-flex justify-content-end align-items m-t-0">
                                                                                            {
                                                                                                data.invalid && data.invalid.filetype && <div className='file-error-message fs-15'>{errorMessage}</div>
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                            }
                                                                            <div className="row drop-title-2">
                                                                                {
                                                                                    !data.invalid && <div className="d-flex flex-direction-column justify-content-start m-t-1">
                                                                                        <div className="set-icon d-flex flex-direction-row justify-content-start align-items">
                                                                                            <div className={`toggle-small`}>
                                                                                                <div
                                                                                                    className={`toggle-box-small ${data.state === 'icon' ? "active" : undefined}`}
                                                                                                    onClick={() => setIconImage(i)}
                                                                                                >
                                                                                                    <div className={`toggle-thumb-small ${data.state === 'icon' ? "active" : undefined}`}></div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="text-color-gray fs-15 p-l-0">
                                                                                                {props.translations[props.lang].course.isIcon}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="set-icon d-flex flex-direction-row justify-content-start align-items m-t-0">
                                                                                            <div className={`toggle-small`}>
                                                                                                <div
                                                                                                    className={`toggle-box-small ${data.state === 'sponsor' ? "active" : undefined}`}
                                                                                                    onClick={() => setSponsorImage(i)}
                                                                                                >
                                                                                                    <div className={`toggle-thumb-small ${data.state === 'sponsor' ? "active" : undefined}`}></div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="text-color-gray fs-15 p-l-0">
                                                                                                {props.translations[props.lang].course.isSponsor}
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>

                                                    </div>
                                                )
                                            })
                                        }
                                     
                                        {provided.placeholder}
                                    </div>
                                </>

                            }
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
            <div className="modal" ref={modalRef}>
                <div className="overlay"></div>
                <div className="dropzone-button-dismiss close" onClick={(() => closeModal())}>
                    <DismissIcon />
                </div>
                <div className="modal-content d-flex justify-content align-items">
                    <img src='' className="modal-image" ref={modalImageRef}></img>
                </div>
            </div>

            {
                props.selectedFiles.map((data, index) => {

                    let imgSrc = '';
                    if (data.type === 'url') {
                        imgSrc = data.url;
                    } else {
                        imgSrc = data.base64
                    }

                    let aspect = 1005 / 734;

                    return <div
                        key={`cropper-box-${index}`}
                        className={`cropper pointer-none row w-100 d-flex justify-content-end align-items-end flex-direction-column ${data.cropper.isCropping ? 'active' : undefined}`}
                        style={{
                            height: divHeightCropper,
                            top: window.innerWidth < 768 && props.homeTopDiv.current.clientHeight
                        }}
                    >
                        <div className={`pointer-auto col-12 cropper-box`}>
                            <div className="row w-100">
                                <div className='cropper-box-header d-flex justify-content-space-between align-items'>
                                    <div className="p-l-15">
                                        {props.translations[props.lang].course.dragToCrop}
                                    </div>
                                    <div className={`button d-flex justify-content-end p-0`} onClick={() => saveCroppedImage(index)}>
                                        <label className="text text-color">{props.translations[props.lang].course.ok}</label>
                                        <div className="icon-color-white icon-container icon-container-color-bg-accent-0">
                                            <OkIcon />
                                        </div>
                                    </div>
                                </div>
                                <div ref={elem => cropperContentRefs.current[index] = elem} className='cropper-box-content d-flex flex-direction-column justify-content align-items' style={{ height: divHeightCropperBox }}>
                                    <SpringScrollbars
                                        style={{
                                            backgroundColor: 'transparent',
                                            height: divHeightCropperBox,
                                            zIndex: 0
                                        }}
                                        thumbcolor='rgba(116, 125, 137, 0.2)'
                                    >
                                        <div className="d-flex justify-content align-items">
                                            <canvas
                                                ref={elem => canvasRefs.current[index] = elem}
                                                style={{
                                                    objectFit: 'contain'
                                                }}
                                                className={`canvas-crop`}
                                            />
                                            <ReactCrop
                                                crop={data.cropper.crop}
                                                locked
                                                onChange={(_, percentCrop) => onCropChange(percentCrop, index)}
                                                onComplete={(c) => onCropComplete(c, index)}
                                                aspect={aspect}
                                            >
                                                <img
                                                    ref={elem => imgRefs.current[index] = elem}
                                                    alt="Crop me"
                                                    src={imgSrc}
                                                    style={{ transform: `scale(${data.cropper.scale}) rotate(${data.cropper.rotate}deg)` }}
                                                    onLoad={(e) => onImageLoad(e, index, aspect, data.cropper.crop, droppedIndex)}
                                                />
                                            </ReactCrop>
                                        </div>
                                    </SpringScrollbars>
                                </div>
                            </div>
                        </div>
                    </div>
                })

            }

        </>
    )
}
export default Dropzone;