import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import SocialBanner from "./SocialBanner";


function TermsAndConditions(props) {

    let { lang } = useParams();

    useEffect ( () => {
        if (lang) {
            props.changeOption(lang, "lang");
        }
    }, [])

	return (
		<>
            <Header
				lang={props.lang}
				translations={props.translations}
				isLoading={props.isLoading}
				selected={props.lang}
				options={props.options}
				changeOption={props.changeOption}
				flags={props.flags}
			/>
            <div className="menu-bg w-100"></div>
			<div className="privacy">
                <label className="title m-b-2">{ props.translations[props.lang].terms.termsAndConditionsStatement }</label>
                <label className="text1 m-b-1">{ props.translations[props.lang].terms.termsAndConditionsStatementText1 }</label>
                <label className="text2 m-b-3">{ props.translations[props.lang].terms.termsAndConditionsStatementText2 }</label>
                
                <label className="subtitle m-b-2">{ props.translations[props.lang].terms.riskWaiver }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].terms.riskWaiverText1 }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].terms.riskWaiverText2 }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].terms.riskWaiverText3 }</label>
                <label className="text2 m-b-3">{ props.translations[props.lang].terms.riskWaiverText4 }</label>

                <label className="subtitle m-b-2">{ props.translations[props.lang].terms.generalRiskWarning }</label>
                <label className="text2">{ props.translations[props.lang].terms.generalRiskWarningText1 }</label>
                <ul className="m-b-2">
                    <li><label className="text2">{ props.translations[props.lang].terms.generalRiskWarningBullet1 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.generalRiskWarningBullet2 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.generalRiskWarningBullet3 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.generalRiskWarningBullet4 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.generalRiskWarningBullet5 }</label></li>
                </ul>
                
                <label className="text2">{ props.translations[props.lang].terms.generalRiskWarningText2 }</label>
                <ul className="m-b-3">
                    <li><label className="text2">{ props.translations[props.lang].terms.generalRiskWarningBullet6 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.generalRiskWarningBullet7 }</label></li>
                </ul>
               
                <label className="subtitle m-b-1">{ props.translations[props.lang].terms.statements }</label>
                <ul className="m-b-3">
                    <li><label className="text2">{ props.translations[props.lang].terms.statementsBullet1 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.statementsBullet2 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.statementsBullet3 }</label></li>
                </ul>

                <label className="subtitle m-b-2">{ props.translations[props.lang].terms.definitionsAndInterpretation }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].terms.definitionsAndInterpretationText }</label>
                <ul className="m-b-3">
                    <li><label className="text2">{ props.translations[props.lang].terms.definitionsAndInterpretationBullet1 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.definitionsAndInterpretationBullet2 }</label></li>
                </ul>

                <label className="subtitle m-b-2">{ props.translations[props.lang].terms.informationAboutUs }</label>
                <label className="text2 m-b-3">{ props.translations[props.lang].terms.informationAboutUsText }</label>

                <label className="subtitle m-b-2">{ props.translations[props.lang].terms.accessToOurApp }</label>
                <ul className="m-b-3">
                    <li><label className="text2">{ props.translations[props.lang].terms.accessToOurAppBullet1 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.accessToOurAppBullet2 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.accessToOurAppBullet3 }</label></li>
                </ul>

                <label className="subtitle m-b-2">{ props.translations[props.lang].terms.intellectualPropertyRights }</label>
                <ul className="m-b-3">
                    <li className="m-b-2">
                        <label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet1 }</label>
                    </li>
                    <li>
                        <label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet2 }</label>
                        <ul className="m-b-2">
                            <li>
                                <label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet21 }</label>
                                <ul>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet211 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet212 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet213 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet214 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet215 }</label></li>
                                </ul>
                            </li>
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet22 }</label></li>
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet23 }</label></li>
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet24 }</label></li>
                        </ul>
                    </li>
                    <li>
                        <label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet3 }</label>
                        <ul className="m-b-2">
                            <li>
                                <label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet31 }</label>
                                <ul>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet311 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet312 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet313 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet314 }</label></li>
                                </ul>
                            </li>
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet32 }</label></li>
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet33 }</label></li>
                            <li>
                                <label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet34 }</label>
                                <ul>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet341 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet342 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet343 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet344 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet345 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet346 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet347 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet348 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet349 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet3410 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet3411 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet3412 }</label></li>
                                </ul>
                            </li>
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet35 }</label></li>
                        </ul>
                    </li>
                    <li>
                        <label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet4 }</label>
                    </li>
                    <li className="m-b-2">
                        <label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet4Text }</label>
                    </li>
                    <li>
                        <label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet5 }</label>
                        <ul className="m-b-2">
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet51 }</label></li>
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet52 }</label></li>
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet53 }</label></li>
                        </ul>
                    </li>
                    <li>
                        <label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet6 }</label>
                        <ul className="m-b-2">
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet61 }</label></li>
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet62 }</label></li>
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet63 }</label></li>
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet64 }</label></li>
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet65 }</label></li>
                            <li><label className="text2">{ props.translations[props.lang].terms.intellectualPropertyRightsBullet66 }</label></li>
                        </ul>
                    </li>
                </ul>

                <label className="subtitle m-b-2">{ props.translations[props.lang].terms.virusesMalwareAndSecurity }</label>
                <ul className="m-b-3">
                    <li><label className="text2">{ props.translations[props.lang].terms.virusesMalwareAndSecurityBullet1 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.virusesMalwareAndSecurityBullet2 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.virusesMalwareAndSecurityBullet3 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.virusesMalwareAndSecurityBullet4 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.virusesMalwareAndSecurityBullet5 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.virusesMalwareAndSecurityBullet6 }</label></li>
                </ul>

                <label className="subtitle m-b-2">{ props.translations[props.lang].terms.acceptableUsagePolicy }</label>
                <ul className="m-b-3">
                    <li>
                        <label className="text2">{ props.translations[props.lang].terms.acceptableUsagePolicyBullet1 }</label>
                        <ul className="m-b-2">
                            <li><label className="text2">{ props.translations[props.lang].terms.acceptableUsagePolicyBullet11 }</label></li>
                            <li><label className="text2">{ props.translations[props.lang].terms.acceptableUsagePolicyBullet12 }</label></li>
                            <li><label className="text2">{ props.translations[props.lang].terms.acceptableUsagePolicyBullet13 }</label></li>
                            <li><label className="text2">{ props.translations[props.lang].terms.acceptableUsagePolicyBullet14 }</label></li>
                            <li>
                                <label className="text2">{ props.translations[props.lang].terms.acceptableUsagePolicyBullet15 }</label>
                                <ul>
                                    <li><label className="text2">{ props.translations[props.lang].terms.acceptableUsagePolicyBullet151 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.acceptableUsagePolicyBullet152 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.acceptableUsagePolicyBullet153 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.acceptableUsagePolicyBullet154 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.acceptableUsagePolicyBullet155 }</label></li>
                                    <li><label className="text2">{ props.translations[props.lang].terms.acceptableUsagePolicyBullet156 }</label></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><label className="text2">{ props.translations[props.lang].terms.acceptableUsagePolicyBullet2 }</label></li>
                </ul>

                <label className="subtitle m-b-2">{ props.translations[props.lang].terms.changesToTheseTermsAndConditions }</label>
                <label className="text2 m-b-3">{ props.translations[props.lang].terms.changesToTheseTermsAndConditionsText }</label>

                <label className="subtitle m-b-2">{ props.translations[props.lang].terms.contactingUs }</label>
                <label className="text2 m-b-3">{ props.translations[props.lang].terms.contactingUsText }</label>

                <label className="subtitle m-b-2">{ props.translations[props.lang].terms.communicationsFromUs }</label>
                <ul className="m-b-3">
                    <li><label className="text2">{ props.translations[props.lang].terms.communicationsFromUsBullet1 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.communicationsFromUsBullet2 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.communicationsFromUsBullet3 }</label></li>
                </ul>

                <label className="subtitle m-b-2">{ props.translations[props.lang].terms.dataProtection }</label>
                <ul className="m-b-3">
                    <li><label className="text2">{ props.translations[props.lang].terms.dataProtectionBullet1 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.dataProtectionBullet2 }</label></li>
                </ul>

                <label className="subtitle m-b-2">{ props.translations[props.lang].terms.lawAndJurisdiction }</label>
                <ul className="m-b-3">
                    <li><label className="text2">{ props.translations[props.lang].terms.lawAndJurisdictionBullet1 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.lawAndJurisdictionBullet2 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].terms.lawAndJurisdictionBullet3 }</label></li>
                </ul>


                {/* <label className="text2">Text</label>
                <label className="caption">Caption</label>
                <a href="#" target="_blank" className="button-menu d-flex justify-content-start p-0">
                    <label className="text-color-accent-0">Button</label>
                    <div className="icon-white-0 icon-container icon-container-color-bg-accent-0">
                        <PlayIcon />
                    </div>
                </a> */}
            </div>
            <SocialBanner
				lang={props.lang}
				translations={props.translations}
				urls={props.urls}
			/>
            <Footer
				theme={props.theme}
				themes={props.themes}
				lang={props.lang}
				languages={props.languages}
				translations={props.translations}
				changeOption={props.changeOption}
				themeIcons={props.themeIcons}
				flags={props.flags}
				urls={props.urls}
			/>
        </>
	);
}

export default TermsAndConditions;