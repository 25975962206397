export const urls = {
    us_en: {
        privacy: "https://wandelar.be/pdf/us_en/Wandelar-Privacy-Policy-us_en.pdf",
        terms: "https://wandelar.be/pdf/us_en/Wandelar-Terms-And-Conditions-us_en.pdf",
    },
    be_nl: {
        privacy: "https://wandelar.be/pdf/be_nl/Wandelar-Privacy-Policy-be_nl.pdf",
        terms: "https://wandelar.be/pdf/be_nl/Wandelar-Terms-And-Conditions-be_nl.pdf",
    },
    appstore: "https://apps.apple.com/be/app/wandelar/id1637407050?platform=iphone",
    playstore: "https://play.google.com/store/apps/details?id=com.Aeroplane.WandelAR",
    demo: "https://www.youtube.com/watch?v=o8HTo0JCZbU",
    social: {
        youtube: {
            username: "WandelAR",
            url: "https://www.youtube.com/@WandelAR/"
        },
        twitter: {
            username: "WandelAR_be",
            url: "https://twitter.com/WandelAR_be"
        },
        instagram: {
            username: "WandelAR.be",
            url: "https://www.instagram.com/WandelAR.be/"
        },
        facebook: {
            username: "WandelAR.be",
            url: "https://www.facebook.com/WandelAR.be"
        },
        snapchat: {
            username: "WandelAR.be",
            url: "https://www.snapchat.com/add/wandelar.be"
        },
        tiktok: {
            username: "WandelAR.be",
            url: "https://tiktok.com/@wandelar.be"
        }
    }
}