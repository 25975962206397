import { useState, useEffect } from 'react';

export default function useFindUser() {
	const [user, setUser] = useState({ user: '', status: 404 });
	const [isLoadingUser, setLoadingUser] = useState(true);
	
	useEffect(() => {
		async function findUser() {
			await fetch('https://wandelar.be/api/user', {
				method: 'GET',
				credentials: 'same-origin'
			}).then(response => {
				return response.json();
			}).then(data => {
				
				if (data.status === 200) {
					setUser(data);
					setLoadingUser(false);
				} else {
					setUser({ user: '', status: 404 });
					// window.location.reload(false);
				}
				
			}).catch(error => {
				// console.log(error);
				setLoadingUser(false);
			});
		}

		findUser();
	}, []);

	return {
		user,
		setUser,
		isLoadingUser
	}
}