import { useControl} from 'react-map-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

function Geocoder(props) {

    const ctrl = new MapboxGeocoder({
        accessToken: props.mapboxAccessToken,
        marker: false,
        collapsed: false,
        placeholder: props.translations[props.lang].course.placeholderSearch,
        language: props.lang.split('_')[1]
    });

    useControl(() => ctrl, { position: props.position });

    ctrl.on('result', (e) => {
        const coords = e.result.geometry.coordinates;
        if (coords && props.marker) {

            props.mapView.current.once('moveend', () => {
                props.setTogglePitch(false);
                props.setHoverInfo(null);
            })
            

            // Add marker
            // var courseNewCopy = { ...props.courseNew };
            // let coordinate = `${coords[1]},${coords[0]}`;
            // props.courseNew.checkpoints.push({ gps: coordinate, qr: "" });
            // props.changeCourseNew(courseNewCopy);
        }

    });
    return null;

    // const geocoder = useControl(
    //     () => {
    //         const ctrl = new MapboxGeocoder({
    //             ...props,
    //             marker: false,
    //             accessToken: props.mapboxAccessToken
    //         });
    //         ctrl.on('loading', props.onLoading);
    //         ctrl.on('results', props.onResults);
    //         ctrl.on('result', evt => {
    //             props.onResult(evt);

    //             const { result } = evt;
    //             const location = result && (result.center || (result.geometry?.type === 'Point' && result.geometry.coordinates));
    //             if (location && props.marker) {
    //                 setMarker(<Marker {...props.marker} longitude={location[0]} latitude={location[1]} />);
    //             } else {
    //                 setMarker(null);
    //             }
    //         });
    //         ctrl.on('error', props.onError);
    //         return ctrl;
    //     },
    //     {
    //         position: props.position
    //     }
    // );

    // // @ts-ignore (TS2339) private member
    // if (geocoder._map) {
    //     if (geocoder.getProximity() !== props.proximity && props.proximity !== undefined) {
    //         geocoder.setProximity(props.proximity);
    //     }
    //     if (geocoder.getRenderFunction() !== props.render && props.render !== undefined) {
    //         geocoder.setRenderFunction(props.render);
    //     }
    //     if (geocoder.getLanguage() !== props.language && props.language !== undefined) {
    //         geocoder.setLanguage(props.language);
    //     }
    //     if (geocoder.getZoom() !== props.zoom && props.zoom !== undefined) {
    //         geocoder.setZoom(props.zoom);
    //     }
    //     if (geocoder.getFlyTo() !== props.flyTo && props.flyTo !== undefined) {
    //         geocoder.setFlyTo(props.flyTo);
    //     }
    //     if (geocoder.getPlaceholder() !== props.placeholder && props.placeholder !== undefined) {
    //         geocoder.setPlaceholder(props.placeholder);
    //     }
    //     if (geocoder.getCountries() !== props.countries && props.countries !== undefined) {
    //         geocoder.setCountries(props.countries);
    //     }
    //     if (geocoder.getTypes() !== props.types && props.types !== undefined) {
    //         geocoder.setTypes(props.types);
    //     }
    //     if (geocoder.getMinLength() !== props.minLength && props.minLength !== undefined) {
    //         geocoder.setMinLength(props.minLength);
    //     }
    //     if (geocoder.getLimit() !== props.limit && props.limit !== undefined) {
    //         geocoder.setLimit(props.limit);
    //     }
    //     if (geocoder.getFilter() !== props.filter && props.filter !== undefined) {
    //         geocoder.setFilter(props.filter);
    //     }
    //     if (geocoder.getLocalGeocoder() !== props.localGeocoder && props.localGeocoder !== undefined) {
    //         geocoder.setLocalGeocoder(props.localGeocoder);
    //     }
    // }
    // return [geocoder, marker];
}

export default Geocoder;