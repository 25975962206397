import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import SocialBanner from "./SocialBanner";


function PrivacyPolicy(props) {

    let { lang } = useParams();

    useEffect ( () => {
        if (lang) {
            props.changeOption(lang, "lang");
        }
    }, [])

	return (
		<>
            <Header
				lang={ props.lang }
				translations={props.translations}
				isLoading={props.isLoading}
				selected={props.lang}
				options={props.options}
				changeOption={props.changeOption}
				flags={props.flags}
			/>
            <div className="menu-bg w-100"></div>
			<div className="privacy">
                <label className="title m-b-2">{ props.translations[props.lang].privacy.privacyPolicy }</label>
                <label className="subtitle m-b-1">{ props.translations[props.lang].privacy.userAgreementAndPrivacyPolicyTitle }</label>
                <label className="text1 m-b-3">{ props.translations[props.lang].privacy.userAgreementAndPrivacyPolicyText }</label>
                
                <label className="subtitle m-b-2">{ props.translations[props.lang].privacy.userAgreementTitle }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.userAgreementText1 }</label>
                <label className="text2 m-b-3">{ props.translations[props.lang].privacy.userAgreementText2 }</label>

                <label className="subtitle m-b-2">{ props.translations[props.lang].privacy.privacyPolicyTitle }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.privacyPolicyText1 }</label>
                <label className="text2">{ props.translations[props.lang].privacy.privacyPolicyText2 }</label>
                <ul className="m-b-2">
                    <li><label className="text2">{ props.translations[props.lang].privacy.privacyPolicyBullet1 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].privacy.privacyPolicyBullet2 }</label></li>
                </ul>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.privacyPolicyText3 }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.privacyPolicyText4 }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.privacyPolicyText5 }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.privacyPolicyText6 }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.privacyPolicyText7 }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.privacyPolicyText8 }</label>
                <label className="text2 m-b-3">{ props.translations[props.lang].privacy.privacyPolicyText9 }</label>

                <label className="subtitle m-b-2">{ props.translations[props.lang].privacy.managingPersonalInformationTitle }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.managingPersonalInformationText1 }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.managingPersonalInformationText2 }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.managingPersonalInformationText3 }</label>
                <label className="text2 m-b-3">{ props.translations[props.lang].privacy.managingPersonalInformationText4 }</label>

                <label className="subtitle m-b-1">{ props.translations[props.lang].privacy.userDataTitle }</label>
                <ul className="m-b-3">
                    <li><label className="text2">{ props.translations[props.lang].privacy.userDataBullet1 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].privacy.userDataBullet2 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].privacy.userDataBullet3 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].privacy.userDataBullet4 }</label></li>
                </ul>

                <label className="subtitle m-b-1">{ props.translations[props.lang].privacy.locationDataTitle }</label>
                <ul>
                    <li><label className="text2">{ props.translations[props.lang].privacy.locationDataBullet1 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].privacy.locationDataBullet2 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].privacy.locationDataBullet3 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].privacy.locationDataBullet4 }</label></li>
                </ul>
                <label className="text2 m-b-3">
                    { props.translations[props.lang].privacy.locationDataText1 }
                    <a href="https://www.mapbox.com/legal/privacy">
                        <label className="text2 pointer">{ props.translations[props.lang].privacy.locationDataText2 }</label>
                    </a>
                </label>
              
                <label className="subtitle m-b-2">{ props.translations[props.lang].privacy.cookies }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.cookiesText1 }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.cookiesText2 }</label>

                
                <ul>
                    <li className="text1 m-b-1">
                        <label className="text1">{ props.translations[props.lang].privacy.cookie1 }</label>
                    </li>
                    <ul>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieName }: </label></strong>
                            <label className="text2 m-b-2">_ga</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription11 }</label>
                            <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
                                <label className="text2 m-b-2 pointer">{ props.translations[props.lang].privacy.cookieDescription12 }</label>
                            </a>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription13 }</label>
                            <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB">
                                <label className="text2 m-b-2 pointer">{ props.translations[props.lang].privacy.cookieDescription14 }</label>
                            </a>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration1 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType1 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty1 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieLink }: </label></strong>
                            <a href="https://policies.google.com/u/1/privacy">
                                <label className="text2 pointer">{ props.translations[props.lang].privacy.cookieLink1 }</label>
                            </a>
                        </li>
                    </ul>
                </ul>

                <ul>
                    <li className="text1 m-b-1">
                        <label className="text1">{ props.translations[props.lang].privacy.cookie2 }</label>
                    </li>
                    <ul>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieName }: </label></strong>
                            <label className="text2 m-b-2">_gid</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription21 }</label>
                            <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB">
                                <label className="text2 m-b-2 pointer">{ props.translations[props.lang].privacy.cookieDescription22 }</label>
                            </a>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration2 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType2 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty2 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieLink }: </label></strong>
                            <a href="https://policies.google.com/u/1/privacy">
                                <label className="text2 pointer">{ props.translations[props.lang].privacy.cookieLink2 }</label>
                            </a>
                        </li>
                    </ul>
                </ul>

                <ul>
                    <li className="text1 m-b-1">
                        <label className="text1">{ props.translations[props.lang].privacy.cookie3 }</label>
                    </li>
                    <ul>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieName }: </label></strong>
                            <label className="text2 m-b-2">_gat</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription31 }</label>
                            <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB">
                                <label className="text2 m-b-2 pointer">{ props.translations[props.lang].privacy.cookieDescription32 }</label>
                            </a>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration3 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType3 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty3 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieLink }: </label></strong>
                            <a href="https://policies.google.com/u/1/privacy">
                                <label className="text2 pointer">{ props.translations[props.lang].privacy.cookieLink3 }</label>
                            </a>
                        </li>
                    </ul>
                </ul>

                <ul>
                    <li className="text1 m-b-1">
                        <label className="text1">{ props.translations[props.lang].privacy.cookie4 }</label>
                    </li>
                    <ul>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieName }: </label></strong>
                            <label className="text2 m-b-2">token</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription4 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration4 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType4 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty4 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieLink }: </label></strong>
                            <a href="https://wandelar.be/PrivacyPolicy/">
                                <label className="text2 pointer">{ props.translations[props.lang].privacy.cookieLink4 }</label>
                            </a>
                        </li>
                    </ul>
                </ul>

                <ul>
                    <li className="text1 m-b-1">
                        <label className="text1">{ props.translations[props.lang].privacy.cookie5 }</label>
                    </li>
                    <ul>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieName }: </label></strong>
                            <label className="text2 m-b-2">_gat _UA -57980862 -1</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription51 }</label>
                            <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB">
                                <label className="text2 m-b-2 pointer">{ props.translations[props.lang].privacy.cookieDescription52 }</label>
                            </a>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration5 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType5 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty5 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieLink }: </label></strong>
                            <a href="https://policies.google.com/u/1/privacy">
                                <label className="text2 pointer">{ props.translations[props.lang].privacy.cookieLink5 }</label>
                            </a>
                        </li>
                    </ul>
                </ul>

                <ul>
                    <li className="text1 m-b-1">
                        <label className="text1">{ props.translations[props.lang].privacy.cookie6 }</label>
                    </li>
                    <ul>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieName }: </label></strong>
                            <label className="text2 m-b-2">accept_ gdpr</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription6 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration6 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType6 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty6 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieLink }: </label></strong>
                            <a href="https://wandelar.be/PrivacyPolicy/">
                                <label className="text2 pointer">{ props.translations[props.lang].privacy.cookieLink6 }</label>
                            </a>
                        </li>
                    </ul>
                </ul>

                <ul>
                    <li className="text1 m-b-1">
                        <label className="text1">{ props.translations[props.lang].privacy.cookie7 }</label>
                    </li>
                    <ul>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieName }: </label></strong>
                            <label className="text2 m-b-2">_mm _vid _1008</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription7 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration7 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType7 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty7 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieLink }: </label></strong>
                            <a href="https://wandelar.be/PrivacyPolicy/">
                                <label className="text2 pointer">{ props.translations[props.lang].privacy.cookieLink7 }</label>
                            </a>
                        </li>
                    </ul>
                </ul>

                <ul>
                    <li className="text1 m-b-1">
                        <label className="text1">{ props.translations[props.lang].privacy.cookie8 }</label>
                    </li>
                    <ul>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieName }: </label></strong>
                            <label className="text2 m-b-2">_mm _uid _1008</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDescription8 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieDuration8 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieType8 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty }: </label></strong>
                            <label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieParty8 }</label>
                        </li>
                        <li className="text1 m-b-1">
                            <strong><label className="text2 m-b-2">{ props.translations[props.lang].privacy.cookieLink }: </label></strong>
                            <a href="https://wandelar.be/PrivacyPolicy/">
                                <label className="text2 pointer">{ props.translations[props.lang].privacy.cookieLink8 }</label>
                            </a>
                        </li>
                    </ul>
                </ul>

                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.cookiesText3 }</label>
                <label className="text2 m-b-3">{ props.translations[props.lang].privacy.cookiesText4 }</label>

                <label className="subtitle m-b-2">{ props.translations[props.lang].privacy.ads }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.adsText1 }</label>
                <label className="text2 m-b-3">
                    { props.translations[props.lang].privacy.adsText2 }
                    <a href="https://unity.com/legal/privacy-policy#:~:text=Unity%20may%20collect%20information%2C%20including,related%20technologies%20to%20deliver%20ads">
                        <label className="text2 pointer">{ props.translations[props.lang].privacy.adsText3 }</label>
                    </a>
                </label>

                <label className="subtitle m-b-1">{ props.translations[props.lang].privacy.deletionOfDataTitle }</label>
                <label className="text2">{ props.translations[props.lang].privacy.deletionOfDataText }</label>
                <ul className="m-b-3">
                    <li><label className="text2">{ props.translations[props.lang].privacy.deletionOfDataBullet1 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].privacy.deletionOfDataBullet2 }</label></li>
                    <li><label className="text2">{ props.translations[props.lang].privacy.deletionOfDataBullet3 }</label></li>
                </ul>
             
                <label className="subtitle m-b-2">{ props.translations[props.lang].privacy.supportTitle }</label>
                <label className="text2 m-b-3">{ props.translations[props.lang].privacy.supportText }</label>
               
                <label className="subtitle m-b-2">{ props.translations[props.lang].privacy.contactDetailsTitle }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.contactDetailsText1 }</label>
                <label className="text2 m-b-1">{ props.translations[props.lang].privacy.contactDetailsText2 }</label>
                <label className="text2 m-b-3">{ props.translations[props.lang].privacy.dateLastEdited }</label>

                
                {/* <label className="text2">Text</label>
                <label className="caption">Caption</label>
                <a href="#" target="_blank" className="button-menu d-flex justify-content-start p-0">
                    <label className="text-color-accent-0">Button</label>
                    <div className="icon-white-0 icon-container icon-container-color-bg-accent-0">
                        <PlayIcon />
                    </div>
                </a> */}
            </div>
            <SocialBanner
				lang={props.lang}
				translations={props.translations}
				urls={props.urls}
			/>
            <Footer
				theme={props.theme}
				themes={props.themes}
				lang={props.lang}
				languages={props.languages}
				translations={props.translations}
				changeOption={props.changeOption}
				themeIcons={props.themeIcons}
				flags={props.flags}
				urls={props.urls}
			/>
        </>
	);
}

export default PrivacyPolicy;