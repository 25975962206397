export const sortOptions = [
    {
        id: 0,
        key: "orderType_0",
    },
    {
        id: 1,
        key: "orderType_1"
    },
    {
        id: 2,
        key: "orderType_2"
    },
    {
        id: 3,
        key: "orderType_3"
    }
]