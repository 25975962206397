import { FacebookIcon, InstagramIcon, SnapchatIcon, TiktokIcon, TwitterIcon, YoutubeIcon } from './Icons';

function SocialBanner(props) {

	return (
		<>
			<div className="map">
				<div className="map-bg">

				</div>
				<div className="map-content">
					<div className="row">
						<div className="col-12 d-flex flex-direction-column justify-content align-items w-100">
							<div className="title m-b-2">{props.translations[props.lang].socialbanner.tagline}</div>
							<div className="col-12 d-flex flex-direction-row justify-content align-items w-100 flex-wrap">
								<div className="social-banner p-l-0 p-r-0">
									<a href={props.urls.social.instagram.url} target="_blank">
										<InstagramIcon />
									</a>
								</div>
								<div className="social-banner p-l-0 p-r-0">
									<a href={props.urls.social.facebook.url} target="_blank">
										<FacebookIcon />
									</a>
								</div>
								<div className="social-banner p-l-0 p-r-0">
									<a href={props.urls.social.twitter.url} target="_blank">
										<TwitterIcon />
									</a>
								</div>
								<div className="social-banner p-l-0 p-r-0">
									<a href={props.urls.social.youtube.url} target="_blank">
										<YoutubeIcon />
									</a>
								</div>
								<div className="social-banner p-l-0 p-r-0">
									<a href={props.urls.social.snapchat.url} target="_blank">
										<SnapchatIcon />
									</a>
								</div>
								<div className="social-banner p-l-0 p-r-0">
									<a href={props.urls.social.tiktok.url} target="_blank">
										<TiktokIcon />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SocialBanner;