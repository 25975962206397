
import { useState, useEffect } from 'react';

import { SendIcon, CheckedIcon, UnCheckedIcon, NumberIcon, EmailIcon, PhoneIcon } from './Icons';

import Header from './Header';
import SocialBanner from './SocialBanner';
import Footer from './Footer';

function Pricing(props) {

	const [isBorderN, setBorderN] = useState(false);
	const [isBorderE, setBorderE] = useState(false);
	const [isBorderT, setBorderT] = useState(false);

	function onFocusN () {
		setBorderN(true)
	}
	function onFocusE () {
		setBorderE(true)
	}
	function onFocusT () {
		setBorderT(true)
	}

	function onBlurN () {
		setBorderN(false)
	}
	function onBlurE () {
		setBorderE(false)
	}
	function onBlurT () {
		setBorderT(false)
	}

	function handleThemeClick (key) {
        var orderCopy = {...props.order};
		orderCopy.theme = key;
		props.changeOrder(orderCopy);
    }

	function handleProductClick (key) {
        var orderCopy = {...props.order};

		if (orderCopy.products.includes(key)) {
			// Key found, remove from array
			const index = orderCopy.products.indexOf(key);	
			if (index > -1) {
				orderCopy.products.splice(index, 1);
			}
		} else {
			// Key not found, add to array
			orderCopy.products.push(key);
		}
		props.changeOrder(orderCopy);
    }

	function handleNumberChange (e) {
        var orderCopy = {...props.order}
		orderCopy.participants = e.target.value
        props.changeOrder(orderCopy)
    }

    function handleEmailChange (e) {
        var orderCopy = {...props.order}
        orderCopy.email = e.target.value
        props.changeOrder(orderCopy)
    }

	function handlePhoneChange (e) {
        var orderCopy = {...props.order}
        orderCopy.phone = e.target.value
        props.changeOrder(orderCopy)
    }

	function phoneValidation () {
		let isValid = false;
		let regex = /^[0-9+/ .-]*$/gm;
		const found = props.order.phone.match(regex);
		if (found && (props.order.phone.length > 8 && props.order.phone.length < 20)) {
			isValid = true;
		}
		return isValid;
	}

	function emailValidation() {
		const regex =
		//   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
		return !(!props.order.email || regex.test(props.order.email) === false);
	}

    async function sendOrder () {

		var isError = false;

		// Check participant count
		if ( parseInt(props.order.participants) === 0 ) {
			// Error
			props.addToast({
				bgColor: 'toast-box-error',
				title: props.translations[props.lang].errors.participantsCount.title,
				msg: props.translations[props.lang].errors.participantsCount.msg,
				caption: props.translations[props.lang].errors.participantsCount.caption
			})
			isError = true
		} else if ( props.order.participants > 10000 ) {
			// Error
			props.addToast({
				bgColor: 'toast-box-error',
				title: props.translations[props.lang].errors.participantsExceeds.title,
				msg: props.translations[props.lang].errors.participantsExceeds.msg,
				caption: props.translations[props.lang].errors.participantsExceeds.caption
			})
			isError = true
		} else if ( isNaN(parseInt(props.order.participants))) {
			// Error
			props.addToast({
				bgColor: 'toast-box-error',
				title: props.translations[props.lang].errors.onlyNumbers.title,
				msg: props.translations[props.lang].errors.onlyNumbers.msg,
				caption: props.translations[props.lang].errors.onlyNumbers.caption
			})
			isError = true
		} else if (!emailValidation()) {
			// Error
			props.addToast({
				bgColor: 'toast-box-error',
				title: props.translations[props.lang].errors.email.title,
				msg: props.translations[props.lang].errors.email.msg,
				caption: props.translations[props.lang].errors.email.caption
			})
			isError = true
		} else if (!phoneValidation()) {
			// Error
			props.addToast({
				bgColor: 'toast-box-error',
				title: props.translations[props.lang].errors.phone.title,
				msg: props.translations[props.lang].errors.phone.msg,
				caption: props.translations[props.lang].errors.phone.caption
			})
			isError = true
		}

		if (!isError)
		{
			let preorder = {
				email: props.order.email,
				theme: props.order.theme,
				participants: props.order.participants,
				products: props.order.products,
				phone: props.order.phone,
				settings: {
					lang: props.lang,
					theme: props.theme,
				}
			}

			const preOrderPost = await fetch('https://wandelar.be/api/preorders', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify( preorder )
			}).then(response => {
				let backgroundClass = 'toast-box-error';
				if (response.status === 201) {
					backgroundClass = 'toast-box-success';
					// Reset form
					props.changeOrder({
						email: '',
						phone: '',
						theme: 0,
						participants: 1,
						products: [],
						participantsPrice: 0,
						tier: 0,
						total: 0,
					})
				}
				props.addToast({
					bgColor: backgroundClass,
					title: props.translations[props.lang].messages['msg'+response.status].title,
					msg: props.translations[props.lang].messages['msg'+response.status].msg,
					caption: props.translations[props.lang].messages['msg'+response.status].caption
				})
				return response.json();
			});
		}
    }

	return (
		<>
			<div className="mask-container mask-container-h-500">
				<div className="mask-shape mask-scale-120">
					<div className="mask-box mask-box-color"></div>
				</div>
				<div className="mask-box mask-top content">
				</div>
			</div>
			<Header
				lang={props.lang}
				translations={props.translations}
				isLoading={props.isLoading}
			/>
			<div className="row banner">
				<div className="col-6 d-flex justify-content-start">
					<div className="d-block">
						<h3>{ props.translations[props.lang].pricing.title }</h3>
						<h2>{ props.translations[props.lang].pricing.subtitle }</h2>
					</div>
				</div>
			</div>
			<div className="row pricing-w">
				<div className="col-8 d-flex justify-content">
					<div className="card smooth-shadow">
						<div className="justify-content-start m-b-2">
							<div className="card-content m-b-1">
								<label className="p-l-1">{ props.translations[props.lang].pricing.chooseTheme }</label>
							</div>
							<div className="card-content m-b-1">
								{
									props.priceData.themes.map( (item, i) => {
										return <button
											key={i}
											className={`card-item select ${props.order.theme === i && 'active'} m-b-1`}
											onClick={ () => handleThemeClick(i) }
										>
											<div className="row d-flex flex-direction-row w-100 pointer-none">
												<div className="card-cl-8">
													<div className="card-item-img">
														<img src={item['theme_'+i].image}></img>
													</div>
													<div className="card-item-text thema">
														<div className="card-item-title">
															{ props.translations[props.lang].themes['theme_'+i].name }
														</div>
														<div className="card-item-subtitle">
															{ props.translations[props.lang].themes['theme_'+i].description }
														</div>
													</div>
												</div>
												<div className="card-cl-2">
													<div className="card-item-price">
														€{ item['theme_'+i].price !== null ? item['theme_'+i].price: "--" }
													</div>
												</div>
												<div className="card-cl-2">
													<div className="checked">
														{
															props.order.theme === i ? <CheckedIcon /> : <UnCheckedIcon />
														}
													</div>
												</div>
											</div>
										</button>
									})
								}
							</div>
						</div>
						<div className="justify-content-start">
							<div className="card-content m-b-2">
								<label className="p-l-1">{ props.translations[props.lang].pricing.amountParticipantsAsk }</label>
								<div className={`input-group ${ isBorderN && 'active' } br-8 m-t-1`}>
									<span className={`input-group-text wh-44 p-none`}>
										<NumberIcon />
									</span>
									<input
										autocomplete="off"
										type="number"
										className={`form-control`}
										placeholder={ props.translations[props.lang].pricing.placeholderAmount }
										min="1" max="9999"
										value={props.order.participants}
										onChange={handleNumberChange}
										onFocus={onFocusN}
										onBlur={onBlurN}
									/>
								</div>
								<div className="text-gray m-t-1 p-l-1">
									€{ props.priceData.tiers[props.order.tier]['tier_'+props.order.tier].price } { props.translations[props.lang].pricing.pricePerParticipant }
								</div>
							</div>                                        
						</div>
						<div className="justify-content-start">
							<div className="card-content m-b-1">
								<label className="p-l-1">{ props.translations[props.lang].pricing.extraProducts }</label>
							</div>
							<div className="card-content">
								{
									props.priceData.products.map( (item, i) => {
										return <button
											key={i}
											className={`card-item select ${ props.order.products.includes(i) && 'active' } m-b-1`}
											onClick={ () => handleProductClick(i) }
										>
											<div className="row d-flex flex-direction-row w-100 pointer-none">
												<div className="card-cl-8">
													<div className="card-item-img">
														<img src={item['product_'+i].image}></img>
													</div>
													<div className="card-item-text thema">
														<div className="card-item-title">
															{ props.translations[props.lang].products['product_'+i].name }
														</div>
														<div className="card-item-subtitle">
															{ props.translations[props.lang].products['product_'+i].description }
														</div>
													</div>
												</div>
												<div className="card-cl-2">
													<div className="card-item-price">
														€{ item['product_'+i].price }
													</div>
												</div>
												<div className="card-cl-2">
													<div className="checked">
														{
															props.order.products.includes(i) ? <CheckedIcon /> : <UnCheckedIcon />
														}
													</div>
												</div>
											</div>
										</button>
									})
								}
							</div>
						</div>
					</div>
				</div>
				<div className="col-4 d-flex justify-content">
					<div className="card smooth-shadow">
						<div className="card-content m-b-1">
							<label className="summary">{ props.translations[props.lang].pricing.summary }</label>
						</div>
						<div className="">
							<label className="summary-title">{ props.translations[props.lang].pricing.theme }</label>
						</div>
						<div className="card-content">
							<div className="card-item">
								<div className="card-item-text">
									<div className="card-item-title">
										{ props.translations[props.lang].themes['theme_'+props.order.theme].name }
									</div>
									<div className="card-item-subtitle">
										{ props.translations[props.lang].themes['theme_'+props.order.theme].description }
									</div>
								</div>
								<div className="card-item-price d-flex justify-content-end">
									€{ props.priceData.themes[props.order.theme]['theme_'+props.order.theme].price !== null ? props.priceData.themes[props.order.theme]['theme_'+props.order.theme].price : "--" }
								</div>
							</div>
						</div>
						<div className="">
							<label className="summary-title">{ props.translations[props.lang].pricing.participants.charAt(0).toUpperCase() + props.translations[props.lang].pricing.participants.slice(1) }</label>
						</div>
						<div className="card-content">
							<div className="card-item">
								<div className="card-item-text">
									<div className="card-item-title">
										{ props.translations[props.lang].pricing.amountParticipants }
									</div>
									<div className="card-item-subtitle">
										{ props.order.participants === '' ? 0 : props.order.participants } { props.translations[props.lang].pricing.participants }
									</div>
								</div>
								<div className="card-item-price d-flex justify-content-end">
									€{ props.order.participantsPrice }
								</div>
							</div>
						</div>
						{
							props.order.products.length !== 0 && <div className="">
								<label className="summary-title">{ props.translations[props.lang].pricing.extraProducts }</label>
							</div>
						}
						{
							props.order.products.map( (item, i) => {
								
								return <div key={i} className="card-content">
									<div className="card-item">
										<div className="card-item-text">
											<div className="card-item-title">
												{ props.translations[props.lang].products['product_'+item].name }
											</div>
											<div className="card-item-subtitle">
											{ props.translations[props.lang].products['product_'+item].description }
											</div>
										</div>
										<div className="card-item-price d-flex justify-content-end">
											€{ props.priceData.products[item]['product_'+item].price }
										</div>
									</div>
								</div>
							})
						}
						<div className="card-content">
							<div className="card-total">
								<div className="card-total-text">
									<div className="card-total-title">
										{ props.translations[props.lang].pricing.total }
									</div>
									<div className="card-total-subtitle">
										{ props.translations[props.lang].pricing.vatExcluded }
									</div>
								</div>
								<div className="card-total-price d-flex justify-content-end">
									€{ props.order.total }
								</div>
							</div>
						</div>
						<div className="row d-flex justify-content align-items flex-direction-column">
							<label className="card-send-text">{ props.translations[props.lang].pricing.sendAsk }</label>
						</div>
						
						<p>{ props.translations[props.lang].pricing.email }</p>
						<div className={`input-group ${ isBorderE && 'active' } br-8 m-b-1`}>
							<input
								type="text"
								className={`form-control`}
								placeholder={ props.translations[props.lang].pricing.emailPlaceholder }
								value={props.order.email}
								onChange={handleEmailChange}
								onFocus={onFocusE}
								onBlur={onBlurE}
							/>
							<span className={`input-group-text wh-44 p-none`}>
								<EmailIcon />
							</span>
						</div>

						<p>{ props.translations[props.lang].pricing.phone }</p>
						<div className={`input-group ${ isBorderT && 'active' } br-8 m-b-1`}>
							<input
								type="text"
								className={`form-control`}
								placeholder={ props.translations[props.lang].pricing.phonePlaceholder }
								value={props.order.phone}
								onChange={handlePhoneChange}
								onFocus={onFocusT}
								onBlur={onBlurT}
							/>
							<span className={`input-group-text wh-44 p-none`}>
								<PhoneIcon />
							</span>
						</div>
						
						<div className="button d-flex justify-content-end p-0" onClick={ sendOrder }>
							<label className="text-color-accent-0">{ props.translations[props.lang].pricing.send }</label>
							<div className="icon-white-0 icon-container icon-container-color-bg-accent-0">
								<SendIcon />
							</div>
						</div>
						<div className="row d-flex justify-content-start align-items">
							<label className="card-send-caption">{ props.translations[props.lang].pricing.sendCaption }</label>
						</div>
					</div>
				</div>
			</div>

			<div className="pricing-container pricing-icons m-b-2">
				<div className="pricing-box pricing-box-content">
					<label>{ props.translations[props.lang].pricing.bigtitle }</label>
				</div>
			</div>

			<SocialBanner
				lang={props.lang}
				translations={props.translations}
				urls={props.urls}
			/>
			<Footer
				theme={props.theme}
				themes={props.themes}
				lang={props.lang}
				languages={props.languages}
				translations={props.translations}
				changeOption={props.changeOption}
				themeIcons={props.themeIcons}
				flags={props.flags}
				urls={props.urls}
			/>
		</>
	);
}
export default Pricing;