import Header from './Header';
import SocialBanner from './SocialBanner';
import Footer from './Footer';

function HowItWorks(props) {
	return (
		<>
			<div className="mask-container mask-container-h-500">
				<div className="mask-shape mask-scale-120">
					<div className="mask-box mask-box-color"></div>
				</div>
				<div className="mask-box mask-top content">
				</div>
			</div>
			<Header
				lang={props.lang}
				translations={props.translations}
				isLoading={props.isLoading}
				selected={props.lang}
				options={props.languages}
				changeOption={props.changeOption}
				flags={props.flags}
			/>
			<div className="row banner">
				<div className="col-6 d-flex justify-content-start">
					<div className="d-block">
						<h3>{ props.translations[props.lang].howitworks.title }</h3>
						<h2>{ props.translations[props.lang].howitworks.subtitle }</h2>
					</div>
				</div>
			</div>
			<div className="row pricing-w">
				<div className="col-12 d-flex justify-content">
					<div className="card smooth-shadow">

						<div className="justify-content-start m-b-2">
							<div className="card-content m-b-1">
								<label>{ props.translations[props.lang].howitworks.faq1.title }</label>
							</div>
							<div className="card-content faq m-b-1">
								<p>{ props.translations[props.lang].howitworks.faq1.text1 }</p>
							</div>
						</div>

						<div className="justify-content-start m-b-2">
							<div className="card-content m-b-1">
								<label>{ props.translations[props.lang].howitworks.faq2.title }</label>
							</div>
							<div className="card-content faq m-b-1">
								<p>{ props.translations[props.lang].howitworks.faq2.text1 }</p>
							</div>
						</div>

						<div className="justify-content-start m-b-2">
							<div className="card-content m-b-1">
								<label>{ props.translations[props.lang].howitworks.faq3.title }</label>
							</div>
							<div className="card-content faq m-b-1">
								<p>{ props.translations[props.lang].howitworks.faq3.text1 }</p>
							</div>
						</div>

						<div className="justify-content-start m-b-2">
							<div className="card-content m-b-1">
								<label>{ props.translations[props.lang].howitworks.faq4.title }</label>
							</div>
							<div className="card-content faq m-b-1">
								<p>{ props.translations[props.lang].howitworks.faq4.text1 }</p>
							</div>
						</div>
						
					</div>
				</div>
				
			</div>

			<div className="pricing-container pricing-icons m-b-2">
				<div className="pricing-box pricing-box-content">
					<label>{ props.translations[props.lang].howitworks.bigtitle }</label>
				</div>
			</div>
			<SocialBanner
				lang={props.lang}
				translations={props.translations}
				urls={props.urls}
			/>
			<Footer
				theme={props.theme}
				themes={props.themes}
				lang={props.lang}
				languages={props.languages}
				translations={props.translations}
				changeOption={props.changeOption}
				themeIcons={props.themeIcons}
				flags={props.flags}
				urls={props.urls}
			/>
		</>
	);
}
export default HowItWorks;