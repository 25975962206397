import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SpringSystem, MathUtil } from 'rebound';

export default class SpringScrollbars extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);
        this.state = {
            top: 0,
            thumbColor: props.thumbcolor,
            thumbColorBg: props.thumbcolorbg,
            padding: props.padding
        };
        this.handleSpringUpdate = this.handleSpringUpdate.bind(this);
        this.renderView = this.renderView.bind(this);
        this.renderThumb = this.renderThumb.bind(this);
    }

    componentDidMount() {
        this.springSystem = new SpringSystem();
        this.spring = this.springSystem.createSpring();
        this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate });

    }

    componentWillUnmount() {
        this.springSystem.deregisterSpring(this.spring);
        this.springSystem.removeAllListeners();
        this.springSystem = undefined;
        this.spring.destroy();
        this.spring = undefined;
    }

    getScrollTop() {
        return this.refs.scrollbars.getScrollTop();
    }

    getScrollHeight() {
        return this.refs.scrollbars.getScrollHeight();
    }

    getHeight() {
        return this.refs.scrollbars.getHeight();
    }

    scrollTo(top) {
        // const { scrollbars } = this.refs;
        // const scrollTop = scrollbars.getScrollTop();
        // const scrollHeight = scrollbars.getScrollHeight();
        // const val = MathUtil.mapValueInRange(top, 0, scrollHeight, scrollHeight * 0, scrollHeight * 0);
        // console.log(val)
        // this.spring.setCurrentValue(scrollTop).setAtRest();
        this.spring.setEndValue(top);
    }

    handleSpringUpdate(spring) {
        const { scrollbars } = this.refs;
        const val = spring.getCurrentValue();
        scrollbars.scrollTop(val);
    }

    renderView({ style, ...props }) {
        const { top, padding } = this.state;
        const viewStyle = {
            // padding: 15,
            // backgroundColor: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(top * 255)}, ${Math.round(255)})`,
            // color: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))})`
            marginBottom: '0px',
        };
        return (
            <div
                className={`box ${padding}`}
                style={{ ...style, ...viewStyle }}
                {...props}/>
        );
    }

    renderThumb({ style, ...props }) {
        const { top, thumbColor, padding } = this.state;
        const thumbStyle = {
            borderRadius: '10px',
            right: '-4px',
            width: '4px',
            backgroundColor: thumbColor
        };
        return (
            <div
                className={`${thumbColor}`}
                style={{ ...style, ...thumbStyle }}
                {...props}/>
        );
    }
    render() {
        return (
            <Scrollbars
                {...this.props}
                ref="scrollbars"
                renderView={this.renderView}
                renderThumbHorizontal={this.renderThumb}
                renderThumbVertical={this.renderThumb}
            />
        );
    }
}