import { useState, useRef, useEffect } from 'react';
import { NavLink as Link, useNavigate, useParams } from "react-router-dom";

import { EmailIcon, LockIcon, RegisterIcon, CheckedIcon, UnCheckedIcon, EyeIcon, EyeSlashIcon } from './Icons';

import Header from './Header';
import SocialBanner from './SocialBanner';
import Footer from './Footer';

function Register(props) {

	let { lang } = useParams();

    useEffect ( () => {
        if (lang) {
            props.changeOption(lang, "lang");
        }
    }, [])

	const navigate = useNavigate();

	const passwordInput = useRef();
	const [isHidden, setIsHidden] = useState(true);

	const [isBorderE, setBorderE] = useState(false);
	const [isBorderP, setBorderP] = useState(false);
	const [isBorderF, setBorderF] = useState(false);
	const [isBorderL, setBorderL] = useState(false);

	function onFocusE() {
		setBorderE(true)
	}
	function onFocusP() {
		setBorderP(true)
	}
	function onFocusF() {
		setBorderF(true)
	}
	function onFocusL() {
		setBorderL(true)
	}

	function onBlurE() {
		setBorderE(false)
	}
	function onBlurP() {
		setBorderP(false)
	}
	function onBlurF() {
		setBorderF(false)
	}
	function onBlurL() {
		setBorderL(false)
	}

	function handleFirstnameChange(e) {
		var registerCopy = { ...props.register };
		registerCopy.firstname = e.target.value;
		props.changeRegister(registerCopy);
	}

	function handleLastnameChange(e) {
		var registerCopy = { ...props.register };
		registerCopy.lastname = e.target.value;
		props.changeRegister(registerCopy);
	}

	function handleEmailChange(e) {
		var registerCopy = { ...props.register };
		registerCopy.email = e.target.value.toLowerCase();
		props.changeRegister(registerCopy);
	}

	function handlePasswordChange(e) {
		var registerCopy = { ...props.register };
		registerCopy.password = e.target.value;
		props.changeRegister(registerCopy);
	}

	function handleConfirmClick(e) {
		var registerCopy = { ...props.register };
		registerCopy.privacy = !registerCopy.privacy;
		registerCopy.terms = !registerCopy.terms;
		props.changeRegister(registerCopy);
	}

	function handleIsHiddenClick() {
		const type = passwordInput.current.getAttribute("type") === "password" ? "text" : "password";
		passwordInput.current.setAttribute("type", type);
		setIsHidden(!isHidden);
	}

	async function register() {

		let register = {
			email: props.register.email,
			password: props.register.password,
			privacy: props.register.privacy ? "true" : "false",
			terms: props.register.terms ? "true" : "false",
			firstname: props.register.firstname,
			lastname: props.register.lastname,
			lang: props.lang,
			theme: props.theme
		}

		const result = await fetch('https://wandelar.be/api/user/register', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(register)
		}).then(response => {
			return response.json();
		}).then(data => {
			console.log(data)
			let backgroundClass = 'toast-box-error';
			if (data.status === 201) {
				backgroundClass = 'toast-box-success';
				// Reset form
				props.changeRegister({
					email: '',
					password: '',
					privacy: false,
					terms: false,
					firstname: '',
					lastname: ''
				})
				navigate('/Login');
			}
			props.addToast({
				bgColor: backgroundClass,
				title: props.translations[props.lang].register.messages['msg' + data.status].title,
				msg: props.translations[props.lang].register.messages['msg' + data.status].msg,
				caption: props.translations[props.lang].register.messages['msg' + data.status].caption
			})
		}).catch(error => {
			console.log(error)
		});

	}

	return (
		<>
			<div className="mask-container mask-container-h-500">
				<div className="mask-shape mask-scale-120">
					<div className="mask-box mask-box-color"></div>
				</div>
				<div className="mask-box mask-top content">
				</div>
			</div>
			<Header
				lang={props.lang}
				translations={props.translations}
				isLoading={props.isLoading}
				selected={props.lang}
				options={props.languages}
				changeOption={props.changeOption}
				flags={props.flags}
			/>
			<div className="row banner">
				<div className="col-6 d-flex justify-content-start">
					<div className="d-block">
						<h3>{props.translations[props.lang].register.title}</h3>
						<h2 className="p-0">{props.translations[props.lang].register.subtitle}</h2>
					</div>
				</div>
			</div>
			<div className="row pricing-w d-flex justify-content">
				<div className="col-6 m-0">
					<div className="card-form smooth-shadow p-1">

						<div className="row">
							<div className="col-5 p-r-1">
								<p>{props.translations[props.lang].register.firstname}</p>
								<div className={`input-group ${isBorderF && 'active'} br-8 m-b-1`}>
									<input
										autoComplete="off"
										type="text"
										className={`form-control`}
										placeholder={props.translations[props.lang].register.firstnamePlaceholder}
										value={props.register.firstname}
										onChange={handleFirstnameChange}
										onFocus={onFocusF}
										onBlur={onBlurF}
									/>
								</div>
							</div>
							<div className="col-7">
								<p>{props.translations[props.lang].register.lastname}</p>
								<div className={`input-group ${isBorderL && 'active'} br-8 m-b-1`}>
									<input
										autocomplete="off"
										type="text"
										className={`form-control`}
										placeholder={props.translations[props.lang].register.lastnamePlaceholder}
										value={props.register.lastname}
										onChange={handleLastnameChange}
										onFocus={onFocusL}
										onBlur={onBlurL}
									/>
								</div>
							</div>
						</div>

						<p>{props.translations[props.lang].register.email}</p>
						<div className={`input-group ${isBorderE && 'active'} br-8 m-b-1`}>
							<input
								autocomplete="off"
								type="text"
								className={`form-control`}
								placeholder={props.translations[props.lang].register.emailPlaceholder}
								value={props.register.email}
								onChange={handleEmailChange}
								onFocus={onFocusE}
								onBlur={onBlurE}
							/>
							<span className={`input-group-text wh-44 p-none`}>
								<EmailIcon />
							</span>
						</div>

						<p>{props.translations[props.lang].register.password}</p>
						<div className={`input-group ${isBorderP && 'active'} br-8 m-b-1`}>
							<input
								autocomplete="off"
								ref={passwordInput}
								type="password"
								className={`form-control`}
								placeholder={props.translations[props.lang].register.passwordPlaceholder}
								value={props.register.password}
								onChange={handlePasswordChange}
								onFocus={onFocusP}
								onBlur={onBlurP}
							/>
							{
								props.register.password !== '' ? <span className={`input-group-text wh-44 p-none pointer`} onClick={handleIsHiddenClick} >
									{
										isHidden ? <EyeIcon /> : <EyeSlashIcon />
									}
								</span>
									:
									<span className={`input-group-text wh-44 p-none pointer`}>
										<LockIcon />
									</span>
							}
						</div>
						<button
							className={`card-item select ${(props.register.privacy === true && props.register.terms === true) && 'active'} m-b-1`}
							onClick={() => handleConfirmClick()}
						>

							<div className="d-flex justify-content-start align-items">
								<div className="">
									{
										(props.register.privacy === true && props.register.terms === true) ? <CheckedIcon /> : <UnCheckedIcon />
									}
								</div>
								<label className="text-color-gray text-align-start p-3 pointer-none">
									{props.translations[props.lang].register.declaration1}
									<Link to="/PrivacyPolicy" className="text-color-accent-0 pointer-auto p-l-0 p-r-0">
										{props.translations[props.lang].register.privacyPolicy}
									</Link>
									{props.translations[props.lang].register.declaration2}
									<Link to="/TermsAndConditions" className="text-color-accent-0 pointer-auto p-l-0 p-r-0">
										{props.translations[props.lang].register.terms}
									</Link>
									{props.translations[props.lang].register.declaration3}
								</label>
							</div>
						</button>

						<div className="d-flex flex-direction-row justify-content m-b-2"
							onClick={register}>
							<div className="button d-flex justify-content-end p-0">
								<label className="text-color-accent-0">{props.translations[props.lang].register.button}</label>
								<div className="icon-white-0 icon-container icon-container-color-bg-accent-0">
									<RegisterIcon />
								</div>
							</div>
						</div>

						<div className="d-flex flex-direction-row justify-content m-t-1 register-caption">
							<label className="color-icon p-l-1">{props.translations[props.lang].register.accountAsk}</label>
							<Link to="/Login" className="register-agree-url pointer-auto p-l-1">
								<label className="pointer"> {props.translations[props.lang].register.loginHere} </label>
							</Link>
						</div>

					</div>
				</div>

			</div>

			<div className="pricing-container pricing-icons m-b-2">
				<div className="pricing-box pricing-box-content">
					<label>{props.translations[props.lang].register.bigtitle}</label>
				</div>
			</div>

			<SocialBanner
				lang={props.lang}
				translations={props.translations}
				urls={props.urls}
			/>
			<Footer
				theme={props.theme}
				themes={props.themes}
				lang={props.lang}
				languages={props.languages}
				translations={props.translations}
				changeOption={props.changeOption}
				themeIcons={props.themeIcons}
				flags={props.flags}
				urls={props.urls}
			/>
		</>
	);
}
export default Register;