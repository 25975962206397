import { useState, useContext } from 'react';
import Menu from './Menu';

import { UserContext } from '../Hooks/UserContext';

function Invoices(props) {

	const { user } = useContext(UserContext);

	return (
		<>
			<Menu 
				lang={props.lang}
				translations={props.translations}
				isMenu={props.isMenu}
				toggleMenu={props.toggleMenu}
			/>
			<div className="page-box home_content">
				<div className="title">{ props.translations[props.lang].invoices.title }</div>
				<p className="subtitle">{ props.translations[props.lang].invoices.subtitle }</p>
			</div>
		</>
	);
}
export default Invoices;