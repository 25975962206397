import { useState } from 'react';
import { EmailIcon, SendIcon } from './Icons';

import Header from './Header';
import SocialBanner from './SocialBanner';
import Footer from './Footer';

function Forgot(props) {

	const [isBorderE, setBorderE] = useState(false);

	function onFocusE () {
		setBorderE(true)
	}

	function onBlurE () {
		setBorderE(false)
	}

	function handleEmailChange (e) {
		var forgotCopy = {...props.forgot}
        forgotCopy.email = e.target.value
        props.changeForgot(forgotCopy)
	}

	async function forgot () {

		let forgot = {
			email: props.forgot.email,
			lang: props.lang,
			theme: props.theme
		}

		const result = await fetch('https://wandelar.be/api/user/forgot', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify( forgot )
		}).then(response => {
			return response.json();
		}).then(data => {
			console.log(data)
			let backgroundClass = 'toast-box-error';
			if (data.status === 200) {
				backgroundClass = 'toast-box-success';
				// Reset form
				props.changeForgot({
					email: ''
				})
			}
			props.addToast({
				bgColor: backgroundClass,
				title: props.translations[props.lang].forgot.messages['msg'+data.status].title,
				msg: props.translations[props.lang].forgot.messages['msg'+data.status].msg,
				caption: props.translations[props.lang].forgot.messages['msg'+data.status].caption
			})
		}).catch(error => {
			console.log(error)
		});
		
    }


	return (
		<>
			<div className="mask-container mask-container-h-500">
				<div className="mask-shape mask-scale-120">
					<div className="mask-box mask-box-color"></div>
				</div>
				<div className="mask-box mask-top content">
				</div>
			</div>
			<Header
				lang={props.lang}
				translations={props.translations}
				isLoading={props.isLoading}
				selected={props.lang}
				options={props.languages}
				changeOption={props.changeOption}
				flags={props.flags}
			/>
			<div className="row banner">
				<div className="col-6 d-flex justify-content-start">
					<div className="d-block">
						<h3>{ props.translations[props.lang].forgot.title }</h3>
						<h2>{ props.translations[props.lang].forgot.subtitle }</h2>
					</div>
				</div>
			</div>
			<div className="row pricing-w d-flex justify-content">
				<div className="col-6 m-1">
					<div className="card-form smooth-shadow p-1">

						<p>{ props.translations[props.lang].forgot.email }</p>
						<div className={`input-group ${ isBorderE && 'active' } br-8 m-b-2`}>
							<input
								type="text"
								className={`form-control`}
								placeholder={ props.translations[props.lang].forgot.emailPlaceholder }
								value={props.forgot.email}
								onChange={handleEmailChange}
								onFocus={onFocusE}
								onBlur={onBlurE}
							/>
							<span className={`input-group-text wh-44 p-none`}>
								<EmailIcon />
							</span>
						</div>

						<div className="d-flex flex-direction-row justify-content m-b-2"
							onClick={ forgot }>
							<div className="button d-flex justify-content-end p-0">
								<label className="text-color-accent-0">{ props.translations[props.lang].forgot.button }</label>
								<div className="icon-white-0 icon-container icon-container-color-bg-accent-0">
									<SendIcon />
								</div>
							</div>
						</div>

					</div>
				</div>
				
			</div>

			<div className="pricing-container pricing-icons m-b-2">
				<div className="pricing-box pricing-box-content">
					<label>{ props.translations[props.lang].forgot.bigtitle }</label>
				</div>
			</div>

			<SocialBanner
				lang={props.lang}
				translations={props.translations}
				urls={props.urls}
			/>
			<Footer
				theme={props.theme}
				themes={props.themes}
				lang={props.lang}
				languages={props.languages}
				translations={props.translations}
				changeOption={props.changeOption}
				themeIcons={props.themeIcons}
				flags={props.flags}
				urls={props.urls}
			/>
		</>
	);
}
export default Forgot;