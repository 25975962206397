import { LoadingIcon } from './Icons'

function Loading(props) {
	return (
		<>
			<div className="loading-container">
                <div className="row d-flex justify-content align-items">
                    <div className="col-12">
                        <LoadingIcon />
                        <div className="">{ props.translations[props.lang].loading.title }</div> 
                    </div>
                </div>
            </div>
        </>
	);
}
export default Loading;