import { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import Menu from './Menu';
import Loading from './Loading';

import { UserContext } from '../Hooks/UserContext';

import SpringScrollbars from './SpringScrollbars';

import Select from './Select';

import { ArrowIcon, EditIcon, SaveIcon, DismissIcon, PublishedIcon, NotPublishedIcon, PlayIcon, StopIcon, NumberIcon, UploadIcon, InfoIcon, ImageIcon, LoadingIcon, LockIcon, ClockIcon, RefreshIcon, MarkerIcon, StartPath, OkIcon, ErrorEmoji, BlockedIcon, TrashIcon, NewTabIcon, CopyIcon } from './Icons';

import $ from 'jquery';
import html2canvas from 'html2canvas';
import TagsInput from './TagsInput';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import './calendar.css';

import MapStatic from './MapStatic';

import Dropzone from "./Dropzone/Dropzone";

import _ from "lodash";

import './uploader.css';

let host = 'https://wandelar.be';

function Course(props) {

	const navigate = useNavigate();

	const { user, setUser } = useContext(UserContext);

	const { id } = useParams();

	const [isLoading, setIsLoading] = useState(false);

	const homeTopDiv = useRef();
	const [scrollHeight, setScrollHeight] = useState(0);
	const [scrollHeightUpload, setScrollHeightUpload] = useState(0);

	const divSize = 120;
	const [divHeight, setDivHeight] = useState(window.innerWidth <= 769 ? divSize : 0);

	const scrollbars = useRef();

	const [isEdit, setIsEdit] = useState({ status: false, type: 'initiate' });

	const [isBorderR, setBorderR] = useState(null);

	const [isBorderN_us_en, setBorderN_us_en] = useState(null);
	const [isBorderN_be_nl, setBorderN_be_nl] = useState(null);
	const [isBorderN_be_fr, setBorderN_be_fr] = useState(null);

	const description_us_en = useRef();
	const description_be_nl = useRef();
	const description_be_fr = useRef();
	const [isBorderD_us_en, setBorderD_us_en] = useState(null);
	const [isBorderD_be_nl, setBorderD_be_nl] = useState(null);
	const [isBorderD_be_fr, setBorderD_be_fr] = useState(null);
	const [descriptionTextareaheight_us_en, setDescriptionTextareaheight_us_en] = useState(null);
	const [descriptionTextareaheight_be_nl, setDescriptionTextareaheight_be_nl] = useState(null);
	const [descriptionTextareaheight_be_fr, setDescriptionTextareaheight_be_fr] = useState(null);

	const [isBorderUsersCurrent, setBorderUsersCurrent] = useState(null);
	const [isBorderUsersTotal, setBorderUsersTotal] = useState(null);

	const [isBorderA_min, setBorderA_min] = useState(null);
	const [isBorderA_max, setBorderA_max] = useState(null);

	const [isSponsorLogoUploading, setIsSponsorLogoUploading] = useState(null);
	const [isBorderU_name, setBorderU_name] = useState(null);
	const [isBorderU_url, setBorderU_url] = useState(null);
	const [isBorderS_name, setBorderS_name] = useState(null);
	const [isBorderS_url, setBorderS_url] = useState(null);

	const [isBorderAd_street, setBorderAd_street] = useState(null);
	const [isBorderAd_number, setBorderAd_number] = useState(null);
	const [isBorderAd_postal, setBorderAd_postal] = useState(null);
	const [isBorderAd_city, setBorderAd_city] = useState(null);
	const [isBorderAd_region, setBorderAd_region] = useState(null);
	const [isBorderAd_country, setBorderAd_country] = useState(null);

	const address_custom_us_en = useRef();
	const address_custom_be_nl = useRef();
	const address_custom_be_fr = useRef();
	const [isBorderAd_custom_us_en, setBorderAd_custom_us_en] = useState(null);
	const [isBorderAd_custom_be_nl, setBorderAd_custom_be_nl] = useState(null);
	const [isBorderAd_custom_be_fr, setBorderAd_custom_be_fr] = useState(null);
	const [address_customTextareaheight_us_en, setAddress_customTextareaheight_us_en] = useState(null);
	const [address_customTextareaheight_be_nl, setAddress_customTextareaheight_be_nl] = useState(null);
	const [address_customTextareaheight_be_fr, setAddress_customTextareaheight_be_fr] = useState(null);

	const [isBorderP, setBorderP] = useState(null);
	const [isBorderDi, setBorderDi] = useState(null);

	const estimation_h = useRef();
	const estimation_m = useRef();
	const [isBorderEh, setBorderEh] = useState(null);
	const [isBorderEm, setBorderEm] = useState(null);

	const [isIconLogoUploading, setIsIconLogoUploading] = useState(null);

	const [isBorderT, setBorderT] = useState(null);

	const [isCropActive, setIsCropActive] = useState(false);

	const [isBack, setIsBack] = useState(false);

	const [dates, setDates] = useState({
		selection: {
			startDate: props.courseNew.start === null ? null : new Date(props.courseNew.start),
			endDate: props.courseNew.end === null ? null : new Date(props.courseNew.end),
			key: 'selection'
		}
		// Add new selection range here
	});

	let imagesObj = [];
	for (var i = 0; i < props.courseNew.images.length; i++) {
		imagesObj.push({
			id: i,
			url: props.courseNew.images[i].url,
			state: props.courseNew.images[i].state,
			type: 'url',
			cropper: {
				isCropping: false,
				scale: 1,
				rotate: 0,
				crop: {
					unit: "%",
					x: 0,
					y: 0,
					width: 0,
					height: 0
				}
			}
		});
	}

	const [validFiles, setValidFiles] = useState(imagesObj);
	const [selectedFiles, setSelectedFiles] = useState(imagesObj);
	const [unsupportedFiles, setUnsupportedFiles] = useState([]);
	const [removedFiles, setRemovedFiles] = useState([]);

	const [uploadBox, setUploadBox] = useState(false);

	const [confirmDeleteBox, setConfirmDeleteBox] = useState(false);

	// Focus
	function onFocusR() {
		setBorderR(true)
	}

	function onFocusN_us_en() {
		setBorderN_us_en(true)
	}

	function onFocusN_be_nl() {
		setBorderN_be_nl(true)
	}

	function onFocusN_be_fr() {
		setBorderN_be_fr(true)
	}

	function onFocusD_us_en() {
		setBorderD_us_en(true)
	}

	function onFocusD_be_nl() {
		setBorderD_be_nl(true)
	}

	function onFocusD_be_fr() {
		setBorderD_be_fr(true)
	}

	function onFocusUsersCurrent() {
		setBorderUsersCurrent(true)
	}

	function onFocusUsersTotal() {
		setBorderUsersTotal(true)
	}

	function onFocusA_min() {
		setBorderA_min(true)
	}

	function onFocusA_max() {
		setBorderA_max(true)
	}

	function onFocusU_name() {
		setBorderU_name(true)
	}

	function onFocusU_url() {
		setBorderU_url(true)
	}

	function onFocusS_name() {
		setBorderS_name(true)
	}

	function onFocusS_url() {
		setBorderS_url(true)
	}

	function onFocusAd_street() {
		setBorderAd_street(true)
	}

	function onFocusAd_number() {
		setBorderAd_number(true)
	}

	function onFocusAd_postal() {
		setBorderAd_postal(true)
	}

	function onFocusAd_city() {
		setBorderAd_city(true)
	}

	function onFocusAd_region() {
		setBorderAd_region(true)
	}

	function onFocusAd_country() {
		setBorderAd_country(true)
	}

	function onFocusAd_custom_us_en() {
		setBorderAd_custom_us_en(true)
	}

	function onFocusAd_custom_be_nl() {
		setBorderAd_custom_be_nl(true)
	}

	function onFocusAd_custom_be_fr() {
		setBorderAd_custom_be_fr(true)
	}

	function onFocusP() {
		setBorderP(true)
	}

	function onFocusDi() {
		setBorderDi(true)
	}

	function onFocusEh() {
		setBorderEh(true)
	}

	function onFocusEm() {
		setBorderEm(true)
	}

	function onFocusT() {
		setBorderT(true)
	}

	// Blur
	function onBlurR() {
		setBorderR(false)
	}

	function onBlurN_us_en() {
		setBorderN_us_en(false)
	}

	function onBlurN_be_nl() {
		setBorderN_be_nl(false)
	}

	function onBlurN_be_fr() {
		setBorderN_be_fr(false)
	}

	function onBlurD_us_en() {
		setBorderD_us_en(false)
	}

	function onBlurD_be_nl() {
		setBorderD_be_nl(false)
	}

	function onBlurD_be_fr() {
		setBorderD_be_fr(false)
	}

	function onBlurUsersCurrent() {
		setBorderUsersCurrent(false)
	}

	function onBlurUsersTotal() {
		setBorderUsersTotal(false)
	}

	function onBlurA_min() {
		setBorderA_min(false)
	}

	function onBlurA_max() {
		setBorderA_max(false)
	}

	function onBlurU_name() {
		setBorderU_name(false)
	}

	function onBlurU_url() {
		setBorderU_url(false)
	}

	function onBlurS_name() {
		setBorderS_name(false)
	}

	function onBlurS_url() {
		setBorderS_url(false)
	}

	function onBlurAd_street() {
		setBorderAd_street(false)
	}

	function onBlurAd_number() {
		setBorderAd_number(false)
	}

	function onBlurAd_postal() {
		setBorderAd_postal(false)
	}

	function onBlurAd_city() {
		setBorderAd_city(false)
	}

	function onBlurAd_region() {
		setBorderAd_region(false)
	}

	function onBlurAd_country() {
		setBorderAd_country(false)
	}

	function onBlurAd_custom_us_en() {
		setBorderAd_custom_us_en(false)
	}

	function onBlurAd_custom_be_nl() {
		setBorderAd_custom_be_nl(false)
	}

	function onBlurAd_custom_be_fr() {
		setBorderAd_custom_be_fr(false)
	}

	function onBlurP() {
		setBorderP(false)
	}

	function onBlurDi() {
		setBorderDi(false)
	}

	function onBlurEh() {
		setBorderEh(false)
	}

	function onBlurEm() {
		setBorderEm(false)
	}

	function onBlurT() {
		setBorderT(true)
	}

	// Handle input change
	function handleRouteChange(e) {
		var courseNewCopy = { ...props.courseNew }
		courseNewCopy.route = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleNameChange_us_en(e) {
		var courseNewCopy = { ...props.courseNew }
		courseNewCopy.name["us_en"] = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleNameChange_be_nl(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.name["be_nl"] = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleNameChange_be_fr(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.name["be_fr"] = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleDescriptionChange_us_en(e) {

		setDescriptionTextareaheight_us_en(description_us_en.current.scrollHeight);

		var courseNewCopy = { ...props.courseNew }
		courseNewCopy.description["us_en"] = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleDescriptionChange_be_nl(e) {

		setDescriptionTextareaheight_be_nl(description_be_nl.current.scrollHeight);

		var courseNewCopy = { ...props.courseNew }
		props.courseNew.description["be_nl"] = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleDescriptionChange_be_fr(e) {

		// const height = e.target.scrollHeight;
		// console.log(height)
		// const rowHeight = 20; 
		// const trows = Math.ceil(height / rowHeight);
		// console.log(trows)

		// setDescriptionTextareaheight_be_fr(trows);
		setDescriptionTextareaheight_be_fr(description_be_fr.current.scrollHeight);

		var courseNewCopy = { ...props.courseNew }
		props.courseNew.description["be_fr"] = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleUsersCurrentChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.users.current = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleUsersTotalChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.users.total = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleAgeMinChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.age.min = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleAgeMaxChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.age.max = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleUrlNameChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.url.name = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleUrlUrlChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.url.url = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleSponsorNameChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.sponsor.name = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleSponsorUrlChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.sponsor.url = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function uploadSponsorLogo(e) {
		console.log('upload sponsor logo')
		setIsSponsorLogoUploading(true);

		setTimeout(() => {
			setIsSponsorLogoUploading(false);
		}, 2000);
	}

	function uploadIconLogo(e) {
		console.log('upload icon logo')
		setIsIconLogoUploading(true);

		setTimeout(() => {
			setIsIconLogoUploading(false);
		}, 2000);
	}

	function removeImage(imageStr) {
		if (imageStr === 'sponsor') {
			var courseNewCopy = { ...props.courseNew }
			props.courseNew.sponsor.logo = '';
			props.changeCourseNew(courseNewCopy)
		} else if (imageStr === 'icon') {
			var courseNewCopy = { ...props.courseNew }
			courseNewCopy.icon = '';
			props.changeCourseNew(courseNewCopy)
		}
	}

	function togglePin() {
		var courseNewCopy = { ...props.courseNew }
		courseNewCopy.pin = !props.courseNew.pin
		props.changeCourseNew(courseNewCopy)
	}

	function toggleWheelchair() {
		var courseNewCopy = { ...props.courseNew }
		courseNewCopy.wheelchair = !props.courseNew.wheelchair
		props.changeCourseNew(courseNewCopy)
	}

	function toggleStroller() {
		var courseNewCopy = { ...props.courseNew }
		courseNewCopy.stroller = !props.courseNew.stroller
		props.changeCourseNew(courseNewCopy)
	}

	function toggleDog() {
		var courseNewCopy = { ...props.courseNew }
		courseNewCopy.dog = !props.courseNew.dog
		props.changeCourseNew(courseNewCopy)
	}

	function toggleFree() {
		var courseNewCopy = { ...props.courseNew }
		courseNewCopy.free = !props.courseNew.free
		props.changeCourseNew(courseNewCopy)
	}

	function toggleAds() {
		var courseNewCopy = { ...props.courseNew }
		courseNewCopy.ads = !props.courseNew.ads
		props.changeCourseNew(courseNewCopy)
	}

	function toggleSkip() {
		var courseNewCopy = { ...props.courseNew }
		courseNewCopy.skip = !props.courseNew.skip
		props.changeCourseNew(courseNewCopy)
	}

	function toggleTime() {
		var courseNewCopy = { ...props.courseNew }
		courseNewCopy.time = !props.courseNew.time
		props.changeCourseNew(courseNewCopy)
	}

	function handleAddressStreetChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.address.street = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleAddressNumberChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.address.number = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleAddressPostalChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.address.postal = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleAddressCityChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.address.city = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleAddressRegionChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.address.region = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleAddressCountryChange(e) {
		var courseNewCopy = { ...props.courseNew }
		props.courseNew.address.country = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleAddressCustomChange_us_en(e) {

		setAddress_customTextareaheight_us_en(address_custom_us_en.current.scrollHeight);

		var courseNewCopy = { ...props.courseNew }
		props.courseNew.address.custom["us_en"] = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleAddressCustomChange_be_nl(e) {

		setAddress_customTextareaheight_be_nl(address_custom_be_nl.current.scrollHeight);

		var courseNewCopy = { ...props.courseNew }
		props.courseNew.address.custom["be_nl"] = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleAddressCustomChange_be_fr(e) {

		setAddress_customTextareaheight_be_fr(address_custom_be_fr.current.scrollHeight);

		var courseNewCopy = { ...props.courseNew }
		props.courseNew.address.custom["be_fr"] = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handlePriceChange(e) {
		var courseNewCopy = { ...props.courseNew }
		if (e.target.value === '') {
			courseNewCopy.price = parseFloat(0)
		} else {
			courseNewCopy.price = parseFloat(e.target.value)
		}

		props.changeCourseNew(courseNewCopy)
	}

	function handleDistanceChange(e) {
		var courseNewCopy = { ...props.courseNew }
		courseNewCopy.distance = e.target.value
		props.changeCourseNew(courseNewCopy)
	}

	function handleEstimationHourChange(e) {
		let value = e.target.value;
		var courseNewCopy = { ...props.courseNew }
		if (estimation_h.current.value === '') {
			value = 0;
		}
		courseNewCopy.estimation = convertToSeconds(parseInt(value), 0) + convertToSeconds(0, parseInt(estimation_m.current.value));
		props.changeCourseNew(courseNewCopy)
	}

	function handleEstimationMinuteChange(e) {
		let value = e.target.value;
		var courseNewCopy = { ...props.courseNew }
		if (estimation_m.current.value === '') {
			value = 0;
		}
		courseNewCopy.estimation = convertToSeconds(0, parseInt(value)) + convertToSeconds(parseInt(estimation_h.current.value), 0);
		props.changeCourseNew(courseNewCopy)
	}

	function setEventDate(item) {
		setDates(item);

		var courseNewCopy = { ...props.courseNew };
		if (item.selection.startDate === null) {
			courseNewCopy.start = null;
		} else {
			courseNewCopy.start = item.selection.startDate.toISOString();
		}

		if (item.selection.endDate === null) {
			courseNewCopy.end = null;
		} else {
			courseNewCopy.end = item.selection.endDate.toISOString();
		}
		props.changeCourseNew(courseNewCopy);
	}

	function clearDates() {
		setDates({
			selection: {
				startDate: null,
				endDate: null,
				key: 'selection'
			}
			// Add new selection range here
		});

		var courseNewCopy = { ...props.courseNew };
		courseNewCopy.start = null;
		courseNewCopy.end = null;
		props.changeCourseNew(courseNewCopy);

	}

	const convertUTC = utcDate => {

		const date = new Date(utcDate);

		const dayIndex = date.getDate();
		const dayName = props.translations[props.lang].days[date.getDay()];

		const monthIndex = date.getMonth(); // Be careful! January is 0, not 1
		const monthName = props.translations[props.lang].months[monthIndex];
		const year = date.getFullYear();
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		const seconds = date.getSeconds().toString().padStart(2, '0');

		const dateString = `${dayName}, ${dayIndex} ${monthName} ${year}`;
		const timeString = `${hours}h ${minutes}m ${seconds}s`;

		return [dateString, timeString];
	}

	const onCopy = useCallback((value) => {
		props.addToast({
			bgColor: 'toast-box-success',
			title: props.translations[props.lang].course.copied.title,
			msg: value,
			caption: props.translations[props.lang].course.copied.caption
		})
	}, [])

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		setScrollHeight(window.innerHeight - homeTopDiv.current.clientHeight - 40);
		setScrollHeightUpload(window.innerHeight - 192);

		setDescriptionTextareaheight_us_en(description_us_en.current.scrollHeight);
		setDescriptionTextareaheight_be_nl(description_be_nl.current.scrollHeight);
		setDescriptionTextareaheight_be_fr(description_be_fr.current.scrollHeight);

		setAddress_customTextareaheight_us_en(address_custom_us_en.current.scrollHeight);
		setAddress_customTextareaheight_be_nl(address_custom_be_nl.current.scrollHeight);
		setAddress_customTextareaheight_be_fr(address_custom_be_fr.current.scrollHeight);

		setIsBack(true);

		// cleanup this component
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [isEdit]);

	// function getByValue2(arr, value) {
	// 	var result  = arr.filter(function(o){return o._id == value;} );
	// 	return result? result[0] : null; // or undefined
	// }

	function handleResize(e) {
		let divMenuHeight = 0;
		if (window.innerWidth <= 769) {
			divMenuHeight = divSize
		}
		setScrollHeight(window.innerHeight - homeTopDiv.current.clientHeight - 50);
		setScrollHeightUpload(window.innerHeight - 192);
	}

	const back = () => {
		navigate(-1)
	}

	const edit = () => {
		setIsEdit({ status: true, type: 'edit' })

		// Reset range picker
		setDates({
			selection: {
				startDate: props.courseNew.start === null ? null : new Date(props.courseNew.start),
				endDate: props.courseNew.end === null ? null : new Date(props.courseNew.end),
				key: 'selection'
			}
			// Add new selection range here
		})

		let imagesObj = [];
		for (var i = 0; i < props.courseNew.images.length; i++) {
			imagesObj.push({
				id: i,
				url: props.courseNew.images[i].url,
				type: 'url',
				state: props.courseNew.images[i].state,
				cropper: {
					isCropping: false,
					scale: 1,
					rotate: 0,
					crop: {
						unit: "%",
						x: 0,
						y: 0,
						width: 0,
						height: 0
					}
				}
			});
		}
		setSelectedFiles(imagesObj);

	}

	// console.log(user)

	const save = async () => {

		let error = false;

		if (props.courseNew.checkpoints.length < 3) {
			error = true;
			props.addToast({
				bgColor: 'toast-box-error',
				title: props.translations[props.lang].course.saveRoute.minCheckpoint.title,
				msg: props.translations[props.lang].course.saveRoute.minCheckpoint.msg,
				caption: props.translations[props.lang].course.saveRoute.minCheckpoint.caption
			})
		}

		for (var i = 0; i < selectedFiles.length; i++) {
			if (selectedFiles[i].invalid) {
				let message = '';
				if (selectedFiles[i].invalid.filetype === true) {
					error = true;
					message = props.translations[props.lang].course.saveRoute.invalid.msg;
					props.addToast({
						bgColor: 'toast-box-error',
						title: props.translations[props.lang].course.saveRoute.invalid.title,
						msg: props.translations[props.lang].course.saveRoute.invalid.msg,
						caption: props.translations[props.lang].course.saveRoute.invalid.caption
					})
				}
				// if (selectedFiles[i].invalid.filesize === true) {
				// 	error = true;
				// 	props.addToast({
				// 		bgColor: 'toast-box-error',
				// 		title: props.translations[props.lang].course.saveRoute.invalid.title,
				// 		msg: message + '. ' + props.translations[props.lang].course.upload.unsupportedSize,
				// 		caption: props.translations[props.lang].course.saveRoute.invalid.caption
				// 	})
				// }
			}
		}

		if (!error) {
			saveRouteData();
		} else {
			return false;
		}
	}

	const saveRouteData = async () => {

		// Prepare image array
		let filesToUpload = [];
		let croppedFilesToUpload = [];
		let filesArrayToDatabase = [];
		for (var i = 0; i < selectedFiles.length; i++) {

			let url = '';
			let status = 'pending';
			let state = '';
			let thumbnail = '';
			let square = '';
			if (selectedFiles[i].type === 'url') {
				status = 'completed';
				url = selectedFiles[i].url;
				state = selectedFiles[i].state;
				thumbnail = selectedFiles[i].thumbnail;
				square = selectedFiles[i].square;
			} else {
				filesToUpload.push(selectedFiles[i]);

				let newFile = selectedFiles[i].croppedFile;
				newFile["base64"] = selectedFiles[i].base64;
				newFile["cropped"] = selectedFiles[i].cropped;
				newFile["cropper"] = selectedFiles[i].cropper;
				newFile["id"] = selectedFiles[i].id;
				newFile["state"] = selectedFiles[i].state;

				croppedFilesToUpload.push(selectedFiles[i].croppedFile)
			}

			filesArrayToDatabase.push({
				id: i,
				status: status,
				url: url,
				state: state,
				thumbnail: thumbnail,
				square: square
			});
		}

		var courseNewCopy = { ...props.courseNew };
		courseNewCopy.images = filesArrayToDatabase;

		let newCheckpoints = []
		for (var i = 0; i < courseNewCopy.checkpoints.length; i++) {

			newCheckpoints.push({
				geo: {
					type: "Point",
					coordinates: [parseFloat(courseNewCopy.checkpoints[i].geo.coordinates[0]), parseFloat(courseNewCopy.checkpoints[i].geo.coordinates[1])]
				},
				qr: ""
			});
		}
		courseNewCopy.checkpoints = newCheckpoints;
		courseNewCopy["filesToRemove"] = removedFiles;

		// Save to database
		const res = await fetch(host + '/api/routes/save', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${user.token}`
			},
			body: JSON.stringify(courseNewCopy)
		}).then(response => {
			return response.json();
		}).then(result => {
			if (result.status === 200) {

				var courseNewCopy = { ...props.courseNew };
				courseNewCopy.checkpoints = result.data.checkpoints;
				courseNewCopy.images = result.data.images;
				props.changeCourseNew(courseNewCopy);

				// Save state in app
				var courseCopy = { ...result.data };
				courseCopy.user = {
					_id: courseCopy.user
				};
				const dataCloneNew = JSON.parse(JSON.stringify(courseCopy));
				props.changeCourse(dataCloneNew);

				setRemovedFiles([]);

				setIsEdit({ status: false, type: 'save' });

				// Save images
				if (filesToUpload.length > 0) {
					isUploadBox(true);
					uploadAndTrackFiles(croppedFilesToUpload);
				}

				// Update app state with new data
				let promises = []
				if (user.roles.includes('admin')) {
					const resetRoutes = props.resetRoutes();
					promises.push(resetRoutes);
				}
				const resetRoutesUser = props.resetRoutesUser();
				promises.push(resetRoutesUser);

				Promise.all(promises).then((values) => {
					// console.log('Route updated')
				});

			} else if (result.status === 400) {
				props.addToast({
					bgColor: 'toast-box-error',
					title: 'Session',
					msg: 'Your session expired. Please login.',
					caption: 'Expired'
				})
				// Logout user and redirect to login
				setUser({ user: '', status: 404 });
				navigate('/Login');
			} else if (result.status === 304) {
				console.log(result);
			} else {
				props.addToast({
					bgColor: 'toast-box-error',
					title: 'Error',
					msg: 'Something went wrong while updating the route data. Please try again!',
					caption: 'Update route data'
				})
			}

		}).catch(error => {
			console.log(error);
		});
	}

	const deleteRoute = async (routeData) => {

		let objToDelete = {
			userId: routeData.user._id,
			routeId: routeData._id,
			images: routeData.images
		}

		// Delete route
		const res = await fetch(host + '/api/routes/delete', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${user.token}`
			},
			body: JSON.stringify(objToDelete)
		}).then(response => {
			return response.json();
		}).then(result => {

			let promises = []
			if (user.roles.includes('admin')) {
				const resetRoutes = props.resetRoutes();
				promises.push(resetRoutes);
			}
			const resetRoutesUser = props.resetRoutesUser();
			promises.push(resetRoutesUser);

			Promise.all(promises).then((values) => {
				navigate(-1);
			});

		}).catch(error => {
			console.log(error);
		});
	}

	const cancel = () => {

		const dataClone = JSON.parse(JSON.stringify(props.course));
		props.changeCourseNew(dataClone);
		setIsEdit({ status: false, type: 'cancel' });

		setDescriptionTextareaheight_us_en(description_us_en.current.scrollHeight);
		setDescriptionTextareaheight_be_nl(description_be_nl.current.scrollHeight);
		setDescriptionTextareaheight_be_fr(description_be_fr.current.scrollHeight);

		let imagesObj = [];
		for (var i = 0; i < props.course.images.length; i++) {
			imagesObj.push({
				id: i,
				url: props.course.images[i].url,
				type: 'url',
				state: props.course.images[i].state
			});
		}

		setValidFiles(imagesObj);
		setUnsupportedFiles([]);
		setRemovedFiles([]);
	}

	const publish = async (status) => {

		let publishObj = {
			_id: props.courseNew._id,
			publish: status
		}
		// Save to database
		const result = await fetch(host + '/api/routes/publish', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${user.token}`
			},
			body: JSON.stringify(publishObj)
		}).then(response => {
			return response.json();
		}).then(result => {

			if (result.status === 200) {
				// Save state in app
				const dataCloneNew = JSON.parse(JSON.stringify(result.data));
				props.changeCourse(dataCloneNew)

				// Update app state with new data
				let promises = []
				if (user.roles.includes('admin')) {
					const resetRoutes = props.resetRoutes();
					promises.push(resetRoutes);
				}
				const resetRoutesUser = props.resetRoutesUser();
				promises.push(resetRoutesUser);

				Promise.all(promises).then((values) => {
					// console.log('Route updated')
				});
			} else if (result.status === 400) {
				props.addToast({
					bgColor: 'toast-box-error',
					title: 'Session',
					msg: 'Your session expired. Please login.',
					caption: 'Expired'
				})
				// Logout user and redirect to login
				setUser({ user: '', status: 404 });
				navigate('/Login');
			} else if (result.status === 304) {
				console.log(result);
			} else {
				props.addToast({
					bgColor: 'toast-box-error',
					title: 'Error',
					msg: 'Something went wrong while updating the route data. Please try again!',
					caption: 'Update route data'
				})
			}

		}).catch(error => {
			console.log(error)
		});
	}

	const download = async (svgString, name, fileType, element) => {

		let svg_file = new Blob([svgString], { type: "image/svg+xml" });

		const svgSize = { width: 512, height: 512 };

		const reader = new FileReader();
		reader.readAsText(svg_file);
		reader.onload = async (evt) => {
			const markup = reader.result;
			let svgDoc = new DOMParser().parseFromString(markup, 'image/svg+xml');
			const unit = 'px';
			$("svg", svgDoc).attr({
				'width': svgSize.width + unit,
				'height': svgSize.height + unit
			});
			const newMarkup = new XMLSerializer().serializeToString(svgDoc);

			let newDataURL;
			if (fileType === 'png') {
				element.current.src = `data:image/svg+xml;utf8,${encodeURIComponent(newMarkup)}`;
				const canvas = await html2canvas(element.current);
				newDataURL = canvas.toDataURL('image/' + fileType);
			} else if (fileType === 'svg') {
				const newBlob = new Blob([newMarkup], { type: "image/svg+xml" });
				newDataURL = URL.createObjectURL(newBlob);
			}

			const link = document.createElement('a');
			let dateCreated = formatDate(new Date());

			if (typeof link.download === 'string') {
				link.href = newDataURL;
				link.download = `${dateCreated.date}-${dateCreated.time}-${name}.${fileType}`;

				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} else {
				window.open(newDataURL);
			}
		};
	};

	function formatDate(date) {
		return {
			date: date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2),
			time: ('0' + (date.getHours())).slice(-2) + '-' + ('0' + (date.getMinutes())).slice(-2) + '-' + ('0' + (date.getSeconds())).slice(-2)
		};
	}

	function convertSecondsToHrsMinSec(sec) {
		let hours = Math.floor(sec / 3600);
		let minutes = Math.floor((sec % 3600) / 60);
		let seconds = sec % 60;
		return {
			hours: hours,
			minutes: minutes,
			seconds: seconds
		}
	}

	function convertToSeconds(hours, minutes) {
		return hours * 3600 + minutes * 60;
	}

	const uploadFiles = (() => {

		const fileRequests = new WeakMap();
		const ENDPOINTS = {
			UPLOAD: host + '/api/routes/images/upload',
			UPLOAD_STATUS: host + '/api/routes/images/upload/status',
			UPLOAD_REQUEST: host + '/api/routes/images/upload/request'
		}
		const defaultOptions = {
			url: ENDPOINTS.UPLOAD,
			startingByte: 0,
			fileId: '',
			userId: props.courseNew.user._id,
			routeId: props.courseNew._id,
			onAbort() { },
			onProgress() { },
			onError() { },
			onComplete() { }
		};

		const uploadFileChunks = (file, options) => {

			const formData = new FormData();
			const req = new XMLHttpRequest();
			const chunk = file.slice(options.startingByte);

			formData.append('chunk', chunk, file.name);
			formData.append('fileId', options.fileId);
			formData.append('userId', options.userId);
			formData.append('routeId', options.routeId);
			formData.append('imageIndex', file.id);
			formData.append('imageState', file.state);

			req.open('POST', options.url, true);

			// req.setRequestHeader('Access-Control-Allow-Origin', 'http://localhost:5000'); // For testing, remove for production
			req.setRequestHeader('Content-Range', `bytes=${options.startingByte}-${options.startingByte + chunk.size}/${file.size}`);
			req.setRequestHeader('X-File-Id', options.fileId);
			req.setRequestHeader('X-User-Id', options.userId);
			req.setRequestHeader('X-Route-Id', options.routeId);
			req.setRequestHeader('X-Image-Index', file.id);
			req.setRequestHeader('X-Image-State', file.state);

			req.setRequestHeader('Authorization', `Bearer ${user.token}`);

			req.onload = (e) => {
				// it is possible for load to be called when the request status is not 200
				// this will treat 200 only as success and everything else as failure
				if (req.status === 200) {
					options.onComplete(e, file);
				} else {
					options.onError(e, file);
				}
			}

			req.upload.onprogress = (e) => {
				const loaded = options.startingByte + e.loaded;
				options.onProgress({
					...e,
					loaded,
					total: file.size,
					percentage: (loaded * 100 / file.size)
				}, file);
			}

			req.ontimeout = (e) => options.onError(e, file);

			req.onabort = (e) => options.onAbort(e, file);

			req.onerror = (e) => options.onError(e, file);

			fileRequests.get(file).request = req;

			req.send(formData);
		};

		const uploadFile = (file, options) => {
			return fetch(ENDPOINTS.UPLOAD_REQUEST, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${user.token}`,
				},
				body: JSON.stringify({
					fileName: file.name,
					userId: props.courseNew.user._id,
					routeId: props.courseNew._id,
					fileId: null
				})
			})
				.then(res => res.json())
				.then(res => {
					options = { ...options, ...res };
					fileRequests.set(file, { request: null, options });
					uploadFileChunks(file, options);
				})
				.catch(e => {
					console.log(e)
					options.onError({ ...e, file })
				})
		}

		const abortFileUpload = async file => {
			const fileReq = fileRequests.get(file);

			if (fileReq && fileReq.request) {
				fileReq.request.abort();
				return true;
			}

			return false;
		};

		const retryFileUpload = file => {
			const fileReq = fileRequests.get(file);

			if (fileReq) {
				// try to get the status just in case it failed mid upload
				return fetch(`${ENDPOINTS.UPLOAD_STATUS}/${file.name}/${fileReq.options.fileId}/${props.courseNew.user._id}/${props.courseNew._id}`, {
					method: 'GET',
					credentials: 'same-origin',
					headers: {
						'Authorization': `Bearer ${user.token}`
					}
				})
					.then(res => res.json())
					.then(res => { // if uploaded we continue
						uploadFileChunks(file, { ...fileReq.options, startingByte: Number(res.totalChunkUploaded) });
					})
					.catch(() => { // if never uploaded we start
						// console.log(fileReq.options)
						uploadFileChunks(file, fileReq.options)
					})
			}
		};

		const clearFileUpload = async file => {
			const fileReq = fileRequests.get(file);

			if (fileReq) {
				await abortFileUpload(file);
				fileRequests.delete(file);

				return true;
			}

			return false;
		};

		const resumeFileUpload = file => {
			const fileReq = fileRequests.get(file);
			if (fileReq) {
				return fetch(`${ENDPOINTS.UPLOAD_STATUS}/${file.name}/${fileReq.options.fileId}/${props.courseNew.user._id}/${props.courseNew._id}`, {
					method: 'GET',
					credentials: 'same-origin',
					headers: {
						'Authorization': `Bearer ${user.token}`
					}
				})
					.then(res => res.json())
					.then(res => {
						uploadFileChunks(file, { ...fileReq.options, startingByte: Number(res.totalChunkUploaded) });
					})
					.catch(e => {
						fileReq.options.onError({ ...e, file })
					})
			}
		}

		return (files, options = defaultOptions) => {
			[...files].forEach(file => uploadFile(file, { ...defaultOptions, ...options }));

			return {
				abortFileUpload,
				retryFileUpload,
				clearFileUpload,
				resumeFileUpload
			}
		}
	})();

	const progressBox = useRef();
	const totalProgressBar = useRef();
	const fileProgressWrapper = useRef();
	const uploadAndTrackFiles = (() => {
		const files = new Map();
		const FILE_STATUS = {
			PENDING: 'pending',
			UPLOADING: 'uploading',
			PAUSED: 'paused',
			COMPLETED: 'completed',
			FAILED: 'failed'
		}
		let uploader = null;

		const updateProgressBox = () => {

			// const [title, uploadProgress, expandBtn, progressBar] = progressBox.current.children;
			const [title, uploadProgress] = progressBox.current.children;
			const progressBar = totalProgressBar.current;

			if (files.size > 0) {
				let totalUploadedFiles = 0;
				let totalUploadingFiles = 0;
				let totalFailedFiles = 0;
				let totalPausedFiles = 0;
				let totalChunkSize = 0;
				let totalUploadedChunkSize = 0;
				// const [uploadedPerc, successCount, failedCount, pausedCount] = uploadProgress.children;

				files.forEach(fileObj => {
					if (fileObj.status === FILE_STATUS.FAILED) {
						totalFailedFiles += 1;
					} else {
						if (fileObj.status === FILE_STATUS.COMPLETED) {
							totalUploadedFiles += 1;
						} else if (fileObj.status === FILE_STATUS.PAUSED) {
							totalPausedFiles += 1;
						} else {
							totalUploadingFiles += 1;
						}

						totalChunkSize += fileObj.size;
						totalUploadedChunkSize += fileObj.uploadedChunkSize;
					}
				});

				const percentage = totalChunkSize > 0 ? Math.min(100, Math.round((totalUploadedChunkSize * 100) / totalChunkSize)) : 0;

				totalProgressBar.current.style.backgroundColor = percentage === 100 && "#5BD672";

				title.textContent = percentage === 100
					? `${props.translations[props.lang].course.upload.uploaded} ${totalUploadedFiles} ${totalUploadedFiles !== 1 ? props.translations[props.lang].course.upload.files : props.translations[props.lang].course.upload.file}`
					: `${props.translations[props.lang].course.upload.uploading} ${totalUploadingFiles}/${files.size} ${files.size !== 1 ? props.translations[props.lang].course.upload.files : props.translations[props.lang].course.upload.file}`;
				// uploadedPerc.textContent = `${percentage}%`;
				uploadProgress.textContent = `${percentage}%`;
				// successCount.textContent = totalUploadedFiles;
				// failedCount.textContent = totalFailedFiles;
				// pausedCount.textContent = totalPausedFiles;
				progressBar.style.width = `${percentage}%`;
				// progressBox.style.backgroundSize = `${percentage}%`;
				// expandBtn.style.display = 'inline-block';
				uploadProgress.style.display = 'block';
				progressBar.style.display = 'block';
			} else {
				title.textContent = props.translations[props.lang].course.upload.noUploadProgress
				// expandBtn.style.display = 'none';
				uploadProgress.style.display = 'none';
				progressBar.style.display = 'none';
			}
		}
		const updateFileElement = fileObject => {
			const [
				{ children: [{ children: [status] }, progressBar] }, // .file-details
				{ children: [retryBtn, pauseBtn, resumeBtn, clearBtn] } // .file-actions
			] = fileObject.element.children;

			requestAnimationFrame(() => {
				status.textContent = fileObject.status === FILE_STATUS.COMPLETED ? fileObject.status : `${Math.round(fileObject.percentage)}%`;
				status.className = `status ${fileObject.status}`;
				progressBar.style.width = fileObject.percentage + '%';
				progressBar.style.background = fileObject.status === FILE_STATUS.COMPLETED
					? '#5BD672' : fileObject.status === FILE_STATUS.FAILED
						? '#FF438C' : '#4F46F8';
				// pauseBtn.style.display = fileObject.status === FILE_STATUS.UPLOADING ? 'inline-block' : 'none';
				// retryBtn.style.display = fileObject.status === FILE_STATUS.FAILED ? 'inline-block' : 'none';
				// resumeBtn.style.display = fileObject.status === FILE_STATUS.PAUSED ? 'inline-block' : 'none';
				// clearBtn.style.display = fileObject.status === FILE_STATUS.COMPLETED || fileObject.status === FILE_STATUS.PAUSED
				// 	? 'inline-block' : 'none';
				updateProgressBox();
			});
		}

		const setFileElement = (file) => {

			const extIndex = file.name.lastIndexOf('.');
			const fileElement = document.createElement('div');
			fileElement.className = 'file-progress m-t-0 p-4';

			fileElement.innerHTML = `
	            <div class="file-details" style="position: relative;">
	                <p>
	                    <span class="status">pending</span>
	                    <span class="file-name">${file.name.substring(0, extIndex)}</span>
	                    <span class="file-ext">${file.name.substring(extIndex)}</span>
	                </p>
	                <div class="progress-bar" style="width: 0;"></div>
	            </div>
	            <div class="file-actions">
	                <button type="button" class="retry-btn" style="display: none;">Retry</button>
	                <button type="button" class="cancel-btn" style="display: none;">Pause</button>
	                <button type="button" class="resume-btn" style="display: none;">Resume</button>
	                <button type="button" class="clear-btn" style="display: none;">Clear</button>
	            </div>
	        `;
			files.set(file, {
				element: fileElement,
				size: file.size,
				status: FILE_STATUS.PENDING,
				percentage: 0,
				uploadedChunkSize: 0
			});

			const [_, { children: [retryBtn, pauseBtn, resumeBtn, clearBtn] }] = fileElement.children;

			clearBtn.addEventListener('click', () => {
				uploader.clearFileUpload(file);
				files.delete(file);
				fileElement.remove();
				updateProgressBox();
			});
			retryBtn.addEventListener('click', () => uploader.retryFileUpload(file));
			pauseBtn.addEventListener('click', () => uploader.abortFileUpload(file));
			resumeBtn.addEventListener('click', () => uploader.resumeFileUpload(file));

			fileProgressWrapper.current.appendChild(fileElement);
		}

		const onComplete = (e, file) => {
			const fileObj = files.get(file);

			fileObj.status = FILE_STATUS.COMPLETED;
			fileObj.percentage = 100;

			updateFileElement(fileObj);

			// Update app state with new data
			let promises = []
			if (user.roles.includes('admin')) {
				const resetRoutes = props.resetRoutes();
				promises.push(resetRoutes);
			}
			const resetRoutesUser = props.resetRoutesUser();
			promises.push(resetRoutesUser);

			Promise.all(promises).then((values) => {
				// console.log('Route updated')
			});

			const getUserRoute = fetch(host + '/api/routes/' + props.courseNew._id, {
				method: 'GET',
				credentials: 'same-origin'
			}).then(response => {
				return response.json()
			});

			Promise.all([getUserRoute]).then((response) => {
				let imagesObj = [];
				for (var i = 0; i < response[0].images.length; i++) {
					imagesObj.push({
						id: i,
						url: response[0].images[i].url,
						type: 'url',
						state: response[0].images[i].state
					});
				}

				setValidFiles(imagesObj);
				setSelectedFiles(imagesObj);

				var courseNewCopy = { ...props.courseNew };
				courseNewCopy.images = response[0].images;
				props.changeCourseNew(courseNewCopy);
			});
		}

		const onProgress = (e, file) => {
			const fileObj = files.get(file);

			fileObj.status = FILE_STATUS.UPLOADING;
			fileObj.percentage = e.percentage;
			fileObj.uploadedChunkSize = e.loaded;

			updateFileElement(fileObj);
		}

		const onError = (e, file) => {
			const fileObj = files.get(file);

			fileObj.status = FILE_STATUS.FAILED;
			fileObj.percentage = 100;

			updateFileElement(fileObj);

			// Update app state with new data
			let promises = []
			if (user.roles.includes('admin')) {
				const resetRoutes = props.resetRoutes();
				promises.push(resetRoutes);
			}
			const resetRoutesUser = props.resetRoutesUser();
			promises.push(resetRoutesUser);

			Promise.all(promises).then((values) => {
				// console.log('Route updated')
			});

			const getUserRoute = fetch(host + '/api/routes/' + props.courseNew._id, {
				method: 'GET',
				credentials: 'same-origin'
			}).then(response => {
				return response.json()
			});

			Promise.all([getUserRoute]).then((response) => {
				let imagesObj = [];
				for (var i = 0; i < response[0].images.length; i++) {
					imagesObj.push({
						id: i,
						url: response[0].images[i].url,
						type: 'url',
						state: response[0].images[i].state
					});
				}

				setValidFiles(imagesObj);
				setSelectedFiles(imagesObj);

				var courseNewCopy = { ...props.courseNew };
				courseNewCopy.images = response[0].images;
				props.changeCourseNew(courseNewCopy);
			});
		}

		const onAbort = (e, file) => {
			const fileObj = files.get(file);

			fileObj.status = FILE_STATUS.PAUSED;

			updateFileElement(fileObj);
		}

		return (uploadedFiles) => {
			[...uploadedFiles].forEach(setFileElement);

			uploader = uploadFiles(uploadedFiles, {
				onProgress,
				onError,
				onAbort,
				onComplete
			});
		}
	})();

	function isUploadBox(value) {
		while (fileProgressWrapper.current.firstChild) {
			fileProgressWrapper.current.removeChild(fileProgressWrapper.current.lastChild);
		}
		totalProgressBar.current.style.width = "0%";
		totalProgressBar.current.style.backgroundColor = "#4F46F8";
		setUploadBox(value);
	}

	const getFilenameFromUrl = (string) => {
		let match = string.match(/([^\/]+$)/gm);
		const firstIndex = match[0].indexOf('-');
		const secondIndex = match[0].indexOf('-', firstIndex + 1);
		const trimmedStr = match[0].substring(secondIndex + 1);
		return trimmedStr
	}

	const obscureString = (string, character) => {
		const [part1, part2] = string.split(character);
		return `${part1}${character}${part2[0]}${new Array(4).join(".")}`;
	};

	return (
		<>
			<Menu
				lang={props.lang}
				translations={props.translations}
				isMenu={props.isMenu}
				toggleMenu={props.toggleMenu}
			/>
			<div className="page-box home_content page-p-l">

				<>
					<div ref={homeTopDiv} className="home_content_top">
						<div className="w-100 d-flex flex-direction-row justify-content-space-between route-title">

							{
								isBack && <div className="d-flex flex-direction-row"
									onClick={back}
								>
									<div className="button d-flex justify-content-start p-0">
										<div className="d-flex align-items justify-content rotate-270 icon-color icon-container icon-container-color-bg-2">
											<ArrowIcon />
										</div>
										<label className="text text-color-white-0">{props.translations[props.lang].course.back}</label>
									</div>
								</div>
							}

							<div className="w-100 m-l-1 d-flex align-items">
								<div className="title">
									<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderR && 'active'} br-8`}>
										<input
											type="text"
											className={`form-control title text-end fs-3`}
											placeholder={props.translations[props.lang].course.routePlaceholder}
											value={props.courseNew.route === null ? undefined : props.courseNew.route}
											onChange={handleRouteChange}
											onFocus={onFocusR}
											onBlur={onBlurR}
											disabled={isEdit.status ? false : true}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="d-flex flex-direction-row justify-content-space-between route-title m-b-0">
							{
								isEdit.status ?
									<div className="d-flex flex-direction-row"
										onClick={cancel}
									>
										<div className="button d-flex justify-content-end p-0">
											<div className="rotate-270 icon-color icon-container icon-container-color-bg-2">
												<DismissIcon />
											</div>
											<label className="text text-color-gray">{props.translations[props.lang].course.cancel}</label>
										</div>
									</div>
									:
									<div className="d-flex flex-direction-row"
										onClick={edit}
									>
										<div className="button d-flex justify-content-end p-0">
											<div className="icon-color icon-container icon-container-color-bg-2">
												<EditIcon />
											</div>
											<label className="text-color-gray">{props.translations[props.lang].course.edit}</label>
										</div>
									</div>
							}

							<div className="d-flex flex-direction-column">
								<div className="publish d-flex flex-direction-row justify-content p-0">
									<label className={`${props.course.publish && 'active'}`} >{props.course.publish ? props.translations[props.lang].course.published : props.translations[props.lang].course.notPublished}</label>
									<div className="icon-color icon-container icon-container-color-bg-1">
										{
											props.course.publish === true ? <div className="pulseBlue"></div> : <div className="dot-gray"></div>
										}
									</div>
								</div>
							</div>

							{
								isEdit.status ?
									<div className="d-flex flex-direction-row"
										onClick={save}
									>
										<div className="button d-flex justify-content-end p-0">
											<label className="text-color-accent-0">{props.translations[props.lang].course.save}</label>
											<div className="icon-container icon-container-color-bg-accent-0">
												<SaveIcon />
											</div>
										</div>
									</div>
									:
									props.courseNew.checkpoints.length > 2 &&
									<>
										{
											!props.course.publish ?
												<div className="d-flex flex-direction-row"
													onClick={() => publish(true)}
												>
													<div className="button d-flex justify-content-end p-0">
														<label className="text-color-white-0">{props.translations[props.lang].course.publish}</label>
														<div className="icon-container icon-container-color-bg-accent-0">
															<PlayIcon />
														</div>
													</div>
												</div>
												:
												<div className="d-flex flex-direction-row"
													onClick={() => publish(false)}
												>
													<div className="button d-flex justify-content-end p-0">
														<label className="text-color-white-0">{props.translations[props.lang].course.stop}</label>
														<div className="icon-container icon-container-color-bg-accent-1">
															<StopIcon />
														</div>
													</div>
												</div>
										}
									</>
							}
						</div>
					</div>
					<div className="overflow-hidden">
						<SpringScrollbars
							ref={scrollbars}
							style={{
								backgroundColor: 'transparent',
								height: scrollHeight,
								zIndex: 0
							}}
							thumbcolor='rgba(116, 125, 137, 0.2)'
							thumbcolorbg='thumbColorBg'
						>
							<div className="card-form-configurator p-5">
								<div className="row m-b-1">
									<div className="col-12">
										<>
											<div className="row">
												{
													props.course.publish === true && <>
														<div className="d-flex flex-direction-row align-items m-b-0 tooltip-down">
															<div className="text">{props.translations[props.lang].course.share}</div>
															<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.shareExplain}`}>
																<InfoIcon />
															</div>
														</div>
														<div className="d-flex flex-direction-row align-items justify-content-space-between tooltip-down">
															<div className="d-flex flex-direction-row align-items justify-content-start ellipsis">
																<div className="profile-url fs-15 text-color">{`wandelar.be/c/${props.courseNew.user.username}/${id}`}</div>
															</div>
															<div className="d-flex flex-direction-row align-items justify-content-end">
																<a href={`https://wandelar.be/c/${props.courseNew.user.username}/${id}`} target="_blank">
																	<div className="dropzone-button bg-2 justify-content-end m-l-0">
																		<div className="icon-color icon-container icon-container-color-bg-2">
																			<NewTabIcon />
																		</div>
																	</div>
																</a>
																<CopyToClipboard onCopy={onCopy} text={`https://wandelar.be/c/${props.courseNew.user.username}/${id}`}>
																	<div className="dropzone-button bg-2 justify-content-end m-l-0">
																		<div className="icon-color icon-container icon-container-color-bg-2">
																			<CopyIcon />
																		</div>
																	</div>
																</CopyToClipboard>
															</div>

														</div>
													</>
												}
												{
													props.courseNew.type === 'gps' ?
														<>
															<MapStatic
																courseNew={props.courseNew}
																changeCourseNew={props.changeCourseNew}
																isEdit={isEdit}
																type={props.courseNew.type}
																checkpoints={props.courseNew.checkpoints}
																lang={props.lang}
																translations={props.translations}
																addToast={props.addToast}
																elevation={props.courseNew.elevation}
																area={props.courseNew.area}
																mapboxAccessToken={props.mapboxAccessToken}
															/>
														</>
														:
														<>
															QR code route checkpoint list
														</>
												}
											</div>
										</>


									</div>
								</div>
								<div className="row m-b-1">
									<div className="col-12">
										<div className="d-flex flex-direction-row align-items m-b-0 tooltip-down">
											<div className="text">{props.translations[props.lang].course.images}</div>
											<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.imagesExplain}`}>
												<InfoIcon />
											</div>
										</div>
										{
											validFiles.length === 0 ?
												<>
													<div className="noRoutesFound m-b-1 p-4">
														<ErrorEmoji />
														<label className="title m-b-1">
															{props.translations[props.lang].course.noImagesTitle} </label>
														<label className="subtitle m-b-0">
															{props.translations[props.lang].course.noImagesSubtitle} </label>
														<label className="subtitle">
															{props.translations[props.lang].course.noImagesText1} </label>
														<label className="subtitle">
															{props.translations[props.lang].course.noImagesText2} </label>
													</div>
												</>
												:
												<>
												</>
										}
										{
											isEdit.status && <Dropzone
												dropzoneId={"dropzone_images"}
												lang={props.lang}
												translations={props.translations}
												removedFiles={removedFiles}
												setRemovedFiles={setRemovedFiles}
												selectedFiles={selectedFiles}
												setSelectedFiles={setSelectedFiles}
												validFiles={validFiles}
												setValidFiles={setValidFiles}
												unsupportedFiles={unsupportedFiles}
												setUnsupportedFiles={setUnsupportedFiles}
												allowedWidth={1005} // 
												allowedHeight={734} //
												isEdit={isEdit}
												homeTopDiv={homeTopDiv}
											/>
										}

										{
											!isEdit.status &&
											<div className="row">
												{
													props.courseNew.images.map((data, i) => {
														return <div key={`img-${i}`} className="route-box-3 p-3 m-b-0">
															{
																data.status === 'completed' &&
																<div className="d-flex flex-direction-column justify-content align-items w-100">
																	<div className="images m-b-0">
																		<img src={data.url} />
																	</div>
																	{
																		data.state === 'icon' && <div className="w-100 text-color-gray ellipsis fs-15 text-center">
																			{props.translations[props.lang].course.iconImage}
																		</div>
																	}
																	{
																		data.state === 'sponsor' && <div className="w-100 text-color-gray ellipsis fs-15 text-center">
																			{props.translations[props.lang].course.sponsor}
																		</div>
																	}
																	{
																		data.state === 'image' && <div className="w-100 text-color-gray ellipsis fs-15 text-center">
																			{props.translations[props.lang].course.carousel} {i}
																		</div>
																	}
																</div>
															}
															{
																data.status === 'pending' &&
																<div className="images">
																	<LoadingIcon />
																</div>
															}
															{
																data.status === 'failed' &&
																<div className="images">
																	<ErrorEmoji />
																</div>
															}
															{
																data.status === 'blocked' &&
																<div className="images">
																	<BlockedIcon />
																</div>
															}
														</div>
													})
												}
											</div>
										}
									</div>
								</div>

								<div className="row m-b-2">
									<div className="col-6 m-b-1">
										<div className="text m-b-0">{props.translations[props.lang].course.date.created}</div>
										<div className="caption course d-flex flex-direction-row align-items">
											<ClockIcon />
											<label className="text-date fs-1">{convertUTC(props.course.createdAt)[0]}</label>
										</div>
									</div>
									<div className="col-6">
										<div className="text m-b-0">{props.translations[props.lang].course.date.updated}</div>
										<div className="caption course d-flex flex-direction-row align-items">
											<ClockIcon />
											<label className="text-date fs-1">{convertUTC(props.course.updatedAt)[0]}</label>
										</div>
									</div>
								</div>

								<div className="row m-b-1">
									<div className="col-3">
										{
											user.status === 200 && user.roles.includes('admin') &&
											<>
												<div className="m-b-0">
													<div className="text m-b-0">{props.translations[props.lang].course.status}</div>
													<Select
														lang={props.lang}
														translations={props.translations}
														selected={props.courseNew.active}
														options={props.activeEnums}
														changeOption={props.changeOption}
														type={'active'}
														icons={null}
														disabled={isEdit}
														theme={''}
													/>
												</div>
											</>
										}
										{/* <div className="m-b-0">
													<div className="text m-b-0">{ props.translations[props.lang].course.type }</div>
													<Select
														lang={props.lang}
														translations={props.translations}
														selected={props.courseNew.type}
														options={props.typeEnums}
														changeOption={props.changeOption}
														type={'type'}
														icons={null}
														disabled={isEdit}
														theme={''}
													/>
												</div> */}
									</div>
									<div className="col-3 p-r-1">
										<div className="m-b-0">
											<div className="d-flex flex-direction-row align-items m-b-0 tooltip-down">
												<div className="text">{props.translations[props.lang].course.current}</div>
												<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.currentExplain}`}>
													<InfoIcon />
												</div>
											</div>
											{
												user.status === 200 && user.roles.includes('admin') ?
													<>
														<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderUsersCurrent && 'active'} br-8`}>
															<span className={`input-group-text wh-44 p-none`}>
																<NumberIcon />
															</span>
															<input
																type="number"
																className={`form-control`}
																placeholder={props.translations[props.lang].course.usersCurrentPlaceholder}
																value={props.courseNew.users.current === null ? undefined : props.courseNew.users.current}
																onChange={handleUsersCurrentChange}
																onFocus={onFocusUsersCurrent}
																onBlur={onBlurUsersCurrent}
																disabled={user.status === 200 && user.roles.includes('admin') && isEdit.status ? false : true}
															/>
														</div>
													</>
													:
													<>
														<div className={`input-group disabled br-8`}>
															<span className={`input-group-text wh-44 p-none`}>
																<NumberIcon />
															</span>
															<p className="fs-1">{props.courseNew.users.current}</p>
														</div>
													</>
											}
										</div>
										<div className="m-b-0">
											<div className="d-flex flex-direction-row align-items m-b-0 tooltip-down">
												<div className="text">{props.translations[props.lang].course.total}</div>
												<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.totalExplain}`}>
													<InfoIcon />
												</div>
											</div>
											{
												user.status === 200 && user.roles.includes('admin') ?
													<>
														{
															isEdit.status ?
																<>
																	<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderUsersTotal && 'active'} br-8`}>
																		<span className={`input-group-text wh-44 p-none`}>
																			<NumberIcon />
																		</span>
																		<input
																			type="number"
																			className={`form-control`}
																			placeholder={props.translations[props.lang].course.usersTotalPlaceholder}
																			value={props.courseNew.users.total === null ? undefined : props.courseNew.users.total}
																			onChange={handleUsersTotalChange}
																			onFocus={onFocusUsersTotal}
																			onBlur={onBlurUsersTotal}
																			disabled={user.status === 200 && user.roles.includes('admin') && isEdit.status ? false : true}
																		/>
																	</div>
																</>
																:
																<>
																	{
																		props.courseNew.users.total === null ?
																			<p className="p-4">{props.translations[props.lang].course.infinite}</p>
																			:
																			<div className="d-flex flex-direction-row align-items">
																				<span className={`input-group-text wh-44 p-none`}>
																					<NumberIcon />
																				</span>
																				<p className="p-l-15">{props.courseNew.users.total}</p>
																			</div>
																	}
																</>
														}
													</>
													:
													<>
														{
															props.courseNew.users.total === null ?
																<p className="p-4">{props.translations[props.lang].course.infinite}</p>
																:
																<div className={`input-group disabled br-8`}>
																	<span className={`input-group-text wh-44 p-none`}>
																		<NumberIcon />
																	</span>
																	<p className="fs-1">{props.courseNew.users.total}</p>
																</div>
														}
													</>
											}
										</div>
									</div>
								</div>

								<div className="row m-b-1">
									<div className="col-6">
										<div className="text m-b-0">{props.translations[props.lang].course.name}</div>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderN_us_en && 'active'} br-8 m-b-1`}>
											<span className={`input-group-text wh-44 p-none`}>
												<label className="input-group-text">{props.translations.us_en.us_en}</label>
											</span>
											<input
												type="text"
												className={`form-control`}
												placeholder={props.translations.us_en.course.namePlaceholder.us_en}
												value={props.courseNew.name.us_en === null ? undefined : props.courseNew.name.us_en}
												onChange={handleNameChange_us_en}
												onFocus={onFocusN_us_en}
												onBlur={onBlurN_us_en}
												disabled={isEdit.status ? false : true}
											/>
											<span className={`input-group-text wh-44 p-none`}>
												<props.flags.us_en />
											</span>
										</div>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderN_be_nl && 'active'} br-8 m-b-1`}>
											<span className={`input-group-text wh-44 p-none`}>
												<label className="input-group-text">{props.translations.be_nl.be_nl}</label>
											</span>
											<input
												type="text"
												className={`form-control`}
												placeholder={props.translations.be_nl.course.namePlaceholder.be_nl}
												value={props.courseNew.name.be_nl === null ? undefined : props.courseNew.name.be_nl}
												onChange={handleNameChange_be_nl}
												onFocus={onFocusN_be_nl}
												onBlur={onBlurN_be_nl}
												disabled={isEdit.status ? false : true}
											/>
											<span className={`input-group-text wh-44 p-none`}>
												<props.flags.be_nl />
											</span>
										</div>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderN_be_fr && 'active'} br-8 m-b-1`}>
											<span className={`input-group-text wh-44 p-none`}>
												<label className="input-group-text">{props.translations.be_fr.be_fr}</label>
											</span>
											<input
												type="text"
												className={`form-control`}
												placeholder={props.translations.be_fr.course.namePlaceholder.be_fr}
												value={props.courseNew.name.be_fr === null ? undefined : props.courseNew.name.be_fr}
												onChange={handleNameChange_be_fr}
												onFocus={onFocusN_be_fr}
												onBlur={onBlurN_be_fr}
												disabled={isEdit.status ? false : true}
											/>
											<span className={`input-group-text wh-44 p-none`}>
												<props.flags.be_fr />
											</span>
										</div>
									</div>
									<div className="col-3 card-p-l m-b-1">
										<div className="text m-b-0">{props.translations[props.lang].course.age}</div>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderA_min && 'active'} br-8 m-b-1`}>
											<span className={`input-group-text wh-44 p-none`}>
												<label className="w-100">{props.translations[props.lang].course.ageMin.slice(0, 3)}.</label>
											</span>
											<input
												type="number"
												className={`form-control`}
												placeholder={props.translations[props.lang].course.ageMinPlaceholder}
												value={props.courseNew.age.min === null ? undefined : props.courseNew.age.min}
												onChange={handleAgeMinChange}
												onFocus={onFocusA_min}
												onBlur={onBlurA_min}
												disabled={isEdit.status ? false : true}
											/>
										</div>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderA_max && 'active'} br-8`}>
											<span className={`input-group-text wh-44 p-none`}>
												<label className="w-100">{props.translations[props.lang].course.ageMax.slice(0, 3)}.</label>
											</span>
											<input
												type="number"
												className={`form-control`}
												placeholder={props.translations[props.lang].course.ageMaxPlaceholder}
												value={props.courseNew.age.max === null ? undefined : props.courseNew.age.max}
												onChange={handleAgeMaxChange}
												onFocus={onFocusA_max}
												onBlur={onBlurA_max}
												disabled={isEdit.status ? false : true}
											/>
										</div>
									</div>
								</div>
								<div className="row m-b-1">
									<div className="col-6">
										<div className="d-flex flex-direction-column justify-content-start">

											<div className="text m-b-0">{props.translations[props.lang].course.url}</div>
											<span className={`input-group-title`}>
												<label className="caption w-100">{props.translations[props.lang].course.urlName}</label>
											</span>
											<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderU_name && 'active'} br-8 m-b-0`}>
												<input
													type="text"
													className={`form-control`}
													placeholder={props.translations[props.lang].course.urlNamePlaceholder}
													value={props.courseNew.url.name === null ? undefined : props.courseNew.url.name}
													onChange={handleUrlNameChange}
													onFocus={onFocusU_name}
													onBlur={onBlurU_name}
													disabled={isEdit.status ? false : true}
												/>
											</div>
											<span className={`input-group-title`}>
												<label className="caption w-100">{props.translations[props.lang].course.urlUrl}</label>
											</span>
											<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderU_url && 'active'} br-8 m-b-0`}>
												<input
													type="text"
													className={`form-control`}
													placeholder={props.translations[props.lang].course.urlUrlPlaceholder}
													value={props.courseNew.url.url === null ? undefined : props.courseNew.url.url}
													onChange={handleUrlUrlChange}
													onFocus={onFocusU_url}
													onBlur={onBlurU_url}
													disabled={isEdit.status ? false : true}
												/>
											</div>


										</div>

									</div>
								</div>
								<div className="row m-b-1">
									<div className="col-6">
										<div className="d-flex flex-direction-column justify-content-start">

											<div className="text m-b-0">{props.translations[props.lang].course.sponsor}</div>
											<span className={`input-group-title`}>
												<label className="caption w-100">{props.translations[props.lang].course.sponsorName}</label>
											</span>
											<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderS_name && 'active'} br-8 m-b-0`}>
												<input
													type="text"
													className={`form-control`}
													placeholder={props.translations[props.lang].course.sponsorNamePlaceholder}
													value={props.courseNew.sponsor.name === null ? undefined : props.courseNew.sponsor.name}
													onChange={handleSponsorNameChange}
													onFocus={onFocusS_name}
													onBlur={onBlurS_name}
													disabled={isEdit.status ? false : true}
												/>
											</div>
											<span className={`input-group-title`}>
												<label className="caption w-100">{props.translations[props.lang].course.sponsorUrl}</label>
											</span>
											<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderS_url && 'active'} br-8 m-b-0`}>
												<input
													type="text"
													className={`form-control`}
													placeholder={props.translations[props.lang].course.sponsorUrlPlaceholder}
													value={props.courseNew.sponsor.url === null ? undefined : props.courseNew.sponsor.url}
													onChange={handleSponsorUrlChange}
													onFocus={onFocusS_url}
													onBlur={onBlurS_url}
													disabled={isEdit.status ? false : true}
												/>
											</div>
											{/* <div className="image-box">
														<div className="text m-b-0 color-transparent">{props.translations[props.lang].course.sponsor}</div>
														<div className={`input-group-title d-flex flex-direction-row align-items`}>
															<label className="caption">{props.translations[props.lang].course.sponsorLogo}</label>
															<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.sponsorLogoExplain}`}>
																<InfoIcon />
															</div>
														</div>
														<div
															className={`image-container-border ${isEdit.status ? "active" : undefined}`}
														>
															<div
																className={`image-container ${isEdit.status ? "active" : undefined}`}
																onClick={isEdit.status ? uploadSponsorLogo : undefined}
															>
																<div className={`image-container-background pointer-none`}>
																	{
																		props.courseNew.sponsor.logo === '' ? <ImageIcon /> : <img src={`${props.courseNew.sponsor.logo}`} />
																	}
																</div>
																<div className={`image-container-overlay pointer-none`}>
																	<UploadIcon />
																	<label className="text">Upload</label>
																</div>

																<div className={`image-container-overlay-uploading pointer-none ${isSponsorLogoUploading && `active`}`}>
																	<LoadingIcon width={25} height={25} />
																	<label className="text">Uploading</label>
																	<div className={`progress-bar-wrapper pointer-none ${isSponsorLogoUploading && `active`}`}>
																		<div className="progress-bar">
																			<span className="progress-bar-fill" style={{ width: '50px'}}></span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														{
															isEdit.status && props.courseNew.sponsor.logo !== '' && <div className="d-flex justify-content w-100">
																<div
																	className="remove"
																	onClick={() => removeImage('sponsor')}
																>
																	<label>{props.translations[props.lang].course.remove}</label>
																</div>
															</div>
														}
													</div> */}

										</div>

									</div>
									<div className="col-3 card-p-l">
										{
											user.status === 200 && user.roles.includes('admin') &&
											<>
												<div className="text m-b-0">{props.translations[props.lang].course.price}</div>
												<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderP && 'active'} br-8 m-b-0`}>
													<span className={`input-group-text wh-44 p-none justify-content fs-2`}>
														€
													</span>
													<input
														type="number"
														step={0.1}
														min={0}
														className={`form-control`}
														placeholder={props.translations[props.lang].course.pricePlaceholder}
														value={props.courseNew.price === null ? undefined : props.courseNew.price}
														onChange={handlePriceChange}
														onFocus={onFocusP}
														onBlur={onBlurP}
														disabled={isEdit.status ? false : true}
													/>
												</div>
											</>
										}
									</div>
								</div>
								<div className="row m-b-1">
									<div className="col-3">
										<div className="text m-b-0">{props.translations[props.lang].course.address.start}</div>
										<span className={`input-group-title`}>
											<label className="caption w-100">{props.translations[props.lang].course.address.street}</label>
										</span>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderAd_street && 'active'} br-8 m-b-0`}>
											<input
												type="text"
												className={`form-control`}
												placeholder={props.translations[props.lang].course.address.placeholders.street}
												value={props.courseNew.address.street === null ? undefined : props.courseNew.address.street}
												onChange={handleAddressStreetChange}
												onFocus={onFocusAd_street}
												onBlur={onBlurAd_street}
												disabled={isEdit.status ? false : true}
											/>
										</div>
										<span className={`input-group-title`}>
											<label className="caption w-100">{props.translations[props.lang].course.address.number}</label>
										</span>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderAd_number && 'active'} br-8 m-b-0`}>
											<input
												type="text"
												className={`form-control`}
												placeholder={props.translations[props.lang].course.address.placeholders.number}
												value={props.courseNew.address.number === null ? undefined : props.courseNew.address.number}
												onChange={handleAddressNumberChange}
												onFocus={onFocusAd_number}
												onBlur={onBlurAd_number}
												disabled={isEdit.status ? false : true}
											/>
										</div>
										<span className={`input-group-title`}>
											<label className="caption w-100">{props.translations[props.lang].course.address.postal}</label>
										</span>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderAd_postal && 'active'} br-8 m-b-0`}>
											<input
												type="text"
												className={`form-control`}
												placeholder={props.translations[props.lang].course.address.placeholders.postal}
												value={props.courseNew.address.postal === null ? undefined : props.courseNew.address.postal}
												onChange={handleAddressPostalChange}
												onFocus={onFocusAd_postal}
												onBlur={onBlurAd_postal}
												disabled={isEdit.status ? false : true}
											/>
										</div>
									</div>
									<div className="col-3 card-p-l">
										<div className="text color-transparent m-b-0">{props.translations[props.lang].course.address.start}</div>
										<span className={`input-group-title`}>
											<label className="caption w-100">{props.translations[props.lang].course.address.city}</label>
										</span>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderAd_city && 'active'} br-8 m-b-0`}>
											<input
												type="text"
												className={`form-control`}
												placeholder={props.translations[props.lang].course.address.placeholders.city}
												value={props.courseNew.address.city === null ? undefined : props.courseNew.address.city}
												onChange={handleAddressCityChange}
												onFocus={onFocusAd_city}
												onBlur={onBlurAd_city}
												disabled={isEdit.status ? false : true}
											/>
										</div>
										<span className={`input-group-title`}>
											<label className="caption w-100">{props.translations[props.lang].course.address.region}</label>
										</span>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderAd_region && 'active'} br-8 m-b-0`}>
											<input
												type="text"
												className={`form-control`}
												placeholder={props.translations[props.lang].course.address.placeholders.region}
												value={props.courseNew.address.region === null ? undefined : props.courseNew.address.region}
												onChange={handleAddressRegionChange}
												onFocus={onFocusAd_region}
												onBlur={onBlurAd_region}
												disabled={isEdit.status ? false : true}
											/>
										</div>
										<span className={`input-group-title`}>
											<label className="caption w-100">{props.translations[props.lang].course.address.country}</label>
										</span>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderAd_country && 'active'} br-8 m-b-0`}>
											<input
												type="text"
												className={`form-control`}
												placeholder={props.translations[props.lang].course.address.placeholders.country}
												value={props.courseNew.address.country === null ? undefined : props.courseNew.address.country}
												onChange={handleAddressCountryChange}
												onFocus={onFocusAd_country}
												onBlur={onBlurAd_country}
												disabled={isEdit.status ? false : true}
											/>
										</div>
									</div>
									<div className="col-6 card-p-l">
										<div className="text color-transparent m-b-0">{props.translations[props.lang].course.address.start}</div>
										<span className={`input-group-title d-flex flex-direction-row align-items`}>
											<label className="caption">{props.translations[props.lang].course.address.custom}</label>
											<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.address.explain}`}>
												<InfoIcon />
											</div>
										</span>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderAd_custom_us_en && 'active'} br-8 m-b-1`}>
											<span className={`input-group-text wh-44 p-none`}>
												<label className="input-group-text">{props.translations.us_en.us_en}</label>
											</span>
											<textarea
												ref={address_custom_us_en}
												onChange={handleAddressCustomChange_us_en}
												className={`form-control`}
												placeholder={props.translations.us_en.course.address.placeholders.custom}
												value={props.courseNew.address.custom.us_en}
												disabled={isEdit.status ? false : true}
												style={{ paddingTop: '12px', height: address_customTextareaheight_us_en }}
												onFocus={onFocusAd_custom_us_en}
												onBlur={onBlurAd_custom_us_en}
											/>
											<span className={`input-group-text wh-44 p-none`}>
												<props.flags.us_en />
											</span>
										</div>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderAd_custom_be_nl && 'active'} br-8 m-b-1`}>
											<span className={`input-group-text wh-44 p-none`}>
												<label className="input-group-text">{props.translations.be_nl.be_nl}</label>
											</span>
											<textarea
												ref={address_custom_be_nl}
												onChange={handleAddressCustomChange_be_nl}
												className={`form-control`}
												placeholder={props.translations.be_nl.course.address.placeholders.custom}
												value={props.courseNew.address.custom.be_nl}
												disabled={isEdit.status ? false : true}
												style={{ paddingTop: '12px', height: address_customTextareaheight_be_nl }}
												onFocus={onFocusAd_custom_be_nl}
												onBlur={onBlurAd_custom_be_nl}
											/>
											<span className={`input-group-text wh-44 p-none`}>
												<props.flags.be_nl />
											</span>
										</div>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderAd_custom_be_fr && 'active'} br-8 m-b-1`}>
											<span className={`input-group-text wh-44 p-none`}>
												<label className="input-group-text">{props.translations.be_fr.be_fr}</label>
											</span>
											<textarea
												ref={address_custom_be_fr}
												onChange={handleAddressCustomChange_be_fr}
												className={`form-control`}
												placeholder={props.translations.be_fr.course.address.placeholders.custom}
												value={props.courseNew.address.custom.be_fr}
												disabled={isEdit.status ? false : true}
												style={{ paddingTop: '12px', height: address_customTextareaheight_be_fr }}
												onFocus={onFocusAd_custom_be_fr}
												onBlur={onBlurAd_custom_be_fr}
											/>
											<span className={`input-group-text wh-44 p-none`}>
												<props.flags.be_fr />
											</span>
										</div>

									</div>
								</div>

								<div className="row m-b-1">
									<div className="col-12">
										<div className="text m-b-0">{props.translations[props.lang].course.description}</div>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderD_us_en && 'active'} br-8 m-b-1`}>
											<span className={`input-group-text wh-44 p-none`}>
												<label className="input-group-text">{props.translations.us_en.us_en}</label>
											</span>
											<textarea
												ref={description_us_en}
												onChange={handleDescriptionChange_us_en}
												className={`form-control`}
												placeholder={props.translations.us_en.course.descriptionPlaceholder.us_en}
												value={props.courseNew.description.us_en}
												disabled={isEdit.status ? false : true}
												style={{ paddingTop: '12px', height: descriptionTextareaheight_us_en }}
												onFocus={onFocusD_us_en}
												onBlur={onBlurD_us_en}
											/>
											<span className={`input-group-text wh-44 p-none`}>
												<props.flags.us_en />
											</span>
										</div>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderD_be_nl && 'active'} br-8 m-b-1`}>
											<span className={`input-group-text wh-44 p-none`}>
												<label className="input-group-text">{props.translations.be_nl.be_nl}</label>
											</span>

											<textarea
												ref={description_be_nl}
												onChange={handleDescriptionChange_be_nl}
												className={`form-control`}
												placeholder={props.translations.be_nl.course.descriptionPlaceholder.be_nl}
												value={props.courseNew.description.be_nl}
												disabled={isEdit.status ? false : true}
												style={{ paddingTop: '12px', height: descriptionTextareaheight_be_nl }}
												onFocus={onFocusD_be_nl}
												onBlur={onBlurD_be_nl}
											/>
											<span className={`input-group-text wh-44 p-none`}>
												<props.flags.be_nl />
											</span>
										</div>
										<div className={`input-group ${isEdit.status ? undefined : 'disabled'} ${isBorderD_be_fr && 'active'} br-8 m-b-1`}>
											<span className={`input-group-text wh-44 p-none`}>
												<label className="input-group-text">{props.translations.be_fr.be_fr}</label>
											</span>
											<textarea
												ref={description_be_fr}
												onChange={handleDescriptionChange_be_fr}
												className={`form-control`}
												placeholder={props.translations.be_fr.course.descriptionPlaceholder.be_fr}
												value={props.courseNew.description.be_fr}
												disabled={isEdit.status ? false : true}
												style={{ paddingTop: '12px', height: descriptionTextareaheight_be_fr }}
												onFocus={onFocusD_be_fr}
												onBlur={onBlurD_be_fr}
											/>
											<span className={`input-group-text wh-44 p-none`}>
												<props.flags.be_fr />
											</span>
										</div>
									</div>
								</div>
								<div className="row m-b-1">
									<div className="col-12">
										<div className="text m-b-0">{props.translations[props.lang].course.tags.title}</div>
										<TagsInput
											translations={props.translations}
											lang={props.lang}
											isEdit={isEdit}
											placeholder={props.courseNew.tags.length === 0 ? props.translations[props.lang].course.tags.placeholders.empty : props.translations[props.lang].course.tags.placeholders.full}
											courseNew={props.courseNew}
											onChange={props.changeCourseNew}
											flags={props.flags}
											addToast={props.addToast}
										/>
									</div>
								</div>
								<div className="row m-b-1">
									<div className="col-12">
										<div className="text m-b-0">{props.translations[props.lang].course.extra}</div>
									</div>
									<div className="col-6">
										<div className={`toggle m-b-0 ${isEdit.status ? "active" : undefined}`}>
											<div className="row">
												<div className="input-group-title">
													<div className="col-9">
														<div className="toggle-text">
															<label className="caption">{props.translations[props.lang].course.pin}</label>
															<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.pinExplain}`}>
																<InfoIcon />
															</div>
														</div>
													</div>
													<div className="col-3 p-3 p-l-1 d-flex toggle-align align-items-end">
														<div
															className={`toggle-box ${props.courseNew.pin ? "active" : undefined} ${isEdit.status ? "cursor" : "disabled"}`}
															onClick={isEdit.status ? togglePin : undefined}
														>
															<div className={`toggle-thumb ${props.courseNew.pin ? "active" : undefined}`}></div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className={`toggle m-b-0 ${isEdit.status ? "active" : undefined}`}>
											<div className="row">
												<div className="input-group-title">
													<div className="col-9">
														<div className="toggle-text">
															<label className="caption">{props.translations[props.lang].course.wheelchair}</label>
															<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.wheelchairExplain}`}>
																<InfoIcon />
															</div>
														</div>
													</div>
													<div className="col-3 p-3 p-l-1 d-flex toggle-align align-items-end">
														<div
															className={`toggle-box ${props.courseNew.wheelchair ? "active" : undefined} ${isEdit.status ? "cursor" : "disabled"}`}
															onClick={isEdit.status ? toggleWheelchair : undefined}
														>
															<div className={`toggle-thumb ${props.courseNew.wheelchair ? "active" : undefined}`}></div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className={`toggle m-b-0 ${isEdit.status ? "active" : undefined}`}>
											<div className="row">
												<div className="input-group-title">
													<div className="col-9">
														<div className="toggle-text">
															<label className="caption">{props.translations[props.lang].course.stroller}</label>
															<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.strollerExplain}`}>
																<InfoIcon />
															</div>
														</div>
													</div>
													<div className="col-3 p-3 p-l-1 d-flex toggle-align align-items-end">
														<div
															className={`toggle-box ${props.courseNew.stroller ? "active" : undefined} ${isEdit.status ? "cursor" : "disabled"}`}
															onClick={isEdit.status ? toggleStroller : undefined}
														>
															<div className={`toggle-thumb ${props.courseNew.stroller ? "active" : undefined}`}></div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className={`toggle m-b-0 ${isEdit.status ? "active" : undefined}`}>
											<div className="row">
												<div className="input-group-title">
													<div className="col-9">
														<div className="toggle-text">
															<label className="caption">{props.translations[props.lang].course.dog}</label>
															<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.dogExplain}`}>
																<InfoIcon />
															</div>
														</div>
													</div>
													<div className="col-3 p-3 p-l-1 d-flex toggle-align align-items-end">
														<div
															className={`toggle-box ${props.courseNew.dog ? "active" : undefined} ${isEdit.status ? "cursor" : "disabled"}`}
															onClick={isEdit.status ? toggleDog : undefined}
														>
															<div className={`toggle-thumb ${props.courseNew.dog ? "active" : undefined}`}></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-6 card-p-l">
										<div className={`toggle m-b-0 ${isEdit.status ? "active" : undefined}`}>
											<div className="row">
												<div className="input-group-title">
													<div className="col-9">
														<div className="toggle-text">
															{/* <div className="lock-route">
																		<LockIcon/>
																	</div> */}
															<label className="caption">{props.translations[props.lang].course.free}</label>
															<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.freeExplain}`}>
																<InfoIcon />
															</div>
														</div>
													</div>
													<div className="col-3 p-3 p-l-1 d-flex toggle-align align-items-end">
														<div
															className={`toggle-box ${props.courseNew.free ? "active" : undefined} ${isEdit.status ? "cursor" : "disabled"}`}
															onClick={isEdit.status ? toggleFree : undefined}
														>
															<div className={`toggle-thumb ${props.courseNew.free ? "active" : undefined}`}></div>
														</div>
													</div>
												</div>
											</div>
										</div>
										{
											user.status === 200 && user.roles.includes('admin') &&
											<>
												<div className={`toggle m-b-0 ${isEdit.status ? "active" : undefined}`}>
													<div className="row">
														<div className="input-group-title">
															<div className="col-9">
																<div className="toggle-text">
																	<label className="caption">{props.translations[props.lang].course.ads}</label>
																	<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.adsExplain}`}>
																		<InfoIcon />
																	</div>
																</div>
															</div>
															<div className="col-3 p-3 p-l-1 d-flex toggle-align align-items-end">
																<div
																	className={`toggle-box ${props.courseNew.ads ? "active" : undefined} ${isEdit.status ? "cursor" : "disabled"}`}
																	onClick={isEdit.status ? toggleAds : undefined}
																>
																	<div className={`toggle-thumb ${props.courseNew.ads ? "active" : undefined}`}></div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</>
										}
										<div className={`toggle m-b-0 ${isEdit.status ? "active" : undefined}`}>
											<div className="row">
												<div className="input-group-title">
													<div className="col-9">
														<div className="toggle-text">
															<label className="caption">{props.translations[props.lang].course.skip}</label>
															<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.skipExplain}`}>
																<InfoIcon />
															</div>
														</div>
													</div>
													<div className="col-3 p-3 p-l-1 d-flex toggle-align align-items-end">
														<div
															className={`toggle-box ${props.courseNew.skip ? "active" : undefined} ${isEdit.status ? "cursor" : "disabled"}`}
															onClick={isEdit.status ? toggleSkip : undefined}
														>
															<div className={`toggle-thumb ${props.courseNew.skip ? "active" : undefined}`}></div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className={`toggle m-b-0 ${isEdit.status ? "active" : undefined}`}>
											<div className="row">
												<div className="input-group-title">
													<div className="col-9">
														<div className="toggle-text">
															<label className="caption">{props.translations[props.lang].course.time}</label>
															<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.timeExplain}`}>
																<InfoIcon />
															</div>
														</div>
													</div>
													<div className="col-3 p-3 p-l-1 d-flex toggle-align align-items-end">
														<div
															className={`toggle-box ${props.courseNew.time ? "active" : undefined} ${isEdit.status ? "cursor" : "disabled"}`}
															onClick={isEdit.status ? toggleTime : undefined}
														>
															<div className={`toggle-thumb ${props.courseNew.time ? "active" : undefined}`}></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-5">
										<div className="d-flex flex-direction-row align-items m-b-0">
											<div className="text">{props.translations[props.lang].course.date.event}</div>
											<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.date.eventExplain}`}>
												<InfoIcon />
											</div>
										</div>
										{
											isEdit.status ?
												<div className={`date-range-box ${isEdit.status ? "active" : undefined}`}>
													<DateRange
														editableDateInputs={true}
														onChange={item => setEventDate({ ...dates, ...item })}
														moveRangeOnFirstSelection={false}
														ranges={[dates.selection]}
														weekStartsOn={1}
														startDatePlaceholder={props.translations[props.lang].course.date.start}
														endDatePlaceholder={props.translations[props.lang].course.date.end}
														minDate={new Date()}
														rangeColors={['#4F46F8', '#747D89']}
													/>
													<div className="d-flex flex-direction-row justify-content-end"
														onClick={clearDates}
													>
														<div className="button d-flex justify-content-end p-0 m-t-1">
															<label className="text">{props.translations[props.lang].course.date.clear}</label>
															<div className="icon-color icon-container icon-container-color-bg-2">
																<RefreshIcon />
															</div>
														</div>
													</div>
												</div>
												:
												<>
													<div>
														<span className="input-group-title">
															<label className="caption w-100">{props.translations[props.lang].course.date.start}</label>
														</span>
														<span className="course">
															{
																props.courseNew.start === null ?
																	<>
																		<div className="caption d-flex flex-direction-row align-items p-l-1">
																			<ClockIcon />
																			<label className="text-date fs-1">{props.translations[props.lang].course.date.today}</label>
																		</div>
																	</>
																	:
																	<>
																		<div className="caption d-flex flex-direction-row align-items p-l-1">
																			<ClockIcon />
																			<label className="text-date fs-1">{convertUTC(props.courseNew.start)[0]}</label>
																		</div>
																	</>
															}
														</span>
													</div>
													<div>
														<span className="input-group-title">
															<label className="caption w-100">{props.translations[props.lang].course.date.end}</label>
														</span>
														<span className="course">
															{
																props.courseNew.end === null ?
																	<>
																		<div className="caption d-flex flex-direction-row align-items p-l-1">
																			<ClockIcon />
																			<label className="text-date fs-1">{props.translations[props.lang].course.date.continuous}</label>
																		</div>
																	</>
																	:
																	<>
																		<div className="caption d-flex flex-direction-row align-items p-l-1">
																			<ClockIcon />
																			<label className="text-date fs-1">{convertUTC(props.courseNew.end)[0]}</label>
																		</div>
																	</>
															}
														</span>
													</div>
												</>
										}
									</div>
								</div>
								{
									isEdit.status &&
									<>
										{
											confirmDeleteBox ?
												<>
													<div className="row d-flex justify-content m-t-2">
														<div className="col-6">

															<div className="remove-box">
																<div className="fs-2">
																	{props.translations[props.lang].course.confirmRemoveRoute.title}
																</div>
																<div className="text-color-gray fs-1">
																	{props.translations[props.lang].course.confirmRemoveRoute.subtitle}
																</div>
																<div className="d-flex flex-direction-row justify-content-space-between">
																	<div className="d-flex flex-direction-row justify-content-start m-t-1"
																		onClick={() => deleteRoute(props.courseNew)}
																	>
																		<div className="button d-flex justify-content-end p-0">
																			<div className="icon-color-white icon-container icon-container-color-bg-accent-1">
																				<OkIcon />
																			</div>
																			<label className="text-color-accent-1">{props.translations[props.lang].course.yes}</label>
																		</div>
																	</div>
																	<div className="d-flex flex-direction-row justify-content-start m-t-1"
																		onClick={() => setConfirmDeleteBox(false)}
																	>
																		<div className="button d-flex justify-content-end p-0">
																			<label className="text-color-gray">{props.translations[props.lang].course.no}</label>
																			<div className="icon-color-gray icon-container icon-container-color-bg-2">
																				<DismissIcon />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</>
												:
												<>
													<div className="d-flex flex-direction-row justify-content-start m-t-1"
														onClick={() => setConfirmDeleteBox(true)}
													>
														<div className="button d-flex justify-content-end p-0">
															<div className="icon-color-white icon-container icon-container-color-bg-accent-1">
																<TrashIcon />
															</div>
															<label className="text-color-accent-2">{props.translations[props.lang].course.removeRoute}</label>
														</div>
													</div>
												</>
										}

									</>

								}
								<div className="subtitle d-flex justify-content m-t-1 m-b-1">
									<label>Route id: {id}</label>
								</div>
							</div>

							{/* <div className="row p-2">
										<div className="col-12">
											
											{
												props.course.checkpoints.map((item, i) => {
													return <div key={i} className="col-2 checkpoint">
														<img className="active" src={`data:image/svg+xml;utf8,${encodeURIComponent(item.qr)}`} />
													</div>
												})
											}
										</div>
									</div> */}
							<div className="row p-2 only-mobile">
								<div
									className="col-12"
									style={{
										backgroundColor: 'transparent',
										// position: 'absolute',
										// bottom: 0,
										height: divHeight
									}}
								>
								</div>
							</div>

						</SpringScrollbars>
					</div>
				</>

			</div>
			<div className={`upload pointer-none row w-100 justify-content-end align-items-end flex-direction-column ${uploadBox ? 'active' : undefined}`}>
				<div className={`pointer-auto col-4 upload-box smooth-shadow`}>
					<div className="row w-100">
						<div className='upload-box-header'>
							<div ref={progressBox} className="upload-progress-tracker expanded">
								<h3></h3>
								<div className="uploads-percentage"></div>
								{/* <p className="upload-progress">
									<div className="row uploads-percentage"></div>
									<span className="success-count"></span>
									<span className="failed-count"></span>
									<span className="paused-count"></span>
								</p> */}
							</div>
							<div className="p-l-1 p-r-1">
								<div className="uploads-progress-box"></div>
								<div ref={totalProgressBar} className="uploads-progress-bar" style={{ width: 0 }}></div>
							</div>
						</div>
						<div className='upload-box-content'>
							<SpringScrollbars
								ref={scrollbars}
								style={{
									backgroundColor: 'transparent',
									height: scrollHeightUpload,
									zIndex: 0
								}}
								thumbcolor='rgba(116, 125, 137, 0.2)'
							>
								<div ref={fileProgressWrapper} className="file-progress-wrapper"></div>
							</SpringScrollbars>
						</div>
						<div className='upload-box-footer p-3'>
							<div className={`button d-flex justify-content-end p-0`} onClick={() => isUploadBox(false)}>
								<label className="text text-color">{props.translations[props.lang].course.ok}</label>
								<div className="icon-color-white icon-container icon-container-color-bg-accent-0">
									<OkIcon />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Course;