import React, { forwardRef, useEffect, useState } from "react";
import Nouislider from "nouislider-react";

function SliderMerge(props) {

    const [slider, setSlider] = useState(null);
    const [threshold, setThreshold] = useState(props.threshold);
    const [separator, setSeparator] = useState(props.separator);

    useEffect(() => {

        if (!slider) return;

        const textIsRtl = getComputedStyle(slider).direction === 'rtl';
        const isRtl = slider.noUiSlider.options.direction === 'rtl';
        const isVertical = slider.noUiSlider.options.orientation === 'vertical';
        const tooltips = slider.noUiSlider.getTooltips();
        const origins = slider.noUiSlider.getOrigins();

        // Move tooltips into the origin element. The default stylesheet handles this.
        tooltips.forEach((tooltip, index) => {
            if (tooltip) {
                origins[index].appendChild(tooltip);
            }
        });

        slider.noUiSlider.on('update', (values, handle, unencoded, tap, positions) => {
            const pools = [[]];
            const poolPositions = [[]];
            const poolValues = [[]];
            let atPool = 0;

            // Assign the first tooltip to the first pool, if the tooltip is configured
            if (tooltips[0]) {
                pools[0][0] = 0;
                poolPositions[0][0] = positions[0];
                poolValues[0][0] = `${parseFloat(values[0]).toFixed(props.tooltipFormat[0].decimals)}${props.tooltipFormat[0].suffix}`;
            }

            for (let i = 1; i < positions.length; i++) {
                if (!tooltips[i] || (positions[i] - positions[i - 1]) > threshold) {
                    atPool++;
                    pools[atPool] = [];
                    poolValues[atPool] = [];
                    poolPositions[atPool] = [];
                }

                if (tooltips[i]) {
                    pools[atPool].push(i);
                    poolValues[atPool].push(`${parseFloat(values[i]).toFixed(props.tooltipFormat[i].decimals)}${props.tooltipFormat[i].suffix}`);
                    poolPositions[atPool].push(positions[i]);
                }
            }

            pools.forEach((pool, poolIndex) => {
                const handlesInPool = pool.length;

                for (let j = 0; j < handlesInPool; j++) {
                    const handleNumber = pool[j];

                    if (j === handlesInPool - 1) {
                        let offset = 0;

                        poolPositions[poolIndex].forEach((value) => {
                            offset += 1000 - 10 * value;
                        });

                        const direction = isVertical ? 'bottom' : 'right';
                        const last = isRtl ? 0 : handlesInPool - 1;
                        const lastOffset = 1000 - 10 * poolPositions[poolIndex][last];
                        offset = (textIsRtl && !isVertical ? 100 : 0) + (offset / handlesInPool) - lastOffset;

                        // Center this tooltip over the affected handles
                        tooltips[handleNumber].innerHTML = poolValues[poolIndex].join(separator);
                        tooltips[handleNumber].style.display = "block";
                        tooltips[handleNumber].style[direction] = `${offset}%`;
                    } else {
                        tooltips[handleNumber].style.display = "none";
                    }
                }
            });
        });
    })

    return (
        <Nouislider
            instanceRef={instance => {
                if (instance && slider === null) {
                    setSlider(instance);
                }
            }}
            {...props}
        />
    )
}

export default SliderMerge;