import { useState, useRef, useContext, useEffect } from 'react';
import { NavLink as Link, useNavigate, useParams } from "react-router-dom";

import { UserContext } from '../Hooks/UserContext';

import { EmailIcon, LockIcon, LoginIcon, EyeIcon, EyeSlashIcon } from './Icons';

import Header from './Header';
import SocialBanner from './SocialBanner';
import Footer from './Footer';

function Login(props) {

	let { lang } = useParams();

    useEffect ( () => {
        if (lang) {
            props.changeOption(lang, "lang");
        }
    }, [])

	const { setUser } = useContext(UserContext);

	const navigate = useNavigate();
	
	const passwordInput = useRef();
	const [isHidden, setIsHidden] = useState(true);

	const [isBorderE, setBorderE] = useState(false);
	const [isBorderP, setBorderP] = useState(false);

	function onFocusE () {
		setBorderE(true)
	}
	function onFocusP () {
		setBorderP(true)
	}

	function onBlurE () {
		setBorderE(false)
	}
	function onBlurP () {
		setBorderP(false)
	}

	function handleEmailChange (e) {
		var loginCopy = {...props.login}
        loginCopy.email = e.target.value
        props.changeLogin(loginCopy)
	}

	function handlePasswordChange (e) {
		var loginCopy = {...props.login}
        loginCopy.password = e.target.value
        props.changeLogin(loginCopy)
	}

	function handleIsHiddenClick () {
		const type = passwordInput.current.getAttribute("type") === "password" ? "text" : "password";
        passwordInput.current.setAttribute("type", type);
		setIsHidden(!isHidden);
	}

	async function login () {

		let login = {
			email: props.login.email,
			password: props.login.password
		}

		const result = await fetch('https://wandelar.be/api/user/login', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify( login )
		}).then(response => {
			return response.json();
		}).then(data => {
			let backgroundClass = 'toast-box-error';
			if (data.status === 200) {
				backgroundClass = 'toast-box-success';
				// Reset form
				props.changeLogin({
					email: '',
					password: ''
				})
				setUser(data);
				navigate('/Dashboard');
				
			} else {
				props.addToast({
					bgColor: backgroundClass,
					title: props.translations[props.lang].login.messages['msg'+data.status].title,
					msg: props.translations[props.lang].login.messages['msg'+data.status].msg,
					caption: props.translations[props.lang].login.messages['msg'+data.status].caption
				})
			}
			
		}).catch(error => {
			console.log(error)
		});
		
    }

	return (
		<>
			<div className="mask-container mask-container-h-500">
				<div className="mask-shape mask-scale-120">
					<div className="mask-box mask-box-color"></div>
				</div>
				<div className="mask-box mask-top content">
				</div>
			</div>
			<Header
				lang={props.lang}
				translations={props.translations}
				isLoading={props.isLoading}
				selected={props.lang}
				options={props.languages}
				changeOption={props.changeOption}
				flags={props.flags}
			/>
			<div className="row banner">
				<div className="col-6 d-flex justify-content-start">
					<div className="d-block">
						<h3>{ props.translations[props.lang].login.title }</h3>
						<h2 className="p-0">{ props.translations[props.lang].login.subtitle }</h2>
					</div>
				</div>
			</div>
			<div className="row pricing-w d-flex justify-content">
				<div className="col-6 m-0">
					<div className="card-form smooth-shadow p-1">

						<p>{ props.translations[props.lang].login.email }</p>
						<div className={`input-group ${ isBorderE && 'active' } br-8 m-b-1`}>
							<input
								type="text"
								className={`form-control`}
								placeholder={ props.translations[props.lang].login.emailPlaceholder }
								value={props.login.email}
								onChange={handleEmailChange}
								onFocus={onFocusE}
								onBlur={onBlurE}
							/>
							<span className={`input-group-text wh-44 p-none`}>
								<EmailIcon />
							</span>
						</div>

						<p>{ props.translations[props.lang].login.password }</p>
						<div className={`input-group ${ isBorderP && 'active' } br-8 m-b-1`}>
							<input
								ref={passwordInput}
								type="password"
								className={`form-control`}
								placeholder={ props.translations[props.lang].login.passwordPlaceholder }
								value={props.login.password}
								onChange={handlePasswordChange}
								onFocus={onFocusP}
								onBlur={onBlurP}
							/>
							{
								props.login.password !== '' ? <span className={`input-group-text wh-44 p-none pointer`} onClick={ handleIsHiddenClick } >
									{
										isHidden ? <EyeIcon /> : <EyeSlashIcon />
									}
								</span>
								:
								<span className={`input-group-text wh-44 p-none pointer`}>
									<LockIcon />
								</span>
							}
						</div>

						<div className="row d-flex justify-content align-items flex-direction-column m-b-1">
							<Link to="/Forgot" className="login-forgot-url pointer-auto">
								<label className="pointer"> { props.translations[props.lang].login.forgotPassword } </label>
							</Link>
						</div>
						
						<div className="d-flex flex-direction-row justify-content m-b-2"
							onClick={ login }>
							<div className="button d-flex justify-content-end p-0">
								<label className="text-color-accent-0">{ props.translations[props.lang].login.button }</label>
								<div className="icon-white-0 icon-container icon-container-color-bg-accent-0">
									<LoginIcon />
								</div>
							</div>
						</div>

						<div className="d-flex flex-direction-row justify-content m-t-1 register-caption">
							<label className="color-icon p-l-1">{ props.translations[props.lang].login.noAccountAsk }</label>
							<Link to="/Register" className="register-agree-url pointer-auto p-l-1">
								<label className="pointer"> { props.translations[props.lang].login.registerHere } </label>
							</Link>
						</div>
						
					</div>
				</div>
				
			</div>

			<div className="pricing-container pricing-icons m-b-2">
				<div className="pricing-box pricing-box-content">
					<label>{ props.translations[props.lang].login.bigtitle }</label>
				</div>
			</div>

			<SocialBanner
				lang={props.lang}
				translations={props.translations}
				urls={props.urls}
			/>
			<Footer
				theme={props.theme}
				themes={props.themes}
				lang={props.lang}
				languages={props.languages}
				translations={props.translations}
				changeOption={props.changeOption}
				themeIcons={props.themeIcons}
				flags={props.flags}
				urls={props.urls}
			/>
		</>
	);
}
export default Login;