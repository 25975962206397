import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ChannelMap from "./ChannelMap";

function Channel(props) {

	const navigate = useNavigate();
	const { username, routeId } = useParams();

	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);
	const [carouselWidth, setCarouselWidth] = useState(0);
	const [contentHeight, setContentHeight] = useState(0);

	const [stats, setStats] = useState({});
	const [userImages, setUserImages] = useState([]);

	const [geoData, setGeoData] = useState(null);
	const [extremeBounds, setExtremeBounds] = useState(null);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		setHeight(window.innerHeight);
		setWidth(window.innerWidth);
		if (window.innerWidth <= 1200) {
			setCarouselWidth(window.innerWidth);
		} else {
			setCarouselWidth(window.innerWidth);
		}
		setContentHeight(window.innerHeight);

		const getUserId = fetch('https://wandelar.be/api/user/' + username, {
			method: 'GET',
			credentials: 'same-origin'
		}).then(response => {
			return response.json();
		}).then(result => {
			
			if (result.status === 200) {

				const userId = result.data;

				const getUserRoutesData = fetch('https://wandelar.be/api/routes/search/channel/' + userId, {
					method: 'GET',
					credentials: 'same-origin'
				}).then(res => {
					if (res.status === 200) {
						return res.json();
					} else {
						navigate('/NotFound');
					}
				});

				Promise.all([getUserRoutesData]).then((responses) => {

					let startCount = 0;
					let coordinates = [];
					let bounds = [];
					let selected = {};
					for (var i = 0; i < responses[0].features.length; i++) {
						responses[0].features[i].geometry.coordinates = [responses[0].features[i].geometry.coordinates[1] , responses[0].features[i].geometry.coordinates[0]];
						coordinates = [...responses[0].features[i].geometry.coordinates];
						bounds.push(coordinates);

						if (routeId === responses[0].features[i].properties.id) {
							selected["id"] = routeId;
							selected["coordinates"] = [coordinates[1], coordinates[0]];
						}

						startCount += responses[0].features[i].properties.users.current;
					}
					setExtremeBounds(calculateExtremeCoords(bounds));

					setStats({
						routes: numberFormat(responses[0].features.length, 1),
						started: numberFormat(startCount, 1)
					})

					let allGeoData = responses[0];
					allGeoData.selected = selected;

					setGeoData(responses[0]);

					// let allGeoData = {
					// 	"features": [],
					// 	"type": "FeatureCollection"
					// };
					// let features = [];
					// let bounds = [];
					// let selected = {};
					// for (var i = 0; i < responses[0].routes.length; i++) {
					// 	let coordinates = [...responses[0].routes[i].checkpoints[0].geo.coordinates];
					// 	let theme = 'null';
					// 	if (responses[0].routes[i].theme) {
					// 		theme = responses[0].routes[i].theme;
					// 	}
					// 	let elevation = 'null';
					// 	if (responses[0].routes[i].elevation) {
					// 		elevation = responses[0].routes[i].elevation;
					// 	}
					// 	let area = 'null';
					// 	if (responses[0].routes[i].area) {
					// 		area = responses[0].routes[i].area;
					// 	}
					// 	let nodes = 'null';
					// 	if (responses[0].routes[i].nodes) {
					// 		nodes = responses[0].routes[i].nodes;
					// 	}
					// 	let feature = {
					// 		"type": "Feature",
					// 		"properties": {
					// 			"id": responses[0].routes[i]._id,
					// 			"name": responses[0].routes[i].name[props.lang],
					// 			"description": responses[0].routes[i].description[props.lang],
					// 			"distance": responses[0].routes[i].distance,
					// 			"estimation": responses[0].routes[i].estimation,
					// 			"checkpoints": responses[0].routes[i].checkpoints,
					// 			"qrcode": responses[0].routes[i].qrcode,
					// 			"tags": responses[0].routes[i].tags,
					// 			"address": responses[0].routes[i].address,
					// 			"age": responses[0].routes[i].age,
					// 			"dog": responses[0].routes[i].dog,
					// 			"stroller": responses[0].routes[i].stroller,
					// 			"wheelchair": responses[0].routes[i].wheelchair,
					// 			"url": responses[0].routes[i].url,
					// 			"time": responses[0].routes[i].time,
					// 			"theme": theme,
					// 			"nodes": nodes,
					// 			"elevation": elevation,
					// 			"area": area
					// 		},
					// 		"geometry": {
					// 			"coordinates": [coordinates[1], coordinates[0]],
					// 			"type": "Point"
					// 		}
					// 	}
					// 	features.push(feature);

					// 	coordinates.reverse();
					// 	bounds.push(coordinates);

					// 	if (routeId === responses[0].routes[i]._id) {
					// 		selected["id"] = routeId;
					// 		selected["coordinates"] = [coordinates[1], coordinates[0]];
					// 	}
					// }
					// setExtremeBounds(calculateExtremeCoords(bounds));

					// allGeoData.features = features;

					// allGeoData.selected = selected;
					// setGeoData(allGeoData);
				});


			} else {
				navigate('/NotFound');
			}
		});

		// navigate('/Dashboard');

		// cleanup this component
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	function handleResize(e) {
		setWidth(window.innerWidth);
		if (window.innerWidth <= 1200) {
			setHeight(window.innerHeight);
			setCarouselWidth(window.innerWidth);
			setContentHeight(window.innerHeight);
		} else {
			setHeight(window.innerHeight);
			setCarouselWidth(window.innerWidth);
			setContentHeight(window.innerHeight);
		}

	}

	function calculateExtremeCoords(coordinates) {
		let minX = Number.POSITIVE_INFINITY;
		let minY = Number.POSITIVE_INFINITY;
		let maxX = Number.NEGATIVE_INFINITY;
		let maxY = Number.NEGATIVE_INFINITY;

		coordinates.forEach(([x, y]) => {
			minX = Math.min(minX, x);
			minY = Math.min(minY, y);
			maxX = Math.max(maxX, x);
			maxY = Math.max(maxY, y);
		});

		return [
			extremeTopLeft(minX, minY),
			extremeBottomRight(maxX, maxY)
		];
	};

	function extremeTopLeft(longitude, latitude) {
		let topCoordinate = latitude;
		let leftCoordinate = longitude;
		return [leftCoordinate, topCoordinate];
	}

	function extremeBottomRight(longitude, latitude) {
		let bottomCoordinate = longitude;
		let rightCoordinate = latitude;
		return [bottomCoordinate, rightCoordinate];
	}

	function shuffle(array) {
		return [...array].sort(() => Math.random() - 0.5);
	}

	function numberFormat(num, digits) {
		const lookup = [
			{ value: 1, symbol: "" },
			{ value: 1e3, symbol: "k" },
			{ value: 1e6, symbol: "M" },
			{ value: 1e9, symbol: "G" },
			{ value: 1e12, symbol: "T" },
			{ value: 1e15, symbol: "P" },
			{ value: 1e18, symbol: "E" }
		];
		const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
		var item = lookup.slice().reverse().find(function (item) {
			return num >= item.value;
		});
		return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
	}

	return (
		<>
			<div className="channel-container row">
				{/* <SpringScrollbars
					style={{
						backgroundColor: 'transparent',
						height: height,
						zIndex: 0
					}}
					thumbcolor='thumbColor'
				> */}
				<div className="col-6">
					<div className={`${userImages.length === 0 ? 'channel-bg-no-images' : undefined}`} style={{ height: height }}>
						{/* {
								userImages.length > 0 && <Carousel data={userImages} width={carouselWidth} height={height} />
							} */}
						<div
							style={{
								width: carouselWidth,
								height: height
							}}
							className="mapboxgl-map-channel"
						>
							{
								geoData !== null && extremeBounds !== null && <ChannelMap
									geoData={geoData}
									extremeBounds={extremeBounds}
									width={carouselWidth}
									height={height}
									lang={props.lang}
									translations={props.translations}
									username={username}
									stats={stats}
									selected={props.lang}
									options={props.languages}
									changeOption={props.changeOption}
									type={'lang'}
									icons={null}
									disabled={false}
									theme={props.theme}
									border={false}
									urls={props.urls}
									routeId={routeId}
									mapboxAccessToken={props.mapboxAccessToken}
								/>
							}
						</div>
					</div>
				</div>

				{/* </SpringScrollbars> */}
			</div>
		</>
	);
}
export default Channel;