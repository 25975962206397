import { useNavigate } from "react-router-dom";

import Menu from './Menu';
import { CopyIcon, DismissIcon, EditIcon, InfoIcon, LogoutIcon, NewTabIcon, RefreshIcon, SaveIcon, TagCrossIcon } from './Icons';
import SpringScrollbars from "./SpringScrollbars";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { UserContext } from "../Hooks/UserContext";

function Profile(props) {

	const { user, setUser, isLoadingUser } = useContext(UserContext);

	const [scrollHeight, setScrollHeight] = useState(0);

	const [usernameQuery, setUsernameQuery] = useState(user.username.name);
	const [isBorderU, setBorderU] = useState(false);
	const usernameRef = useRef();

	const [isEdit, setIsEdit] = useState({ statu5s: false, type: 'initiate' });

	const navigate = useNavigate();

	const logout = async () => {
		await fetch('https://wandelar.be/api/user/logout', {
			method: 'GET',
			credentials: 'same-origin'
		}).then(response => {
			return response.json();
		}).then(data => {
			setUser({ user: '', status: 404 });
			window.location.reload(false);
		});
	}

	function onFocusU() {
		setBorderU(true)
	}

	function onBlurU() {
		setBorderU(false)
	}

	const onCopy = useCallback((value) => {
		props.addToast({
			bgColor: 'toast-box-success',
			title: props.translations[props.lang].course.copied.title,
			msg: value,
			caption: props.translations[props.lang].course.copied.caption
		})
	}, [])

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		setScrollHeight(window.innerHeight - 40);
		console.log(user)
		setUsernameQuery(user.username);

		// cleanup this component
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	function handleResize(e) {
		setScrollHeight(window.innerHeight - 40);
	}

	const save = async () => {

		// Save username to database

		// Save to database
		const res = await fetch('https://wandelar.be/api/user/update', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${user.token}`
			},
			body: JSON.stringify({
				username: usernameQuery
			})
		}).then(response => {
			return response.json();
		}).then(result => {
			if (result.status === 200) {
				setIsEdit({ status: false, type: 'save' })
				let updatedUser = { ...user }
				updatedUser.username = result.username.name;
				setUser(updatedUser);

				props.addToast({
					bgColor: 'toast-box-success',
					title: props.translations[props.lang].profile.error.code200.title,
					msg: props.translations[props.lang].profile.error.code200.msg,
					caption: props.translations[props.lang].profile.error.code200.caption
				})
			} else {
				if (result.status === 409) {
					if (result.type === 'set') {
						props.addToast({
							bgColor: 'toast-box-error',
							title: props.translations[props.lang].profile.error.code409.set.title,
							msg: props.translations[props.lang].profile.error.code409.set.msg,
							caption: props.translations[props.lang].profile.error.code409.set.caption
						})
					}
					if (result.type === 'taken') {
						props.addToast({
							bgColor: 'toast-box-error',
							title: props.translations[props.lang].profile.error.code409.taken.title,
							msg: props.translations[props.lang].profile.error.code409.taken.msg,
							caption: props.translations[props.lang].profile.error.code409.taken.caption
						})
					}
					if (result.type === 'user') {
						props.addToast({
							bgColor: 'toast-box-error',
							title: props.translations[props.lang].profile.error.code409.user.title,
							msg: props.translations[props.lang].profile.error.code409.user.msg,
							caption: props.translations[props.lang].profile.error.code409.user.caption
						})
					}
				}
				if (result.status === 413) {
					if (result.type === 'min') {
						props.addToast({
							bgColor: 'toast-box-error',
							title: props.translations[props.lang].profile.error.code413.min.title,
							msg: props.translations[props.lang].profile.error.code413.min.msg,
							caption: props.translations[props.lang].profile.error.code413.min.caption
						})
					}
					if (result.type === 'max') {
						props.addToast({
							bgColor: 'toast-box-error',
							title: props.translations[props.lang].profile.error.code413.max.title,
							msg: props.translations[props.lang].profile.error.code413.max.msg,
							caption: props.translations[props.lang].profile.error.code413.max.caption
						})
					}
					if (result.type === 'char') {
						props.addToast({
							bgColor: 'toast-box-error',
							title: props.translations[props.lang].profile.error.code413.char.title,
							msg: props.translations[props.lang].profile.error.code413.char.msg,
							caption: props.translations[props.lang].profile.error.code413.char.caption
						})
					}
				}
			}

		}).catch(error => {
			console.log(error);
		});
	}

	const edit = () => {
		setIsEdit({ status: true, type: 'edit' })
	}

	const cancel = () => {
		setIsEdit({ status: false, type: 'cancel' });

		// Reset username
		setUsernameQuery(user.username.name);
	}

	const onTagCrossClickUsername = () => {
		setUsernameQuery('');
	}

	const onRefreshClickUsername = () => {
		setUsernameQuery(user.username.name)
	}

	return (
		<>
			<Menu
				lang={props.lang}
				translations={props.translations}
				isMenu={props.isMenu}
				toggleMenu={props.toggleMenu}
			/>
			<div className="page-box home_content p-4">
				<SpringScrollbars
					style={{
						backgroundColor: 'transparent',
						height: scrollHeight,
						zIndex: 0
					}}
					thumbcolor='rgba(116, 125, 137, 0.2)'
					thumbcolorbg='thumbColorBg'
				>
					<div className="card-form-configurator p-5">
						<div className="row m-b-1">
							<div className="col-12 m-b-1">
								<div className="d-flex flex-direction-row justify-content-space-between align-items">
									{
										isEdit.status ?
											<div className="d-flex flex-direction-row"
												onClick={cancel}
											>
												<div className="button d-flex justify-content-end p-0">
													<div className="rotate-270 icon-color icon-container icon-container-color-bg-2">
														<DismissIcon />
													</div>
													<label className="text text-color-gray">{props.translations[props.lang].course.cancel}</label>
												</div>
											</div>
											:
											<div className="d-flex flex-direction-row"
												onClick={edit}
											>
												<div className="button d-flex justify-content-end p-0">
													<div className="icon-color icon-container icon-container-color-bg-2">
														<EditIcon />
													</div>
													<label className="text-color-gray">{props.translations[props.lang].course.edit}</label>
												</div>
											</div>
									}
									<div className="fs-3 d-flex flex-direction-column justify-content-start align-items-start">
										{props.translations[props.lang].profile.title}
										{/* <div className="fs-1 text-color-gray">
											{props.translations[props.lang].profile.subtitle}
										</div> */}
									</div>
									{
										isEdit.status ? <div className="button d-flex justify-content-end" onClick={() => save()}>
											<label className="text-color-accent-0">{props.translations[props.lang].course.save}</label>
											<div className="icon-container icon-container-color-bg-accent-0">
												<SaveIcon />
											</div>
										</div>
											:
											<div className="button d-flex flex-direction-row justify-content-end" onClick={() => logout()}>
												<label className="text-color-gray">{props.translations[props.lang].profile.logout}</label>
												<div className="icon-color icon-container icon-container-color-bg-2">
													<LogoutIcon />
												</div>
											</div>
									}

								</div>
							</div>
							<div className="col-12">
								<div className="m-b-0">
									<div className="d-flex flex-direction-row align-items m-b-0 tooltip-down">
										<div className="text">{props.translations[props.lang].profile.channel}</div>
										<div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].profile.channelExplain}`}>
											<InfoIcon />
										</div>
									</div>
									<div className="d-flex flex-direction-row align-items m-b-0 tooltip-down">
										<div className="profile-url">https://wandelar.be/c/</div>
										<div className={`input-group ${isEdit.status ? 'm-l-0' : 'disabled'} ${isBorderU ? 'active' : undefined} br-8`}>
											{/* <div className={`${isEdit.status ? 'profile-wh-44' : undefined} p-none d-flex justify-content align-items`}>
												@
											</div> */}
											<input
												id="search-input"
												ref={usernameRef}
												type="text"
												className={`form-control p-0`}
												placeholder={user.username.name}
												value={usernameQuery}
												onInput={e => setUsernameQuery(e.target.value)}
												onFocus={onFocusU}
												onBlur={onBlurU}
												disabled={isEdit.status ? false : true}
											/>
											<span className={`input-group-text h-44 p-none`}>

												{
													isEdit.status && <>
														<div className="refresh-profile-icon" onClick={() => onTagCrossClickUsername()}>
															<TagCrossIcon />
														</div>
														<div className="refresh-profile-icon" onClick={() => onRefreshClickUsername()}>
															<RefreshIcon />
														</div>
													</>

												}
											</span>
										</div>

										{
											!isEdit.status && <>
												<a href={`https://wandelar.be/c/${user.username.name}`} target="_blank">
													<div className="dropzone-button justify-content-end m-l-0">
														<div className="icon-color icon-container icon-container-color-bg-2">
															<NewTabIcon />
														</div>
													</div>
												</a>
												<CopyToClipboard onCopy={onCopy} text={`https://wandelar.be/c/${user.username.name}`}>
													<div className="dropzone-button justify-content-end m-l-0">
														<div className="icon-color icon-container icon-container-color-bg-2">
															<CopyIcon />
														</div>
													</div>
												</CopyToClipboard>
											</>

										}
									</div>



								</div>
							</div>
						</div>

					</div>

				</SpringScrollbars>
			</div>
		</>
	);
}
export default Profile;