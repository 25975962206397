import { NavLink as Link } from "react-router-dom";

import { AppLogo, AppStoreLogo, PlayStoreLogo } from './Icons';
import Select from './Select';

function Footer(props) {
	return (
		<>
			<div className="footer">

                <div className="row m-b-3">
                    <div className="col-3 d-flex flex-direction-column footer-menu">
                        <Link to="/PrivacyPolicy">
                            { props.translations[props.lang].footer.privacy }
						</Link>
                        <Link to="/TermsAndConditions">
                            { props.translations[props.lang].footer.terms }
						</Link>
                    </div>
                    <div className="col-3 d-flex flex-direction-column footer-menu">
                        <a href="mailto:hello@wandelar.be">{ props.translations[props.lang].footer.contact }</a>
                    </div>
                        
                </div>

                <div className="row">
                    <div className="col-12 m-b-2 p-1">
                        <Link to="/">
                            <div className="logo-content">
                                <div className="logo">
                                    <div className="logo-name">
                                        <AppLogo />
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div className="tagline d-block p-t-1">{ props.translations[props.lang].footer.tagline }</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6 m-b-2">
                        <div className="stores">
                            <div className="appstore-logo m-0">
                                <a href={props.urls.appstore} target="_blank"><AppStoreLogo /></a>
                            </div>
                            <div className="playstore-logo m-0">
                                <a href={props.urls.playstore} target="_blank"><PlayStoreLogo /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 d-flex justify-content align-content m-b-2">
                        <div className="m-0">
                            <Select
                                lang={props.lang}
                                translations={props.translations}
                                selected={props.theme}
                                options={props.themes}
                                changeOption={props.changeOption}
                                type={'theme'}
                                icons={props.themeIcons}
                                disabled={false}
                                theme={''}
                            />
                        </div>
                    </div>
                </div>

                <div className="caption">{ new Date().getFullYear() } { props.translations[props.lang].footer.copyright }</div>
                  

            </div>
        </>
	);
}

export default Footer;