import { WarningIcon, OkIcon } from './Icons';

function Toast(props) {
    return <>
        <div className="toast pointer-none row w-100 d-flex justify-content-end align-items-end flex-direction-column">
            {
                props.toasts.map((item, i) =>
                    <div key={item.id} className={`pointer-auto col-4 toast-box smooth-shadow m-b-1 ${ item.bgColor }`}>
                        <div className="row w-100">
                            <div className='col-12'>
                                <WarningIcon />
                                <div className='col-11'>
                                    <div className='title m-b-0'>
                                        { item.title }
                                    </div>
                                    <div className='msg m-b-1'>
                                        { item.msg }
                                    </div>
                                    <div className='caption'>
                                        { item.caption }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="button d-flex justify-content-end p-0" onClick={ () => { props.removeToast(item.id) }}>
                                    <label className="text-color-white-0">{ props.translations[props.lang].toast.ok }</label>
                                    <div className="icon-accent-0 icon-container icon-container-color-bg-white-0">
                                        <OkIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    </>;
}
export default Toast;