import { useEffect, useState } from "react";
import { NavLink as Link } from "react-router-dom";

import { AppLogo } from './Icons';
import Select from "./Select";

function NotFound(props) {

	const [height, setHeight] = useState(0);
	const [contentHeight, setContentHeight] = useState(0);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		setHeight(window.innerHeight);
		setContentHeight(window.innerHeight);

		// cleanup this component
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	function handleResize(e) {
		setHeight(window.innerHeight);
		setContentHeight(window.innerHeight);
	}

	return (
		<>
			<div className="notfound-bg" style={{ height: height }}>
				<div className="row d-flex flex-direction-row justify-content align-items" style={{ height: contentHeight }}>
					<div className="col-4 p-4">
						<div className="card-form smooth-shadow-invite p-4">
							<div className="d-flex flex-direction-row justify-content-end align-items">
								{/* <Select
									lang={props.lang}
									translations={props.translations}
									selected={props.lang}
									options={props.languages}
									changeOption={props.changeOption}
									type={'lang'}
									icons={null}
									disabled={false}
									theme={props.theme}
								/> */}
							</div>
							<div className="d-flex flex-direction-column justify-content align-items text-center">
								<div className="footer m-b-2">
									<Link to="/">
										<div className="logo-content">
											<div className="logo">
												<div className="logo-name">
													<AppLogo />
												</div>
											</div>
										</div>
									</Link>
								</div>
								<div className="fs-3 text-color-gray m-b-1 ff-corben">{props.translations[props.lang].notfound.title}</div>
								<div className="fs-1 m-b-0 p-l-1 p-r-1">{props.translations[props.lang].notfound.subtitle}</div>

								{/* <div className="d-flex flex-direction-column justify-content align-items m-t-1">
									<div className="fs-1 p-l-1 p-r-1 m-b-0">{props.translations[props.lang].invite.text2}</div>
									<div className="button d-flex justify-content-start p-0 pointer-none m-b-0">
										<div className="icon-color-white icon-container icon-container-color-bg-0">
											<ScanIcon />
										</div>
									</div>
									<div className="fs-1 p-l-1 p-r-1">{props.translations[props.lang].invite.text3}</div>
								</div> */}
								<div className="fs-15 text-color-gray m-t-2">{props.translations[props.lang].notfound.caption}</div>

							</div>
						</div>
					</div>

				</div>
			</div>

		</>
	);
}
export default NotFound;