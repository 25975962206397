import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { UserContext } from '../Hooks/UserContext';

export default function Private() {

	const { user } = useContext(UserContext);

	return user.status === 200 ? <Outlet /> : <Navigate to="/" />;
}