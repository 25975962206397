import Menu from './Menu';
import Courses from './Courses';

function Dashboard(props) {
	return (
		<>
			<Menu 
				lang={props.lang}
				translations={props.translations}
				isMenu={props.isMenu}
				toggleMenu={props.toggleMenu}
			/>
			<Courses
				lang={props.lang}
				translations={props.translations}
				isMenu={props.isMenu}
				toggleMenu={props.toggleMenu}
				routes={props.routes}
				changeCourse={props.changeCourse}
				changeCourseNew={props.changeCourseNew}
				selected={props.selected}
				options={props.options}
				changeOption={props.changeOption}
				routeLayout={props.routeLayout}
				setRouteLayout={props.setRouteLayout}
				addToast={props.addToast}
				search={props.search}
				setSearch={props.setSearch}
				getRoutes={props.getRoutes}
				isLoadingRoutes={props.isLoadingRoutes}
				scrollPos={props.scrollPos}
				setScrollPos={props.setScrollPos}
				resetRoutes={props.resetRoutes}
				resetRoutesUser={props.resetRoutesUser}
				filterRoutes={props.filterRoutes}
				setIsLoadingRoutes={props.setIsLoadingRoutes}
				filters={props.filters}
				setFilters={props.setFilters}
				mapboxAccessToken={props.mapboxAccessToken}
				resetSearch={props.resetSearch}
				resetSearchUser={props.resetSearchUser}
				user={props.user}
			/>
		</>
	);
}
export default Dashboard;