import { NavLink as Link } from "react-router-dom";

import { NewTabIcon, SendIcon, ArrowIcon } from './Icons';

import Header from './Header';
import SocialBanner from './SocialBanner';
import Footer from './Footer';

function Unsubscribe(props) {

	return (
		<>
			<Header
				lang={props.lang}
				translations={props.translations}
				isLoading={props.isLoading}
				selected={props.lang}
				options={props.languages}
				changeOption={props.changeOption}
				flags={props.flags}
			/>
			<div className="row pricing-w d-flex justify-content">
				<div className="col-6 m-t-5">
					<div className="card-form smooth-shadow p-1 m-t-5 m-b-3">
						<h3 className="text-color">{ props.translations[props.lang].unsubscribe.title }</h3>
						<p className="text-gray">{ props.translations[props.lang].unsubscribe.subtitle }</p>
						<Link to={'/'}>
							<div className="button-menu d-flex justify-content-end p-0">
								<label className="text-color-accent-0">{ props.translations[props.lang].unsubscribe.button }</label>
								<div className="icon-white-0 icon-container icon-container-color-bg-accent-0">
									<NewTabIcon/>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</div>

			<SocialBanner
				lang={props.lang}
				translations={props.translations}
				urls={props.urls}
			/>
			<Footer
				theme={props.theme}
				themes={props.themes}
				lang={props.lang}
				languages={props.languages}
				translations={props.translations}
				changeOption={props.changeOption}
				themeIcons={props.themeIcons}
				flags={props.flags}
				urls={props.urls}
			/>
		</>
	);
}
export default Unsubscribe;