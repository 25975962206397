import { useState, useRef } from 'react';
import { NavLink as Link, useParams, useNavigate } from 'react-router-dom';

import { SendIcon, EyeIcon, EyeSlashIcon, LockIcon, AppLogo } from './Icons';

import Header from './Header';
import SocialBanner from './SocialBanner';
import Footer from './Footer';
import Select from './Select';

function Reset(props) {

	const navigate = useNavigate();
	let { code } = useParams();

	const passwordInput = useRef();
	const [isHidden, setIsHidden] = useState(true);

	const [isBorderP, setBorderP] = useState(false);

	function onFocusP () {
		setBorderP(true)
	}

	function onBlurP () {
		setBorderP(false)
	}

	function handlePasswordChange (e) {
		var resetCopy = {...props.reset};
        resetCopy.password = e.target.value;
        props.changeReset(resetCopy);
	}
	
	function handleIsHiddenClick () {
		const type = passwordInput.current.getAttribute("type") === "password" ? "text" : "password";
        passwordInput.current.setAttribute("type", type);
		setIsHidden(!isHidden);
	}

	async function reset () {

		let reset = {
			password: props.reset.password,
			lang: props.lang,
			theme: props.theme
		}

		const result = await fetch('https://wandelar.be/api/user/reset/'+code, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify( reset )
		}).then(response => {
			return response.json();
		}).then(data => {
			console.log(data)
			let backgroundClass = 'toast-box-error';
			if (data.status === 200) {
				backgroundClass = 'toast-box-success';
				// Reset form
				props.changeReset({
					password: '',
				})
				navigate('/Login');
			}
			props.addToast({
				bgColor: backgroundClass,
				title: props.translations[props.lang].reset.messages['msg'+data.status].title,
				msg: props.translations[props.lang].reset.messages['msg'+data.status].msg,
				caption: props.translations[props.lang].reset.messages['msg'+data.status].caption
			})
		}).catch(error => {
			console.log(error)
		});
		
    }

	return (
		<>
			<div className="mask-container mask-container-h-500">
				<div className="mask-shape mask-scale-120">
					<div className="mask-box mask-box-color"></div>
				</div>
				<div className="mask-box mask-top content">
				</div>
			</div>
			<div className="header">
                <div className="row">
                    <div className="col-12 d-flex justify-content align-items menu-desktop">
                        <Link to="/">
                            <div className="logo-content">
                                <div className="logo">
                                    <div className="logo-name">
                                        <AppLogo />
                                    </div>
                                </div>
                            </div>
                        </Link>
                        {/* <Select
							lang={props.lang}
							translations={props.translations}
							selected={props.lang}
							options={props.languages}
							changeOption={props.changeOption}
							type={'lang'}
							icons={null}
                            disabled={false}
							theme={props.theme}
						/> */}
                    </div>
                </div>                    
            </div>
			<div className="row banner">
				<div className="col-6 d-flex justify-content-start">
					<div className="d-block">
						<h3>{ props.translations[props.lang].reset.title }</h3>
						<h2>{ props.translations[props.lang].reset.subtitle }</h2>
					</div>
				</div>
			</div>
			<div className="row pricing-w d-flex justify-content">
				<div className="col-6 m-1">
					<div className="card-form smooth-shadow p-1">

						<p>{ props.translations[props.lang].reset.password }</p>
						<div className={`input-group ${ isBorderP && 'active' } br-8 m-b-1`}>
							<input
								ref={passwordInput}
								type="password"
								className={`form-control`}
								placeholder={ props.translations[props.lang].reset.passwordPlaceholder }
								value={props.reset.password}
								onChange={handlePasswordChange}
								onFocus={onFocusP}
								onBlur={onBlurP}
							/>
							{
								props.reset.password !== '' ? <span className={`input-group-text wh-44 p-none pointer`} onClick={ handleIsHiddenClick } >
									{
										isHidden ? <EyeIcon /> : <EyeSlashIcon />
									}
								</span>
								:
								<span className={`input-group-text wh-44 p-none pointer`}>
									<LockIcon />
								</span>
							}
						</div>

						<div className="d-flex flex-direction-row justify-content m-b-2"
							onClick={ reset }>
							<div className="button d-flex justify-content-end p-0">
								<label className="text-color-accent-0">{ props.translations[props.lang].reset.button }</label>
								<div className="icon-white-0 icon-container icon-container-color-bg-accent-0">
									<SendIcon />
								</div>
							</div>
						</div>

					</div>
				</div>
				
			</div>

			<div className="pricing-container pricing-icons m-b-2">
				<div className="pricing-box pricing-box-content">
					<label>{ props.translations[props.lang].reset.bigtitle }</label>
				</div>
			</div>

			<SocialBanner
				lang={props.lang}
				translations={props.translations}
				urls={props.urls}
			/>
			<Footer
				theme={props.theme}
				themes={props.themes}
				lang={props.lang}
				languages={props.languages}
				translations={props.translations}
				changeOption={props.changeOption}
				themeIcons={props.themeIcons}
				flags={props.flags}
				urls={props.urls}
			/>
		</>
	);
}
export default Reset;