import { useEffect, useState, useRef, useContext, forwardRef } from 'react';
import { useNavigate } from "react-router-dom";

import Loading from './Loading';
import Select from './Select';

import { SearchIcon, TagCrossIcon, RouteIcon, GridIcon, RowsIcon, NumberIcon, ErrorEmoji, ProfilesIcon, ClockIcon, ScrollTopIcon, PlusIcon, ImageIcon, RefreshIcon, FilterIcon, OkIcon, DismissIcon, CrossIcon, CrossIconSmall, PinIcon, LocationIcon, CheckedIcon, UnCheckedIcon, RouteEmoji } from './Icons';

import SpringScrollbars from './SpringScrollbars';
import { UserContext } from '../Hooks/UserContext';

import { Scrollbars } from 'react-custom-scrollbars-2';
import { SpringSystem, MathUtil } from 'rebound';

import './slider.css';
import SliderMerge from './SliderMerge';

function filterRoutes(routes, query, lang) {
	if (!query) {
		return routes;
	}

	return routes.filter((route) => {

		let bool = false;

		// Search by name
		const nameLowerCase = route.name[lang].toLowerCase();
		if (nameLowerCase.includes(query.toLowerCase())) {
			bool = nameLowerCase.includes(query.toLowerCase())
		}

		// Search by tag
		for (var i = 0; i < route.tags.length; i++) {
			const tagLowerCase = route.tags[i][lang].toLowerCase();
			if (tagLowerCase.includes(query.toLowerCase())) {
				bool = tagLowerCase.includes(query.toLowerCase())
			}
		}

		// Search by id
		const idLowerCase = route._id.toLowerCase();
		if (idLowerCase.includes(query.toLowerCase())) {
			bool = idLowerCase.includes(query.toLowerCase())
		}

		// Search by published
		const publishedLowerCase = `publish:${route.publish.toString().toLowerCase()}`;
		if (publishedLowerCase.includes(query.toLowerCase())) {
			bool = publishedLowerCase.includes(query.toLowerCase())
		}

		return bool;
	});
};


function Courses(props) {

	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState(false);

	const searchInput = useRef();
	const [searchQuery, setSearchQuery] = useState('');

	const searchLocationInput = useRef();
	const [searchLocationQuery, setSearchLocationQuery] = useState('');
	const [searchLocationBackup, setSearchLocationBackup] = useState('');
	// const filteredRoutes = filterRoutes(props.routes, searchQuery, props.lang);

	const [isBorderS, setBorderS] = useState(false);
	const [isBorderL, setBorderL] = useState(false);

	const homeTopDiv = useRef();
	const [scrollHeight, setScrollHeight] = useState(0)
	const divSize = 120
	const [divHeight, setDivHeight] = useState(window.innerWidth <= 769 ? divSize : 0)

	const springSystem = new SpringSystem();
	const spring = springSystem.createSpring();

	const [loading, setLoading] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
	const [loading_4, setLoading_4] = useState([0, 1, 2, 3]);
	const [isLoadingNewRoute, setIsLoadingNewRoute] = useState(false);

	const [scrollHeightFilter, setScrollHeightFilter] = useState(0);
	const [filterBox, setFilterBox] = useState(false);

	const [isFilterOn, setIsFilterOn] = useState(false);

	const [locationResult, setLocationResult] = useState(null);

	const [coordinates, setCoordinates] = useState(null);
	const [searchRadius, setSearchRadius] = useState(50);
	const [distanceRange, setDistanceRange] = useState([0, 40]);
	const [ageRange, setAgeRange] = useState([0, 99]);
	const [game, setGame] = useState(null);
	const [timer, setTimer] = useState(null);

	const sliderDistanceRef = useRef(null);
	const sliderAgeRef = useRef(null);

	useEffect(() => {
		window.addEventListener('click', handleClick);
		window.addEventListener('resize', handleResize);
		setScrollHeightFilter(window.innerHeight - 145);

		if (window.innerWidth <= 769) {
			setScrollHeight(window.innerHeight - homeTopDiv.current.clientHeight - 30);
		} else {
			setScrollHeight(window.innerHeight - homeTopDiv.current.clientHeight);
		}

		spring.addListener({ onSpringUpdate: handleSpringUpdate });

		props.resetSearch();
		props.resetSearchUser();

		search();

		// console.log(homeTopDiv.current.clientHeight)
		// setScrollHeight(window.innerHeight - homeTopDiv.current.clientHeight - 50);

		// cleanup this component
		return () => {
			window.removeEventListener('click', handleClick);
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('onSpringUpdate', handleSpringUpdate);
		};
	}, [])

	function isFilterBox(bool) {
		setFilterBox(bool);
	}

	function clearFilters() {

		// For UI
		let newFilter = { ...props.filters }
		newFilter["location"] = false;
		newFilter["nearMe"] = false;
		newFilter["distance"] = false;
		newFilter["age"] = false;
		newFilter["withGame"] = false;
		newFilter["noGame"] = false;
		newFilter["withTimer"] = false;
		newFilter["noTimer"] = false;
		props.setFilters(newFilter);
		setSearchLocationQuery('');
		setSearchLocationBackup('');
		setLocationResult(null);

		// For data
		setCoordinates(null);
		setSearchRadius(50);
		setDistanceRange(null);
		setAgeRange(null);
		setGame(null);
		setTimer(null);

		let body = {
			lang: props.lang,
			page: 0,
			limit: 10,
			query: "",
			location: {
				coordinates: null,
				radius: 50
			},
			distance: {
				range: null
			},
			age: {
				range: null
			},
			game: null,
			timer: null
		}
		props.setSearch(body);

	}

	const handleSpringUpdate = () => {
		const val = spring.getCurrentValue();
		scrollbars.current.scrollTop(val);
	}

	function getScrollTop() {
		return scrollbars.current.getScrollTop();
	}

	function getScrollHeight() {
		return scrollbars.current.getScrollHeight();
	}

	function getClientHeight() {
		return scrollbars.current.getClientHeight();
	}

	function scrollTo(top) {
		props.setScrollPos(top)
		// const scrollTop = getScrollTop();
		// const scrollHeight = getScrollHeight();
		// const val = MathUtil.mapValueInRange(top, 0, scrollHeight, scrollHeight * 0, scrollHeight * 0);
		// spring.setCurrentValue(scrollTop).setAtRest();
		// spring.setEndValue(val);
	}

	function scrollToStatic(val) {
		scrollbars.current.scrollTop(val);
	}

	function onFocusS() {
		setBorderS(true)
	}

	function onFocusL() {
		setBorderL(true)
	}

	function onBlurL() {
		setBorderL(false)
	}

	function handleSearchClick(e) {
		search();
	}

	async function handleSearchLocationClick(e) {

		const result = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchLocationQuery}.json?access_token=${props.mapboxAccessToken}`, {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		}).then(response => {
			return response.json();
		}).then(result => {

			if (result && result.features.length > 0) {
				setLocationResult(result)
			} else {
				props.addToast({
					bgColor: 'toast-box-error',
					title: props.translations[props.lang].filter.location.error.title,
					msg: props.translations[props.lang].filter.location.error.msg,
					caption: props.translations[props.lang].filter.location.error.caption
				})
			}

		}).catch(error => {
			console.log(error);
		});

	}

	function handleFilterClick(e) {
		isFilterBox(!filterBox)
		// setBorderS(true)
		// searchInput.current.focus();
	}

	function setQuery(value) {
		setSearchLocationQuery(value);
		setLocationResult(null);
	}

	function handleClick(e) {

		if (e.target.id === 'tag-cross-icon') {
			setSearchQuery('');
			searchInput.current.focus();
		} else if (e.target.id === 'filter-icon') {
			// searchInput.current.focus();
		} else if (e.target.id === 'search-input') {
			searchInput.current.focus();
		} else if (e.target.id === 'search-icon') {
			// searchInput.current.focus();
		} else {
			setBorderS(false)
		}

	}

	function handleResize(e) {
		let divMenuHeight = 0;
		if (window.innerWidth <= 769) {
			divMenuHeight = divSize
			setScrollHeight(window.innerHeight - homeTopDiv.current.clientHeight - 30);
		} else {
			setScrollHeight(window.innerHeight - divMenuHeight);
		}

		setDivHeight(divMenuHeight)
		// setScrollHeight(window.innerHeight - homeTopDiv.current.clientHeight)
		setScrollHeightFilter(window.innerHeight - 145);
	}

	const convertUTC = utcDate => {

		const date = new Date(utcDate);

		const dayIndex = date.getDate();
		const dayName = props.translations[props.lang].days[date.getDay()];

		const monthIndex = date.getMonth(); // Be careful! January is 0, not 1
		const monthName = props.translations[props.lang].months[monthIndex];
		const year = date.getFullYear();
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		const seconds = date.getSeconds().toString().padStart(2, '0');

		const dateString = `${dayName}, ${dayIndex} ${monthName} ${year}`;
		const timeString = `${hours}h ${minutes}m ${seconds}s`;

		return [dateString, timeString];
	}

	const scrollbars = useRef();
	function scrollToTop() {
		scrollToStatic(0);
	}
	function scrollToBottom() {
		const scrollHeight = getScrollHeight();
		scrollToStatic(scrollHeight);
	}

	function refresh() {

		scrollToStatic(0);

		props.setIsLoadingRoutes(true);
		let promises = []
		if (user.roles.includes('admin')) {
			const resetRoutes = props.resetRoutes();
			promises.push(resetRoutes);
		}
		const resetRoutesUser = props.resetRoutesUser();
		promises.push(resetRoutesUser);

		Promise.all(promises).then((values) => {
			props.setIsLoadingRoutes(false);
		});
	}

	function selectRoute(data) {
		const dataClone = JSON.parse(JSON.stringify(data));
		const dataCloneNew = JSON.parse(JSON.stringify(data));
		props.changeCourse(dataClone)
		props.changeCourseNew(dataCloneNew)
		navigate('/Route/' + data._id)
	}

	const addNewRoute = async () => {

		// Add to database
		const res = await fetch('https://wandelar.be/api/routes', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${user.token}`
			}
		}).then(response => {
			return response.json();
		}).then(result => {

			if (result.status === 201) {

				setIsLoadingNewRoute(true);

				// Wait for indexing and then reset routes
				setTimeout(() => {

					let promises = []
					if (user.roles.includes('admin')) {
						const resetRoutes = props.resetRoutes();
						promises.push(resetRoutes);
					}
					const resetRoutesUser = props.resetRoutesUser();
					promises.push(resetRoutesUser);

					Promise.all(promises).then((values) => {
						setIsLoadingNewRoute(false);
						selectRoute(result.route);
					});

				}, 2000)

			} else {
				props.addToast({
					bgColor: 'toast-box-error',
					title: props.translations[props.lang].course.new.error.title,
					msg: props.translations[props.lang].course.new.error.msg,
					caption: props.translations[props.lang].course.new.error.caption
				})
			}

		}).catch(error => {
			console.log(error);
		});
	}

	function renderView({ style, ...props }) {
		const top = 0;
		const padding = '';
		const viewStyle = {
			// padding: 15,
			// backgroundColor: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(top * 255)}, ${Math.round(255)})`,
			// color: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))})`
			marginBottom: '0px',
		};
		return (
			<div
				className={`box ${padding}`}
				style={{ ...style, ...viewStyle }}
				{...props} />
		);
	}

	function renderThumb({ style, ...props }) {
		const top = 0;
		const thumbColor = 'rgba(116, 125, 133, 0.2)';
		const padding = '';
		const thumbStyle = {
			borderRadius: '10px',
			right: '-4px',
			width: '4px',
			backgroundColor: thumbColor
		};
		return (
			<div
				className={`${thumbColor}`}
				style={{ ...style, ...thumbStyle }}
				{...props} />
		);
	}

	const onScroll = (e) => {
		props.setScrollPos(e.target.scrollTop)
	}

	const onUpdate = async (e) => {
		let bottom = getScrollHeight() - getScrollTop() === getClientHeight();

		if (bottom && !props.isLoadingRoutes) {
			props.getRoutes();

			props.setScrollPos(getScrollHeight() - 1)
			// bottom = false;
		}
	}

	function applyAndSearch() {
		search();
	}

	function search() {

		if (props.filters.location && coordinates === null) {
			props.addToast({
				bgColor: 'toast-box-error',
				title: props.translations[props.lang].filter.location.error.title,
				msg: props.translations[props.lang].filter.location.error.msg,
				caption: props.translations[props.lang].filter.location.error.caption
			})
			return;
		}

		scrollToStatic(0);

		setSearchLocationQuery(searchLocationBackup);

		let distanceMinMax = null;
		if (distanceRange && props.filters.distance) {
			distanceMinMax = {
				min: distanceRange[0],
				max: distanceRange[1]
			}
		}

		let ageMinMax = null;
		if (ageRange && props.filters.age) {
			ageMinMax = {
				min: ageRange[0],
				max: ageRange[1]
			}
		}

		let body = {
			lang: props.lang,
			page: 0,
			limit: 10,
			query: searchQuery,
			location: {
				coordinates: coordinates,
				radius: searchRadius
			},
			distance: {
				range: distanceMinMax
			},
			age: {
				range: ageMinMax
			},
			game: game,
			timer: timer
		}

		let bodyInitial = {
			lang: props.lang,
			page: 0,
			limit: 10,
			query: "",
			location: {
				coordinates: null,
				radius: 50
			},
			distance: {
				range: null
			},
			age: {
				range: null
			},
			game: null,
			timer: null
		}

		if (JSON.stringify(body) === JSON.stringify(bodyInitial)) {
			setIsFilterOn(false);
		} else {
			setIsFilterOn(true);
		}

		props.setIsLoadingRoutes(true);

		let promises = [];
		const resetRoutes = props.filterRoutes(body);
		promises.push(resetRoutes);

		Promise.all(promises).then((values) => {
			props.setIsLoadingRoutes(false);
			isFilterBox(false);
		});
	}

	function setPlace(query, coordinates) {
		setCoordinates([coordinates[1], coordinates[0]]);
		setSearchLocationQuery(query);
		setSearchLocationBackup(query);
		setLocationResult(null);
	}

	function highlightedText(sentence, words) {
		const highlightedSentence = highlightWords(sentence, words);
		return (
			<div dangerouslySetInnerHTML={{ __html: highlightedSentence }} />
		);
	}

	function highlightWords(sentence, words) {
		let word = "", result = "";
		let wasUppercase = false;
		for (let i = 0; i < sentence.length; i++) {
			const char = sentence[i];
			if (/[a-zA-Z0-9]/.test(char)) {
				word += char;
				wasUppercase = /[A-Z]/.test(char);
			} else {
				result += processWord(word, wasUppercase, words) + char;
				word = "";
				wasUppercase = false;
			}
		}
		result += processWord(word, wasUppercase, words);
		return result;

		function processWord(word, wasUppercase, words) {
			let result = "";
			let lowerCaseWord = word.toLowerCase();
			for (let j = 0; j < words.length; j++) {
				const index = lowerCaseWord.indexOf(words[j].toLowerCase());
				if (index !== -1) {
					result += word.slice(0, index);
					result += `<label class="color-purple fw-0">${word.slice(index, index + words[j].length)}</label>`;
					word = word.slice(index + words[j].length);
					lowerCaseWord = word.toLowerCase();
				}
			}
			return result + (wasUppercase ? word[0].toUpperCase() + word.slice(1) : word);
		}
	}

	function toggleLocation() {
		let newFilter = { ...props.filters }
		newFilter["location"] = !props.filters.location;
		newFilter["nearMe"] = false;
		props.setFilters(newFilter);

		setCoordinates(null);
		setSearchRadius(50);
	}

	function toggleDistance() {

		if (props.filters.distance) {
			setDistanceRange(null);
		} else {
			setDistanceRange([0, 40])
		}

		let newFilter = { ...props.filters }
		newFilter["distance"] = !props.filters.distance;
		props.setFilters(newFilter);
	}

	function toggleAge() {

		if (props.filters.age) {
			setAgeRange(null);
		} else {
			setAgeRange([0, 99])
		}

		let newFilter = { ...props.filters }
		newFilter["age"] = !props.filters.age;
		props.setFilters(newFilter);
	}

	async function toggleNearMe() {
		if (!props.filters.nearMe) {

			navigator.geolocation.getCurrentPosition((data, error) => {
				if (data && !error) {
					setCoordinates(getCoordinates(data));

					setSearchLocationQuery('');
					setSearchLocationBackup('');
					setLocationResult(null);

					let newFilter = { ...props.filters }
					newFilter["nearMe"] = !props.filters.nearMe;
					props.setFilters(newFilter);
				} else {
					props.addToast({
						bgColor: 'toast-box-error',
						title: props.translations[props.lang].filter.geo.error.title,
						msg: props.translations[props.lang].filter.geo.error.msg,
						caption: props.translations[props.lang].filter.geo.error.caption
					})
				}
				console.log(error)
			});

		} else {
			setCoordinates(null);
			let newFilter = { ...props.filters }
			newFilter["nearMe"] = !props.filters.nearMe;
			props.setFilters(newFilter);
		}
	}

	function getCoordinates(position) {
		let coordinates = [position.coords.latitude, position.coords.longitude]
		return coordinates;
	}

	function toggleWithGame() {

		if (props.filters.noGame) {
			let newFilter = { ...props.filters }
			newFilter["noGame"] = !props.filters.noGame;
			newFilter["withGame"] = !props.filters.withGame;
			props.setFilters(newFilter);

			if (!newFilter.withGame && !newFilter.noGame) {
				setGame(null)
			} else if (newFilter.withGame) {
				setGame(true)
			} else if (newFilter.noGame) {
				setGame(false)
			}
		} else {
			let newFilter = { ...props.filters }
			newFilter["withGame"] = !props.filters.withGame;
			props.setFilters(newFilter);

			if (!newFilter.withGame && !newFilter.noGame) {
				setGame(null)
			} else if (newFilter.withGame) {
				setGame(true)
			} else if (newFilter.noGame) {
				setGame(false)
			}
		}

	}

	function toggleNoGame() {

		if (props.filters.withGame) {
			let newFilter = { ...props.filters }
			newFilter["withGame"] = !props.filters.withGame;
			newFilter["noGame"] = !props.filters.noGame;
			props.setFilters(newFilter);

			if (!newFilter.withGame && !newFilter.noGame) {
				setGame(null)
			} else if (newFilter.withGame) {
				setGame(true)
			} else if (newFilter.noGame) {
				setGame(false)
			}
		} else {
			let newFilter = { ...props.filters }
			newFilter["noGame"] = !props.filters.noGame;
			props.setFilters(newFilter);

			if (!newFilter.withGame && !newFilter.noGame) {
				setGame(null)
			} else if (newFilter.withGame) {
				setGame(true)
			} else if (newFilter.noGame) {
				setGame(false)
			}
		}

	}

	function toggleWithTimer() {

		if (props.filters.noTimer) {
			let newFilter = { ...props.filters }
			newFilter["noTimer"] = !props.filters.noTimer;
			newFilter["withTimer"] = !props.filters.withTimer;
			props.setFilters(newFilter);

			if (!newFilter.withTimer && !newFilter.noTimer) {
				setTimer(null)
			} else if (newFilter.withTimer) {
				setTimer(true)
			} else if (newFilter.noTimer) {
				setTimer(false)
			}

		} else {
			let newFilter = { ...props.filters }
			newFilter["withTimer"] = !props.filters.withTimer;
			props.setFilters(newFilter);

			if (!newFilter.withTimer && !newFilter.noTimer) {
				setTimer(null)
			} else if (newFilter.withTimer) {
				setTimer(true)
			} else if (newFilter.noTimer) {
				setTimer(false)
			}
		}
	}

	function toggleNoTimer() {

		if (props.filters.withTimer) {
			let newFilter = { ...props.filters }
			newFilter["withTimer"] = !props.filters.withTimer;
			newFilter["noTimer"] = !props.filters.noTimer;
			props.setFilters(newFilter);

			if (!newFilter.withTimer && !newFilter.noTimer) {
				setTimer(null)
			} else if (newFilter.withTimer) {
				setTimer(true)
			} else if (newFilter.noTimer) {
				setTimer(false)
			}

		} else {
			let newFilter = { ...props.filters }
			newFilter["noTimer"] = !props.filters.noTimer;
			props.setFilters(newFilter);

			if (!newFilter.withTimer && !newFilter.noTimer) {
				setTimer(null)
			} else if (newFilter.withTimer) {
				setTimer(true)
			} else if (newFilter.noTimer) {
				setTimer(false)
			}
		}

	}

	useEffect(() => {
		if (scrollbars.current) {
			scrollToStatic(props.scrollPos);
		}
	}, [])

	const tipHandleRender = (node, handleProps) => {
		return (
			<div style={{
				// borderColor: 'blue',
				borderRadius: '4px',
				height: 28,
				width: 28,
				marginLeft: -8,
				marginTop: -8,
				backgroundColor: 'rgba(116, 125, 133, 0.2)',
				cursor: 'default'
			}}>
				test
			</div>
		);
	};

	return (
		<>
			<div className="page-box home_content">

				{
					isLoading ?
						<Loading
							lang={props.lang}
							translations={props.translations}
						/>
						:
						<>
							<div className="top-div-p" ref={homeTopDiv}>
								<div className="row">
									<div className="col-12">
										<div className="row">
											<div className="d-flex flex-direction-row justify-content-space-between">
												<div className="d-flex flex-direction-column">
													<div className="fs-3">
														<label>{props.translations[props.lang].dashboard.hello} {user.firstname}</label>
													</div>
													<div className="text-color-gray fs-1 m-b-1">
														<label>{props.translations[props.lang].dashboard.title}</label>
													</div>
												</div>
											</div>
										</div>
										<div className="card-form d-flex flex-direction-row align-items m-b-0">
											<div className={`input-group ${isBorderS && 'active'} br-8`}>
												<span className={`input-group-text wh-44 p-none`}>
													<div id="filter" className="wh-44 pointer" onClick={handleFilterClick} >
														{
															isFilterOn && <div className="dot-purple position-absolute top-dot left-dot"></div>
														}
														<FilterIcon />
													</div>
												</span>
												<input
													id="search-input"
													ref={searchInput}
													type="text"
													className={`form-control`}
													placeholder={props.translations[props.lang].configurator.searchPlaceholder}
													value={searchQuery}
													onInput={e => setSearchQuery(e.target.value)}
													onFocus={onFocusS}
												/>
												<div className={`d-flex flex-direction-row`}>
													{/* {
														isBorderS === true && searchQuery !== '' &&
														<div id="tag-cross" className="wh-44">
															<TagCrossIcon />
														</div>
													} */}
													<div id="search" className="wh-44" onClick={handleSearchClick} >
														<SearchIcon />
													</div>
												</div>
											</div>
											<div className="p-l-0 pointer" onClick={refresh}>
												<div className="wh-42 icon-bg refresh">
													<RefreshIcon />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<div className="filter">
											<div className="d-flex flex-direction-row justify-content-space-between flex-wrap">
												<div className="d-flex flex-direction-row justify-content-start">
													<div className="icon-active d-flex flex-direction-row">
														<div className={`icon-bg pointer ${props.routeLayout === 'grid' && 'active'}`} onClick={() => {
															props.setRouteLayout('grid')
															localStorage.setItem('routeLayout', 'grid')
															scrollToStatic(0);
														}}>
															<GridIcon />
														</div>
														<div className="p-l-0">
															<div className={`icon-bg pointer ${props.routeLayout === 'list' && 'active'}`} onClick={() => {
																props.setRouteLayout('list')
																localStorage.setItem('routeLayout', 'list')
																scrollToStatic(0);
															}}>
																<RowsIcon />
															</div>
														</div>
													</div>
													{/* <div className="float-right scale-9 select-on-top">
														<Select
															lang={props.lang}
															translations={props.translations}
															selected={props.selected}
															options={props.options}
															changeOption={props.changeOption}
															type={'sort'}
															icons={null}
															disabled={false}
															theme={''}
														/>
													</div> */}
													{/* <div className="p-l-0" onClick={scrollToTop} >
														<div className="icon-bg">
															<ScrollTopIcon />
														</div>
													</div>
													<div className="p-l-0" onClick={scrollToBottom} >
														<div className="icon-bg rotate-180">
															<ScrollTopIcon />
														</div>
													</div> */}
												</div>
												{
													props.routes.length === 0 ? <>
														{
															isFilterOn && <>
																<div className="d-flex flex-direction-row justify-content-end">
																	<div className="d-flex flex-direction-row m-l-1"
																		onClick={addNewRoute}
																	>
																		<div className="button d-flex justify-content-end p-0">
																			<label className="text text-color-accent-0">{props.translations[props.lang].course.create}</label>
																			<div className="wh-42 icon-container icon-container-color-bg-accent-0">
																				<PlusIcon />
																			</div>
																		</div>
																	</div>
																</div>
															</>
														}
													</>
														:
														<div className="d-flex flex-direction-row justify-content-end">
															<div className="d-flex flex-direction-row m-l-1"
																onClick={addNewRoute}
															>
																<div className="button d-flex justify-content-end p-0">
																	<label className="text text-color-accent-0">{props.translations[props.lang].course.create}</label>
																	<div className="wh-42 icon-container icon-container-color-bg-accent-0">
																		<PlusIcon />
																	</div>
																</div>
															</div>
														</div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="br-8 overflow-hidden route-padding"
								style={{
									height: scrollHeight
								}}
							>
								<Scrollbars
									ref={scrollbars}
									style={{
										backgroundColor: 'transparent',
										height: scrollHeight,
										zIndex: 0
									}}
									renderView={renderView}
									// renderThumbHorizontal={renderThumb}
									renderThumbVertical={renderThumb}
									onUpdate={onUpdate}
									onScroll={onScroll}
								>
									<div className="row">
										<>
											{
												props.isLoadingRoutes &&
												<>
													{
														props.routeLayout === 'grid' &&
														<>
															{
																loading.map((i) => {
																	return <div key={`${i}_loading`} className="route-cl-3 p-3">
																		<div className="route-item pointer-none">
																			<div className="route-pointer-box pointer-none">
																				<div className="shimmer-overlay">
																					<div className="route-preview-image">
																						<div className="route-preview-image-container shimmerBg d-flex justify-content align-items"></div>
																					</div>
																					<div className="route-header">
																						<div className="dot-gray m-r-1 shimmerBg"></div>
																						<label className="text fs-2 text-load shimmerBg"></label>
																					</div>
																					<div className="route-footer d-flex flex-direction-row align-items-end justify-content-space-between load-p">
																						<label className="caption-load shimmerBg"></label>
																						<label className="distance-load d-flex justify-content-end shimmerBg"></label>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																})
															}
														</>
													}
													{
														props.routeLayout === 'list' &&
														<>
															{
																loading.map((i) => {
																	return <div key={`${i}_loading`} className="route-list pointer-none p-3">
																		<div className="shimmer-overlay">
																			<div className="route-list-item-bg d-flex flex-direction-row justify-content-space-between align-items pointer-none">
																				<div className="w-100 d-flex flex-direction-row align-items">

																					<div className="route-list-image shimmerBg"></div>
																					<div className="w-100 d-flex flex-direction-column justify-content-space-between p-l-15">
																						<div className="d-flex flex-direction-row align-items">
																							<div className="dot-gray m-r-1 shimmerBg"></div>
																							<label className="fs-1 list text-load shimmerBg"></label>
																						</div>
																						<div className="d-flex justify-content-space-between align-items-end m-t-0">
																							<label className="user-load shimmerBg"></label>
																							<label className="text fs-2 distance-load d-flex justify-content-end shimmerBg m-r-15"></label>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																})
															}
														</>
													}
												</>
											}
										</>
										<>
											{
												isLoadingNewRoute && props.routeLayout === 'grid' &&

												<div className="route-cl-3 p-3">
													<div className="route-item pointer-none">
														<div className="route-pointer-box pointer-none">
															<div className="shimmer-overlay">
																<div className="route-preview-image">
																	<div className="route-preview-image-container shimmerBg d-flex justify-content align-items"></div>
																</div>
																<div className="route-header">
																	<div className="dot-gray m-r-1 shimmerBg"></div>
																	<label className="text fs-2 text-load shimmerBg"></label>
																</div>
																<div className="route-footer d-flex flex-direction-row align-items-end justify-content-space-between load-p">
																	<label className="caption-load shimmerBg"></label>
																	<label className="distance-load d-flex justify-content-end shimmerBg"></label>
																</div>
															</div>
														</div>
													</div>
												</div>

											}
											{
												isLoadingNewRoute && props.routeLayout === 'list' &&
												<div className="route-list pointer-none p-3">
													<div className="shimmer-overlay">
														<div className="route-list-item-bg d-flex flex-direction-row justify-content-space-between align-items pointer-none">
															<div className="w-100 d-flex flex-direction-row align-items">

																<div className="route-list-image shimmerBg"></div>
																<div className="w-100 d-flex flex-direction-column justify-content-space-between p-l-15">
																	<div className="d-flex flex-direction-row align-items">
																		<div className="dot-gray m-r-1 shimmerBg"></div>
																		<label className="fs-1 list text-load shimmerBg"></label>
																	</div>
																	<div className="d-flex justify-content-space-between align-items-end m-t-0">
																		<label className="user-load shimmerBg"></label>
																		<label className="text fs-2 distance-load d-flex justify-content-end shimmerBg m-r-15"></label>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											}
										</>
										<>
											{
												props.routes.length === 0 && props.isLoadingRoutes ?
													<>
														{
															props.routeLayout === 'grid' &&
															<>
																{
																	loading.map((i) => {
																		return <div key={`${i}_loading`} className="route-cl-3 p-3">
																			<div className="route-item pointer-none">
																				<div className="route-pointer-box pointer-none">
																					<div className="shimmer-overlay">
																						<div className="route-preview-image">
																							<div className="route-preview-image-container shimmerBg d-flex justify-content align-items"></div>
																						</div>
																						<div className="route-header">
																							<div className="dot-gray m-r-1 shimmerBg"></div>
																							<label className="text fs-2 text-load shimmerBg"></label>
																						</div>
																						<div className="route-footer d-flex flex-direction-row align-items-end justify-content-space-between load-p">
																							<label className="caption-load shimmerBg"></label>
																							<label className="distance-load d-flex justify-content-end shimmerBg"></label>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	})
																}
															</>
														}
														{
															props.routeLayout === 'list' &&
															<>
																{
																	loading.map((i) => {
																		return <div key={`${i}_loading`} className="route-list pointer-none p-3">
																			<div className="shimmer-overlay">
																				<div className="route-list-item-bg d-flex flex-direction-row justify-content-space-between align-items pointer-none">
																					<div className="w-100 d-flex flex-direction-row align-items">

																						<div className="route-list-image shimmerBg"></div>
																						<div className="w-100 d-flex flex-direction-column justify-content-space-between p-l-15">
																							<div className="d-flex flex-direction-row align-items">
																								<div className="dot-gray m-r-1 shimmerBg"></div>
																								<label className="fs-1 list text-load shimmerBg"></label>
																							</div>
																							<div className="d-flex justify-content-space-between align-items-end m-t-0">
																								<label className="user-load shimmerBg"></label>
																								<label className="text fs-2 distance-load d-flex justify-content-end shimmerBg m-r-15"></label>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	})
																}
															</>
														}
													</>
													:
													<>
														{
															props.routes.length === 0 ?
																<>
																	{
																		isFilterOn ? <div className="noRoutesFound p-4">
																			<ErrorEmoji />
																			<label className="title m-b-1 word-wrap">
																				{props.translations[props.lang].noRoutesFoundTitle} </label>
																			<label className="subtitle word-wrap">
																				{props.translations[props.lang].noRoutesFoundSubTitle} </label>
																			<label className="subtitle m-t-0 word-wrap">
																				{props.translations[props.lang].noRoutesFoundText} </label>
																		</div>
																			:
																			<>
																				{
																					!isLoadingNewRoute && <>
																						<div className="noRoutesFound p-4 m-b-1">
																							{/* <RouteEmoji /> */}
																							<label className="fs-3 word-wrap ff-corben">{props.translations[props.lang].noRoutesTitle}</label>
																							<label className="subtitle word-wrap m-t-0">{props.translations[props.lang].noRoutesSubTitle}</label>
																							<div className="d-flex flex-direction-row justify-content m-t-1">
																								<div className="d-flex flex-direction-row"
																									onClick={addNewRoute}
																								>
																									<div className="button d-flex justify-content-end p-0">
																										{/* <label className="text text-color-accent-0">{props.translations[props.lang].course.create}</label> */}
																										<div className="wh-42 icon-container icon-container-color-bg-accent-0">
																											<PlusIcon />
																										</div>
																									</div>
																								</div>
																							</div>
																							<label className="subtitle m-t-0 word-wrap m-t-1">{props.translations[props.lang].noRoutesText}</label>
																						</div>
																						{
																							props.routeLayout === 'grid' &&
																							<>
																								{
																									loading_4.map((i) => {
																										return <div key={`${i}_loading`} className="route-cl-3 p-3">
																											<div className="route-item pointer-none">
																												<div className="route-pointer-box pointer-none">
																												{/* <div className="shimmer-overlay"> */}
																													<div className="">
																														<div className="route-preview-image">
																															<div className="route-preview-image-container shimmerBg d-flex justify-content align-items"></div>
																														</div>
																														<div className="route-header">
																															<div className="dot-gray m-r-1 shimmerBg"></div>
																															<label className="text fs-2 text-load shimmerBg"></label>
																														</div>
																														<div className="route-footer d-flex flex-direction-row align-items-end justify-content-space-between load-p">
																															<label className="caption-load shimmerBg"></label>
																															<label className="distance-load d-flex justify-content-end shimmerBg"></label>
																														</div>
																													</div>
																												</div>
																											</div>
																										</div>
																									})
																								}
																							</>
																						}
																						{
																							props.routeLayout === 'list' &&
																							<>
																								{
																									loading_4.map((i) => {
																										return <div key={`${i}_loading`} className="route-list pointer-none p-3">
																											{/* <div className="shimmer-overlay">
																											 */}
																											 <div className="">
																												<div className="route-list-item-bg d-flex flex-direction-row justify-content-space-between align-items pointer-none">
																													<div className="w-100 d-flex flex-direction-row align-items">

																														<div className="route-list-image shimmerBg"></div>
																														<div className="w-100 d-flex flex-direction-column justify-content-space-between p-l-15">
																															<div className="d-flex flex-direction-row align-items">
																																<div className="dot-gray m-r-1 shimmerBg"></div>
																																<label className="fs-1 list text-load shimmerBg"></label>
																															</div>
																															<div className="d-flex justify-content-space-between align-items-end m-t-0">
																																<label className="user-load shimmerBg"></label>
																																<label className="text fs-2 distance-load d-flex justify-content-end shimmerBg m-r-15"></label>
																															</div>
																														</div>
																													</div>
																												</div>
																											</div>
																										</div>
																									})
																								}
																							</>
																						}
																					</>
																				}
																			</>
																	}
																</>
																:
																<>
																	{
																		props.routeLayout === 'grid' &&
																		<>
																			{
																				props.routes.map((item, i) => {
																					return <div key={item._id} className="route-cl-3 p-3" onClick={e => selectRoute(item)}>
																						<div className="route-item">
																							<div className="route-pointer-box pointer-none">
																								<div className="route-preview-image">
																									{
																										item.images.find(o => o.state === 'icon') ?
																											<>
																												<div className="route-preview-image-container" style={{ backgroundImage: `url(${item.images.find(o => o.state === 'icon').url})` }}></div>
																											</>
																											:
																											<>
																												<div className="route-preview-image-container d-flex justify-content align-items">
																													<ImageIcon />
																												</div>
																											</>
																									}
																									{
																										Object.keys(item.theme).length !== 0 && <div className="route-game">
																											<div className="route-game-image" style={{ backgroundImage: `url(${item.theme.images.find(o => o.state === 'icon').url})` }}></div>
																										</div>
																									}
																								</div>
																								<div className="route-header d-flex flex-direction-column justify-content-start align-items-start">
																									<div className="d-flex flex-direcion-row justify-content-start align-items">
																										{
																											item.publish === true ? <div className="pulseBlue m-r-1"></div> : <div className="dot-gray m-r-1"></div>
																										}
																										<label className="text fs-1 grid">{item.route === '' ? props.translations[props.lang].course.routePlaceholder : item.route}</label>
																									</div>
																									<label className="text-date m-t-0">@{item.user.username}</label>
																								</div>
																								<div className="route-footer d-flex flex-direction-row align-items justify-content-space-between">
																									<div className="tags-text ellipsis">
																										<label>{item.address.region === '' ? props.translations[props.lang].course.address.region : item.address.region}</label>
																									</div>
																									<div className="p-l-0">
																										<label className="d-flex justify-content-end fs-15 text-color-gray p-r-15">{item.distance} km</label>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				})
																			}
																			{
																				props.isLoadingRoutes &&
																				<>
																					{
																						loading.map((i) => {
																							return <div key={`${i}_loading`} className="route-cl-3 p-3">
																								<div className="route-item pointer-none">
																									<div className="route-pointer-box pointer-none">
																										<div className="shimmer-overlay">
																											<div className="route-preview-image">
																												<div className="route-preview-image-container shimmerBg d-flex justify-content align-items"></div>
																											</div>
																											<div className="route-header">
																												<div className="dot-gray m-r-1 shimmerBg"></div>
																												<label className="text fs-2 text-load shimmerBg"></label>
																											</div>
																											<div className="route-footer d-flex flex-direction-row align-items-end justify-content-space-between load-p">
																												<label className="caption-load shimmerBg"></label>
																												<label className="distance-load d-flex justify-content-end shimmerBg"></label>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																						})
																					}
																				</>
																			}
																		</>
																	}
																	{
																		props.routeLayout === 'list' &&
																		<>
																			{
																				props.routes.map((item, i) => {
																					return <div key={item._id} className="route-list p-3" onClick={e => selectRoute(item)}>
																						<div className="route-list-item">
																							<div className="route-list-item-bg d-flex flex-direction-row justify-content-space-between align-items pointer-none">
																								<div className="w-100 d-flex flex-direction-row align-items">
																									<div className="route-list-image">
																										{
																											item.images.find(o => o.state === 'icon') ?
																												<>
																													<div className="route-list-image-container" style={{ backgroundImage: `url(${item.images.find(o => o.state === 'icon').url})` }}></div>
																												</>
																												:
																												<>
																													<div className="route-list-image-container d-flex justify-content align-items">
																														<ImageIcon />
																													</div>
																												</>
																										}
																										{
																											Object.keys(item.theme).length !== 0 && <div className="route-game-list">
																												<div className="route-game-image-list" style={{ backgroundImage: `url(${item.theme.images.find(o => o.state === 'icon').url})` }}></div>
																											</div>
																										}
																									</div>
																									<div className="w-100 d-flex flex-direction-column justify-content-space-between p-l-15">
																										<div className="d-flex flex-direction-row align-items">
																											{
																												item.publish === true ? <div className="pulseBlue m-r-1"></div> : <div className="dot-gray m-r-1"></div>
																											}
																											<div className="multiline-ellipsis p-l-0">
																												<label className="fs-1 list">{item.route === '' ? props.translations[props.lang].course.routePlaceholder : item.route}</label>
																											</div>
																										</div>
																										<div className="caption d-flex justify-content-space-between m-t-15">
																											<div className="caption-text m-t-0">
																												<span className="text-date">@{item.user.username}</span>
																											</div>
																											<div>
																												<label className="text-caption p-r-0">{`${item.distance.toFixed(1)} km`}</label>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				})
																			}
																			{
																				props.isLoadingRoutes &&
																				<>
																					{
																						loading.map((i) => {
																							return <div key={`${i}_loading`} className="route-list pointer-none p-3">
																								<div className="shimmer-overlay">
																									<div className="route-list-item-bg d-flex flex-direction-row justify-content-space-between align-items pointer-none">
																										<div className="w-100 d-flex flex-direction-row align-items">

																											<div className="route-list-image shimmerBg"></div>
																											<div className="w-100 d-flex flex-direction-column justify-content-space-between p-l-15">
																												<div className="d-flex flex-direction-row align-items">
																													<div className="dot-gray m-r-1 shimmerBg"></div>
																													<label className="fs-1 list text-load shimmerBg"></label>
																												</div>
																												<div className="d-flex justify-content-space-between align-items-end m-t-0">
																													<label className="user-load shimmerBg"></label>
																													<label className="text fs-2 distance-load d-flex justify-content-end shimmerBg m-r-15"></label>
																												</div>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																						})
																					}
																				</>
																			}
																		</>
																	}
																</>
														}
													</>
											}
										</>

										<div className="row">
											<div
												className="col-12"
												style={{
													backgroundColor: 'transparent',
													// position: 'absolute',
													// bottom: 0,
													height: divHeight
												}}
											>
											</div>
										</div>

									</div>
								</Scrollbars>
							</div>
						</>
				}

			</div>
			<div className={`filters pointer-none row w-100 justify-content-end align-items-end flex-direction-column ${filterBox ? 'active' : undefined}`}>
				<div className={`pointer-auto col-4 filter-box smooth-shadow`}>
					<div className="row w-100">
						<div className='filter-box-header'>
							<div className="filter-top">
								<div className={`wh-42 button d-flex justify-content-end p-0`} onClick={() => isFilterBox(false)}>
									<div className="wh-42 icon-color-gray icon-container icon-container-color-bg-3">
										<CrossIcon />
									</div>
								</div>
								<label className="fs-2 p-l-1 text-color">{props.translations[props.lang].filter.title}</label>
								<div className="p-r-0">
									<div className="d-flex flex-direction-row align-items pointer-auto pointer tags-text-icon dark ellipsis" onClick={() => clearFilters()}>
										<div className="wh-27">
											<CrossIconSmall />
										</div>
										<label>{props.translations[props.lang].filter.clearAll}</label>
									</div>
								</div>
							</div>
						</div>
						<div className='filter-box-content p-t-1'>
							<SpringScrollbars
								style={{
									backgroundColor: 'transparent',
									height: scrollHeightFilter,
									zIndex: 0
								}}
								thumbcolor='rgba(116, 125, 137, 0.2)'
							>
								<div className="">
									<div className={`m-b-0 active`}>
										<div className="row">
											<div className="d-flex flex-direction-column border-gray br-8">
												<div className="d-flex align-items justify-content-space-between">
													<div className="toggle-text">
														<label className="">{props.translations[props.lang].filter.location.search}</label>
													</div>
													<div
														className={`toggle-box ${props.filters.location ? "active" : undefined} pointer`}
														onClick={toggleLocation}
													>
														<div className={`toggle-thumb ${props.filters.location ? "active" : undefined}`}></div>
													</div>
												</div>
												{
													props.filters.location && <div className="d-flex flex-direction-column m-t-1">
														<div className="d-flex flex-direction-row align-items justify-content-space-between m-b-0">
															<div className="d-flex flex-direction-row align-items text-color-gray">
																<LocationIcon />
																<label className="">{props.translations[props.lang].filter.nearMe}</label>
															</div>
															<div
																className={`toggle-box ${props.filters.nearMe ? "active" : undefined} pointer`}
																onClick={toggleNearMe}
															>
																<div className={`toggle-thumb ${props.filters.nearMe ? "active" : undefined}`}></div>
															</div>
														</div>
														{
															!props.filters.nearMe && <div className="d-flex flex-direction-column">
																<div className="d-flex flex-direction-column align-items justify-content-space-between m-b-1">
																	<div className={`input-group light ${isBorderL && 'active'} br-8`}>
																		<input
																			ref={searchLocationInput}
																			type="text"
																			className={`form-control`}
																			placeholder={props.translations[props.lang].filter.locationPlaceholder}
																			value={searchLocationQuery}
																			onInput={e => setQuery(e.target.value)}
																			onFocus={onFocusL}
																			onBlur={onBlurL}
																		/>
																		<div className={`d-flex flex-direction-row`}>
																			<div className="wh-44" onClick={handleSearchLocationClick} >
																				<SearchIcon />
																			</div>
																		</div>
																	</div>
																	{
																		locationResult && locationResult.features && locationResult.features.length > 0 &&
																		<div className="d-flex flex-direction-column border-gray br-8 m-t-0">
																			{
																				locationResult.features.map((place, i) => {
																					return <div key={`${i}_place`} className={`location-box ${i !== 0 && "m-t-0"}`} onClick={() => setPlace(place.place_name, place.center)}>
																						<label className="text fs-15 pointer-none">{highlightedText(place.place_name, locationResult.query)}</label>
																					</div>
																				})
																			}
																		</div>
																	}
																</div>
															</div>
														}
														<div className="d-flex flex-direction-column align-items-start justify-content">
															<label className="fs-15 text-color-gray">{props.translations[props.lang].filter.radius}</label>
															<div className="slider-noui m-t-2">
																<SliderMerge
																	connect={'lower'}
																	start={50}
																	range={{ min: 0, max: 50 }}
																	value={searchRadius}
																	onChange={(value) => setSearchRadius(Number(value[0].replace('.00', '')))}
																	tooltipFormat={[{
																		decimals: 0,
																		suffix: ' km'
																	}]}
																	tooltips={true}
																/>
															</div>
														</div>
													</div>
												}
											</div>
											<div className="d-flex flex-direction-column border-gray br-8 m-t-1">
												<div className="d-flex align-items justify-content-space-between">
													<div className="toggle-text">
														<label className="">{props.translations[props.lang].filter.distance}</label>
													</div>
													<div
														className={`toggle-box ${props.filters.distance ? "active" : undefined} pointer`}
														onClick={toggleDistance}
													>
														<div className={`toggle-thumb ${props.filters.distance ? "active" : undefined}`}></div>
													</div>
												</div>
												{
													props.filters.distance && <div className="d-flex flex-direction-column m-t-1">
														<div className="d-flex flex-direction-column align-items-start justify-content">
															<label className="fs-15 text-color-gray">{props.translations[props.lang].filter.range}</label>
															<div className="slider-noui m-t-2">
																<SliderMerge
																	connect={true}
																	behaviour={'drag'}
																	start={[0, 40]}
																	range={{ min: 0, max: 40 }}
																	value={distanceRange}
																	onChange={(values) => setDistanceRange([Number(values[0].replace('.00', '')), Number(values[1].replace('.00', ''))])}
																	tooltipFormat={[
																		{
																			decimals: 0,
																			suffix: ` km`
																		},
																		{
																			decimals: 0,
																			suffix: ` km`
																		}
																	]}
																	tooltips={true}
																	threshold={13}
																	separator={' - '}
																/>
															</div>
														</div>
													</div>
												}
											</div>
											<div className="d-flex flex-direction-column border-gray br-8 m-t-1">
												<div className="d-flex align-items justify-content-space-between">
													<div className="toggle-text">
														<label className="">{props.translations[props.lang].filter.age}</label>
													</div>
													<div
														className={`toggle-box ${props.filters.age ? "active" : undefined} pointer`}
														onClick={toggleAge}
													>
														<div className={`toggle-thumb ${props.filters.age ? "active" : undefined}`}></div>
													</div>
												</div>
												{
													props.filters.age && <div className="d-flex flex-direction-column m-t-1">
														<div className="d-flex flex-direction-column align-items-start justify-content">
															<label className="fs-15 text-color-gray">{props.translations[props.lang].filter.range}</label>
															<div className="slider-noui m-t-2">
																<SliderMerge
																	connect={true}
																	behaviour={'drag'}
																	start={[0, 99]}
																	range={{ min: 0, max: 99 }}
																	value={ageRange}
																	onChange={(values) => setAgeRange([Number(values[0].replace('.00', '')), Number(values[1].replace('.00', ''))])}
																	tooltipFormat={[
																		{
																			decimals: 0,
																			suffix: `${props.translations[props.lang].filter.year}`
																		},
																		{
																			decimals: 0,
																			suffix: `${props.translations[props.lang].filter.year}`
																		}
																	]}
																	tooltips={true}
																	threshold={8}
																	separator={' - '}
																/>
															</div>
														</div>
													</div>
												}
											</div>
											<div className="d-flex flex-direction-column border-gray-0 br-8 m-t-1">
												<div className="d-flex flex-direction-row justify-content-space-between">
													<div className="toggle-text">
														<label className="">{props.translations[props.lang].filter.withGames}</label>
													</div>
													<div
														className={`pointer`}
														onClick={toggleWithGame}
													>
														{
															props.filters.withGame === true ? <CheckedIcon /> : <UnCheckedIcon />
														}
													</div>
												</div>
												<div className="d-flex flex-direction-row justify-content-space-between">
													<div className="toggle-text">
														<label className="">{props.translations[props.lang].filter.noGames}</label>
													</div>
													<div
														className={`pointer`}
														onClick={toggleNoGame}
													>
														{
															props.filters.noGame === true ? <CheckedIcon /> : <UnCheckedIcon />
														}
													</div>
												</div>
											</div>
											<div className="d-flex flex-direction-column border-gray-0 br-8 m-t-1">
												<div className="d-flex flex-direction-row justify-content-space-between">
													<div className="toggle-text">
														<label className="">{props.translations[props.lang].filter.withTimer}</label>
													</div>
													<div
														className={`pointer`}
														onClick={toggleWithTimer}
													>
														{
															props.filters.withTimer === true ? <CheckedIcon /> : <UnCheckedIcon />
														}
													</div>
												</div>
												<div className="d-flex flex-direction-row justify-content-space-between">
													<div className="toggle-text">
														<label className="">{props.translations[props.lang].filter.noTimer}</label>
													</div>
													<div
														className={`pointer`}
														onClick={toggleNoTimer}
													>
														{
															props.filters.noTimer === true ? <CheckedIcon /> : <UnCheckedIcon />
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</SpringScrollbars>
						</div>
						<div className='filter-box-footer p-3 d-flex justify-content-end'>
							<div className={`w-100 button d-flex justify-content-end p-0`} onClick={() => applyAndSearch()}>
								<label className="text text-color">{props.translations[props.lang].filter.apply}</label>
								<div className="icon-color-white icon-container icon-container-color-bg-accent-0">
									<OkIcon />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Courses;