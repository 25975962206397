import { MonoLightIcon, MonoDarkIcon } from '../Components/Icons';

export const themes = [
    {
        id: 0,
        key: 'monoLight',
        icon: MonoLightIcon,
    },
    {
        id: 1,
        key: 'monoDark',
        icon: MonoDarkIcon
    }
]

export const themeIcons = {
    monoLight: MonoLightIcon,
    monoDark: MonoDarkIcon,
}