import { useRef, useState } from "react"
import { DismissTagIcon, PlusIcon } from "./Icons"

function TagsInput(props) {

    const inputfield_us_en = useRef();
    const inputfield_be_nl = useRef();
    const inputfield_be_fr = useRef();

    const [isBorderT_us_en, setBorderT_us_en] = useState(null);
    const [isBorderT_be_nl, setBorderT_be_nl] = useState(null);
    const [isBorderT_be_fr, setBorderT_be_fr] = useState(null);

    function onFocusT_us_en() {
        setBorderT_us_en(true)
    }

    function onFocusT_be_nl() {
        setBorderT_be_nl(true)
    }

    function onFocusT_be_fr() {
        setBorderT_be_fr(true)
    }

    function onBlurT_us_en() {
        setBorderT_us_en(false)
    }

    function onBlurT_be_nl() {
        setBorderT_be_nl(false)
    }

    function onBlurT_be_fr() {
        setBorderT_be_fr(false)
    }

    function addTag() {

        if (inputfield_us_en.current.value !== '' && inputfield_be_nl.current.value !== '' && inputfield_be_fr.current.value !== '') {
            var courseNewCopy = { ...props.courseNew }
            courseNewCopy.tags = [...courseNewCopy.tags, { us_en: inputfield_us_en.current.value, be_nl: inputfield_be_nl.current.value, be_fr: inputfield_be_fr.current.value }]
            props.onChange(courseNewCopy)

            inputfield_us_en.current.value = '';
            inputfield_be_nl.current.value = '';
            inputfield_be_fr.current.value = '';
        } else {
            props.addToast({
                bgColor: 'toast-box-error',
                title: props.translations[props.lang].course.tags.error.title,
                msg: props.translations[props.lang].course.tags.error.msg,
                caption: props.translations[props.lang].course.tags.error.caption
            })
        }

    }

    function removeTag(index) {
        var courseNewCopy = { ...props.courseNew }
        var temp = [...courseNewCopy.tags];
        temp.splice(index, 1);
        courseNewCopy.tags = temp;
        props.onChange(courseNewCopy);
    }

    return (
        <>
            <div className={`row`}>
                <div className={`tags-input-row p-4 ${props.isEdit.status && 'active'}`}>
                    <div className="d-flex align-items">
                        <div className={`tags-input-container`}>
                            <div className="d-flex flex-direction-column">
                                <div className="d-flex flex-direction-row flex-wrap">
                                    {props.courseNew.tags.map((tag, index) => (
                                        <div className="tag-item-input d-flex flex-direction-row align-items m-r-0 m-b-005" key={index}>
                                            <div className="tag-item-input d-flex flex-direction-column">
                                                <span className="text p-r-0 d-flex align-items">
                                                    <span className="text p-r-0 text-color-gray fs-0">{props.translations.us_en.us_en}</span>
                                                    {tag.us_en}
                                                </span>
                                                <span className="text p-r-0 d-flex align-items">
                                                    <span className="text p-r-0 text-color-gray fs-0">{props.translations.be_nl.be_nl}</span>
                                                    {tag.be_nl}
                                                </span>
                                                <span className="text p-r-0 d-flex align-items">
                                                    <span className="text p-r-0 text-color-gray fs-0">{props.translations.be_fr.be_fr}</span>
                                                    {tag.be_fr}
                                                </span>
                                            </div>
                                            {
                                                props.isEdit.status && <div onClick={() => removeTag(index)}>
                                                    <DismissTagIcon />
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                                {
                                    props.isEdit.status && <>
                                        <div className="d-flex flex-direction-column m-t-1">
                                            <div className="text m-b-0">{props.translations[props.lang].course.tags.subtitle}</div>
                                            <div className={`input-group ${props.isEdit.status ? undefined : 'disabled'} ${isBorderT_us_en && 'active'} br-8 m-b-0`}>
                                                <span className={`input-group-text wh-44 p-none`}>
                                                    <label className="input-group-text">{props.translations.us_en.us_en}</label>
                                                </span>
                                                <input
                                                    ref={inputfield_us_en}
                                                    type="text"
                                                    className={`form-control`}
                                                    placeholder={props.placeholder.us_en}
                                                    onFocus={onFocusT_us_en}
                                                    onBlur={onBlurT_us_en}
                                                    disabled={props.isEdit.status ? false : true}
                                                />
                                                <span className={`input-group-text wh-44 p-none`}>
                                                    <props.flags.us_en />
                                                </span>
                                            </div>
                                            <div className={`input-group ${props.isEdit.status ? undefined : 'disabled'} ${isBorderT_be_nl && 'active'} br-8 m-b-0`}>
                                                <span className={`input-group-text wh-44 p-none`}>
                                                    <label className="input-group-text">{props.translations.be_nl.be_nl}</label>
                                                </span>
                                                <input
                                                    ref={inputfield_be_nl}
                                                    type="text"
                                                    className={`form-control`}
                                                    placeholder={props.placeholder.be_nl}
                                                    onFocus={onFocusT_be_nl}
                                                    onBlur={onBlurT_be_nl}
                                                    disabled={props.isEdit.status ? false : true}
                                                />
                                                <span className={`input-group-text wh-44 p-none`}>
                                                    <props.flags.be_nl />
                                                </span>
                                            </div>
                                            <div className={`input-group ${props.isEdit.status ? undefined : 'disabled'} ${isBorderT_be_fr && 'active'} br-8`}>
                                                <span className={`input-group-text wh-44 p-none`}>
                                                    <label className="input-group-text">{props.translations.be_fr.be_fr}</label>
                                                </span>
                                                <input
                                                    ref={inputfield_be_fr}
                                                    type="text"
                                                    className={`form-control`}
                                                    placeholder={props.placeholder.be_fr}
                                                    onFocus={onFocusT_be_fr}
                                                    onBlur={onBlurT_be_fr}
                                                    disabled={props.isEdit.status ? false : true}
                                                />
                                                <span className={`input-group-text wh-44 p-none`}>
                                                    <props.flags.be_fr />
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        props.isEdit.status && <div onClick={addTag} className="d-flex justify-content-end align-items">
                            <div className="add-button">
                                <PlusIcon />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default TagsInput