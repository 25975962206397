import { useContext } from 'react';
import { NavLink as Link } from "react-router-dom";

import { UserContext } from '../Hooks/UserContext';
import { AppLogo, SelectIcon, ProfileIcon, SettingsIcon, DashboardIcon, PhotoIcon, LockIcon } from './Icons';

function Menu(props) {

	const { user } = useContext(UserContext);

	const obscureEmail = (email) => {
		const [name, domain] = email.split("@");
		return `${name[0]}${name[1]}${new Array(4).join("*")}@${domain}`;
	};

	return (
		<>
			<div className={`sidebar ${ props.isMenu === true ? 'active' : ''}`}>
				<div className="sidebar-content">
					<div className="logo_content m-b-2">
						<Link to="/">
							<div className="logo">
								<div className="logo_name">
									<AppLogo />
								</div>
							</div>
						</Link>
						<div className={`arrow ${ props.isMenu === true ? 'active' : ''}`} id="btn" onClick={ props.toggleMenu }>
							<SelectIcon/>
						</div>
					</div>
					<div className="list">
						{
							user.status === 200 && user.roles.includes('admin') && <>
								<div className="menu-subtitle m-b-0 p-l-0">{ props.translations[props.lang].menu.home.manage }</div>
								<Link to="/Configurator">
									<div className="list-element m-b-2">
										<div className="row list-element-lock p-r-1">
											<div className="icon-conf">
												<div className="icon-bg">
													<PhotoIcon/>
												</div>
											</div>
											<div className="text-conf">
												<span className="links_name">{ props.translations[props.lang].menu.home.configurator }</span>
												<span className="tooltip">{ props.translations[props.lang].menu.home.configurator }</span>
											</div>
											<div className="lock-conf">
												<LockIcon/>
											</div>
										</div>
									</div>
								</Link>
							</>
						}
						<div className="menu-subtitle m-b-0 p-l-0">{ props.translations[props.lang].menu.home.orienteering }</div>
						<Link to="/Dashboard">
							<div className="list-element">
								<div className="icon-bg">
									<DashboardIcon/>
								</div>
								<div className="links_name_box">
									<span className="links_name">{ props.translations[props.lang].menu.home.dashboard }</span>
								</div>
								<span className="tooltip">{ props.translations[props.lang].menu.home.dashboard }</span>
							</div>
						</Link>
						{/* <Link to="/Organise">
							<div className="list-element">
								<div className="icon-bg">
									<OrientIcon/>
								</div>
								<div className="links_name_box">
									<span className="links_name">{ props.translations[props.lang].menu.home.organise }</span>
								</div>
								<span className="tooltip">{ props.translations[props.lang].menu.home.organise }</span>
							</div>
						</Link> */}
						{/* <Link to="/Events">
							<div className="list-element">
								<div className="icon-bg">
									<CalendarIcon/>
								</div>
								<div className="links_name_box">
									<span className="links_name">{ props.translations[props.lang].menu.home.events }</span>
								</div>
								<span className="tooltip">{ props.translations[props.lang].menu.home.events }</span>
							</div>
						</Link> */}
						{/* <div className="menu-subtitle m-t-2 m-b-0 p-l-0">{ props.translations[props.lang].menu.home.billing }</div>
						<Link to="/Invoices">
							<div className="list-element">
								<div className="icon-bg">
									<InvoiceIcon/>
								</div>
								<div className="links_name_box">
									<span className="links_name">{ props.translations[props.lang].menu.home.invoices }</span>
								</div>
								<span className="tooltip">{ props.translations[props.lang].menu.home.invoices }</span>
							</div>
						</Link> */}
					</div>
					<div className="list-bottom">
						<div className="menu-subtitle m-b-0 p-l-0">{ props.translations[props.lang].menu.home.account }</div>
						<Link to="/Settings">
							<div className="list-element">
								<div className="icon-bg">
									<SettingsIcon/>
								</div>
								<div className="links_name_box">
									<span className="links_name">{ props.translations[props.lang].menu.home.settings }</span>
								</div>
								<span className="tooltip">{ props.translations[props.lang].menu.home.settings }</span>
							</div>
						</Link>
						<Link to="/Profile">
							<div className="list-element">
								<div className="icon-bg">
									<ProfileIcon/>
								</div>
								<div className="links_name_box">
									<div className="name">{ user.firstname } { user.lastname }</div>
									<div className="email">{ user.status === 200 && obscureEmail(user.email) }</div>
								</div>
								<span className="tooltip">{ user.firstname } { user.lastname } | { user.status === 200 && obscureEmail(user.email) }</span>
							</div>
						</Link>
					</div>
				</div>
			</div>
			<div className="menu-mobile-home">
				<div className="menu-mobile-home-box smooth-shadow">
						{/* <div className="card-cl-2">
							<Link to="/Invoices">
								<div className="menu-mobile-card">
									<div className="button-icon icon-bg">
										<InvoiceIcon/>
									</div>
									<label className="text">Invoices</label>
								</div>
							</Link>
						</div> */}
						{
							user.status === 200 && user.roles.includes('admin') && <>
								<div className="card-cl-2">
									<Link to="/Configurator">
										<div className="menu-mobile-card">
											<div className="button-icon icon-bg">
												<PhotoIcon/>
												<div className="lock-conf-mobile">
													<LockIcon/>
												</div>
											</div>
											<label className="text">{ props.translations[props.lang].menu.home.configurator }</label>
										</div>
									</Link>
								</div>
							</>
						}
						<div className="card-cl-2">
							<Link to="/Dashboard">
								<div className="menu-mobile-card">
									<div className="button-icon icon-bg">
										<DashboardIcon/>
									</div>
									<label className="text">{ props.translations[props.lang].menu.home.dashboard }</label>
								</div>
							</Link>
						</div>
						<div className="card-cl-2">
							<Link to="/Profile">
								<div className="menu-mobile-card">
									<div className="button-icon icon-bg">
										<ProfileIcon/>
									</div>
									<label className="text">{ props.translations[props.lang].menu.home.profile }</label>
								</div>
							</Link>
						</div>
						<div className="card-cl-2">
							<Link to="/Settings">
								<div className="menu-mobile-card">
									<div className="button-icon icon-bg">
										<SettingsIcon/>
									</div>
									<label className="text">{ props.translations[props.lang].menu.home.settings }</label>
								</div>
							</Link>
						</div>
				</div>
			</div>
		</>
	);
}
export default Menu;