export const typeEnums = [
    {
        id: 0,
        key: "gps"
    },
    {
        id: 1,
        key: "qr"
    }
]

export const activeEnums = [
    {
        id: 0,
        key: "Pending"
    },
    {
        id: 1,
        key: "Active"
    },
    {
        id: 2,
        key: "Cancelled"
    },
    {
        id: 3,
        key: "Done"
    }
]