import Menu from './Menu';
import Select from "./Select";

function Settings(props) {

	return (
		<>
			<Menu 
				lang={props.lang}
				translations={props.translations}
				isMenu={props.isMenu}
				toggleMenu={props.toggleMenu}
			/>
			<div className="page-box home_content p-4">
				<div className="row p-4 row">
					<div className="col-12">
						<div className="title">
							<label>{ props.translations[props.lang].settings.title }</label>
						</div>
						<div className="subtitle">
							<label>{ props.translations[props.lang].settings.subtitle }</label>
						</div>
					</div>
				</div>

				<div className="m-b-1">
					<Select
						lang={props.lang}
						translations={props.translations}
						selected={props.theme}
						options={props.themes}
						changeOption={props.changeOption}
						type={'theme'}
						icons={props.themeIcons}
						disabled={false}
						theme={''}
					/>
				</div>
				<div className="">
					{/* <Select
						lang={props.lang}
						translations={props.translations}
						selected={props.lang}
						options={props.languages}
						changeOption={props.changeOption}
						type={'lang'}
						icons={null}
						disabled={false}
						theme={''}
					/> */}
				</div>
			</div>
		</>
	);
}
export default Settings;