import { useState, useEffect, useContext } from 'react';
import { NavLink as Link } from "react-router-dom";

import NavbarDesktop from './NavbarDesktop';
import { AppLogo, MenuIcon } from './Icons';
import Select from './Select';

import { UserContext } from '../Hooks/UserContext';

function Header(props) {

    const { user } = useContext(UserContext);

    const[isVisible, setVisibility] = useState(false);
    const toggleVisibility = () => setVisibility(!isVisible);

    useEffect(() => {
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    function handleClick (e) {
        if (e.target.id !== 'hamburger') {
            setVisibility(false)
        }
    }

	return (
		<>
            <div className="header">
                <div className="row">
                    <div className="col-12 d-flex justify-content align-items menu-desktop">
                        <Link to="/">
                            <div className="logo-content">
                                <div className="logo">
                                    <div className="logo-name">
                                        <AppLogo />
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <NavbarDesktop
                            lang={props.lang}
                            translations={props.translations}
                            selected={props.selected}
                            options={props.options}
                            changeOption={props.changeOption}
                            type={'lang'}
                            icons={props.flags}
                            disabled={false}
                        />
                        {/* <div className="col-8 d-flex justify-content">

                            <div className="dropdown">
                                <div className="dropbtn">
                                    <label>{ props.translations[props.lang].menu.solutions }</label>
                                </div>
                                <div className="dropdown-content smooth-shadow">
                                    <Link to="/Teambuilding">
                                        <label>{ props.translations[props.lang].menu.teambuilding }</label>
                                    </Link>
                                    <Link to="/Discover">
                                        <label>{ props.translations[props.lang].menu.discover }</label>
                                    </Link>
                                    <Link to="/Custom">
                                        <label>{ props.translations[props.lang].menu.custom }</label>
                                    </Link>
                                </div>
                            </div>
                            <div className="dropdown">
                                <Link to="/Pricing">
                                    <div className="dropbtn">
                                        <label>{ props.translations[props.lang].menu.pricing }</label>
                                    </div>
                                </Link>
                            </div>
                            <div className="dropdown">
                                <div className="dropbtn">
                                    <label>{ props.translations[props.lang].menu.about }</label>
                                </div>
                                <div className="dropdown-content smooth-shadow">
                                    <Link to="/Story">
                                        <label>{ props.translations[props.lang].menu.story }</label>
                                    </Link>
                                    <Link to="/Team">
                                        <label>{ props.translations[props.lang].menu.team }</label>
                                    </Link>
                                    <a href="mailto:hello@wandelar.be">{ props.translations[props.lang].menu.contact }</a>
                                </div>
                            </div>
                            
                            <Link className="d-block-inline" to="/HowItWorks">
                                <div className="button">
                                    <label>{ props.translations[props.lang].menu.howitworks }</label>
                                    <div></div>
                                </div>
                            </Link>
                            {
                                !props.isLoading && <Link className="d-block-inline" to="/Pricing">
                                    <div className="button">
                                        <label>{ props.translations[props.lang].menu.pricing }</label>
                                        <div></div>
                                    </div>
                                </Link>
                            }
                        </div> */}
                    </div>
                    <div className="menu-mobile">
                        <div className="row">
                            <div className="col-12 logo-menu-mobile p-2 w-100">
                                <Link to="/">
                                    <div className="logo-content">
                                        <div className="logo">
                                            <div className="logo-name">
                                                <AppLogo />
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                {/* <div id="hamburger" className="button" onClick={ toggleVisibility }>
                                    <label>{ props.translations[props.lang].menu.menu }</label>
                                    <MenuIcon />
                                </div> */}

                                <div className="dropdown">
                                    <div className="button-menu">
                                        <label>{ props.translations[props.lang].menu.menu }</label>
                                        <MenuIcon />
                                    </div>
                                    <div className="dropdown-content dropdown-position-right smooth-shadow">
                                        {/* <Link to="/Teambuilding">
                                            <label>{ props.translations[props.lang].menu.teambuilding }</label>
                                        </Link>
                                        <Link to="/Discover">
                                            <label>{ props.translations[props.lang].menu.discover }</label>
                                        </Link>
                                        <Link to="/Custom">
                                            <label>{ props.translations[props.lang].menu.custom }</label>
                                        </Link>
                                        <Link to="/Pricing">
                                            <label>{ props.translations[props.lang].menu.pricing }</label>
                                        </Link>
                                    
                                        <Link to="/Story">
                                            <label>{ props.translations[props.lang].menu.story }</label>
                                        </Link>
                                        <Link to="/Team">
                                            <label>{ props.translations[props.lang].menu.team }</label>
                                        </Link> */}
                                        {
                                        user.status === 200
                                        ? 
                                        <>
                                            <Link to="/Dashboard">
                                                <label>{ props.translations[props.lang].menu.hello } {user.firstname}</label>
                                            </Link>
                                        </>
                                        :
                                        <>
                                            <Link to="/Login">
                                                <label>{ props.translations[props.lang].menu.login }</label>
                                            </Link>
                                            <Link to="/Register">
                                                <label>{ props.translations[props.lang].menu.register }</label>
                                            </Link>
                                        </>
                                    }
                                        <a href="mailto:hello@wandelar.be">{ props.translations[props.lang].menu.contact }</a>
                                        <div className="d-flex justify-content">
                                            {/* <Select
                                                lang={props.lang}
                                                translations={props.translations}
                                                selected={props.selected}
                                                options={props.options}
                                                changeOption={props.changeOption}
                                                type={'lang'}
                                                icons={null}
                                                disabled={false}
                                                theme={props.theme}
                                            /> */}
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                    
            </div>
            
        </>
	);
}

export default Header;