import React, { useEffect, useRef, useState } from 'react';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { AddIcon, AppLogo, CopyIcon, DismissIcon, DownloadIcon, DragIcon, ElevationIcon, ErrorEmoji, FitRouteIcon, InfoIcon, MapStyleIcon, MarkerIcon, TrashIcon } from './Icons';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Geocoder from './Geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import $ from 'jquery';
import html2canvas from 'html2canvas';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import mapboxgl from 'mapbox-gl';

function MapStatic(props) {

    const mapContainer = useRef(null);
    const mapView = useRef(null);
    const [popupInfo, setPopupInfo] = useState(null);

    const mapStyles = {
        satellite: "mapbox://styles/mapbox/satellite-v9",
        wandelar: "mapbox://styles/maeroplane/clbcaemy7000a14nn8eo6o60b"
    }
    const [mapStyle, setMapStyle] = useState(mapStyles.wandelar);
    const [toggleStyles, setToggleStyles] = useState(true);
    const [togglePitch, setTogglePitch] = useState(false);

    let startPoint = props.checkpoints.length === 0 ? [0, 0] : props.checkpoints[0].geo.coordinates;

    const [center, setCenter] = useState([startPoint[0], startPoint[1]]);

    let bounds = [];
    for (var i = 0; i < props.checkpoints.length; i++) {
        let coordinates = [...props.checkpoints[i].geo.coordinates];
        coordinates.reverse();
        bounds.push(coordinates);
    }

    const [extremeBounds, setExtremeBounds] = useState(props.type === 'gps' ? calculateExtremeCoords(bounds) : null);

    const longitudesRef = useRef([]);
    const latitudesRef = useRef([]);
    const longitudesRefDesktop = useRef([]);
    const latitudesRefDesktop = useRef([]);

    const [addPin, setAddPin] = useState(false);
    const [pinLng, setPinLng] = useState(0);
    const [pinLat, setPinLat] = useState(0);

    const qrCodesRef = useRef([]);

    const qrCodeImg = useRef();
    const qrCodeImgSocial = useRef();

    const [_customLayer, setCustomLayer] = useState(null);

    function toggleMapStyle() {
        setToggleStyles(!toggleStyles);

        if (toggleStyles) {
            setMapStyle(mapStyles.satellite)
        } else {
            setMapStyle(mapStyles.wandelar)
        }
    }

    function togglePitchMap() {
        setTogglePitch(!togglePitch);
    }

    function mapEaseTo(pitch) {
        mapView.current.easeTo({ pitch: pitch })
    }

    function calculateExtremeCoords(coordinates) {
        let minX = Number.POSITIVE_INFINITY;
        let minY = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;

        coordinates.forEach(([x, y]) => {
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
        });

        return [
            extremeTopLeft(minX, minY),
            extremeBottomRight(maxX, maxY)
        ];
    };

    function extremeTopLeft(longitude, latitude) {
        let topCoordinate = latitude;
        let leftCoordinate = longitude;
        return [leftCoordinate, topCoordinate];
    }

    function extremeBottomRight(longitude, latitude) {
        let bottomCoordinate = longitude;
        let rightCoordinate = latitude;
        return [bottomCoordinate, rightCoordinate];
    }

    function fitBounds(minLng, minLat, maxLng, maxLat) {
        mapView.current.fitBounds(
            [
                [minLng, minLat],
                [maxLng, maxLat]
            ],
            { padding: { top: 80, bottom: 40, left: 20, right: 20 }, duration: 1000 }
        );
    }

    function onDragStart() {
        // console.log('on drag start')
    }

    function onDrag(e, index) {
        var courseNewCopy = { ...props.courseNew };
        courseNewCopy.checkpoints[index].geo = {
            type: "Point",
            coordinates: [e.lngLat.lng, e.lngLat.lat]
        };
        props.changeCourseNew(courseNewCopy);
    }

    function onDragEnd(e, index) {
        var courseNewCopy = { ...props.courseNew };
        courseNewCopy.checkpoints[index].geo = {
            type: "Point",
            coordinates: [e.lngLat.lng, e.lngLat.lat]
        };
        props.changeCourseNew(courseNewCopy);

        calculateDistanceAndDuration(courseNewCopy);
    }

    function centerRouteOnMap(courseNewCopy) {
        let bounds = [];
        for (var i = 0; i < courseNewCopy.checkpoints.length; i++) {
            let coordinates = [...courseNewCopy.checkpoints[i].geo.coordinates];
            coordinates.reverse();
            bounds.push(coordinates);
        }

        setExtremeBounds(calculateExtremeCoords(bounds));
    }

    const handleDrop = (droppedItem) => {
        // Do nothing when dropped outside of container
        if (!droppedItem.destination) return;
        var courseNewCopy = { ...props.courseNew };
        // Remove dragged checkpoint
        const [reorderedItem] = courseNewCopy.checkpoints.splice(droppedItem.source.index, 1);
        // Add dropped checkpoint
        courseNewCopy.checkpoints.splice(droppedItem.destination.index, 0, reorderedItem);
        props.changeCourseNew(courseNewCopy);

        centerRouteOnMap(courseNewCopy);
        calculateDistanceAndDuration(courseNewCopy);

        for (var i = 0; i < longitudesRef.current.length; i++) {
            let element = longitudesRef.current[i];
            element.classList.contains("active") && element.classList.remove("active")
        }
        for (var i = 0; i < latitudesRef.current.length; i++) {
            let element = latitudesRef.current[i];
            element.classList.contains("active") && element.classList.remove("active")
        }
        for (var i = 0; i < longitudesRefDesktop.current.length; i++) {
            let element = longitudesRefDesktop.current[i];
            element.classList.contains("active") && element.classList.remove("active")
        }
        for (var i = 0; i < latitudesRefDesktop.current.length; i++) {
            let element = latitudesRefDesktop.current[i];
            element.classList.contains("active") && element.classList.remove("active")
        }
    };

    function onFocusLng(index) {
        let element = longitudesRef.current[index];
        !element.classList.contains("active") && element.classList.add("active");

        let elementDesktop = longitudesRefDesktop.current[index];
        !elementDesktop.classList.contains("active") && elementDesktop.classList.add("active")

    }

    function onBlurLng(index) {
        let element = longitudesRef.current[index];
        element.classList.contains("active") && element.classList.remove("active");

        let elementDesktop = longitudesRefDesktop.current[index];
        elementDesktop.classList.contains("active") && elementDesktop.classList.remove("active")
    }

    function onFocusLat(index) {
        let element = latitudesRef.current[index];
        !element.classList.contains("active") && element.classList.add("active");

        let elementDesktop = latitudesRefDesktop.current[index];
        !elementDesktop.classList.contains("active") && elementDesktop.classList.add("active")
    }

    function onBlurLat(index) {
        let element = latitudesRef.current[index];
        element.classList.contains("active") && element.classList.remove("active");

        let elementDesktop = latitudesRefDesktop.current[index];
        elementDesktop.classList.contains("active") && elementDesktop.classList.remove("active")
    }

    function handleLongitudeChange(e, index) {
        var courseNewCopy = { ...props.courseNew };
        let split = courseNewCopy.checkpoints[index].geo.coordinates;

        props.courseNew.checkpoints[index].geo = {
            type: "Point",
            coordinates: [e.target.value, split[1]]
        };

        props.changeCourseNew(courseNewCopy);
    }

    function handleLatitudeChange(e, index) {
        var courseNewCopy = { ...props.courseNew };
        let split = courseNewCopy.checkpoints[index].geo.coordinates;

        props.courseNew.checkpoints[index].geo = {
            type: "Point",
            coordinates: [split[0], e.target.value]
        };

        props.changeCourseNew(courseNewCopy);
    }

    function addCheckpoint() {
        // setAddPin(false);

        if (props.courseNew.checkpoints.length >= 25) {
            props.addToast({
                bgColor: 'toast-box-error',
                title: props.translations[props.lang].course.checkpoints.check.title,
                msg: props.translations[props.lang].course.checkpoints.check.msg,
                caption: props.translations[props.lang].course.checkpoints.check.caption
            })
        } else {
            var courseNewCopy = { ...props.courseNew };
            courseNewCopy.checkpoints.push({
                geo: {
                    type: "Point",
                    coordinates: [parseFloat(mapView.current.getCenter().lat), parseFloat(mapView.current.getCenter().lng)]
                },
                qr: ""
            });
            props.changeCourseNew(courseNewCopy);

            centerRouteOnMap(courseNewCopy);
            calculateDistanceAndDuration(courseNewCopy)
        }
    }

    function removeCheckpoint(index) {
        var courseNewCopy = { ...props.courseNew };
        courseNewCopy.checkpoints.splice(index, 1);
        props.changeCourseNew(courseNewCopy);

        calculateDistanceAndDuration(courseNewCopy);
    }

    function calculateDistanceAndDuration(courseNewCopy) {

        if (courseNewCopy.checkpoints.length > 1) {
            let coordinates = '';
            for (var i = 0; i < courseNewCopy.checkpoints.length; i++) {

                let coordinate = [...courseNewCopy.checkpoints[i].geo.coordinates];
                if (i === courseNewCopy.checkpoints.length - 1) {
                    coordinates += `${coordinate[1]},${coordinate[0]}`
                } else {
                    coordinates += `${coordinate[1]},${coordinate[0]};`
                }
            }
            fetch(`https://api.mapbox.com/directions/v5/mapbox/walking/${coordinates}?access_token=${props.mapboxAccessToken}`)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data.routes[0].distance);
                    // console.log(data.routes[0].duration)
                    let dist = parseFloat((data.routes[0].distance / 1000).toFixed(3));
                    let locale = props.lang.split("_");
                    // setDistance(dist.toLocaleString(`${locale[0].toLowerCase()}-${locale[1].toUpperCase()}`));
                    setDistance(dist);
                    setDuration(parseFloat(data.routes[0].duration.toFixed(0)));
                });
        }
    }

    function setDistance(value) {
        var courseNewCopy = { ...props.courseNew };
        props.courseNew.distance = value;
        props.changeCourseNew(courseNewCopy);
    }

    function setDuration(value) {
        var courseNewCopy = { ...props.courseNew };
        courseNewCopy.estimation = value;
        props.changeCourseNew(courseNewCopy);
    }

    function centerPin() {
        setPinLng(mapView.current.getCenter().lng);
        setPinLat(mapView.current.getCenter().lat);
    }

    function convertSecondsToHrsMinSec(sec) {
        let hours = Math.floor(sec / 3600);
        let minutes = Math.floor((sec % 3600) / 60);
        let seconds = sec % 60;
        return `${hours.toFixed(0)}h ${minutes.toFixed(0)}m ${seconds.toFixed(0)}s`
    }

    function formatDistance(distanceInKm) {

        var distanceInMeters = parseFloat(distanceInKm) * 1000;

        var km = Math.floor(distanceInMeters / 1000);
        var meters = distanceInMeters % 1000;

        let formattedDistance = '';

        if (km === 0) {
            return meters.toFixed(0) + ' m';
        }

        return km.toFixed(0) + ' km ' + meters.toFixed(0) + ' m';
    }

    const download = async (svgString, name, fileType, index) => {

        let svg_file = new Blob([svgString], { type: "image/svg+xml" });

        const svgSize = { width: 512, height: 512 };

        const reader = new FileReader();
        reader.readAsText(svg_file);
        reader.onload = async (evt) => {
            const markup = reader.result;
            let svgDoc = new DOMParser().parseFromString(markup, 'image/svg+xml');
            const unit = 'px';
            $("svg", svgDoc).attr({
                'width': svgSize.width + unit,
                'height': svgSize.height + unit
            });
            const newMarkup = new XMLSerializer().serializeToString(svgDoc);

            let newDataURL;
            if (fileType === 'png') {
                qrCodesRef.current[index].src = `data:image/svg+xml;utf8,${encodeURIComponent(newMarkup)}`;
                const canvas = await html2canvas(qrCodesRef.current[index]);
                newDataURL = canvas.toDataURL('image/' + fileType);
            } else if (fileType === 'svg') {
                const newBlob = new Blob([newMarkup], { type: "image/svg+xml" });
                newDataURL = URL.createObjectURL(newBlob);
            }

            const link = document.createElement('a');
            let dateCreated = formatDate(new Date());

            if (typeof link.download === 'string') {
                link.href = newDataURL;
                link.download = `${dateCreated.date}-${dateCreated.time}-${name}.${fileType}`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                window.open(newDataURL);
            }
        };
    };

    const downloadQRCodeRoute = async (svgString, name, fileType, element) => {

        console.log(svgString)
        let svg_file = new Blob([svgString], { type: "image/svg+xml" });

        const svgSize = { width: 512 * window.devicePixelRatio, height: 512 * window.devicePixelRatio};

        console.log(window.devicePixelRatio)
        console.log(svgSize)
        const reader = new FileReader();
        reader.readAsText(svg_file);
        reader.onload = async (evt) => {
            const markup = reader.result;

            console.log(markup);

            let svgDoc = new DOMParser().parseFromString(markup, 'image/svg+xml');
            const unit = 'px';
            $("svg", svgDoc).attr({
                'width': svgSize.width,
                'height': svgSize.height
            });
            const newMarkup = new XMLSerializer().serializeToString(svgDoc);

            console.log(svgDoc)
            console.log(newMarkup)
            console.log(encodeURIComponent(newMarkup))

            let newDataURL;
            if (fileType === 'png') {
                element.current.src = `data:image/svg+xml;utf8,${encodeURIComponent(newMarkup)}`;
                console.log(element.current)
                console.log(element.current.src)
                const canvas = await html2canvas(element.current, { backgroundColor: null });
                console.log(canvas)
                newDataURL = canvas.toDataURL('image/' + fileType);
            } else if (fileType === 'svg') {
                const newBlob = new Blob([newMarkup], { type: "image/svg+xml" });
                newDataURL = URL.createObjectURL(newBlob);
            }

            console.log(newDataURL)

            const link = document.createElement('a');
            let dateCreated = formatDate(new Date());

            if (typeof link.download === 'string') {
                link.href = newDataURL;
                link.download = `${dateCreated.date}-${dateCreated.time}-${name}.${fileType}`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                window.open(newDataURL);
            }
        };
    };

    function formatDate(date) {
        return {
            date: date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2),
            time: ('0' + (date.getHours())).slice(-2) + '-' + ('0' + (date.getMinutes())).slice(-2) + '-' + ('0' + (date.getSeconds())).slice(-2)
        };
    }

    const onCopy = React.useCallback((value) => {
        props.addToast({
            bgColor: 'toast-box-success',
            title: props.translations[props.lang].course.copied.title,
            msg: value,
            caption: props.translations[props.lang].course.copied.caption
        })
    }, [])

    const convertUTC = utcDate => {

        const date = new Date(utcDate);

        const dayIndex = date.getDate();
        const dayName = props.translations[props.lang].days[date.getDay()];

        const monthIndex = date.getMonth(); // Be careful! January is 0, not 1
        const monthName = props.translations[props.lang].months[monthIndex];
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        const dateString = `${dayName}, ${dayIndex} ${monthName} ${year}`;
        const timeString = `${hours}h ${minutes}m ${seconds}s`;

        return [dateString, timeString];
    }

    useEffect(() => {
        if (mapView.current) {
            setPinLng(mapView.current.getCenter().lng);
            setPinLat(mapView.current.getCenter().lat);
        }
    }, [addPin])

    useEffect(() => {
        if (mapView.current) {
            if (togglePitch) {
                mapEaseTo(85);
                mapView.current.getMap().setLayoutProperty('3d-model', 'visibility', 'visible');
            } else {
                mapEaseTo(0);
                mapView.current.getMap().setLayoutProperty('3d-model', 'visibility', 'none');
            }
        }
    }, [togglePitch])

    useEffect(() => {

        if (props.elevation) {
            // parameters to ensure the model is georeferenced correctly on the map
            const modelOrigin = [props.elevation.terrain[0].lng, props.elevation.terrain[0].lat];

            // const modelAltitude = props.elevation.terrain[0].y;
            const modelAltitude = 0;
            const modelRotate = [0, 0, 0];

            const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
                modelOrigin,
                modelAltitude
            );

            const scale = modelAsMercatorCoordinate.meterInMercatorCoordinateUnits();

            // transformation parameters to position, rotate and scale the 3D model onto the map
            const modelTransform = {
                translateX: modelAsMercatorCoordinate.x,
                translateY: modelAsMercatorCoordinate.y,
                translateZ: modelAsMercatorCoordinate.z,
                rotateX: modelRotate[0],
                rotateY: modelRotate[1],
                rotateZ: modelRotate[2],
                /* Since the 3D model is in real world meters, a scale transform needs to be
                * applied since the CustomLayerInterface expects units in MercatorCoordinates.
                */
                scale: scale
            };

            const THREE = window.THREE;

            // configuration of the custom layer for a 3D model per the CustomLayerInterface
            const customLayer = {
                id: '3d-model',
                type: 'custom',
                renderingMode: '3d',
                onAdd: function (map, gl) {
                    this.camera = new THREE.Camera();
                    this.scene = new THREE.Scene();

                    // create two three.js lights to illuminate the model
                    const directionalLight = new THREE.DirectionalLight(0xffffff);
                    directionalLight.position.set(0, -70, 100).normalize();
                    this.scene.add(directionalLight);

                    const directionalLight2 = new THREE.DirectionalLight(0xffffff);
                    directionalLight2.position.set(0, 70, 100).normalize();
                    this.scene.add(directionalLight2);

                    // Create 3d model
                    if (props.elevation) {

                        let segments = props.elevation.terrain.length;
                        let height = 400;
                        let cylinderProps = {
                            radiusTop: 1000,
                            radiusBottom: 1000,
                            height: height,
                            radialSegments: segments,
                            heightSegments: 1,
                            openEnded: true
                        };
                        var cylinderGeometry = new THREE.CylinderGeometry(cylinderProps.radiusTop, cylinderProps.radiusBottom, cylinderProps.height, cylinderProps.radialSegments, cylinderProps.heightSegments, cylinderProps.openEnded);
                        var flatArray = cylinderGeometry.attributes.position.array;

                        var objArray = [];
                        for (var i = 0; i < flatArray.length; i += 3) {
                            objArray.push({ x: flatArray[i], y: flatArray[i + 1] + (height / 2), z: flatArray[i + 2] });
                        }

                        var objFlattenArray = [];
                        let terrain = props.elevation.terrain;
                        for (var i = 0; i < objArray.length; i++) {
                            if (i < terrain.length) {
                                // set position
                                let position = mapboxgl.MercatorCoordinate.fromLngLat(
                                    [props.elevation.terrain[i].lng, props.elevation.terrain[i].lat],
                                    props.elevation.terrain[i].y
                                );
                                objFlattenArray.push((position.x - modelAsMercatorCoordinate.x) / scale)
                                objFlattenArray.push((modelAsMercatorCoordinate.y - position.y) / scale)
                                objFlattenArray.push((position.z - modelAsMercatorCoordinate.z) / scale)

                            } else {
                                // set position
                                if (props.elevation.terrain[i - terrain.length]) {
                                    let position = mapboxgl.MercatorCoordinate.fromLngLat(
                                        [props.elevation.terrain[i - terrain.length].lng, props.elevation.terrain[i - terrain.length].lat],
                                        0
                                    );
                                    objFlattenArray.push((position.x - modelAsMercatorCoordinate.x) / scale)
                                    objFlattenArray.push((modelAsMercatorCoordinate.y - position.y) / scale)
                                    objFlattenArray.push((position.z - modelAsMercatorCoordinate.z) / scale)
                                }
                            }
                        }

                        var vertices = new Float32Array(objFlattenArray);
                        cylinderGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
                        cylinderGeometry.computeVertexNormals();
                        cylinderGeometry.computeBoundingBox();

                        let top = cylinderGeometry.boundingBox.max.z;

                        // Material wireframe
                        const mat_cylinder = new THREE.MeshPhongMaterial({ color: 0x0000ff, wireframe: true });

                        // Material gradient
                        const uniforms = {
                            opacity: { value: 1 },
                            color_bottom: { value: new THREE.Color('#4f46f8') },
                            height_bottom: { value: 0.0 },
                            color_top: { value: new THREE.Color('#4f46f8') },
                            height_top: { value: top },
                            height_half: { value: top * 1.0 }
                        };

                        var mat_gradient = new THREE.ShaderMaterial({
                            uniforms: uniforms,
                            vertexShader: `
                            varying vec3 vUv;
                            void main() {
                                vUv = position;
                                gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
                            }
                        `,
                            fragmentShader: `
                            uniform float opacity;

                            uniform vec3 color_bottom;
                            uniform float height_bottom;

                            uniform vec3 color_top;
                            uniform float height_top;

                            uniform float height_half;

                            varying vec3 vUv;

                            void main() {
                                
                                // y < 0 = transparent, > 1 = opaque
                                float alpha = smoothstep(height_bottom, height_half, vUv.z);

                                // y < 1 = color1, > 2 = color2
                                float colorMix = smoothstep(height_half, height_top, vUv.z);

                                gl_FragColor = vec4(mix(color_bottom, color_top, colorMix), alpha) * opacity;
                            }
                        `
                        });
                        mat_gradient.transparent = true;
                        mat_gradient.side = THREE.DoubleSide;

                        // Add mesh to scene
                        var cylinderMesh = new THREE.Mesh(cylinderGeometry, mat_gradient);

                        cylinderMesh.scale.set(1, 1, 3);
                        this.scene.add(cylinderMesh);

                        // var helper = new THREE.BoxHelper(mesh);
                        // this.scene.add(helper);

                        // const axesHelper = new THREE.AxesHelper( 5000 );
                        // this.scene.add( axesHelper );

                    }

                    this.map = map;

                    // use the Mapbox GL JS map canvas for three.js
                    this.renderer = new THREE.WebGLRenderer({
                        canvas: map.getCanvas(),
                        context: gl,
                        antialias: true
                    });

                    this.renderer.autoClear = false;
                },
                render: function (gl, matrix) {

                    const rotationX = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(1, 0, 0),
                        modelTransform.rotateX
                    );
                    const rotationY = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(0, 1, 0),
                        modelTransform.rotateY
                    );
                    const rotationZ = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(0, 0, 1),
                        modelTransform.rotateZ
                    );

                    const m = new THREE.Matrix4().fromArray(matrix);
                    const l = new THREE.Matrix4()
                        .makeTranslation(
                            modelTransform.translateX,
                            modelTransform.translateY,
                            modelTransform.translateZ
                        )
                        .scale(
                            new THREE.Vector3(
                                modelTransform.scale,
                                -modelTransform.scale,
                                modelTransform.scale
                            )
                        )
                        .multiply(rotationX)
                        .multiply(rotationY)
                        .multiply(rotationZ);

                    this.camera.projectionMatrix = m.multiply(l);
                    this.renderer.resetState();
                    this.renderer.render(this.scene, this.camera);
                    this.map.triggerRepaint();
                }
            };
            setCustomLayer(customLayer);
        }
    }, [])

    return (
        <>
            <div className="col-12 m-b-0">
                {
                    props.checkpoints.length > 1 && <>
                        <div className="d-flex flex-direction-row justify-content-space-between m-b-1 m-t-1">
                            <div className="d-flex flex-direction-column justify-content p-l-1">
                                <span className={`input-group-title`}>
                                    <label className="caption w-100 d-flex justify-content-start p-0 m-b-0">{props.translations[props.lang].course.distance}</label>
                                </span>
                                <label className="caption w-100 d-flex justify-content fs-15">{formatDistance(props.courseNew.distance)}</label>
                            </div>
                            <div className="d-flex flex-direction-column justify-content">
                                <div className="d-flex flex-direction-row justify-content align-items m-b-0 tooltip-down">
                                    <div className="text">{props.translations[props.lang].course.qrcode}</div>
                                    <div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.qrcodeExplain}`}>
                                        <InfoIcon />
                                    </div>
                                </div>
                                <div className="d-flex flex-direction-column justify-content align-items">
                                    <div className="channel-qr">
                                        <img
                                            className="wh-100 br-8"
                                            src={`data:image/svg+xml;utf8,${encodeURIComponent(props.courseNew.qrcode)}`}
                                        />
                                    </div>
                                    <div className="qrcode-text row d-flex justify-content align-items m-t-0">
                                        <div
                                            className="download-purple d-flex flex-direction-row"
                                            onClick={() => downloadQRCodeRoute(props.courseNew.qrcode, 'route-code', 'png', qrCodeImgSocial)}
                                        >
                                            <label className="label">{props.translations[props.lang].course.social} <span className="span">*.png</span></label>
                                            <div className="wh-27">
                                                <DownloadIcon />
                                            </div>
                                        </div>
                                        <div
                                            className="download"
                                            onClick={() => downloadQRCodeRoute(props.courseNew.qrcode, 'route-code', 'svg')}
                                        >
                                            <label>{props.translations[props.lang].course.download} <span>*.svg</span></label>
                                        </div>
                                        <div
                                            className="download"
                                            onClick={() => downloadQRCodeRoute(props.courseNew.qrcode, 'route-code', 'png', qrCodeImg)}
                                        >
                                            <label>{props.translations[props.lang].course.download} <span>*.png</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="qrcode large">
                                    <img
                                        ref={qrCodeImg}
                                        src={`data:image/svg+xml;utf8,${encodeURIComponent(props.courseNew.qrcode)}`}
                                    />
                                </div>
                                <div className="qrcode social">
                                    <div ref={qrCodeImgSocial} className="social-box p-4 text-align-center">
                                        <div className="p-4">
                                            <div className=" w-100 fs-social-header fw-0 multiline-ellipsis word-break text-align-center">{`${props.courseNew.user.username}`}</div>
                                        </div>
                                        <img
                                            src={`data:image/svg+xml;utf8,${encodeURIComponent(props.courseNew.qrcode)}`}
                                        />
                                        <div className="social-box-footer d-flex flex-direction-column justify-content align-items">
                                            <label className="fs-social-footer m-t-15 fw-0">{props.translations[props.lang].course.socialTag1}</label>
                                            <label className="fs-social-footer m-b-1 fw-0">{props.translations[props.lang].course.socialTag2}</label>
                                            {/* <div className="logo-content">
                                                <div className="logo">
                                                    <div className="logo-name">
                                                        <AppLogo />
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-direction-column justify-content p-r-1">
                                <span className={`input-group-title d-flex`}>
                                    <label className="caption w-100 d-flex justify-content-end p-0 m-b-0">{props.translations[props.lang].course.estimation}</label>
                                </span>
                                <label className="caption w-100 d-flex justify-content fs-15">{convertSecondsToHrsMinSec(props.courseNew.estimation)}</label>
                            </div>
                        </div>
                    </>
                }
                <div className="d-flex flex-direction-row align-items m-b-0 tooltip-down">
                    <div className="text">{props.translations[props.lang].course.checkpoints.title}</div>
                    <div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.checkpoints.explain}`}>
                        <InfoIcon />
                    </div>
                </div>
                <div className={`map-box w-100 p-3 ${props.isEdit.status ? 'active' : undefined}`}>
                    <div ref={mapContainer} className={`map-container ${props.isEdit.status ? 'active' : undefined} m-b-0`}>
                        <Map
                            ref={mapView}
                            mapboxAccessToken={props.mapboxAccessToken}
                            initialViewState={{
                                longitude: props.checkpoints.length === 0 ? 4.4699 : center[1],
                                latitude: props.checkpoints.length === 0 ? 50.5039 : center[0],
                                bounds: props.checkpoints.length !== 0 && [extremeBounds[0][0], extremeBounds[0][1], extremeBounds[1][0], extremeBounds[1][1]],
                                fitBoundsOptions: {
                                    padding: { top: 80, bottom: 40, left: 20, right: 20 }
                                },
                                zoom: extremeBounds === null ? 9 : 14
                            }}
                            mapStyle={mapStyle}
                            antialias={true}
                            scrollZoom={props.isEdit.status ? true : false}
                            dragRotate={props.isEdit.status ? true : false}
                            dragPan={props.isEdit.status ? true : false}
                            doubleClickZoom={props.isEdit.status ? true : false}
                            keyboard={props.isEdit.status ? true : false}
                            touchPitch={props.isEdit.status ? true : false}
                            touchZoomRotate={props.isEdit.status ? true : false}
                            cursor={props.isEdit.status ? 'grab' : 'default'}
                            onRender={addPin ? centerPin : undefined}
                            maxPitch={75}
                            onLoad={(map) => {
                                if (props.elevation) {
                                    map.target.addLayer(_customLayer);
                                    map.target.setLayoutProperty('3d-model', 'visibility', 'none');
                                }
                            }}
                        >
                            {
                                props.isEdit.status && <>
                                    {/* <GeolocateControl
                                        position={"bottom-right"}
                                        trackUserLocation={true}
                                        showAccuracyCircle={true}
                                        positionOptions={ { enableHighAccuracy:true, timeout:6000 } }
                                    /> */}
                                    <Geocoder
                                        lang={props.lang}
                                        translations={props.translations}
                                        mapboxAccessToken={props.mapboxAccessToken}
                                        position="top-left"
                                        marker={true}
                                        courseNew={props.courseNew}
                                        changeCourseNew={props.changeCourseNew}
                                        createIcon={{ name: 'search-icon', path: '<svg width="18px" height="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M8.613,15.961c4.058,0,7.347-3.29,7.347-7.347S12.671,1.266,8.613,1.266,1.266,4.555,1.266,8.613s3.29,7.347,7.347,7.347Z" fill="none" stroke="#363b42" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/><path d="M16.734,16.734l-1.547-1.547" fill="none" stroke="#363b42" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/></svg>' }}
                                    />
                                </>
                            }
                            {
                                extremeBounds !== null && !togglePitch && props.checkpoints.map((obj, index) => {
                                    let coordinates = obj.geo.coordinates;
                                    let str = null;
                                    let type;
                                    if (index === 0) {
                                        type = "startpoint";
                                    } else if (index > 0 && index < props.checkpoints.length - 1) {
                                        str = index;
                                        type = "checkpoint";
                                    } else if (index === props.checkpoints.length - 1) {
                                        type = "endpoint";
                                    }

                                    return (
                                        <Marker
                                            key={`marker-${index}`}
                                            longitude={coordinates[1]}
                                            latitude={coordinates[0]}
                                            anchor="bottom"
                                            draggable={props.isEdit.status ? true : false}
                                            onDragStart={onDragStart}
                                            onDrag={(e) => onDrag(e, index)}
                                            onDragEnd={(e) => onDragEnd(e, index)}
                                            onClick={e => {
                                                e.originalEvent.stopPropagation();

                                                props.isEdit.status && setPopupInfo({
                                                    type: type,
                                                    str: str,
                                                    longitude: coordinates[0],
                                                    latitude: coordinates[1]
                                                });
                                                props.isEdit.status && mapView.current.flyTo({
                                                    center: [coordinates[0], coordinates[1]],
                                                    bounds: extremeBounds !== null && extremeBounds.length > 0 && [extremeBounds[0][0], extremeBounds[0][1], extremeBounds[1][0], extremeBounds[1][1]],
                                                    fitBoundsOptions: {
                                                        padding: { top: 80, bottom: 40, left: 20, right: 20 }
                                                    },
                                                    duration: 1000
                                                });


                                            }}
                                            style={{ cursor: props.isEdit.status ? 'move' : 'default' }}
                                        >
                                            <MarkerIcon
                                                width={46}
                                                height={46}
                                                state={type}
                                                text={str}
                                                place={false}
                                                isEdit={props.isEdit}
                                                move={true}
                                            />
                                        </Marker>
                                    )
                                })
                            }
                            {
                                addPin && props.isEdit.status && <>
                                    <Marker
                                        longitude={pinLng}
                                        latitude={pinLat}
                                        anchor="bottom"
                                        style={{ cursor: props.isEdit.status ? 'default' : 'pointer' }}
                                    >
                                        <MarkerIcon
                                            width={46}
                                            height={46}
                                            state={"startpoint"}
                                            text={""}
                                            isEdit={props.isEdit}
                                            place={true}
                                        />
                                    </Marker>
                                </>
                            }
                            {/* {
                                popupInfo !== null && props.isEdit.status && <Popup
                                    longitude={popupInfo.longitude}
                                    latitude={popupInfo.latitude}
                                    anchor="top"
                                    closeButton={false}
                                    closeOnClick={false}
                                    closeOnMove={false}
                                    focusAfterOpen={true}
                                    offset={-5}
                                    className="marker-popup"
                                >
                                    <div className="d-flex flex-direction-column">
                                        <div className="w-100 d-flex flex-direction-row align-items justify-content-space-between m-b-0">
                                            <MarkerIcon
                                                width={46}
                                                height={46}
                                                state={popupInfo.type}
                                                text={popupInfo.str}
                                                isEdit={props.isEdit}
                                                place={false}
                                            />
                                            <div onClick={() => setPopupInfo(null)} className="map-popup-close-button">
                                                <DismissTagIcon />
                                            </div>
                                        </div>
                                        <label className="fs-1 text-gray m-b-0">{props.translations[props.lang].course.checkpoints[popupInfo.type]} {popupInfo.str}</label>
                                    </div>

                                </Popup>
                            } */}
                        </Map>
                    </div>
                    {
                        addPin && props.isEdit.status && <div className="w-100 d-flex justify-content align-items">
                            <div className="add-pin w-100">
                                <div className="subtitle m-b-0">{props.translations[props.lang].course.placePin.subtitle}</div>
                                <div className="w-100 d-flex flex-direction-row justify-content-space-between">
                                    <div className="button d-flex justify-content-start p-0 m-b-0" onClick={() => setAddPin(false)}>
                                        <div className="icon-color-white icon-container icon-container-color-bg-2">
                                            <DismissIcon />
                                        </div>
                                        <label className="text text-color-accent-0">{props.translations[props.lang].course.placePin.close}</label>
                                    </div>
                                    <div className="button d-flex justify-content-start p-0 m-b-0" onClick={() => addCheckpoint()}>
                                        <label className="text text-color-accent-0">{props.translations[props.lang].course.placePin.add}</label>
                                        <div className="icon-color-white icon-container icon-container-color-bg-accent-0">
                                            <AddIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        props.isEdit.status && props.checkpoints.length > 0 && !addPin && <div className="map-ui">
                            <div className="w-100 d-flex flex-direction-column">
                                {/* <div className="subtitle m-b-0">{ props.translations[props.lang].course.dragPin}</div> */}
                                <div className="w-100 d-flex flex-direction-row justify-content-space-between">
                                    <div className="button p-0" onClick={() => setAddPin(true)}>
                                        <div className="icon-color-white-1 icon-container icon-container-color-bg-accent-0">
                                            <AddIcon />
                                        </div>
                                        <label className="text text-color-accent-0">{props.translations[props.lang].course.placePin.title}</label>
                                    </div>
                                    <div className="d-flex flex-direction-row">
                                        {
                                            props.elevation && <div className="button p-0 m-l-0" onClick={() => togglePitchMap()}>
                                                <div className="icon-color icon-container icon-container-color-bg-2">
                                                    <ElevationIcon />
                                                </div>
                                            </div>
                                        }
                                        {
                                            props.checkpoints.length > 1 && <div className="button p-0" onClick={() => fitBounds(extremeBounds[0][0], extremeBounds[0][1], extremeBounds[1][0], extremeBounds[1][1])}>
                                                {/* <label className="text text-color-white-0">{ props.translations[props.lang].course.fitRoute }</label> */}
                                                <div className="icon-color icon-container icon-container-color-bg-2">
                                                    <FitRouteIcon />
                                                </div>
                                            </div>
                                        }
                                        <div className="button p-0 m-l-0" onClick={() => toggleMapStyle()}>
                                            <div className="icon-color icon-container icon-container-color-bg-2">
                                                <MapStyleIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className={'col-12'}>
                {
                    props.courseNew.checkpoints.length === 0 ?
                        <>
                            <div className="noRoutesFound">
                                {
                                    props.isEdit.status && !addPin && <>
                                        <div className="w-100 d-flex flex-direction-row justify-content-end"
                                            onClick={() => setAddPin(true)}
                                        >
                                            <div className="button d-flex justify-content-end p-0 m-t-1">
                                                <label className="text ">{props.translations[props.lang].course.placePin.title}</label>
                                                <div className="icon-color-white-1 icon-container icon-container-color-bg-accent-0">
                                                    <AddIcon />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <ErrorEmoji />
                                <label className="title m-b-1">{props.translations[props.lang].course.noCheckpointsTitle}</label>
                                {
                                    !props.isEdit.status && <>
                                        <label className="subtitle m-b-0">
                                            {props.translations[props.lang].course.noCheckpointsSubtitle2} </label>
                                    </>
                                }
                                {
                                    props.isEdit.status && <>
                                        <label className="subtitle">
                                            {props.translations[props.lang].course.noCheckpointsText1} </label>
                                        <label className="subtitle">
                                            {props.translations[props.lang].course.noCheckpointsText2} </label>
                                    </>
                                }
                            </div>
                        </>
                        :
                        <>
                            {/* <div className="row">
                                <div className="legend w-100 p-4">
                                    <label className="title m-b-0">
                                        {props.translations[props.lang].course.legend} </label>
                                    <label className="subtitle">
                                        {props.translations[props.lang].course.scrollToZoom} </label>
                                    <label className="subtitle">
                                        {props.translations[props.lang].course.clickAndDragMapToMove} </label>
                                    <label className="subtitle">
                                        {props.translations[props.lang].course.clickMapToAddPin} </label>
                                    <label className="subtitle">
                                        {props.translations[props.lang].course.clickAndDragPinToMove} </label>
                                </div>
                            </div> */}

                            {
                                props.isEdit.status ?
                                    <>
                                        <DragDropContext onDragEnd={handleDrop}>
                                            <Droppable droppableId="list-container">
                                                {(provided) => (
                                                    <div
                                                        className="list-container"
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {
                                                            props.courseNew.checkpoints.map((obj, index) => {
                                                                let coordinates = obj.geo.coordinates;
                                                                let str = null;
                                                                let type;
                                                                if (index === 0) {
                                                                    type = "startpoint";
                                                                } else if (index > 0 && index < props.checkpoints.length - 1) {
                                                                    str = index;
                                                                    type = "checkpoint";
                                                                } else if (index === props.checkpoints.length - 1) {
                                                                    type = "endpoint";
                                                                }

                                                                let qrCode = obj.qr;

                                                                return (
                                                                    <Draggable
                                                                        key={`checkpoint-${index}`}
                                                                        draggableId={`checkpoint-${index}`}
                                                                        index={index}
                                                                    >
                                                                        {(provided) => (
                                                                            <div
                                                                                className={`row item-container ${props.isEdit.status ? 'active' : undefined} ${props.courseNew.checkpoints.length - 1 !== index ? "m-b-0" : undefined}`}
                                                                                ref={provided.innerRef}
                                                                                {...provided.dragHandleProps}
                                                                                {...provided.draggableProps}
                                                                            >
                                                                                <div className={`${props.isEdit.status ? "col-12" : "col-6"}`}>
                                                                                    <div className={`w-100 d-flex flex-direction-column align-items`}>
                                                                                        <div className={`w-100 d-flex flex-direction-row justify-content-space-between align-items`}>
                                                                                            <div className="d-flex align-items rotate-90">
                                                                                                {
                                                                                                    props.isEdit.status && <DragIcon />
                                                                                                }
                                                                                            </div>
                                                                                            <div className="w-100 d-flex flex-direction-row align-items">
                                                                                                <MarkerIcon
                                                                                                    width={46}
                                                                                                    height={46}
                                                                                                    state={type}
                                                                                                    text={str}
                                                                                                    isEdit={props.isEdit}
                                                                                                    place={false}
                                                                                                />
                                                                                                <label className="fs-1 text-gray p-l-0 pointer-none">{props.translations[props.lang].course.checkpoints[type]} {str}</label>
                                                                                            </div>
                                                                                            {
                                                                                                props.isEdit.status && <>
                                                                                                    <div className={`coordinates-desktop w-100 d-flex flex-direction-row align-items`}>
                                                                                                        <div
                                                                                                            ref={el => longitudesRefDesktop.current[index] = el}
                                                                                                            className={`input-group ${!props.isEdit.status && 'disabled'} br-8`}
                                                                                                        >
                                                                                                            <span className={`input-group-text wh-44 p-none`}>
                                                                                                                <label className="input-group-text">{props.translations[props.lang].course.checkpoints.longitude.slice(0, 3)}.</label>
                                                                                                            </span>
                                                                                                            <input
                                                                                                                type="number"
                                                                                                                draggable={false}
                                                                                                                className={`form-control pointer-auto`}
                                                                                                                placeholder={props.translations[props.lang].course.checkpoints.longitudePlaceholder}
                                                                                                                value={coordinates[0]}
                                                                                                                onChange={(e) => handleLongitudeChange(e, index)}
                                                                                                                onClick={(e) => onFocusLng(index)}
                                                                                                                onBlur={(e) => onBlurLng(index)}
                                                                                                                disabled={props.isEdit.status ? false : true}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="m-l-0"></div>
                                                                                                        <div
                                                                                                            ref={el => latitudesRefDesktop.current[index] = el}
                                                                                                            className={`input-group ${props.isEdit.status ? undefined : 'disabled'} br-8`}
                                                                                                        >
                                                                                                            <span className={`input-group-text wh-44 p-none`}>
                                                                                                                <label className="input-group-text">{props.translations[props.lang].course.checkpoints.latitude.slice(0, 3)}.</label>
                                                                                                            </span>
                                                                                                            <input
                                                                                                                type="number"
                                                                                                                draggable={false}
                                                                                                                className={`form-control pointer-auto`}
                                                                                                                placeholder={props.translations[props.lang].course.checkpoints.latitudePlaceholder}
                                                                                                                value={coordinates[1]}
                                                                                                                onChange={(e) => handleLatitudeChange(e, index)}
                                                                                                                onClick={(e) => onFocusLat(index)}
                                                                                                                onBlur={(e) => onBlurLat(index)}
                                                                                                                disabled={props.isEdit.status ? false : true}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <CopyToClipboard onCopy={onCopy} text={`${coordinates[0]},${coordinates[1]}`}>
                                                                                                        <div className="dropzone-button m-l-0">
                                                                                                            <CopyIcon />
                                                                                                        </div>
                                                                                                    </CopyToClipboard>
                                                                                                </>
                                                                                            }
                                                                                            {
                                                                                                props.isEdit.status && <div className="dropzone-button m-l-0" onClick={() => removeCheckpoint(index)}>
                                                                                                    <TrashIcon />
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        {
                                                                                            props.isEdit.status && <div className={`coordinates-mobile w-100 d-flex flex-direction-row align-items`}>
                                                                                                <div
                                                                                                    ref={el => longitudesRef.current[index] = el}
                                                                                                    className={`input-group ${props.isEdit.status ? undefined : 'disabled'} br-8`}
                                                                                                >
                                                                                                    <span className={`input-group-text wh-44 p-none`}>
                                                                                                        <label className="input-group-text">{props.translations[props.lang].course.checkpoints.longitude.slice(0, 3)}.</label>
                                                                                                    </span>
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        draggable={false}
                                                                                                        className={`form-control pointer-auto`}
                                                                                                        placeholder={props.translations[props.lang].course.checkpoints.longitudePlaceholder}
                                                                                                        value={coordinates[0]}
                                                                                                        onChange={(e) => handleLongitudeChange(e, index)}
                                                                                                        onClick={(e) => onFocusLng(index)}
                                                                                                        onBlur={(e) => onBlurLng(index)}
                                                                                                        disabled={props.isEdit.status ? false : true}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="m-l-0"></div>
                                                                                                <div
                                                                                                    ref={el => latitudesRef.current[index] = el}
                                                                                                    className={`input-group ${props.isEdit.status ? undefined : 'disabled'} br-8`}
                                                                                                >
                                                                                                    <span className={`input-group-text wh-44 p-none`}>
                                                                                                        <label className="input-group-text">{props.translations[props.lang].course.checkpoints.latitude.slice(0, 3)}.</label>
                                                                                                    </span>
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        draggable={false}
                                                                                                        className={`form-control pointer-auto`}
                                                                                                        placeholder={props.translations[props.lang].course.checkpoints.latitudePlaceholder}
                                                                                                        value={coordinates[1]}
                                                                                                        onChange={(e) => handleLatitudeChange(e, index)}
                                                                                                        onClick={(e) => onFocusLat(index)}
                                                                                                        onBlur={(e) => onBlurLat(index)}
                                                                                                        disabled={props.isEdit.status ? false : true}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            })
                                                        }
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </>
                                    :
                                    <>
                                        <div className="row">
                                            <div className="d-flex flex-direction-row align-items m-b-0 tooltip-down">
                                                <div className="text">{props.translations[props.lang].course.qrcodes}</div>
                                                <div className="tooltip p-l-0" data-tooltip={`${props.translations[props.lang].course.qrcodesExplain}`}>
                                                    <InfoIcon />
                                                </div>
                                            </div>
                                            <div className={``}>
                                                {
                                                    props.courseNew.checkpoints.map((obj, index) => {
                                                        let qrCode = obj.qr;

                                                        return <div key={`qr-${index}`} className="route-box-3 p-4">
                                                            <div className="qrcode large w-100">
                                                                <img
                                                                    ref={el => qrCodesRef.current[index] = el}
                                                                    src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`}
                                                                />
                                                            </div>
                                                            <div className="qrcode w-100 d-flex justify-content align-items">
                                                                <img
                                                                    src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`}
                                                                />
                                                                <div className="qrcode-text row w-100  d-flex justify-content align-items m-t-0">
                                                                    <div
                                                                        className="download"
                                                                        onClick={() => download(qrCode, 'route-code', 'svg')}
                                                                    >
                                                                        <label>{props.translations[props.lang].course.download} <span>*.svg</span></label>
                                                                    </div>
                                                                    <div
                                                                        className="download"
                                                                        onClick={() => download(qrCode, 'route-code', 'png', index)}
                                                                    >
                                                                        <label>{props.translations[props.lang].course.download} <span>*.png</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                            }

                        </>
                }

            </div>
        </>
    )
}
export default MapStatic;