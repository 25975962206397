import { useState, useEffect, Fragment } from 'react';
import { /*HashRouter*/ BrowserRouter as Router, Routes, Route } from "react-router-dom"

import { UserContext } from './Hooks/UserContext';
import useFindUser from './Hooks/useFindUser';

import { themes, themeIcons } from './Data/themes';
import { translations, languages, flags } from './Data/lang';
import { sortOptions } from './Data/sort';
import { urls } from './Data/urls';
import { typeEnums, activeEnums } from './Data/routeEnums';

import Homepage from "./Components/Homepage";
import HowItWorks from './Components/HowItWorks';
import Pricing from "./Components/Pricing";
import Login from "./Components/Login";
import Register from "./Components/Register";
import Forgot from "./Components/Forgot";
import Toast from './Components/Toast';
import NotFound from './Components/NotFound';
import Welcome from './Components/Welcome';
import Invite from './Components/Invite';
import Reset from './Components/Reset';
import Unsubscribe from './Components/Unsubscribe';

import Configurator from "./Components/Configurator";
import Dashboard from "./Components/Dashboard";
import Organise from "./Components/Organise";
import Events from "./Components/Events";
import Invoices from "./Components/Invoices";
import Settings from "./Components/Settings";
import Profile from "./Components/Profile";

import Course from "./Components/Course";

import Private from './Components/Private';

import './Styles/Styles.css';

import { ParallaxProvider } from 'react-scroll-parallax';
import PrivacyPolicy from './Components/PrivacyPolicy';
import ScrollToTop from './Components/ScrollToTop';
import TermsAndConditions from './Components/TermsAndConditions';
import Channel from './Components/Channel';

function App() {

	const { user, setUser, isLoadingUser } = useFindUser();

	const [mapboxAccessToken, setMapboxAccessToken] = useState(`pk.eyJ1IjoibWFlcm9wbGFuZSIsImEiOiJjbDZ2eGR2MHowNXRqM2pwOTUydnJnaWE0In0.CSShO7uWJA4JF3jNpaufAQ`);

	const [isLoading, setIsLoading] = useState(true);
	const [priceData, setPriceData] = useState(null);

	const [isLoadingRoutes, setIsLoadingRoutes] = useState(false);

	var themeValue = localStorage.getItem("theme");
	if (themeValue === null) {
		themeValue = 'monoLight';
		localStorage.setItem('theme', themeValue);
	}

	var langValue = localStorage.getItem("lang");
	if (langValue === null) {
		langValue = 'be_nl';
		localStorage.setItem('lang', langValue);
	}

	var langValue = localStorage.getItem("lang");
	if (langValue === null) {
		langValue = 'be_fr';
		localStorage.setItem('lang', langValue);
	}

	var sortValue = localStorage.getItem("sortBy");
	if (sortValue === null) {
		sortValue = 'orderType_0';
		localStorage.setItem('sortBy', sortValue);
	}

	langValue = 'be_nl';

	var routeLayoutValue = localStorage.getItem("routeLayout");
	if (routeLayoutValue === null) {
		routeLayoutValue = 'grid';
		localStorage.setItem('routeLayout', routeLayoutValue);
	}

	const [theme, setTheme] = useState(themeValue);
	const [lang, setLang] = useState(langValue);
	const [sortBy, setSortBy] = useState(sortValue);
	const [routeLayout, setRouteLayout] = useState(routeLayoutValue);

	const [toasts, setToasts] = useState([]);

	const [isMenu, setIsMenu] = useState(true);

	const [privateRoutes, setPrivateRoutes] = useState([]);
	const [userRoutes, setUserRoutes] = useState([]);

	const [course, setCourse] = useState(null);
	const [courseNew, setCourseNew] = useState(null);

	const toggleMenu = () => {
		setIsMenu(!isMenu)
	}

	const [order, setOrder] = useState({
		email: '',
		phone: '',
		theme: 0,
		participants: 1,
		products: [],
		participantsPrice: 0,
		tier: 0,
		total: 0,
	});

	const [login, setLogin] = useState({
		email: '',
		password: '',
	});

	const [search, setSearch] = useState({
		lang: lang,
		page: 0,
		limit: 10,
		query: "",
		location: {
			coordinates: null,
			radius: 50
		},
		distance: {
			range: null
		},
		age: {
			range: null
		},
		game: null,
		timer: null
	});

	const [filters, setFilters] = useState({
		location: false,
		nearMe: false,
		distance: false,
		age: false,
		withGame: false,
		noGame: false,
		withTimer: false,
		noTimer: false
	});

	const [searchUser, setSearchUser] = useState({
		lang: lang,
		page: 0,
		limit: 10,
		query: "",
		location: {
			coordinates: null,
			radius: 50
		},
		distance: {
			range: null
		},
		age: {
			range: null
		},
		game: null,
		timer: null
	});

	const [isEnd, setIsEnd] = useState(false);
	const [isEndUser, setIsEndUser] = useState(false);

	const [scrollPosition, setScrollPosition] = useState(0);
	const [scrollPositionUser, setScrollPositionUser] = useState(0);

	const [register, setRegister] = useState({
		firstname: '',
		lastname: '',
		email: '',
		password: '',
		privacy: false,
		terms: false
	});

	const [forgot, setForgot] = useState({
		email: '',
	});

	const [reset, setReset] = useState({
		password: '',
	});

	function addToast(data) {
		let toast = {
			id: toasts.length,
			bgColor: data.bgColor,
			title: data.title,
			msg: data.msg,
			caption: data.caption
		}
		setToasts([...toasts, toast])
	}

	function removeToast(id) {

		var temp = [...toasts];
		temp.splice(id, 1);

		for (var i = 0; i < temp.length; i++) {
			temp[i].id = i;
		}

		setToasts(temp)
	}

	useEffect(() => {
		document.documentElement.className = '';
		document.documentElement.classList.add(`theme-${theme}`);

		// // Get price data
		// const getPriceData = fetch('https://wandelar.be/api/prices')
		// .then(response => {
		// 	return response.json()
		// });

		// Promise.all([ getPriceData ]).then((responses) => {
		// 	setIsLoading(false);
		// 	setPriceData(responses[0]);
		// });

	}, [])

	const changeRegister = (data) => {
		setRegister(data)
	}

	const changeLogin = (data) => {
		setLogin(data)
	}

	const changeForgot = (data) => {
		setForgot(data)
	}

	const changeReset = (data) => {
		setReset(data)
	}

	const changeOrder = (order) => {

		var result = calculatePrice(order);

		var orderCopy = { ...order }
		orderCopy.participantsPrice = result.participantsPrice;
		orderCopy.total = result.total;
		orderCopy.tier = result.tier;

		setOrder(orderCopy)
	}

	const changeOption = (selected, type) => {

		if (type === 'theme') {
			setTheme(selected)
			document.documentElement.className = '';
			document.documentElement.classList.add(`theme-${selected}`);
			localStorage.setItem('theme', selected)
		}

		if (type === 'lang') {
			for (const [key, value] of Object.entries(languages)) {
				if (selected === value.key) {
					setLang(selected)
					localStorage.setItem('lang', selected)
				}
			}
		}

		if (type === 'sort') {
			setSortBy(selected)
			localStorage.setItem('sortBy', selected)
		}

		if (type === 'type') {
			var courseNewCopy = { ...courseNew }
			courseNewCopy.type = selected
			changeCourseNew(courseNewCopy)
		}

		if (type === 'active') {
			var courseNewCopy = { ...courseNew }
			courseNewCopy.active = selected
			changeCourseNew(courseNewCopy)
		}

	}

	const changeCourse = async (dataCourse) => {
		setCourse(dataCourse)
	}

	const changeCourseNew = (dataCourseNew) => {
		setCourseNew(dataCourseNew)
	}

	function calculateSubTotal(order, key) {

		var participantsPrice = parseInt(order.participants) * priceData.tiers[key]['tier_' + key].price;
		var themePrice = priceData.themes[order.theme]['theme_' + order.theme].price;

		var productPrice = 0
		for (var i = 0; i < order.products.length; i++) {
			var key = order.products[i];
			productPrice = productPrice + priceData.products[key]['product_' + key].price;
		}

		var total = themePrice + productPrice + participantsPrice;

		return {
			participantsPrice: participantsPrice,
			total: total,
			tier: key
		}
	}

	const calculatePrice = (order) => {
		let numOfParticipants = parseInt(order.participants);

		for (let key in priceData.tiers) {
			let tier = priceData.tiers[key]['tier_' + key];
			if (numOfParticipants >= tier.min && numOfParticipants <= tier.max) return calculateSubTotal(order, key);
		}
		return {
			participantsPrice: 0,
			total: 0,
			tier: 0
		};
	}

	const getRoutes = async () => {
		if (!isEnd) {
			setIsLoadingRoutes(true);
			const routes = await fetch('https://wandelar.be/api/routes/admin', {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${user.token}`
				},
				body: JSON.stringify(search)
			}).then(response => {
				return response.json()
			}).then(result => {

				if (result.length === 0) {
					setIsEnd(true)
				} else {
					setPrivateRoutes([...privateRoutes, ...result])
					let body = { ...search }
					body["page"] = body.page + body.limit;
					setSearch(body);
					setIsEnd(false);
				}
				setIsLoadingRoutes(false);

				// console.log(privateRoutes)
			});
		}
	}

	const getRoutesUser = async () => {
		if (!isEndUser) {
			setIsLoadingRoutes(true);
			const routes = await fetch('https://wandelar.be/api/routes/user', {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${user.token}`
				},
				body: JSON.stringify(searchUser)
			}).then(response => {
				return response.json()
			}).then(result => {

				if (result.length === 0) {
					setIsEndUser(true)
				} else {
					setUserRoutes([...userRoutes, ...result])
					let body = { ...searchUser }
					body["page"] = body.page + body.limit;
					setSearchUser(body);
					setIsEndUser(false);
				}
				setIsLoadingRoutes(false);

				// console.log(userRoutes)
			});
		}
	}

	const resetRoutes = async () => {

		let body = {
			lang: lang,
			page: 0,
			limit: 10,
			query: "",
			location: {
				coordinates: null,
				radius: 50
			},
			distance: {
				range: null
			},
			age: {
				range: null
			},
			game: null,
			timer: null
		}

		const routes = await fetch('https://wandelar.be/api/routes/admin', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${user.token}`
			},
			body: JSON.stringify(body)
		}).then(response => {
			return response.json()
		}).then(result => {
			setPrivateRoutes([...result])
			body["page"] = body.page + body.limit;
			setSearch(body);
			setIsEnd(false);
		});

	}

	const resetRoutesUser = async () => {

		let body = {
			lang: lang,
			page: 0,
			limit: 10,
			query: "",
			location: {
				coordinates: null,
				radius: 50
			},
			distance: {
				range: null
			},
			age: {
				range: null
			},
			game: null,
			timer: null
		}

		const routes = await fetch('https://wandelar.be/api/routes/user', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${user.token}`
			},
			body: JSON.stringify(body)
		}).then(response => {
			return response.json()
		}).then(result => {

			setUserRoutes([...result])
			body["page"] = body.page + body.limit;
			setSearchUser(body);
			setIsEndUser(false);
		});

	}

	const filterRoutes = async (body) => {
		const routes = await fetch('https://wandelar.be/api/routes/admin', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${user.token}`
			},
			body: JSON.stringify(body)
		}).then(response => {
			return response.json()
		}).then(result => {
			setPrivateRoutes([...result])
			body["page"] = body.page + body.limit;
			setSearch(body);
			setIsEnd(false);
		});

	}

	const filterRoutesUser = async (body) => {

		const routes = await fetch('https://wandelar.be/api/routes/user', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${user.token}`
			},
			body: JSON.stringify(body)
		}).then(response => {
			return response.json()
		}).then(result => {

			setUserRoutes([...result])
			body["page"] = body.page + body.limit;
			setSearchUser(body);
			setIsEndUser(false);
		});

	}

	const resetSearch = () => {
		setSearch({
			lang: lang,
			page: 0,
			limit: 10,
			query: "",
			location: {
				coordinates: null,
				radius: 50
			},
			distance: {
				range: null
			},
			age: {
				range: null
			},
			game: null,
			timer: null
		})
	}

	const resetSearchUser = () => {
		setSearchUser({
			lang: lang,
			page: 0,
			limit: 10,
			query: "",
			location: {
				coordinates: null,
				radius: 50
			},
			distance: {
				range: null
			},
			age: {
				range: null
			},
			game: null,
			timer: null
		})
	}

	return (
		<ParallaxProvider>
			<div className="App">
				<Router>
					<ScrollToTop />
					<Fragment>
						<UserContext.Provider value={{ user, setUser, isLoadingUser }}>
							<Routes>
								<Route exact path="/" element={
									<Homepage
										lang={lang}
										translations={translations}
										isLoading={isLoading}
										theme={theme}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />
								<Route exact path="/:lang" element={
									<Homepage
										lang={lang}
										translations={translations}
										isLoading={isLoading}
										theme={theme}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />

								<Route path="/c/:username" element={
									<Channel
										lang={lang}
										translations={translations}
										isMenu={isMenu}
										toggleMenu={toggleMenu}
										theme={theme}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
										selected={sortBy}
										options={sortOptions}
										routeLayout={routeLayout}
										setRouteLayout={setRouteLayout}
										mapboxAccessToken={mapboxAccessToken}
									/>
								} />

								<Route path="/c/:username/:routeId" element={
									<Channel
										lang={lang}
										translations={translations}
										isMenu={isMenu}
										toggleMenu={toggleMenu}
										theme={theme}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
										selected={sortBy}
										options={sortOptions}
										routeLayout={routeLayout}
										setRouteLayout={setRouteLayout}
										mapboxAccessToken={mapboxAccessToken}
									/>
								} />

								<Route exact path="/HowItWorks" element={
									<HowItWorks
										lang={lang}
										translations={translations}
										isLoading={isLoading}
										theme={theme}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />
								<Route exact path="/Pricing" element={
									<Pricing
										lang={lang}
										translations={translations}
										order={order}
										changeOrder={changeOrder}
										priceData={priceData}
										addToast={addToast}
										isLoading={isLoading}
										theme={theme}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />
								<Route exact path="/Login" element={
									<Login
										lang={lang}
										translations={translations}
										login={login}
										changeLogin={changeLogin}
										addToast={addToast}
										isLoading={isLoading}
										theme={theme}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />
								<Route exact path="/Login/:lang" element={
									<Login
										lang={lang}
										translations={translations}
										login={login}
										changeLogin={changeLogin}
										addToast={addToast}
										isLoading={isLoading}
										theme={theme}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />
								<Route exact path="/Register" element={
									<Register
										lang={lang}
										theme={theme}
										translations={translations}
										register={register}
										changeRegister={changeRegister}
										addToast={addToast}
										isLoading={isLoading}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />
								<Route exact path="/Register/:lang" element={
									<Register
										lang={lang}
										theme={theme}
										translations={translations}
										register={register}
										changeRegister={changeRegister}
										addToast={addToast}
										isLoading={isLoading}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />
								<Route exact path="/Forgot" element={
									<Forgot
										lang={lang}
										theme={theme}
										translations={translations}
										forgot={forgot}
										changeForgot={changeForgot}
										addToast={addToast}
										isLoading={isLoading}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />

								<Route path='/Route/:id' element={
									<Course
										lang={lang}
										translations={translations}
										isMenu={isMenu}
										toggleMenu={toggleMenu}
										privateRoutes={privateRoutes}
										userRoutes={userRoutes}
										course={course}
										changeCourse={changeCourse}
										courseNew={courseNew}
										changeCourseNew={changeCourseNew}
										flags={flags}
										changeOption={changeOption}
										typeEnums={typeEnums}
										activeEnums={activeEnums}
										setUserRoutes={setUserRoutes}
										setPrivateRoutes={setPrivateRoutes}
										addToast={addToast}
										getRoutes={getRoutes}
										getRoutesUser={getRoutesUser}
										resetRoutes={resetRoutes}
										resetRoutesUser={resetRoutesUser}
										mapboxAccessToken={mapboxAccessToken}
									/>
								} />

								<Route exact path='/Configurator' element={<Private />}>
									<Route exact path='/Configurator' element={
										<Configurator
											lang={lang}
											translations={translations}
											isMenu={isMenu}
											toggleMenu={toggleMenu}
											routes={privateRoutes}
											changeCourse={changeCourse}
											changeCourseNew={changeCourseNew}
											selected={sortBy}
											options={sortOptions}
											changeOption={changeOption}
											routeLayout={routeLayout}
											setRouteLayout={setRouteLayout}
											addToast={addToast}
											setUserRoutes={setUserRoutes}
											setPrivateRoutes={setPrivateRoutes}
											search={search}
											setSearch={setSearch}
											getRoutes={getRoutes}
											isLoadingRoutes={isLoadingRoutes}
											scrollPos={scrollPosition}
											setScrollPos={setScrollPosition}
											resetRoutes={resetRoutes}
											resetRoutesUser={resetRoutesUser}
											filterRoutes={filterRoutes}
											setIsLoadingRoutes={setIsLoadingRoutes}
											filters={filters}
											setFilters={setFilters}
											mapboxAccessToken={mapboxAccessToken}
											resetSearch={resetSearch}
											resetSearchUser={resetSearchUser}
											role={'admin'}
											user={null}
										/>
									} />
								</Route>

								<Route exact path='/Dashboard' element={<Private />}>
									<Route exact path='/Dashboard' element={
										<Dashboard
											lang={lang}
											translations={translations}
											isMenu={isMenu}
											toggleMenu={toggleMenu}
											routes={userRoutes}
											changeCourse={changeCourse}
											changeCourseNew={changeCourseNew}
											selected={sortBy}
											options={sortOptions}
											changeOption={changeOption}
											routeLayout={routeLayout}
											setRouteLayout={setRouteLayout}
											addToast={addToast}
											setUserRoutes={setUserRoutes}
											setPrivateRoutes={setPrivateRoutes}
											search={searchUser}
											setSearch={setSearchUser}
											getRoutes={getRoutesUser}
											isLoadingRoutes={isLoadingRoutes}
											scrollPos={scrollPositionUser}
											setScrollPos={setScrollPositionUser}
											resetRoutes={resetRoutes}
											resetRoutesUser={resetRoutesUser}
											filterRoutes={filterRoutesUser}
											setIsLoadingRoutes={setIsLoadingRoutes}
											filters={filters}
											setFilters={setFilters}
											mapboxAccessToken={mapboxAccessToken}
											resetSearch={resetSearch}
											resetSearchUser={resetSearchUser}
											user={user}
										/>
									} />
								</Route>

								<Route exact path='/Organise' element={<Private />}>
									<Route exact path='/Organise' element={
										<Organise
											lang={lang}
											translations={translations}
											isMenu={isMenu}
											toggleMenu={toggleMenu}
										/>
									} />
								</Route>
								<Route exact path='/Events' element={<Private />}>
									<Route exact path='/Events' element={
										<Events
											lang={lang}
											translations={translations}
											isMenu={isMenu}
											toggleMenu={toggleMenu}
										/>
									} />
								</Route>

								<Route exact path='/Invoices' element={<Private />}>
									<Route exact path='/Invoices' element={
										<Invoices
											lang={lang}
											translations={translations}
											isMenu={isMenu}
											toggleMenu={toggleMenu}
										/>
									} />
								</Route>

								<Route exact path='/Settings' element={<Private />}>
									<Route exact path='/Settings' element={
										<Settings
											lang={lang}
											translations={translations}
											isMenu={isMenu}
											toggleMenu={toggleMenu}
											theme={theme}
											themes={themes}
											languages={languages}
											changeOption={changeOption}
											themeIcons={themeIcons}
											flags={flags}
										/>
									} />
								</Route>
								<Route exact path='/Profile' element={<Private />}>
									<Route exact path='/Profile' element={
										<Profile
											lang={lang}
											translations={translations}
											isMenu={isMenu}
											toggleMenu={toggleMenu}
											addToast={addToast}
										/>
									} />
								</Route>

								<Route exact path="/PrivacyPolicy" element={
									<PrivacyPolicy
										lang={lang}
										translations={translations}
										isLoading={isLoading}
										options={languages}
										changeOption={changeOption}
										flags={flags}
										theme={theme}
										themes={themes}
										languages={languages}
										themeIcons={themeIcons}
										urls={urls}
									/>
								} />

								<Route exact path="/TermsAndConditions" element={
									<TermsAndConditions
										lang={lang}
										translations={translations}
										isLoading={isLoading}
										options={languages}
										changeOption={changeOption}
										flags={flags}
										theme={theme}
										themes={themes}
										languages={languages}
										themeIcons={themeIcons}
										urls={urls}
									/>
								} />

								<Route exact path="/PrivacyPolicy/:lang" element={
									<PrivacyPolicy
										lang={lang}
										translations={translations}
										isLoading={isLoading}
										options={languages}
										changeOption={changeOption}
										flags={flags}
										theme={theme}
										themes={themes}
										languages={languages}
										themeIcons={themeIcons}
										urls={urls}
									/>
								} />

								<Route exact path="/TermsAndConditions/:lang" element={
									<TermsAndConditions
										lang={lang}
										translations={translations}
										isLoading={isLoading}
										options={languages}
										changeOption={changeOption}
										flags={flags}
										theme={theme}
										themes={themes}
										languages={languages}
										themeIcons={themeIcons}
										urls={urls}
									/>
								} />


								<Route path="/Welcome" element={
									<Welcome
										lang={lang}
										translations={translations}
										isMenu={isMenu}
										toggleMenu={toggleMenu}
										theme={theme}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />

								<Route path="/Welcome/:lang" element={
									<Welcome
										lang={lang}
										translations={translations}
										isMenu={isMenu}
										toggleMenu={toggleMenu}
										theme={theme}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />

								<Route path="/Invite/:routeId" element={
									<Invite
										lang={lang}
										translations={translations}
										isMenu={isMenu}
										toggleMenu={toggleMenu}
										theme={theme}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />

								<Route path="/Invite/:routeId/:routeIndex" element={
									<Invite
										lang={lang}
										translations={translations}
										isMenu={isMenu}
										toggleMenu={toggleMenu}
										theme={theme}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />

								<Route path="/Reset/:code" element={
									<Reset
										lang={lang}
										theme={theme}
										themes={themes}
										translations={translations}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
										reset={reset}
										changeReset={changeReset}
										addToast={addToast}
									/>
								} />
								<Route path="/Unsubscribe" element={
									<Unsubscribe
										lang={lang}
										theme={theme}
										themes={themes}
										translations={translations}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />
								<Route path='*' element={
									<NotFound
										lang={lang}
										translations={translations}
										isMenu={isMenu}
										toggleMenu={toggleMenu}
										theme={theme}
										themes={themes}
										languages={languages}
										changeOption={changeOption}
										themeIcons={themeIcons}
										flags={flags}
										urls={urls}
									/>
								} />
							</Routes>
						</UserContext.Provider>
					</Fragment>
				</Router>

				{
					toasts.length !== 0 && <Toast
						lang={lang}
						translations={translations}
						toasts={toasts}
						removeToast={removeToast}
					/>
				}

			</div>
		</ParallaxProvider>
	);
}

export default App;