// export const dataLayer = {
//     id: 'clusters',
//     type: 'circle',
//     filter: ['has', 'point_count'],
//     paint: {
//         'circle-color': [
//             'step',
//             ['get', 'point_count'],
//             '#ff4388', // Purple
//             100,
//             '#ff4388', // Purple
//             750,
//             '#4f46f4' // Red
//         ],
//         'circle-radius': [
//             'step',
//             ['get', 'point_count'],
//             20,
//             100,
//             30,
//             750,
//             40
//         ]
//     }
// };

export const dataLayer = {
    id: 'clusters',
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
        'icon-image': 'map-pin-empty',
        'icon-allow-overlap': true,
        'icon-anchor': 'bottom',
        'icon-size': 0.5
    }
};
export const nodeLayer = {
    id: 'node',
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
        'icon-image': 'node-pin-empty',
        'icon-allow-overlap': true,
        'icon-anchor': 'top',
        'icon-size': 0.5
    }
};

export const iconLayer = {
    id: 'wandelar-pin',
    type: 'symbol',
    filter: ['!', ['has', 'point_count']],
    layout: {
        'icon-image': 'map-pin',
        'icon-anchor': 'bottom',
        'icon-size': 0.5
    }
};

export const textLayer = {
    id: 'cluster-count',
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 18,
        'text-offset': [0, -1.3]
    },
    paint: {
        "text-color": "#000000"
    }
};

export const unclusteredLayer = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'earthquakes',
    filter: ['!', ['has', 'point_count']],
    paint: {
        'circle-color': '#ff4388',
        'circle-radius': 6,
        'circle-stroke-width': 3,
        'circle-stroke-color': '#fff'
    }
};

export const unclusteredShadowLayer = {
    id: "points_shadow",
    interactive: true,
    type: "circle",
    source: "geojson",
    paint: {
        "circle-radius": 16,
        "circle-color": "rgba(54,59,66, 0.5)",
        "circle-blur": 0.8
    },
    filter: ["in", "$type", "Point"]
}